// src/components/Sidebar2.js
import React, { useState, useEffect, useRef } from "react";
import { NavLink, useParams, Outlet } from "react-router-dom";
import "../css/sidebar2.css";

const Sidebar2 = React.memo(() => {
  const { courseId, courseName } = useParams();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const mountRef = useRef(false);

  useEffect(() => {
    if (!mountRef.current) {
      console.log("Sidebar2 컴포넌트 최초 마운트");
      mountRef.current = true;
    }
  }, []);

  const handleCurriculumClick = () => {};

  return (
    <div className="sidebar2">
      <ul>
        <li>
          <NavLink
            to={`/courses/${courseId}/curriculum/${encodeURIComponent(
              courseName
            )}`}
            end
            onClick={handleCurriculumClick}
          >
            Curriculum
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/courses/${courseId}/flashcards`}
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            Flashcards
          </NavLink>
        </li>
      </ul>
    </div>
  );
});

export default Sidebar2;
