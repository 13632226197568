import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTable } from "../contexts/TableContext";
import { useCurriculum } from "../contexts/CurriculumContext";
import "../css/SearchResultModal.css";

const SearchResultModal = ({ onResultClick }) => {
  const navigate = useNavigate();
  const { courseId, courseName } = useParams();
  const {
    searchResults,
    isSearchModalOpen,
    setIsSearchModalOpen,
    highlightSearchResult,
  } = useTable();

  // CurriculumContext에서 필요한 데이터와 함수 가져오기
  const { getSubunitNameById } = useCurriculum();

  const handleResultClick = (result) => {
    onResultClick(result);
    highlightSearchResult(result.matchText);
    setIsSearchModalOpen(false);
  };

  if (!isSearchModalOpen) return null;

  return (
    <div className="search-modal-overlay">
      <div className="search-modal">
        <div className="search-modal-header">
          <h3>Search Results ({searchResults.length})</h3>
          <button onClick={() => setIsSearchModalOpen(false)}>✕</button>
        </div>
        <div className="search-modal-content">
          {searchResults.length > 0 ? (
            searchResults.map((result, index) => (
              <div
                key={index}
                className="search-result-item"
                onClick={() => handleResultClick(result)}
              >
                <p className="search-result-content">{result.content}</p>
                <span className="search-result-location">
                  Subunit: {getSubunitNameById(result.subunitId)}
                </span>
              </div>
            ))
          ) : (
            <p className="no-results">No search results found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchResultModal;
