import React, { createContext, useState, useEffect, useContext } from "react";
import { collection, onSnapshot, doc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase/firebase";

const TaskContext = createContext();

export const TaskProvider = ({ children }) => {
  const [taskLists, setTaskLists] = useState([]);

  useEffect(() => {
    let unsubscribe;

    const initializeTasks = async () => {
      if (!auth.currentUser) return;

      // 단일 리스너로 변경
      const tasksRef = collection(db, "users", auth.currentUser.uid, "tasks");
      unsubscribe = onSnapshot(tasksRef, (snapshot) => {
        const tasksData = snapshot.docs.map((doc) => doc.data());
        setTaskLists(tasksData);
      });
    };

    initializeTasks();

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [auth.currentUser]);

  useEffect(() => {
    localStorage.setItem("taskLists", JSON.stringify(taskLists));
  }, [taskLists]);

  const toggleTask = (listIndex, taskIndex) => {
    setTaskLists((prevLists) => {
      const newLists = [...prevLists];
      newLists[listIndex].tasks[taskIndex].completed =
        !newLists[listIndex].tasks[taskIndex].completed;
      return newLists;
    });
  };

  const fetchTasks = async () => {
    const user = auth.currentUser;
    if (!user) return;

    const docRef = doc(db, `users/${user.uid}/todolist/myTodoList`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      setTaskLists(data.taskLists || []);
    }
  };

  return (
    <TaskContext.Provider
      value={{ taskLists, setTaskLists, toggleTask, fetchTasks }}
    >
      {children}
    </TaskContext.Provider>
  );
};

export const useTasks = () => useContext(TaskContext);
