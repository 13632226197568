// src/components/Topbar.js
import React, { useState } from "react";
import { FaSearch } from "react-icons/fa"; // 돋보기 아이콘 import
import { useNavigate } from "react-router-dom"; // useNavigate import 추가
import "../css/topbar.css"; // CSS 파일을 import

function Topbar({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate(); // useNavigate 훅 사용

  const handleSearch = () => {
    onSearch(searchTerm);
  };

  const handleLogoClick = () => {
    navigate("/dashboard"); // 대시보드 페이지로 이동하도록 수정
  };

  return (
    <div className="topbar">
      <div className="topbar-content">
        <div className="logo-container">
          <img
            src="/toolbaricons/inuse/home.svg"
            alt="Home"
            onClick={handleLogoClick}
            style={{
              cursor: "pointer",
              width: "24px",
              height: "24px",
              filter: "brightness(0) invert(1)", // SVG를 흰색으로 변경
            }}
          />
        </div>
        <div className="topbar-search-container">
          <input
            type="text"
            placeholder="Search..."
            className="topbar-search-input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && handleSearch()}
          />
          <button className="topbar-search-button" onClick={handleSearch}>
            <FaSearch className="topbar-search-icon" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Topbar;
