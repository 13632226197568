import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { useTable } from "react-table";
import {
  Editor,
  EditorState,
  RichUtils,
  ContentState,
  getDefaultKeyBinding,
  Modifier,
  SelectionState,
} from "draft-js";
import "draft-js/dist/Draft.css";
import "../css/AnotherComponent.css";
import { FontContext } from "../contexts/FontContext";
import { useLayout } from "../contexts/LayoutContext";
import { auth } from "../firebase/firebase"; // Firebase auth import
import { useTable as useTableContext } from "../contexts/TableContext";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";
import { unstable_batchedUpdates as batch } from "react-dom";

// styleMap을 동적으로 생성하는 함수를 추가합니다.
const createStyleMap = (color) => {
  const styleMap = {
    [`HIGHLIGHT_${color.replace("#", "")}`]: {
      backgroundColor: color,
    },
    [`COLOR_${color.replace("#", "")}`]: {
      color: color,
    },
  };
  return styleMap;
};

// 행 변환 함수 추가
const transformRow = (row) => {
  const newRow = {};
  Object.keys(row).forEach((colId) => {
    if (Array.isArray(row[colId])) {
      // 배열인 경우 (에디터 데이터)
      newRow[colId] = row[colId].map((editor) => ({
        content: editor.content || "",
        styleRanges: editor.styleRanges || [],
        ...editor,
      }));
    } else {
      // 배열이 아닌 경우
      newRow[colId] = [
        {
          content: typeof row[colId] === "string" ? row[colId] : "",
          styleRanges: [],
        },
      ];
    }
  });
  return newRow;
};

const AnotherComponent = () => {
  const { layout } = useLayout();
  const {
    saveTableData,
    loadTableData,
    setCurrentIds,
    updateWidths,
    updateEditorDivider,
    updateColumnWidths,
  } = useTableContext();

  const params = useParams();
  const { courseId, subunitId } = params;

  useEffect(() => {}, [courseId, subunitId]);

  // tableData 초기화 로직 수정
  const [tableState, setTableState] = useState({
    data: [],
    rowCount: 29,
    columns: [
      { Header: "", accessor: "col1" },
      { Header: "", accessor: "col2" },
    ],
    prevData: null,
  });

  // 이전에 선언된 editorState를 제거하고 새로운 이름으로 변경
  const [currentEditorState, setCurrentEditorState] = useState(
    EditorState.createEmpty()
  );

  const [visibilityState, setVisibilityState] = useState({
    firstColumn: false,
    secondColumn: false,
    rowByRow: false,
    rowByRowCol2: false,
  });

  const getTableClassName = () => {
    switch (layout) {
      case "layout2":
        return "table-layout-2";
      case "layout3":
        return "table-layout-3";
      case "layout4":
        return "table-layout-4";
      case "layout5":
        return "table-layout-5";
      case "layout6":
        return "table-layout-6";
      case "layout7":
        return "table-layout-7";
      case "layout8":
        return "table-layout-8";
      default:
        return "";
    }
  };

  const [rowCount, setRowCount] = useState(29);

  const [columns, setColumns] = useState([
    { Header: "", accessor: "col1" },
    { Header: "", accessor: "col2" },
  ]);
  // editorStates 초기화
  const [editorStates, setEditorStates] = useState({}); // 빈 객체로 시작

  const [isDragging, setIsDragging] = useState(false);
  const [startCell, setStartCell] = useState(null);
  const tableRef = useRef(null);
  const [cellValues, setCellValues] = useState({});
  const [isComposing, setIsComposing] = useState(false);
  const [splitCells, setSplitCells] = useState({});
  const [columnWidths, setColumnWidths] = useState(() => {
    const savedData = tableState?.columnWidths;
    return savedData || { col1: 20, col2: 80 };
  });
  const dividerRef = useRef(null);
  const tableContainerRef = useRef(null);
  const [isDraggingDivider, setIsDraggingDivider] = useState(false);
  const [activeDivider, setActiveDivider] = useState(null);
  const [isResizingColumn, setIsResizingColumn] = useState(false);
  const [isRemovingSplit, setIsRemovingSplit] = useState(false);
  const [isFirstColumnHidden, setIsFirstColumnHidden] = useState(false);
  const [isSecondColumnHidden, setIsSecondColumnHidden] = useState(false);
  const [visibleRows, setVisibleRows] = useState([]);
  const [isShowingRowByRow, setIsShowingRowByRow] = useState(false);
  const tableWidth = 700;
  const tableHeight = 900;
  const calculatedRowHeight = Math.floor(tableHeight / rowCount);
  const remainingHeight = tableHeight - calculatedRowHeight * rowCount;

  const [activeEditor, setActiveEditor] = useState(null);
  const [editorWidths, setEditorWidths] = useState({});

  const [showToolbar, setShowToolbar] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [toolbarPosition, setToolbarPosition] = useState({ top: 0, left: 0 });
  const [showHighlightColors, setShowHighlightColors] = useState(false);
  const [showFontColors, setShowFontColors] = useState(false);
  const [copiedCells, setCopiedCells] = useState(null);

  // styleMap 상태를 수정합니다.
  const [styleMap, setStyleMap] = useState({
    // 하이라이트 기본 스타일
    HIGHLIGHT_FFFCDB: { backgroundColor: "#FFFCDB" },
    HIGHLIGHT_FDEEDB: { backgroundColor: "#FDEEDB" },
    HIGHLIGHT_FEEFF6: { backgroundColor: "#FEEFF6" },
    HIGHLIGHT_F6ECF4: { backgroundColor: "#F6ECF4" },
    HIGHLIGHT_E9F6FE: { backgroundColor: "#E9F6FE" },
    HIGHLIGHT_EBF5EC: { backgroundColor: "#EBF5EC" },
    HIGHLIGHT_F4E9E3: { backgroundColor: "#F4E9E3" },

    // 폰트 컬러 기본 스타일
    COLOR_F24722: { color: "#F24722" },
    COLOR_FFA629: { color: "#FFA629" },
    COLOR_FFCD29: { color: "#FFCD29" },
    COLOR_15AE5C: { color: "#15AE5C" },
    COLOR_0D99FF: { color: "#0D99FF" },
    COLOR_9747FF: { color: "#9747FF" },
    COLOR_000000: { color: "#000000" },
    COLOR_FFFFFF: { color: "#FFFFFF" },

    // 텍스트 정렬 스타일 추가
    ALIGN_LEFT: { textAlign: "left" },
    ALIGN_CENTER: { textAlign: "center" },
    ALIGN_RIGHT: { textAlign: "right" },

    // superscript와 subscript 스타일 추가
    SUPERSCRIPT: {
      verticalAlign: "super",
      fontSize: "0.8em",
    },
    SUBSCRIPT: {
      verticalAlign: "sub",
      fontSize: "0.8em",
    },
  });

  const { font } = useContext(FontContext);

  // editorStyle을 수정니다.
  const editorStyle = {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontFamily: "var(--editor-font-family)",
    cursor: "text", // 에디터 내부에서만 텍스트 커서 사용
  };

  // 1. 디바운스 추가
  const handleSplitColumn = useCallback(() => {
    if (!activeEditor) return; // 조기 반환

    const [rowIndex, columnId, editorIndex] = activeEditor.split("-");
    const cellKey = `${rowIndex}-${columnId}`;
    const parsedEditorIndex = parseInt(editorIndex); // 한 번만 파싱

    setEditorWidths((prev) => {
      const currentWidths = prev[cellKey] || [100];
      const targetEditorWidth = currentWidths[parsedEditorIndex];

      if (!targetEditorWidth) return prev; // 유효하지 않은 경우 처리

      const splitWidth = targetEditorWidth / 2;
      const newWidths = [...currentWidths];
      newWidths[parsedEditorIndex] = splitWidth;
      newWidths.splice(parsedEditorIndex + 1, 0, splitWidth);

      return { ...prev, [cellKey]: newWidths };
    });
  }, [activeEditor]);

  // 2. 분할 작업 큐 추가
  const [splitQueue, setSplitQueue] = useState([]);
  const processSplitQueue = useCallback(() => {
    if (splitQueue.length > 0) {
      const currentSplit = splitQueue[0];
      handleSplitColumn(currentSplit);
      setSplitQueue((prev) => prev.slice(1));
    }
  }, [splitQueue, handleSplitColumn]);

  useEffect(() => {
    if (splitQueue.length > 0) {
      const timer = setTimeout(processSplitQueue, 50);
      return () => clearTimeout(timer);
    }
  }, [splitQueue, processSplitQueue]);

  // 3. 분할 요청 처리 함수
  const requestSplitColumn = () => {
    setSplitQueue((prev) => [...prev, activeEditor]);
  };

  useEffect(() => {
    const handleSplitColumn = () => {
      if (activeEditor) {
        // 기존의 handleSplitColumn 로직
      }
    };

    window.addEventListener("splitColumn", handleSplitColumn);
    return () => window.removeEventListener("splitColumn", handleSplitColumn);
  }, [activeEditor]);

  const [data, setData] = useState(
    Array(rowCount)
      .fill()
      .map(() => ({
        col1: [],
        col2: [],
      }))
  );

  const [prevTableData, setPrevTableData] = useState(data);

  useEffect(() => {
    if (!data || !courseId || !subunitId) return;

    // 1. 간단한 변경 감지
    const hasChanged = checkIfDataChanged(prevTableData, data);
    if (!hasChanged) return;

    // 2. 변경된 부분만 업데이트
    const dataToSave = {
      tableData: optimizeDataTransform(data),
      columnWidths,
      editorWidths,
      styleMap: styleMap || {},
    };

    setTableState(dataToSave);
    setPrevTableData(data);
  }, [data, courseId, subunitId]);

  // 변경 감지 최적화
  const checkIfDataChanged = (prev, current) => {
    const prevString = JSON.stringify(prev);
    const currentString = JSON.stringify(current);
    const hasChanged = prevString !== currentString;
    return hasChanged;
  };

  // 데이터 변환 최적화
  const optimizeDataTransform = (data) => {
    // 필요한 데이터만 변환
    return data.map((row) => transformRow(row));
  };

  const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleCellChange = useCallback((rowIndex, columnId, value) => {
    setCellValues((prev) => ({
      ...prev,
      [`${rowIndex}-${columnId}`]: value,
    }));
  }, []);

  const measurementRef = useRef(null);

  const handleDividerMouseDown = useCallback((e, cellKey, dividerIndex) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingDivider(true);
    setActiveDivider({ cellKey, dividerIndex });
  }, []);

  const handleDividerMouseMove = useCallback(
    (e) => {
      if (!isDraggingDivider || !activeDivider) return;

      const { cellKey, dividerIndex } = activeDivider;
      const cell = tableRef.current.querySelector(
        `td[data-cell-key="${cellKey}"]`
      );
      if (!cell) return;

      const cellRect = cell.getBoundingClientRect();
      const relativeX = e.clientX - cellRect.left;
      const cellWidth = cellRect.width;

      setSplitCells((prev) => {
        const widths = [...prev[cellKey]];
        const totalWidth = widths[dividerIndex] + widths[dividerIndex + 1];
        const newLeftWidth = (relativeX / cellWidth) * 100;
        const newRightWidth = totalWidth - newLeftWidth;

        widths[dividerIndex] = Math.max(10, Math.min(90, newLeftWidth));
        widths[dividerIndex + 1] = Math.max(10, Math.min(90, newRightWidth));

        return { ...prev, [cellKey]: widths };
      });
    },
    [isDraggingDivider, activeDivider]
  );

  const handleDividerMouseUp = useCallback(() => {
    setIsDraggingDivider(false);
    setActiveDivider(null);
  }, []);

  const handleColumnDividerMouseDown = (e) => {
    e.preventDefault();
    const startX = e.clientX;
    const startWidth = columnWidths.col1;
    let currentWidths = { ...columnWidths };

    const handleMouseMove = (moveEvent) => {
      const deltaX = moveEvent.clientX - startX;
      const containerWidth = tableRef.current.offsetWidth;
      const percentageDelta = (deltaX / containerWidth) * 100;
      const newCol1Width = Math.max(
        10,
        Math.min(90, startWidth + percentageDelta)
      );
      const newCol2Width = 100 - newCol1Width;

      currentWidths = {
        col1: newCol1Width,
        col2: newCol2Width,
      };

      setColumnWidths(currentWidths);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);

      // 마우스를 뗄 때 현재 상태의 너비를 사용
      if (courseId && subunitId) {
        updateColumnWidths(courseId, subunitId, currentWidths);
      }
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  useEffect(() => {
    if (isDraggingDivider) {
      document.addEventListener("mousemove", handleDividerMouseMove);
      document.addEventListener("mouseup", handleDividerMouseUp);
    }
    return () => {
      document.removeEventListener("mousemove", handleDividerMouseMove);
      document.removeEventListener("mouseup", handleDividerMouseUp);
    };
  }, [isDraggingDivider, handleDividerMouseMove, handleDividerMouseUp]);

  const handleTextSelection = useCallback((editorState) => {
    // 1. 빠른 초기 체크
    if (!editorState) return;

    const selection = editorState.getSelection();
    if (selection.isCollapsed()) {
      setShowToolbar(false);
      return;
    }

    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();

    // 2. 같은 블록 내 선택인 경우만 처리
    if (startKey === endKey) {
      const content = editorState.getCurrentContent();
      const block = content.getBlockForKey(startKey);
      const text = block
        .getText()
        .slice(selection.getStartOffset(), selection.getEndOffset());

      if (text) {
        // 3. 모든 상태 업데이트를 한번에 처리
        batch(() => {
          setSelectedText(text);
          setShowToolbar(true);

          // 4. DOM 측정은 필요할 때만
          const windowSelection = window.getSelection();
          if (windowSelection?.rangeCount > 0) {
            const range = windowSelection.getRangeAt(0);
            const rect = range.getBoundingClientRect();
            const tableRect = tableRef.current?.getBoundingClientRect();

            if (rect && tableRect) {
              setToolbarPosition({
                top: rect.top - tableRect.top - 60,
                left: rect.left - tableRect.left,
              });
            }
          }
        });
      }
    } else {
      setShowToolbar(false);
    }
  }, []); // 의존성 배열 추가

  const handleRemoveSplit = useCallback(() => {
    setIsRemovingSplit((prev) => !prev);
  }, []);

  const toggleFirstColumn = useCallback(() => {
    setIsFirstColumnHidden((prev) => !prev);
  }, [isFirstColumnHidden]);

  const toggleSecondColumn = useCallback(() => {
    setIsSecondColumnHidden((prev) => !prev);
  }, [isSecondColumnHidden]);

  const toggleShowRowByRow = useCallback(() => {
    if (isShowingRowByRow) {
      setIsShowingRowByRow(false);
      setVisibleRows([]);
      setIsFirstColumnHidden(false);
    } else {
      setIsShowingRowByRow(true);
      setVisibleRows([]);
      setIsFirstColumnHidden(true);
    }
  }, [isShowingRowByRow]);

  // 1. 상태 추가
  const [hiddenContentRowsCol1, setHiddenContentRowsCol1] = useState([]);
  const [hiddenContentRowsCol2, setHiddenContentRowsCol2] = useState([]);

  // 2. useEffect로 텍스트 있는 행 찾기
  useEffect(() => {
    const contentRowsCol1 = [];
    const contentRowsCol2 = [];

    //  번의 순회로 두 컬럼 모두 체크
    for (let i = 0; i < rowCount; i++) {
      // 각 컬럼의 내용을 독립적으로 체크
      if (hasCellContent(i, "col1")) {
        contentRowsCol1.push(i);
      }
      if (hasCellContent(i, "col2")) {
        contentRowsCol2.push(i);
      }
    }

    // 두 ��태를 한 번에 업데이트
    batch(() => {
      setHiddenContentRowsCol1(contentRowsCol1); // 컬럼1 데이터
      setHiddenContentRowsCol2(contentRowsCol2); // 컬럼2 데이터
    });
  }, [data, rowCount]);

  const showNextRow = useCallback(() => {
    if (isShowingRowByRow) {
      setVisibleRows((prev) => {
        const nextHiddenRow = hiddenContentRowsCol1.find(
          (row) => !prev.includes(row)
        );
        if (nextHiddenRow !== undefined) {
          return [...prev, nextHiddenRow];
        }
        return prev;
      });
    }
  }, [isShowingRowByRow, hiddenContentRowsCol1]);

  const [visibleRowsCol2, setVisibleRowsCol2] = useState([]);
  const [isShowingRowByRowCol2, setIsShowingRowByRowCol2] = useState(false);

  const toggleShowRowByRowCol2 = useCallback(() => {
    if (isShowingRowByRowCol2) {
      setIsShowingRowByRowCol2(false);
      setVisibleRowsCol2([]);
      setIsSecondColumnHidden(false);
    } else {
      setIsShowingRowByRowCol2(true);
      setVisibleRowsCol2([]);
      setIsSecondColumnHidden(true);
    }
  }, [isShowingRowByRowCol2]);

  const showNextRowCol2 = useCallback(() => {
    if (isShowingRowByRowCol2) {
      setVisibleRowsCol2((prev) => {
        const nextHiddenRow = hiddenContentRowsCol2.find(
          (row) => !prev.includes(row)
        );
        if (nextHiddenRow !== undefined) {
          return [...prev, nextHiddenRow];
        }
        return prev;
      });
    }
  }, [isShowingRowByRowCol2, hiddenContentRowsCol2]);

  const handleDividerClick = (e, rowIndex, columnId, editorIndex) => {
    if (isRemovingSplit) {
      e.stopPropagation();
      const cellKey = `${rowIndex}-${columnId}`;

      // 에디터 상태 업데이트
      setEditorStates((prev) => {
        const newStates = { ...prev };
        const leftKey = `${rowIndex}-${columnId}-${editorIndex}`;
        const rightKey = `${rowIndex}-${columnId}-${editorIndex + 1}`;

        if (newStates[leftKey] && newStates[rightKey]) {
          const leftState = newStates[leftKey];
          const rightState = newStates[rightKey];
          const leftContent = leftState.getCurrentContent().getPlainText();
          const rightContent = rightState.getCurrentContent().getPlainText();
          const mergedContent = ContentState.createFromText(
            leftContent + rightContent
          );
          const newEditorState = EditorState.createWithContent(mergedContent);

          newStates[leftKey] = newEditorState;
          delete newStates[rightKey];
        }
        return newStates;
      });

      // 에디터 너비 업데이트
      const newEditorWidths = { ...editorWidths };
      const currentWidths = newEditorWidths[cellKey] || [100];
      currentWidths[editorIndex] =
        currentWidths[editorIndex] + currentWidths[editorIndex + 1];
      currentWidths.splice(editorIndex + 1, 1);
      newEditorWidths[cellKey] = currentWidths;

      // 테이블 데이터 업데이트
      // 필요한 부분만 복사
      const newData = {
        ...data, // 최상위 레벨
        [rowIndex]: {
          // 수정할 행만
          ...data[rowIndex], // 행의 기존 데이터
          [columnId]: [
            // 수정할 열만
            ...data[rowIndex][columnId], // 열의 기존 데이터
          ],
        },
      };
      const leftContent =
        newData[rowIndex]?.[columnId]?.[editorIndex]?.content || "";
      const rightContent =
        newData[rowIndex]?.[columnId]?.[editorIndex + 1]?.content || "";

      // 데이터 배열이 존재하지 않는 경우 초기화
      if (!Array.isArray(newData[rowIndex][columnId])) {
        newData[rowIndex][columnId] = [];
      }

      if (!newData[rowIndex][columnId][editorIndex]) {
        newData[rowIndex][columnId][editorIndex] = { content: "" };
      }

      newData[rowIndex][columnId][editorIndex].content =
        leftContent + rightContent;
      newData[rowIndex][columnId].splice(editorIndex + 1, 1);

      // Firebase 업데이트
      updateEditorDivider(courseId, subunitId, newEditorWidths, newData);

      setIsRemovingSplit(false);
    }
  };

  // 1. 이벤트 맵 생성 - useMemo로 메모이제이션
  const eventMap = useMemo(
    () => ({
      splitColumn: handleSplitColumn,
      toggleFirstColumn: toggleFirstColumn,
      toggleShowRowByRow: toggleShowRowByRow,
      showNextRow: showNextRow,
      toggleSecondColumn: toggleSecondColumn,
      toggleShowRowByRowCol2: toggleShowRowByRowCol2,
      showNextRowCol2: showNextRowCol2,
      removeSplit: handleRemoveSplit,
    }),
    [
      handleSplitColumn,
      toggleFirstColumn,
      toggleShowRowByRow,
      showNextRow,
      toggleSecondColumn,
      toggleShowRowByRowCol2,
      showNextRowCol2,
      handleRemoveSplit,
    ]
  );

  // 2. useEffect에서 단일 핸들러 사용
  useEffect(() => {
    // 하나의 이벤트 핸들러로 모든 이벤트 처리
    const handleEvent = (event) => {
      const handler = eventMap[event.type];
      if (handler) handler();
    };

    // 한 번에 모든 이벤트 리스너 등록
    Object.keys(eventMap).forEach((eventName) => {
      window.addEventListener(eventName, handleEvent);
    });

    // 클린업: 한 번에 모든 이벤트 리스너 제거
    return () => {
      Object.keys(eventMap).forEach((eventName) => {
        window.removeEventListener(eventName, handleEvent);
      });
    };
  }, [eventMap]); // eventMap만 의존성으로 사용
  // 1. 디바운스 함수
  const debouncedSaveToFirebase = useCallback(
    debounce((courseId, subunitId, dataToSave) => {
      saveTableData(courseId, subunitId, dataToSave);
    }, 1000),
    []
  );

  // 2. 이벤트 리스너 등록/제거
  useEffect(() => {
    if (isDraggingDivider) {
      document.addEventListener("mousemove", handleDividerMouseMove);
      document.addEventListener("mouseup", handleDividerMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleDividerMouseMove);
      document.removeEventListener("mouseup", handleDividerMouseUp);
    };
  }, [isDraggingDivider]);

  // 스타일 적용 함수
  const applyStyle = useCallback(
    (style, value = null) => {
      if (!activeEditor) {
        return;
      }

      const [rowIndex, columnId, editorIndex] = activeEditor.split("-");
      const editorKey = `${rowIndex}-${columnId}-${editorIndex}`;
      let newEditorState = editorStates[editorKey] || EditorState.createEmpty();

      // 스타일 적용
      if (style === "align") {
        const contentState = newEditorState.getCurrentContent();
        const selectionState = newEditorState.getSelection();
        const blockData = { textAlign: value };

        const newContentState = Modifier.setBlockData(
          contentState,
          selectionState,
          blockData
        );

        newEditorState = EditorState.push(
          newEditorState,
          newContentState,
          "change-block-data"
        );
      } else if (style === "highlight") {
        const highlightStyle = `HIGHLIGHT_${value.replace("#", "")}`;
        newEditorState = RichUtils.toggleInlineStyle(
          newEditorState,
          highlightStyle
        );
      } else if (style === "color") {
        const colorStyle = `COLOR_${value.replace("#", "")}`;
        newEditorState = RichUtils.toggleInlineStyle(
          newEditorState,
          colorStyle
        );
      } else {
        newEditorState = RichUtils.toggleInlineStyle(newEditorState, style);
      }

      // 에디터 상태 업데이트
      setEditorStates((prev) => ({
        ...prev,
        [editorKey]: newEditorState,
      }));

      // 데이터 업데이트
      setData((prevData) => {
        const newData = [...prevData];
        if (!newData[rowIndex][columnId][editorIndex]) {
          newData[rowIndex][columnId][editorIndex] = {};
        }

        const updatedEditor = {
          content: newEditorState.getCurrentContent().getPlainText(),
          styleRanges: getStyleRangesFromEditorState(newEditorState),
        };

        newData[rowIndex][columnId][editorIndex] = updatedEditor;
        return newData;
      });

      // 디바운스된 저장 함수 호출
      if (courseId && subunitId) {
        debouncedSaveToFirebase(courseId, subunitId, {
          tableData: data,
          columnWidths,
          editorWidths,
          styleMap,
        });
      }
    },
    [courseId, subunitId, data, columnWidths, editorWidths, styleMap]
  );

  const handleHighlightStyle = (editorState, value) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();

    // 기존 하이라이트 제거
    const contentWithoutHighlight = Object.keys(styleMap)
      .filter((key) => key.startsWith("HIGHLIGHT_"))
      .reduce(
        (content, highlightStyle) =>
          Modifier.removeInlineStyle(content, selection, highlightStyle),
        contentState
      );

    let newEditorState = EditorState.push(
      editorState,
      contentWithoutHighlight,
      "change-inline-style"
    );

    if (value !== null) {
      const styleName = `HIGHLIGHT_${value.replace("#", "")}`;
      newEditorState = RichUtils.toggleInlineStyle(newEditorState, styleName);
    }

    return newEditorState;
  };

  const handleColorStyle = (editorState, value) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();

    // 기존 색상 제거
    const contentWithoutColor = Object.keys(styleMap)
      .filter((key) => key.startsWith("COLOR_"))
      .reduce(
        (content, colorStyle) =>
          Modifier.removeInlineStyle(content, selection, colorStyle),
        contentState
      );

    let newEditorState = EditorState.push(
      editorState,
      contentWithoutColor,
      "change-inline-style"
    );

    const styleName = `COLOR_${value.replace("#", "")}`;
    return RichUtils.toggleInlineStyle(newEditorState, styleName);
  };

  const handleScriptStyle = (editorState, style) => {
    const oppositeStyle = style === "SUPERSCRIPT" ? "SUBSCRIPT" : "SUPERSCRIPT";
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();

    // 반대 스타일 제거
    const contentWithoutOpposite = Modifier.removeInlineStyle(
      contentState,
      selection,
      oppositeStyle
    );

    let newEditorState = EditorState.push(
      editorState,
      contentWithoutOpposite,
      "change-inline-style"
    );

    return RichUtils.toggleInlineStyle(newEditorState, style);
  };

  // 테이블 데이터 업데이트 함수
  const updateTableData = (newEditorState, rowIndex, columnId, editorIndex) => {
    const newContentState = newEditorState.getCurrentContent();

    setData((prevData) => {
      const newData = [...prevData];
      if (!newData[rowIndex][columnId]) {
        newData[rowIndex][columnId] = [];
      }

      newData[rowIndex][columnId][editorIndex] = {
        ...newData[rowIndex][columnId][editorIndex],
        content: newContentState.getPlainText(),
        styleRanges: getStyleRangesFromEditorState(newEditorState),
      };

      // Firebase 저장 디바운스 처리
      const dataToSave = {
        tableData: newData,
        columnWidths,
        editorWidths,
        styleMap,
      };
      debouncedSaveToFirebase(courseId, subunitId, dataToSave);

      return newData;
    });
  };
  const compareEditorStates = (prevState, newState) => {
    if (!prevState || !newState) {
      return {
        contentChanged: true,
        selectionChanged: true,
        prevContent: "",
        newContent: "",
        prevSelection: {},
        newSelection: {},
      };
    }

    const prevContent = prevState.getCurrentContent().getPlainText();
    const newContent = newState.getCurrentContent().getPlainText();
    const prevSelection = prevState.getSelection();
    const newSelection = newState.getSelection();

    return {
      contentChanged: prevContent !== newContent,
      selectionChanged: !prevSelection.equals(newSelection),
      prevContent,
      newContent,
      prevSelection: prevSelection.toJS(),
      newSelection: newSelection.toJS(),
    };
  };

  const handleEditorChange = useCallback(
    (newEditorState, rowIndex, columnId, editorIndex) => {
      const contentState = newEditorState.getCurrentContent();
      const selection = newEditorState.getSelection();
      const currentStyles = newEditorState.getCurrentInlineStyle();

      setEditorStates((prev) => ({
        ...prev,
        [`${rowIndex}-${columnId}-${editorIndex}`]: newEditorState,
      }));

      setData((prevData) => {
        const newData = [...prevData];
        if (!newData[rowIndex][columnId][editorIndex]) {
          newData[rowIndex][columnId][editorIndex] = {};
        }

        const updatedEditor = {
          content: contentState.getPlainText(),
          styleRanges: getStyleRangesFromEditorState(newEditorState),
        };

        newData[rowIndex][columnId][editorIndex] = updatedEditor;
        return newData;
      });

      // Firebase 저장 시점
      if (courseId && subunitId) {
        debouncedSaveToFirebase(courseId, subunitId, {
          tableData: data,
          columnWidths,
          editorWidths,
          styleMap,
        });
      }
    },
    [courseId, subunitId, data, columnWidths, editorWidths, styleMap]
  );

  // 스타일 범위를 가져오는 헬퍼 함수
  const getStyleRangesFromEditorState = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const firstBlock = contentState.getFirstBlock(); // 첫 번째 블록만 처리
    const styleRanges = [];

    let currentStyle = null;
    let currentOffset = 0;

    // 문자 스트를 한 번만 가��옴
    const charList = firstBlock.getCharacterList();
    const blockLength = firstBlock.getLength();

    for (let offset = 0; offset < blockLength; offset++) {
      const char = charList.get(offset);
      const style = char.getStyle();

      if (!currentStyle || !currentStyle.equals(style)) {
        if (currentStyle) {
          // 스타일 배열을 캐시하여 재사용
          const styleArray = currentStyle.toArray();
          styleRanges.push({
            offset: currentOffset,
            length: offset - currentOffset,
            style: styleArray,
          });
        }
        currentStyle = style;
        currentOffset = offset;
      }
    }

    // 마지막 스타일 범위 처리
    if (currentStyle) {
      styleRanges.push({
        offset: currentOffset,
        length: blockLength - currentOffset,
        style: currentStyle.toArray(),
      });
    }

    return styleRanges;
  };

  // handleEditorFocus 수정
  const handleEditorFocus = useCallback(
    (rowIndex, columnId, editorIndex) => {
      const newActiveEditor = `${rowIndex}-${columnId}-${editorIndex}`;
      setActiveEditor(newActiveEditor);

      // 해당 에디터의 상태가 없을 때만 새로운 상태 생성
      requestAnimationFrame(() => {
        setEditorStates((prev) => {
          if (prev[newActiveEditor]) {
            return prev;
          }
          return {
            ...prev,
            [newActiveEditor]: EditorState.createEmpty(),
          };
        });
      });
    },
    [] // 의존성 배열 수정
  );

  const EditorDivider = ({ onMouseDown, style, isRemovingSplit }) => {
    return (
      <div
        className={`editor-divider ${isRemovingSplit ? "remove-mode" : ""}`}
        style={{
          ...style,
          right: isRemovingSplit ? "-2.5px" : "-5px", // 른쪽 여백 조
          top: 0,
          bottom: 0,
          position: "absolute",
          width: isRemovingSplit ? "5px" : "10px", // 너 증가
          backgroundColor: isRemovingSplit
            ? "rgba(255, 99, 71, 0.7)"
            : "transparent", // 평소에 투명하게
          cursor: isRemovingSplit ? "pointer" : "col-resize",
          height: "100%",
          transition: "all 0.3s ease",
          // hover 효과를 위한 가상 요소 대신 실제 요소 사용
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onMouseDown={onMouseDown}
      >
        {!isRemovingSplit && (
          <div
            style={{
              width: "2px",
              height: "100%",
              backgroundColor: "rgba(224, 224, 224, 0.8)",
              transition: "background-color 0.2s ease",
            }}
          />
        )}
      </div>
    );
  };

  const handleEditorResize = useCallback(
    (rowIndex, columnId, editorIndex, e) => {
      const cellKey = `${rowIndex}-${columnId}`;
      const cell = tableRef.current.querySelector(
        `td[data-cell-key="${cellKey}"]`
      );
      const cellRect = cell.getBoundingClientRect();

      const handleMouseMove = (moveEvent) => {
        // requestAnimationFrame으로 불필요한 업데이트 방지
        requestAnimationFrame(() => {
          const relativeX = moveEvent.clientX - cellRect.left;
          const newWidth = (relativeX / cellRect.width) * 100;
          const clampedWidth = Math.max(10, Math.min(90, newWidth));

          setEditorWidths((prev) => {
            // 변경이 필요한 경우만 새 객체 생성
            const currentWidth = (prev[cellKey] || [100])[editorIndex];
            if (currentWidth === clampedWidth) return prev;

            return {
              ...prev,
              [cellKey]: [
                ...(prev[cellKey] || [100]).slice(0, editorIndex),
                clampedWidth,
                ...(prev[cellKey] || [100]).slice(editorIndex + 1),
              ],
            };
          });
        });
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);

        // 마우스를 뗄 때만 Firebase에 업데이트
        updateWidths(courseId, subunitId, columnWidths, editorWidths);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);

      // cleanup 함수 추가
      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };
    },
    [courseId, subunitId, columnWidths, editorWidths, updateWidths]
  );

  const startEditorResize = useCallback(
    (rowIndex, columnId, editorIndex, e) => {
      const cellKey = `${rowIndex}-${columnId}`;
      const cell = tableRef.current.querySelector(
        `td[data-cell-key="${cellKey}"]`
      );
      const cellRect = cell.getBoundingClientRect();
      const startX = e.clientX;
      const initialWidths = [...(editorWidths[cellKey] || [100])];

      const handleMouseMove = (moveEvent) => {
        const deltaX = moveEvent.clientX - startX;
        const totalWidth = cellRect.width;
        const percentDelta = (deltaX / totalWidth) * 100;

        setEditorWidths((prev) => {
          const currentWidths = [...(prev[cellKey] || [100])];
          const leftWidth = Math.max(
            10,
            Math.min(90, initialWidths[editorIndex] + percentDelta)
          );
          const rightWidth = Math.max(
            10,
            Math.min(90, initialWidths[editorIndex + 1] - percentDelta)
          );

          currentWidths[editorIndex] = leftWidth;
          currentWidths[editorIndex + 1] = rightWidth;

          return { ...prev, [cellKey]: currentWidths };
        });
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        updateWidths(courseId, subunitId, columnWidths, editorWidths);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    },
    [courseId, subunitId, columnWidths, editorWidths, updateWidths]
  );

  const focusNextCell = useCallback(
    (rowIndex, columnId) => {
      const nextRowIndex = rowIndex + 1;
      if (nextRowIndex >= rows.length) return; // early return

      const nextEditorKey = `${nextRowIndex}-${columnId}-0`;
      setActiveEditor(nextEditorKey);

      // requestAnimationFrame 내부에서만 상태 업데이트
      requestAnimationFrame(() => {
        setEditorStates((prev) => ({
          ...prev,
          [nextEditorKey]: EditorState.createEmpty(),
        }));
      });
    },
    [rows.length]
  );

  const handleReturn = useCallback(
    (e, editorState) => {
      e.preventDefault();

      if (activeEditor) {
        const [rowIndex, columnId] = activeEditor.split("-");
        const nextEditorKey = `${parseInt(rowIndex) + 1}-${columnId}-0`;

        // 클재 에디터의 블록 데이터를 가져옴
        const currentContent = editorState.getCurrentContent();
        const currentBlock = currentContent.getFirstBlock();
        const blockData = currentBlock.getData();

        // 다음 에디터의 현재 상태를 가져옴
        const nextEditorState =
          editorStates[nextEditorKey] || EditorState.createEmpty();

        // 새로운 에디터 상태를 설정 (기존 내용 유지)
        setEditorStates((prev) => ({
          ...prev,
          [nextEditorKey]: EditorState.forceSelection(
            nextEditorState,
            nextEditorState.getSelection().merge({
              hasFocus: true,
              anchorOffset: 0,
              focusOffset: 0,
            })
          ),
        }));

        setActiveEditor(nextEditorKey);
      }

      return "handled";
    },
    [activeEditor, editorStates]
  );

  // useEffect를 사용여 activeEditor 변경  스 이동
  useEffect(() => {
    if (activeEditor) {
      const [rowIndex, columnId, editorIndex] = activeEditor.split("-");
      const editorKey = `${rowIndex}-${columnId}-${editorIndex}`;

      requestAnimationFrame(() => {
        setEditorStates((prev) => ({
          ...prev,
          [editorKey]: EditorState.moveFocusToEnd(
            prev[editorKey] || EditorState.createEmpty()
          ),
        }));
      });
    }
  }, [activeEditor]);

  // moveFocus 수정
  const moveFocus = useCallback(
    (direction) => {
      if (activeEditor) {
        const [currentRow, columnId, currentEditor] = activeEditor.split("-");
        const currentCol = columns.findIndex(
          (col) => col.accessor === columnId
        );

        let nextRow = Number(currentRow);
        let nextCol = currentCol;
        let nextEditor = Number(currentEditor);

        switch (direction) {
          case "up":
            nextRow = Math.max(0, nextRow - 1);
            break;
          case "down":
            nextRow = Math.min(rows.length - 1, nextRow + 1);
            break;
          case "left":
            if (nextEditor > 0) {
              nextEditor--;
            } else if (nextCol > 0) {
              nextCol--;
              nextEditor =
                (editorWidths[`${nextRow}-${columns[nextCol]?.accessor}`] || [])
                  .length - 1;
            }
            break;
          case "right":
            if (
              nextEditor <
              (editorWidths[`${nextRow}-${columns[nextCol]?.accessor}`] || [])
                .length -
                1
            ) {
              nextEditor++;
            } else if (nextCol < columns.length - 1) {
              nextCol++;
              nextEditor = 0;
            }
            break;
        }

        if (nextCol >= 0 && nextCol < columns.length) {
          const nextEditorKey = `${nextRow}-${columns[nextCol].accessor}-${nextEditor}`;
          setActiveEditor(nextEditorKey);
          setEditorStates((prev) => ({
            ...prev,
            [nextEditorKey]: prev[nextEditorKey] || EditorState.createEmpty(),
          }));
          return true; // 커스 이동이 처리되었음을 나타냅니다.
        }
      }
      return false; // 포스 이동이 처리되지 않았을 나타냅니다.
    },
    [activeEditor, rows, columns, editorWidths]
  );

  const handleKeyCommand = useCallback(
    (command, editorState) => {
      switch (command) {
        case "move-up":
          return moveFocus("up") ? "handled" : "not-handled";
        case "move-down":
          return moveFocus("down") ? "handled" : "not-handled";
        case "move-left":
          return moveFocus("left") ? "handled" : "not-handled";
        case "move-right":
          return moveFocus("right") ? "handled" : "not-handled";
        default:
          return "not-handled";
      }
    },
    [moveFocus]
  );

  const keyBindingFn = (e) => {
    if (e.keyCode === 9) {
      // Tab 키 감지
      e.preventDefault();
      // 현 에디터 키에서 rowIndex, columnId, editorIndex 추출
      if (activeEditor) {
        const [currentRow, currentCol, currentIndex] = activeEditor.split("-");

        // Shift + Tab인 경우
        if (e.shiftKey) {
          const prevEditorKey = getPreviousEditorKey(
            currentRow,
            currentCol,
            currentIndex
          );
          if (prevEditorKey) {
            const [prevRow, prevCol, prevIndex] = prevEditorKey.split("-");
            setActiveEditor(prevEditorKey);
            handleEditorFocus(prevRow, prevCol, prevIndex);
          }
        } else {
          // 기존 Tab 동작
          const nextEditorKey = getNextEditorKey(
            currentRow,
            currentCol,
            currentIndex
          );
          if (nextEditorKey) {
            const [nextRow, nextCol, nextIndex] = nextEditorKey.split("-");
            setActiveEditor(nextEditorKey);
            handleEditorFocus(nextRow, nextCol, nextIndex);
          }
        }
      }
      return null;
    }
    return getDefaultKeyBinding(e);
  };

  useEffect(() => {
    const handleShowAllColumns = () => {
      setIsFirstColumnHidden(false);
      setIsSecondColumnHidden(false);
    };

    window.addEventListener("showAllColumns", handleShowAllColumns);

    return () => {
      window.removeEventListener("showAllColumns", handleShowAllColumns);
    };
  }, []);

  useEffect(() => {
    const handleResetReviewMode = () => {
      setIsFirstColumnHidden(false);
      setIsSecondColumnHidden(false);
      setIsShowingRowByRow(false);
      setIsShowingRowByRowCol2(false);
      setVisibleRows([]);
      setVisibleRowsCol2([]);
    };

    window.addEventListener("resetReviewMode", handleResetReviewMode);

    return () => {
      window.removeEventListener("resetReviewMode", handleResetReviewMode);
    };
  }, []);

  useEffect(() => {
    const handleToggleShowRowByRow = (event) => {
      if (!event.detail) return;
      const { isActive, hideAll } = event.detail;

      if (isActive && hideAll) {
        // Hide Rows가 활성화되고 모든 에디터를 가리라는 신���가 왔을 때
        setVisibleEditors((prev) => ({ ...prev, col1: [] }));
      }
    };

    window.addEventListener("toggleShowRowByRow", handleToggleShowRowByRow);

    return () => {
      window.removeEventListener(
        "toggleShowRowByRow",
        handleToggleShowRowByRow
      );
    };
  }, []);

  useEffect(() => {
    const handleToggleShowRowByRowCol2 = (event) => {
      if (!event.detail) return;
      const { isActive, hideAll } = event.detail;

      if (isActive && hideAll) {
        // Hide Rows가 활성화되고 모든 에디터를 가리라는 신호가 왔을 때
        setVisibleEditors((prev) => ({ ...prev, col2: [] }));
      }
    };

    window.addEventListener(
      "toggleShowRowByRowCol2",
      handleToggleShowRowByRowCol2
    );
    return () => {
      window.removeEventListener(
        "toggleShowRowByRowCol2",
        handleToggleShowRowByRowCol2
      );
    };
  }, []);

  const handleEditorBlur = useCallback(() => {
    // 에터 비성화 시 커 스타일 원래대로 변경
    const editorElements = document.querySelectorAll(".DraftEditor-root");
    editorElements.forEach((element) => {
      element.style.cursor = "default";
    });
  }, []);

  useEffect(() => {
    const handleContextMenu = (e) => {
      if (showToolbar) {
        setShowToolbar(false);
        setSelectedText("");
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [showToolbar]);

  useEffect(() => {
    if (layout === "layout7" || layout === "layout8") {
      setColumnWidths({ col1: 100, col2: 0 });
    } else if (layout === "layout5" || layout === "layout6") {
      setColumnWidths({ col1: 50, col2: 50 });
    } else {
      setColumnWidths({ col1: 20, col2: 80 });
    }
  }, [layout]);
  // 1. useEffect 린업 함수 가
  useEffect(() => {
    let isMounted = true;

    const loadData = async () => {
      if (!courseId || !subunitId) {
        return;
      }

      try {
        await new Promise((resolve) => {
          if (auth.currentUser) {
            resolve();
          } else {
            const unsubscribe = auth.onAuthStateChanged((user) => {
              if (user) {
                unsubscribe();
                resolve();
              }
            });
          }
        });

        if (!isMounted) return;

        const loadedData = await loadTableData(courseId, subunitId);

        if (!isMounted) return;

        if (loadedData?.tableData?.length > 0) {
          setData(loadedData.tableData);
          setTableState(loadedData);
          setColumnWidths(loadedData.columnWidths);
          setEditorWidths(loadedData.editorWidths);

          const newEditorStates = {};
          loadedData.tableData.forEach((row, rowIndex) => {
            Object.keys(row).forEach((colId) => {
              if (Array.isArray(row[colId])) {
                row[colId].forEach((editor, editorIndex) => {
                  const content = editor.content || "";
                  let contentState = ContentState.createFromText(content);

                  if (editor.styleRanges) {
                    editor.styleRanges.forEach((range) => {
                      const selection = SelectionState.createEmpty(
                        contentState.getFirstBlock().getKey()
                      ).merge({
                        anchorOffset: range.offset,
                        focusOffset: range.offset + range.length,
                      });

                      // range.style이 배열이 아닌 경우 배열로 변환
                      const styles = Array.isArray(range.style)
                        ? range.style
                        : typeof range.style === "string"
                        ? [range.style]
                        : [];

                      // 유효한 스타일만 적용
                      styles.forEach((style) => {
                        if (typeof style === "string") {
                          contentState = Modifier.applyInlineStyle(
                            contentState,
                            selection,
                            style
                          );
                        }
                      });
                    });
                  }

                  newEditorStates[`${rowIndex}-${colId}-${editorIndex}`] =
                    EditorState.createWithContent(contentState);
                });
              }
            });
          });
          setEditorStates(newEditorStates);
        }
      } catch (error) {
        console.error("데이터 로드 중 오류:", error);
      }
    };

    loadData();

    return () => {
      isMounted = false;
    };
  }, [courseId, subunitId]);

  // 1. 얕은 비교로 변경
  const hasDataChanged = () => {
    if (prevTableData.length !== data.length) return true;
    return data.some((row, i) => {
      return JSON.stringify(row) !== JSON.stringify(prevTableData[i]);
    });
  };

  useEffect(() => {
    let isMounted = true;

    const saveData = async () => {
      if (!courseId || !subunitId || !isMounted) return;

      try {
        // 변경된 데이터만 확인
        if (hasDataChanged()) {
          const dataToSave = {
            tableData: data,
            columnWidths,
            editorWidths,
            styleMap: styleMap || {},
          };
          await saveTableData(courseId, subunitId, dataToSave);
          if (isMounted) {
            setPrevTableData(data);
          }
        }
      } catch (error) {
        console.error("데이터 저장 중 오류:", error);
      }
    };

    // 디바운스 적용
    const timeoutId = setTimeout(saveData, 1000);

    return () => {
      isMounted = false;
      clearTimeout(timeoutId);
    };
  }, [
    data,
    prevTableData,
    columnWidths,
    editorWidths,
    styleMap,
    courseId,
    subunitId,
  ]);

  // ID들을 TableContext에 설정
  useEffect(() => {
    if (courseId && subunitId) {
      setCurrentIds(courseId, subunitId);
    }
  }, [courseId, subunitId, setCurrentIds]);

  // 다음 에디터 키를 가져오는 함수
  const getNextEditorKey = (currentRow, currentCol, currentIndex) => {
    // 현재 열의 다음 에디터
    const currentEditors = data[currentRow][currentCol];
    if (currentIndex < currentEditors.length - 1) {
      return `${currentRow}-${currentCol}-${parseInt(currentIndex) + 1}`;
    }

    // 다음 열로 이동
    if (currentCol === "col1") {
      return `${currentRow}-col2-0`;
    }

    // 다음 행의  번째 로 이동
    if (parseInt(currentRow) < data.length - 1) {
      return `${parseInt(currentRow) + 1}-col1-0`;
    }

    return null;
  };

  // getPreviousEditorKey 함수 추가
  const getPreviousEditorKey = (currentRow, currentCol, currentIndex) => {
    // 현재 열의 이전 에디터
    if (currentIndex > 0) {
      return `${currentRow}-${currentCol}-${parseInt(currentIndex) - 1}`;
    }

    // 이 열로 이동
    if (currentCol === "col2") {
      const prevColEditors = editorWidths[`${currentRow}-col1`] || [100];
      return `${currentRow}-col1-${prevColEditors.length - 1}`;
    }

    // 이전 행의 마지막 열로 이동
    if (parseInt(currentRow) > 0) {
      const prevRow = parseInt(currentRow) - 1;
      const prevColEditors = editorWidths[`${prevRow}-col2`] || [100];
      return `${prevRow}-col2-${prevColEditors.length - 1}`;
    }

    return null;
  };

  // 툴바 외부 클릭을 감지하는 핸들러 추가
  const handleToolbarOutsideClick = useCallback((e) => {
    // 툴바 영역을 클릭한 것이 아니라면 툴바 숨기기
    if (!e.target.closest(".editor-text-toolbar")) {
      setShowToolbar(false);
      setSelectedText("");
    }
  }, []);
  // 1. 이벤트 리스너 추가 시점에 로그
  useEffect(() => {
    if (showToolbar) {
      document.addEventListener("mousedown", handleToolbarOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleToolbarOutsideClick);
      };
    }
  }, [showToolbar, handleToolbarOutsideClick]);

  // 2. 드래그 관련 이벤트 리스너
  useEffect(() => {
    if (isDraggingDivider) {
      document.addEventListener("mousemove", handleDividerMouseMove);
      document.addEventListener("mouseup", handleDividerMouseUp);
    }
    return () => {
      document.removeEventListener("mousemove", handleDividerMouseMove);
      document.removeEventListener("mouseup", handleDividerMouseUp);
    };
  }, [isDraggingDivider, handleDividerMouseMove, handleDividerMouseUp]);

  // 3. 텍스트 선택 이벤트 리스너
  useEffect(() => {
    const handleTextSelection = (e) => {
      // ... existing code ...
    };

    document.addEventListener("mouseup", handleTextSelection);
    document.addEventListener("keyup", handleTextSelection);

    return () => {
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      }
      document.removeEventListener("mouseup", handleTextSelection);
      document.removeEventListener("keyup", handleTextSelection);
    };
  }, []);

  // 4. 컴포넌트 언마운트 시 정리
  useEffect(() => {
    return () => {
      // 1. 기존 상태들 초기화
      batch(() => {
        setEditorStates({});
        setActiveEditor(null);
        setShowToolbar(false);
        setSelectedText("");
        setToolbarPosition({ top: 0, left: 0 });
        setIsDragging(false);
        setIsDraggingDivider(false);
        setActiveDivider(null);
      });

      // 2. 텍스트 선택 해제
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      }

      // 3. 디바운스된 함수들의 대기 중인 호출 취소
      if (handleEditorChange.cancel) {
        handleEditorChange.cancel();
      }
      if (debouncedSaveToFirebase?.cancel) {
        debouncedSaveToFirebase.cancel();
      }
    };
  }, []); // 컴포넌트 마운트/언마운트 시에만 실행

  // 에디터 활성화 상태를 관리하는 새로운 state 추가
  const [isEditorReady, setIsEditorReady] = useState(false);

  // 컴포넌트 마운트 시 약간의 지연 후 에디터 활성화
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsEditorReady(true);
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  // 에디터 상태 생성 함수
  const getOrCreateEditorState = useCallback(
    (rowIndex, columnId, editorIndex) => {
      const editorKey = `${rowIndex}-${columnId}-${editorIndex}`;

      // 이미 존재하는 상태면 그대로 반환
      if (editorStates[editorKey]) {
        return editorStates[editorKey];
      }

      const cellData = data[rowIndex]?.[columnId]?.[editorIndex];

      // 기본 에디터 상태 생성
      const defaultEditorState = EditorState.createEmpty();

      if (!cellData) {
        setEditorStates((prev) => ({
          ...prev,
          [editorKey]: defaultEditorState,
        }));
        return defaultEditorState;
      }

      // ContentState 생성
      const contentState = ContentState.createFromText(cellData.content || "");

      // 블록 데이터에 정렬 정보 설정
      const selectionState = SelectionState.createEmpty(
        contentState.getFirstBlock().getKey()
      );
      const newContentState = Modifier.setBlockData(
        contentState,
        selectionState,
        {
          textAlign:
            cellData.blockData?.textAlign || cellData.textAlignment || "left",
        }
      );

      // 새 에디터 상태 생성
      const newEditorState = EditorState.createWithContent(newContentState);

      // 상태 저장
      setEditorStates((prev) => ({
        ...prev,
        [editorKey]: newEditorState,
      }));

      return newEditorState;
    },
    [editorStates, data]
  );

  // 셀에 텍스트가 있는지 확인하는 헬퍼 함수 추가
  const hasCellContent = useCallback(
    (rowIndex, columnId) => {
      const cellEditors = data[rowIndex][columnId] || [];
      return cellEditors.some((editor) => {
        return editor?.content && editor.content.trim().length > 0;
      });
    },
    [data]
  );

  // visibleEditors 상태 추가
  const [visibleEditors, setVisibleEditors] = useState({
    col1: [],
    col2: [],
  });

  // 에디터 콘텐츠 확인 함수
  const hasEditorContent = useCallback(
    (rowIndex, columnId, editorIndex) => {
      return (
        data[rowIndex]?.[columnId]?.[editorIndex]?.content?.trim().length > 0
      );
    },
    [data]
  );

  // isEditorVisible 함수 수정
  const isEditorVisible = useCallback(
    (rowIndex, columnId, editorIndex) => {
      const editorKey = `${rowIndex}-${columnId}-${editorIndex}`;

      // Exit 모드이거나 Show Rows가 활성화된 경우 모든 에디터 표시
      if (!isShowingRowByRow && !isShowingRowByRowCol2) {
        return true;
      }

      // Hide Rows가 활성화된 경우
      if (
        (columnId === "col1" && isShowingRowByRow) ||
        (columnId === "col2" && isShowingRowByRowCol2)
      ) {
        // visibleEditors에 포함된 에디터만 표시
        return visibleEditors[columnId]?.includes(editorKey) || false;
      }

      return true;
    },
    [isShowingRowByRow, isShowingRowByRowCol2, visibleEditors]
  );

  // Exit 모드 이벤트 리스너 추가
  useEffect(() => {
    const handleExitReviewMode = () => {
      setIsShowingRowByRow(false);
      setIsShowingRowByRowCol2(false);
    };

    window.addEventListener("exitReviewMode", handleExitReviewMode);

    return () => {
      window.removeEventListener("exitReviewMode", handleExitReviewMode);
    };
  }, []);

  // Show Rows 이벤트 핸들러
  useEffect(() => {
    const handleToggleShowRowByRow = (event) => {
      if (!event.detail) return;
      const { isActive, hideAll } = event.detail;
      if (isActive && hideAll) {
        // Hide Rows가 활성화되고 모든 에디터를 가리라는 신호가 왔을 때
        setVisibleEditors((prev) => ({ ...prev, col1: [] }));
      }
    };

    window.addEventListener("toggleShowRowByRow", handleToggleShowRowByRow);

    return () => {
      window.removeEventListener(
        "toggleShowRowByRow",
        handleToggleShowRowByRow
      );
    };
  }, []);

  // Next Row 이벤트 핸들러
  useEffect(() => {
    const handleShowNextRow = (event) => {
      if (!event || !event.detail || !event.detail.columnId || !data) return;

      const { columnId } = event.detail;
      const allEditors = [];

      // 해당 열의 모든 에디터를 순회하면서 콘텐츠가 있는 에디터만 수집
      data.forEach((row, rowIndex) => {
        const editors = row[columnId] || [];
        editors.forEach((_, editorIndex) => {
          if (hasEditorContent(rowIndex, columnId, editorIndex)) {
            allEditors.push(`${rowIndex}-${columnId}-${editorIndex}`);
          }
        });
      });

      // 현재 보이는 에디터들 중 마지막 다음 에디터를 찾아서 보이게 함
      const currentVisible = visibleEditors[columnId] || [];
      const lastVisibleIndex = Math.max(
        -1,
        ...currentVisible.map((key) => allEditors.indexOf(key))
      );

      if (lastVisibleIndex < allEditors.length - 1) {
        const nextEditor = allEditors[lastVisibleIndex + 1];
        setVisibleEditors((prev) => ({
          ...prev,
          [columnId]: [...(prev[columnId] || []), nextEditor], // 콤마 위치 수정
        }));
      }
    };

    window.addEventListener("showNextRow", handleShowNextRow);

    return () => {
      window.removeEventListener("showNextRow", handleShowNextRow);
    };
  }, [data, hasEditorContent, visibleEditors]);

  // blockStyleFn 함수 정
  const blockStyleFn = (contentBlock) => {
    const data = contentBlock.getData();
    const textAlign = data.get("textAlign");

    // 명시적인 클래스 이름 반환
    if (textAlign) {
      return `public-DraftStyleDefault-block text-align-${textAlign}`;
    }
    return "public-DraftStyleDefault-block";
  };

  const handleToggleRedText = useCallback(() => {
    if (!data || !courseId || !subunitId) {
      return;
    }

    const newData = data.map((row) => {
      const newRow = { ...row };
      Object.keys(newRow).forEach((colId) => {
        if (Array.isArray(newRow[colId])) {
          newRow[colId] = newRow[colId].map((editor) => {
            if (!editor.styleRanges) return editor;

            return {
              ...editor,
              styleRanges: editor.styleRanges.map((range) => {
                // range.style이 배열이 아닌 경우 배열로 변환
                const styles = Array.isArray(range.style)
                  ? range.style
                  : [range.style];

                return {
                  ...range,
                  style: styles.map((style) => {
                    if (style === "COLOR_F24722") return "COLOR_FFFFFF";
                    if (style === "COLOR_FFFFFF") return "COLOR_F24722";
                    return style;
                  }),
                };
              }),
            };
          });
        }
      });
      return newRow;
    });

    const hasChanges = JSON.stringify(data) !== JSON.stringify(newData);

    if (hasChanges) {
      const event = new CustomEvent("redTextStateChanged");
      window.dispatchEvent(event);

      const dataToSave = {
        tableData: newData,
        columnWidths,
        editorWidths,
        styleMap,
      };

      saveTableData(courseId, subunitId, dataToSave).then(() => {
        loadTableData(courseId, subunitId).then((loadedData) => {
          if (loadedData?.tableData?.length > 0) {
            setData(loadedData.tableData);
            setTableState(loadedData);

            const newEditorStates = {};
            loadedData.tableData.forEach((row, rowIndex) => {
              Object.keys(row).forEach((colId) => {
                if (Array.isArray(row[colId])) {
                  row[colId].forEach((editor, editorIndex) => {
                    const content = editor.content || "";
                    let contentState = ContentState.createFromText(content);

                    if (editor.styleRanges) {
                      editor.styleRanges.forEach((range) => {
                        const selection = SelectionState.createEmpty(
                          contentState.getFirstBlock().getKey()
                        ).merge({
                          anchorOffset: range.offset,
                          focusOffset: range.offset + range.length,
                        });

                        // range.style이 배열이 아닌 경우 배열로 변환
                        const styles = Array.isArray(range.style)
                          ? range.style
                          : [range.style];
                        styles.forEach((style) => {
                          contentState = Modifier.applyInlineStyle(
                            contentState,
                            selection,
                            style
                          );
                        });
                      });
                    }

                    newEditorStates[`${rowIndex}-${colId}-${editorIndex}`] =
                      EditorState.createWithContent(contentState);
                  });
                }
              });
            });
            setEditorStates(newEditorStates);
          }
        });
      });
    }
  }, [
    data,
    courseId,
    subunitId,
    columnWidths,
    editorWidths,
    styleMap,
    saveTableData,
    loadTableData,
  ]);

  // 이벤트 리스너에도 로그 추가
  useEffect(() => {
    const handleToggleRedTextEvent = () => {
      handleToggleRedText();
    };

    window.addEventListener("toggleRedText", handleToggleRedTextEvent);

    return () => {
      window.removeEventListener("toggleRedText", handleToggleRedTextEvent);
    };
  }, [handleToggleRedText]);

  const handlePaste = (text, editorState, rowIndex, columnId, editorIndex) => {
    // 줄바꿈 문자를 공백으로 대체하고 연속된 백을 하나로 통일
    const sanitizedText = text.replace(/[\r\n\s]+/g, " ").trim();

    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    // 현재 선택 영역에 정제된 텍스트 삽입
    const newContentState = Modifier.replaceText(
      contentState,
      selection,
      sanitizedText
    );

    // 새로운 에디터 상태 생성
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters"
    );

    // 에디터 상태 업데트
    handleEditorChange(newEditorState, rowIndex, columnId, editorIndex);

    return "handled";
  };

  useEffect(() => {
    const loadData = async () => {
      if (courseId && subunitId) {
        const loadedData = await loadTableData(courseId, subunitId);
        if (loadedData?.columnWidths) {
          setColumnWidths(loadedData.columnWidths);
        }
      }
    };

    loadData();
  }, [courseId, subunitId]);

  return (
    <div
      className={`resizable-table-container custom-editor-container ${getTableClassName()}`}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      ref={tableContainerRef}
      style={{
        position: "relative",
        width: `${tableWidth}px`,
        height: `${tableHeight}px`,
        marginTop: "25px",
        marginLeft: "25px",
        cursor: "text",
      }}
    >
      {showToolbar && selectedText && (
        <div
          className="editor-text-toolbar"
          style={{
            position: "absolute",
            top: `${toolbarPosition.top}px`,
            left: `${toolbarPosition.left}px`,
            zIndex: 1000,
            backgroundColor: "white",
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "4px",
            boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <button onClick={() => applyStyle("BOLD")}>
            <img src="/toolbaricons/inuse/format-bold.svg" alt="Bold" />
          </button>
          <button onClick={() => applyStyle("ITALIC")}>
            <img src="/toolbaricons/inuse/format-italic.svg" alt="Italic" />
          </button>
          <button onClick={() => applyStyle("UNDERLINE")}>
            <img
              src="/toolbaricons/inuse/format-underline.svg"
              alt="Underline"
            />
          </button>
          <button onClick={() => applyStyle("SUPERSCRIPT")}>
            <img src="/toolbaricons/inuse/superscript.svg" alt="Superscript" />
          </button>
          <button onClick={() => applyStyle("SUBSCRIPT")}>
            <img src="/toolbaricons/inuse/subscript.svg" alt="Subscript" />
          </button>
          <div className="highlight-container">
            <button
              onClick={() => {
                setShowHighlightColors(!showHighlightColors);
                setShowFontColors(false);
              }}
            >
              <img src="/toolbaricons/inuse/type-square.svg" alt="하이라이트" />
            </button>
            {showHighlightColors && (
              <div className="anothercomponent-highlight-colors">
                {[
                  "remove",
                  "#FFFCDB",
                  "#FDEEDB",
                  "#FEEFF6",
                  "#F6ECF4",
                  "#E9F6FE",
                  "#EBF5EC",
                  "#F4E9E3",
                  "#F4E9E3",
                ].map((color) => (
                  <button
                    key={color}
                    className={`highlight-color-button ${
                      color === "remove" ? "remove-highlight" : ""
                    }`}
                    style={{
                      backgroundColor: color === "remove" ? "white" : color,
                    }}
                    onClick={() =>
                      applyStyle("highlight", color === "remove" ? null : color)
                    }
                  >
                    {color === "remove" && <span>✕</span>}
                  </button>
                ))}
              </div>
            )}
          </div>
          <div className="font-color-container">
            <button
              onClick={() => {
                setShowFontColors(!showFontColors);
                setShowHighlightColors(false);
              }}
            >
              <img
                src="/toolbaricons/inuse/format-text-color.svg"
                alt="글자 색상"
              />
            </button>
            {showFontColors && (
              <div className="font-colors">
                {[
                  "#F24722", // 빨간색
                  "#FFA629", // 주색
                  "#FFCD29", // 노란색
                  "#15AE5C", // 초록색
                  "#0D99FF", // 파란색
                  "#9747FF", // 보라색
                  "#000000", // 검은색
                  "#FFFFFF", // 흰색
                ].map((color) => (
                  <button
                    key={color}
                    className="font-color-button"
                    style={{ backgroundColor: color }}
                    onClick={() => applyStyle("color", color)}
                  />
                ))}
              </div>
            )}
          </div>
          <button onClick={() => applyStyle("align", "left")}>
            <img src="/toolbaricons/inuse/align-left.svg" alt="왼쪽 정렬" />
          </button>
          <button onClick={() => applyStyle("align", "center")}>
            <img src="/toolbaricons/inuse/align-center.svg" alt="가운데 정렬" />
          </button>
          <button onClick={() => applyStyle("align", "right")}>
            <img src="/toolbaricons/inuse/align-right.svg" alt="오른쪽 정렬" />
          </button>
        </div>
      )}
      <table
        {...getTableProps()}
        className={`react-table ${getTableClassName()}`}
        ref={(el) => {
          tableRef.current = el;
          measurementRef.current = el;
        }}
        style={{
          height: `${tableHeight}px`,
          borderCollapse: "separate",
          borderSpacing: 0,
          border: "none",
        }}
      >
        <colgroup>
          <col style={{ width: `${columnWidths.col1}%` }} />
          <col style={{ width: `${columnWidths.col2}%` }} />
        </colgroup>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                key={rowIndex}
                style={{
                  height:
                    rowIndex === rows.length - 1
                      ? `${calculatedRowHeight + remainingHeight}px`
                      : `${calculatedRowHeight}px`,
                }}
              >
                {row.cells.map((cell, cellIndex) => {
                  const cellKey = `${rowIndex}-${cell.column.id}`;
                  return (
                    <td
                      {...cell.getCellProps()}
                      key={cell.column.id}
                      onClick={(event) => {
                        if (!event.target.closest(".editor-wrapper")) {
                          setActiveEditor(`${rowIndex}-${cell.column.id}-0`);
                        }
                      }}
                      style={{
                        height: "auto",
                        position: "relative",
                        padding: 0,
                        overflow: "hidden",
                        backgroundColor:
                          (cell.column.id === "col1" &&
                            isShowingRowByRow &&
                            hasCellContent(rowIndex, "col1") && // 추가
                            !visibleRows.includes(rowIndex)) ||
                          (cell.column.id === "col2" &&
                            isShowingRowByRowCol2 &&
                            hasCellContent(rowIndex, "col2") && // 추가
                            !visibleRowsCol2.includes(rowIndex)) ||
                          (cell.column.id === "col1" &&
                            isFirstColumnHidden &&
                            !isShowingRowByRow) ||
                          (cell.column.id === "col2" &&
                            isSecondColumnHidden &&
                            !isShowingRowByRowCol2)
                            ? "#f5f5f5" // 숨김 상태일 때 회색
                            : "white", // 기본 배경색
                        cursor: "default",
                      }}
                      data-row={rowIndex}
                      data-column={cell.column.id}
                      data-cell-key={`${rowIndex}-${cell.column.id}`}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {(
                          editorWidths[`${rowIndex}-${cell.column.id}`] || [100]
                        ).map((width, editorIndex) => {
                          const editorKey = `${rowIndex}-${cell.column.id}-${editorIndex}`;
                          const editorContent =
                            data[rowIndex][cell.column.id][editorIndex]
                              ?.content || "";

                          return (
                            <React.Fragment key={editorKey}>
                              <div
                                style={{
                                  width: `${width}%`,
                                  position: "relative",
                                  height: "100%",
                                  visibility: isEditorVisible(
                                    rowIndex,
                                    cell.column.id,
                                    editorIndex
                                  )
                                    ? "visible"
                                    : "hidden",
                                  backgroundColor: isEditorVisible(
                                    rowIndex,
                                    cell.column.id,
                                    editorIndex
                                  )
                                    ? "white"
                                    : "#f5f5f5",
                                  cursor:
                                    activeEditor ===
                                    `${rowIndex}-${cell.column.id}-${editorIndex}`
                                      ? "text"
                                      : "default",
                                }}
                                className="editor-wrapper"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEditorFocus(
                                    rowIndex,
                                    cell.column.id,
                                    editorIndex
                                  );
                                  setActiveEditor(
                                    `${rowIndex}-${cell.column.id}-${editorIndex}`
                                  );
                                }}
                                onMouseUp={(e) => {
                                  const selection = window.getSelection();
                                  const selectedText = selection.toString();

                                  if (selectedText) {
                                    const range = selection.getRangeAt(0);
                                    const rect = range.getBoundingClientRect();
                                    const tableRect =
                                      tableRef.current.getBoundingClientRect();
                                    const contentElement =
                                      document.querySelector(".content");
                                    const scale = parseFloat(
                                      contentElement?.style.transform.match(
                                        /scale\((.*?)\)/
                                      )?.[1] || 1
                                    );

                                    // 클릭한 실제 위치 계산
                                    const clickX = e.clientX;
                                    const clickY = e.clientY;

                                    // scale과 스크롤을 고려한 툴바 위치 계산
                                    const toolbarX =
                                      (clickX - tableRect.left) / scale;
                                    const toolbarY =
                                      (clickY - tableRect.top) / scale - 80; // ��바 높이만큼 위로 조정

                                    setSelectedText(selectedText);
                                    setShowToolbar(true);
                                    setToolbarPosition({
                                      top: toolbarY,
                                      left: toolbarX,
                                    });

                                    // 현재 선택된 에디터 정보 저장
                                    const currentEditorKey = `${rowIndex}-${cell.column.id}-${editorIndex}`;
                                    setActiveEditor(currentEditorKey);
                                  }
                                }}
                              >
                                {isEditorReady ? (
                                  <Editor
                                    key={`editor-${rowIndex}-${cell.column.id}-${editorIndex}`}
                                    data-editor-key={`${rowIndex}-${cell.column.id}-${editorIndex}`}
                                    editorState={getOrCreateEditorState(
                                      rowIndex,
                                      cell.column.id,
                                      editorIndex
                                    )}
                                    onChange={(newState) => {
                                      handleEditorChange(
                                        newState,
                                        rowIndex,
                                        cell.column.id,
                                        editorIndex
                                      );
                                    }}
                                    onCompositionStart={() => {
                                      setIsComposing(true);
                                    }}
                                    onCompositionEnd={() => {
                                      setIsComposing(false);
                                      const editorState =
                                        getOrCreateEditorState(
                                          rowIndex,
                                          cell.column.id,
                                          editorIndex
                                        );
                                      if (editorState) {
                                        handleEditorChange(
                                          editorState,
                                          rowIndex,
                                          cell.column.id,
                                          editorIndex
                                        );
                                      }
                                    }}
                                    // 정렬 및 스타일 관련 속성 추가
                                    blockStyleFn={blockStyleFn}
                                    customStyleMap={styleMap}
                                    textAlignment={
                                      data[rowIndex]?.[cell.column.id]?.[
                                        editorIndex
                                      ]?.blockData?.textAlign || "left"
                                    }
                                    stripPastedStyles={true}
                                    handleReturn={handleReturn}
                                    handleKeyCommand={handleKeyCommand}
                                    keyBindingFn={keyBindingFn}
                                    style={editorStyle}
                                    handlePastedText={(text) =>
                                      handlePaste(
                                        text,
                                        getOrCreateEditorState(
                                          rowIndex,
                                          cell.column.id,
                                          editorIndex
                                        ),
                                        rowIndex,
                                        cell.column.id,
                                        editorIndex
                                      )
                                    }
                                  />
                                ) : null}
                                {editorIndex <
                                  (
                                    editorWidths[
                                      `${rowIndex}-${cell.column.id}`
                                    ] || [100]
                                  ).length -
                                    1 && (
                                  <EditorDivider
                                    onMouseDown={(e) => {
                                      if (isRemovingSplit) {
                                        e.preventDefault();
                                        handleDividerClick(
                                          e,
                                          rowIndex,
                                          cell.column.id,
                                          editorIndex
                                        );
                                      } else {
                                        e.preventDefault();
                                        startEditorResize(
                                          rowIndex,
                                          cell.column.id,
                                          editorIndex,
                                          e
                                        );
                                      }
                                    }}
                                    style={{
                                      right:
                                        editorIndex ===
                                        (
                                          editorWidths[
                                            `${rowIndex}-${cell.column.id}`
                                          ] || [100]
                                        ).length -
                                          1
                                          ? "0"
                                          : "-1px",
                                    }}
                                    isRemovingSplit={isRemovingSplit}
                                  />
                                )}
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div
        ref={dividerRef}
        className={`column-divider ${
          layout === "layout5" || layout === "layout6" ? "hidden" : ""
        }`}
        style={{
          position: "absolute",
          top: "0",
          left: `${columnWidths.col1}%`,
          width: "10px",
          height: "100%",
          background: "transparent",
          cursor: "col-resize",
          zIndex: 1000,
          display:
            layout === "layout5" || layout === "layout6" ? "none" : "block",
        }}
        onMouseDown={handleColumnDividerMouseDown}
      />
    </div>
  );
};

export default AnotherComponent;
