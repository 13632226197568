import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../css/Calendar.css";
import Moveable from "react-moveable";
import { useCalendar } from "../contexts/CalendarContext";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const db = getFirestore();
const auth = getAuth();

// Firebase에서 데이터 가져오기
export const fetchCellContentsFromFirebase = async (view) => {
  const user = auth.currentUser;
  if (!user) return {};

  const docRef = doc(db, `users/${user.uid}/Calendar/${view}`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return {};
  }
};

// Firebase에 데이터 저장하기
export const saveCellContentsToFirebase = async (contents, view) => {
  const user = auth.currentUser;
  if (!user) return;

  const docRef = doc(db, `users/${user.uid}/Calendar/${view}`);
  await setDoc(docRef, contents, { merge: true });
};

const fetchCellContentsForPeriod = async (startDate, endDate) => {
  const user = auth.currentUser;
  if (!user) return {};

  const docRef = doc(db, `users/${user.uid}/Calendar/month1andweek1`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const allData = docSnap.data();
    return Object.fromEntries(
      Object.entries(allData).filter(([key]) => {
        const date = new Date(key);
        return date >= startDate && date <= endDate;
      })
    );
  } else {
    return {};
  }
};

// months 배열을 컴포넌트 외부에서 정의합니다.
const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

const springColors = [
  { name: "Spring 1", color: "rgba(241, 223, 213, 0.2)" },
  { name: "Spring 2", color: "rgba(227, 226, 224, 0.2)" },
  { name: "Spring 3", color: "rgba(223, 233, 235, 0.2)" },
  { name: "Spring 4", color: "rgba(241, 238, 233, 0.2)" },
  { name: "Spring 5", color: "rgba(194, 183, 177, 0.2)" },
  { name: "Spring 6", color: "rgba(225, 215, 205, 0.2)" },
  { name: "Spring 7", color: "rgba(212, 226, 239, 0.2)" },
];

const summerColors = [
  { name: "Summer 1", color: "rgba(251, 211, 212, 0.2)" },
  { name: "Summer 2", color: "rgba(254, 236, 194, 0.2)" },
  { name: "Summer 3", color: "rgba(252, 220, 197, 0.2)" },
  { name: "Summer 4", color: "rgba(243, 178, 156, 0.2)" },
  { name: "Summer 5", color: "rgba(195, 221, 196, 0.2)" },
  { name: "Summer 6", color: "rgba(203, 238, 240, 0.2)" },
  { name: "Summer 7", color: "rgba(221, 220, 239, 0.2)" },
];

const autumnColors = [
  { name: "Autumn 1", color: "rgba(220, 196, 142, 0.2)" },
  { name: "Autumn 2", color: "rgba(234, 239, 211, 0.2)" },
  { name: "Autumn 3", color: "rgba(179, 192, 164, 0.2)" },
  { name: "Autumn 4", color: "rgba(80, 81, 104, 0.2)" },
  { name: "Autumn 5", color: "rgba(39, 35, 58, 0.2)" },
  { name: "Autumn 6", color: "rgba(136, 90, 90, 0.2)" },
  { name: "Autumn 7", color: "rgba(53, 58, 71, 0.2)" },
];

const winterColors = [
  { name: "Winter 1", color: "rgba(35, 50, 83, 0.2)" },
  { name: "Winter 2", color: "rgba(155, 169, 208, 0.2)" },
  { name: "Winter 3", color: "rgba(199, 216, 232, 0.2)" },
  { name: "Winter 4", color: "rgba(222, 232, 242, 0.2)" },
  { name: "Winter 5", color: "rgba(217, 220, 237, 0.2)" },
  { name: "Winter 6", color: "rgba(198, 200, 215, 0.2)" },
  { name: "Winter 7", color: "rgba(246, 245, 250, 0.2)" },
];

// Firebase에 week2 색상 데이터 저장하기
const saveWeek2ColorsToFirebase = async (colors) => {
  const user = auth.currentUser;
  if (!user) return;

  const docRef = doc(db, `users/${user.uid}/Calendar/week2`);
  await setDoc(docRef, { timeBlockColors: colors }, { merge: true });
};

// Firebase에서 week2 색상 데이터 가져오기
const fetchWeek2ColorsFromFirebase = async () => {
  const user = auth.currentUser;
  if (!user) return {};

  const docRef = doc(db, `users/${user.uid}/Calendar/week2`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data().timeBlockColors || {};
  } else {
    return {};
  }
};

// 초기 색상 이름 설정
const defaultColorNames = {
  "#9DB4C0": "Subject 1", // 부드러운 블루 그레이
  "#C2B0C9": "Subject 2", // 라벤더 퍼플
  "#86A69D": "Subject 3", // 세이지 그린
  "#C9A9A6": "Subject 4", // 로즈 톤
  "#A4BE7B": "Subject 5", // 올리브 그린
  "#DEB6AB": "Subject 6", // 피치 톤
  "#B5C0D0": "Subject 7", // 스틸 블루
  transparent: "Remove Background",
};

// Firebase에 색상 이름 저장하기
const saveColorNamesToFirebase = async (names) => {
  const user = auth.currentUser;
  if (!user) return;

  const docRef = doc(db, `users/${user.uid}/Calendar/week2`);
  await setDoc(docRef, { colorNames: names }, { merge: true });
};

// Firebase에서 색상 이름 가져오기
const fetchColorNamesFromFirebase = async () => {
  const user = auth.currentUser;
  if (!user) return defaultColorNames;

  const docRef = doc(db, `users/${user.uid}/Calendar/week2`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data().colorNames || defaultColorNames;
  } else {
    return defaultColorNames;
  }
};

const saveDayCellContentsToFirebase = async (key, content) => {
  const user = auth.currentUser;
  if (!user) return;

  const docRef = doc(db, `users/${user.uid}/Calendar/day1`);
  const docSnap = await getDoc(docRef);
  const existingData = docSnap.exists() ? docSnap.data() : {};

  const newContents = { ...existingData, [key]: content };
  await setDoc(docRef, newContents, { merge: true });
};

const saveDayCellBackgroundColorsToFirebase = async (colors) => {
  const user = auth.currentUser;
  if (!user) return;

  const docRef = doc(db, `users/${user.uid}/Calendar/day1`);
  const docSnap = await getDoc(docRef);
  const existingData = docSnap.exists() ? docSnap.data() : {};

  const newColors = { ...existingData, dayCellBackgroundColors: colors };
  await setDoc(docRef, newColors, { merge: true });
};

const saveDayColumnWidthsToFirebase = async (widths) => {
  const user = auth.currentUser;
  if (!user) return;

  const docRef = doc(db, `users/${user.uid}/Calendar/day1`);
  const docSnap = await getDoc(docRef);
  const existingData = docSnap.exists() ? docSnap.data() : {};

  const newWidths = { ...existingData, dayColumnWidths: widths };
  await setDoc(docRef, newWidths, { merge: true });
};

// Firebase에서 배경색 데이터 가져오기
const fetchDayCellBackgroundColorsFromFirebase = async () => {
  const user = auth.currentUser;
  if (!user) return {};

  const docRef = doc(db, `users/${user.uid}/Calendar/day1`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists() && docSnap.data().dayCellBackgroundColors) {
    return docSnap.data().dayCellBackgroundColors;
  }
  return {};
};

// Firebase 저장/불러오기 함수 추가
const saveYearCellContentsToFirebase = async (contents) => {
  const user = auth.currentUser;
  if (!user) return;

  const docRef = doc(db, `users/${user.uid}/Calendar/year`);
  await setDoc(docRef, { yearCellContents: contents }, { merge: true });
};

const fetchYearCellContentsFromFirebase = async () => {
  const user = auth.currentUser;
  if (!user) return {};

  const docRef = doc(db, `users/${user.uid}/Calendar/year`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists() && docSnap.data().yearCellContents) {
    return docSnap.data().yearCellContents;
  }
  return {};
};

// 일간 뷰 데이터 fetch 함수 추가
const fetchDayCellContentsFromFirebase = async (date) => {
  const user = auth.currentUser;
  if (!user) return {};

  const docRef = doc(db, `users/${user.uid}/Calendar/day1`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  }
  return {};
};

const Calendar = () => {
  const {
    monthlyContents = {},
    weeklyContents = {},
    updateMonthlyContents,
    updateWeeklyContents,
  } = useCalendar();
  const [columnWidths, setColumnWidths] = useState([
    16.67, 16.67, 16.67, 16.67, 16.67, 16.67,
  ]);
  const [isResizing, setIsResizing] = useState(false);
  const resizeIndex = useRef(null);
  const today = new Date();
  const [selectedMonth, setSelectedMonth] = useState(
    months[today.getMonth()] || months[0]
  );
  const [selectedYear, setSelectedYear] = useState(today.getFullYear());
  const [rowCount, setRowCount] = useState(6);
  const [view, setView] = useState("month"); // 새로운 상태 추가
  const [selectedWeek, setSelectedWeek] = useState(() => {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), 1);
    const firstDay = start.getDay();
    const week = Math.floor(
      (now.getDate() + (firstDay === 0 ? 6 : firstDay - 1)) / 7
    );
    return Math.min(5, week);
  });
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [editingCell, setEditingCell] = useState(null);
  const [font, setFont] = useState("TTPrincessLike");
  const quillRefs = useRef({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImages] = useState({
    month: {},
    week1: {},
    week2: {},
    day: {},
    year: {},
  });
  const [target, setTarget] = useState(null);
  const [clipTarget, setClipTarget] = useState(null);
  const targetRefs = useRef([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [yearCellContents, setYearCellContents] = useState({});
  const [editingYearCell, setEditingYearCell] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dayCellContents, setDayCellContents] = useState({});
  const [selectedTimeBlocks, setSelectedTimeBlocks] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [showMiniToolbar, setShowMiniToolbar] = useState(false);
  const [miniToolbarPosition, setMiniToolbarPosition] = useState({
    x: 0,
    y: 0,
  });
  const [timeBlockColors, setTimeBlockColors] = useState({});
  const [colorNames, setColorNames] = useState(defaultColorNames);

  // fonts 배열 추가
  const fonts = [
    { name: "THEGaeideuk", url: "/fonts/THEGaeideuk.ttf" },
    { name: "TTAgainSea", url: "/fonts/TTAgainSea.ttf" },
    { name: "TTAPleasantDay", url: "/fonts/TTAPleasantDay.ttf" },
    { name: "TTARainyDayBK", url: "/fonts/TTARainyDayBK.ttf" },
    { name: "TTEveryDayThank", url: "/fonts/TTEveryDayThank.ttf" },

    { name: "TTLovelySSong", url: "/fonts/TTLovelySSong.ttf" },
    { name: "TTOhilyeoJoha", url: "/fonts/TTOhilyeoJoha.ttf" },
    { name: "TTOnABetterDay", url: "/fonts/TTOnABetterDay.ttf" },
    { name: "TTPenguinHeart", url: "/fonts/TTPenguinHeart.ttf" },
    { name: "TTPenWorkBook", url: "/fonts/TTPenWorkBook.ttf" },
    { name: "TTPrincessLike", url: "/fonts/TTPrincessLike.ttf" },

    { name: "TTTodayGulimM", url: "/fonts/TTTodayGulimM.ttf" },
  ];

  const [showFontOptions, setShowFontOptions] = useState(false);
  const [showFontColors, setShowFontColors] = useState(false);
  const [fontColor, setFontColor] = useState("#e1e1e1");
  const fontColorRef = useRef(null);

  const handleFontColorChange = (color) => {
    setFontColor(color);
    applyStyle("color", color);
    setShowFontColors(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        fontColorRef.current &&
        !fontColorRef.current.contains(event.target)
      ) {
        setShowFontColors(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const daysInMonth = getDaysInMonth(
      selectedYear,
      months.indexOf(selectedMonth)
    );
    const firstDay = getFirstDayOfMonth(
      selectedYear,
      months.indexOf(selectedMonth)
    );
    const totalDays = firstDay + daysInMonth;
    const newRowCount = Math.ceil(totalDays / 7);
    setRowCount(newRowCount);
  }, [selectedMonth, selectedYear]);

  const handlePrevYear = () => {
    setSelectedYear((prev) => prev - 1);
    setSelectedDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setFullYear(newDate.getFullYear() - 1);
      return newDate;
    });
  };

  const handleNextYear = () => {
    setSelectedYear((prev) => prev + 1);
    setSelectedDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setFullYear(newDate.getFullYear() + 1);
      return newDate;
    });
  };

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    const firstDay = new Date(year, month, 1).getDay();
    return firstDay === 0 ? 6 : firstDay - 1; // 일요일(0)을 6으로 변경
  };

  const getCalendarCellStyle = (isToday) => {
    return isToday
      ? {
          // backgroundColor: "#f7f5f4", // 오늘 날짜의 배경색 제거
        }
      : {};
  };

  const [selectedCell, setSelectedCell] = useState(null);
  const [cellBackgroundColors, setCellBackgroundColors] = useState({});
  const [selectedColorPalette, setSelectedColorPalette] =
    useState(springColors);

  const handleCellClick = (day) => {
    setEditingCell(day);
    setSelectedCell(day);
    // 이전에 선택된 의 'selected' 클래스 제거
    const prevSelectedCell = document.querySelector(".calendar-cell.selected");
    if (prevSelectedCell) {
      prevSelectedCell.classList.remove("selected");
    }
    // 새로 선택된 셀에 'selected' 클래스 추가
    const newSelectedCell = document.querySelector(
      `.calendar-cell[data-day="${day}"]`
    );
    if (newSelectedCell) {
      newSelectedCell.classList.add("selected");
    }
  };

  const [showBackgroundColors, setShowBackgroundColors] = useState(false);
  const backgroundColorRef = useRef(null);

  const handleBackgroundColorChange = (color) => {
    if (selectedCell) {
      setCellBackgroundColors((prev) => {
        const newColors = { ...prev, [selectedCell]: color };
        saveDayCellBackgroundColorsToFirebase(newColors); // Firebase에 저장
        return newColors;
      });
    } else if (selectedDayCell) {
      setDayCellBackgroundColors((prev) => {
        const newColors = { ...prev, [selectedDayCell]: color };
        saveDayCellBackgroundColorsToFirebase(newColors); // Firebase에 저장
        return newColors;
      });
    }
    setShowBackgroundColors(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        backgroundColorRef.current &&
        !backgroundColorRef.current.contains(event.target)
      ) {
        setShowBackgroundColors(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderBackgroundColorOptions = () => {
    const darkModePastelColors = [
      { name: "Lavender", color: "rgba(187, 173, 255, 0.15)" },
      { name: "Rose", color: "rgba(255, 173, 173, 0.15)" },
      { name: "Mint", color: "rgba(173, 255, 201, 0.15)" },
      { name: "Sky", color: "rgba(173, 217, 255, 0.15)" },
      { name: "Peach", color: "rgba(255, 214, 173, 0.15)" },
      { name: "Lilac", color: "rgba(226, 173, 255, 0.15)" },
      { name: "Sage", color: "rgba(200, 255, 173, 0.15)" },
      { name: "Ocean", color: "rgba(173, 255, 247, 0.15)" },
      { name: "Coral", color: "rgba(255, 173, 201, 0.15)" },
      { name: "Periwinkle", color: "rgba(173, 181, 255, 0.15)" },
      { name: "Honeydew", color: "rgba(236, 255, 173, 0.15)" },
      { name: "Azure", color: "rgba(173, 247, 255, 0.15)" },
      { name: "Blush", color: "rgba(255, 173, 173, 0.15)" },
      { name: "Clear", color: "#1e1e1e" },
    ];

    return (
      <div className="background-colors">
        {darkModePastelColors.map((colorOption) => (
          <button
            key={colorOption.name}
            className="background-color-button"
            style={{ backgroundColor: colorOption.color }}
            onClick={() => handleBackgroundColorChange(colorOption.color)}
            title={colorOption.name}
          />
        ))}
      </div>
    );
  };

  const handleCellContentChange = (day, content) => {
    if (view === "week1") {
      // 위클리 1뷰용 데이터 저장
      updateWeeklyContents(day, content);
      saveCellContentsToFirebase(
        {
          ...weeklyContents,
          [day]: content,
        },
        "week1"
      );
    } else if (view === "month") {
      // 먼슬리뷰용 데이터 저장
      updateMonthlyContents(day, content);
      saveCellContentsToFirebase(
        {
          ...monthlyContents,
          [day]: content,
        },
        "monthly"
      );
    }
  };

  const handleDayCellContentChange = (key, content) => {
    setDayCellContents((prev) => {
      const newContents = { ...prev, [key]: content };
      saveDayCellContentsToFirebase(key, content);
      return newContents;
    });
  };

  const applyStyle = useCallback(
    (style, value) => {
      if (quillRefs.current[editingCell]) {
        const editor = quillRefs.current[editingCell].getEditor();
        const selection = editor.getSelection();
        if (selection) {
          switch (style) {
            case "bold":
              editor.format("bold", !editor.getFormat(selection).bold);
              break;
            case "italic":
              editor.format("italic", !editor.getFormat(selection).italic);
              break;
            case "underline":
              editor.format(
                "underline",
                !editor.getFormat(selection).underline
              );
              break;
            case "strike":
              editor.format("strike", !editor.getFormat(selection).strike);
              break;
            case "color":
              editor.format("color", value);
              break;
          }
        }
      }
    },
    [editingCell]
  );

  const handleColorChange = (e) => {
    applyStyle("color", e.target.value);
  };

  const renderCalendarDays = () => {
    const daysInMonth = getDaysInMonth(
      selectedYear,
      months.indexOf(selectedMonth)
    );
    const firstDay = getFirstDayOfMonth(
      selectedYear,
      months.indexOf(selectedMonth)
    );
    const days = [];
    const today = new Date();

    // 빈 셀 추가
    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} />);
    }

    // 달력 날짜 셀 생성
    for (let i = 1; i <= daysInMonth; i++) {
      const isToday =
        i === today.getDate() &&
        selectedMonth === months[today.getMonth()] &&
        selectedYear === today.getFullYear();

      const day = `${selectedYear}-${selectedMonth}-${i}`;
      const isEditing = editingCell === day;
      const cellStyle = getCalendarCellStyle(isToday);

      days.push(
        <div
          key={i}
          className={`calendar-cell ${isToday ? "today" : ""} ${
            isEditing ? "editing" : ""
          } ${selectedCell === day ? "selected" : ""}`}
          style={{ ...cellStyle, backgroundColor: cellBackgroundColors[day] }}
          onClick={() => handleCellClick(day)}
        >
          <div className={isToday ? "today-date" : ""}>{i}</div>
          <div className="cell-content">
            <ReactQuill
              ref={(el) => (quillRefs.current[day] = el)}
              value={monthlyContents?.[day] || ""} // 안전한 접근을 위해 옵셔널 체이닝 추가
              onChange={(content) => handleCellContentChange(day, content)}
              onFocus={() => setEditingCell(day)}
              modules={{
                toolbar: false,
              }}
              formats={[
                "bold",
                "italic",
                "underline",
                "strike",
                "color",
                "size",
              ]}
              style={{ height: "100%" }}
              className="no-spellcheck"
            />
          </div>
        </div>
      );
    }

    return days;
  };

  const renderWeekCalendar1 = () => {
    const startOfWeek = new Date(
      selectedYear,
      months.indexOf(selectedMonth),
      1 + selectedWeek * 7 - 1
    );
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1);
    const days = [];

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(startOfWeek);
      currentDate.setDate(startOfWeek.getDate() + i);
      const isToday = currentDate.toDateString() === today.toDateString();
      const day = `${currentDate.getFullYear()}-${
        months[currentDate.getMonth()]
      }-${currentDate.getDate()}`;
      const isEditing = editingCell === day;
      const cellStyle = getCalendarCellStyle(isToday);

      days.push(
        <div
          key={i}
          className={`calendar-cell ${isToday ? "today" : ""} ${
            isEditing ? "editing" : ""
          } ${selectedCell === day ? "selected" : ""}`}
          style={cellStyle}
          onClick={() => handleCellClick(day)}
        >
          <div>{currentDate.getDate()}</div>
          <div className="cell-content">
            <ReactQuill
              ref={(el) => (quillRefs.current[day] = el)}
              value={weeklyContents[day] || ""}
              onChange={(content) => handleCellContentChange(day, content)}
              onFocus={() => setEditingCell(day)}
              modules={{
                toolbar: false,
              }}
              formats={[
                "bold",
                "italic",
                "underline",
                "strike",
                "color",
                "size",
              ]}
              style={{ height: "100%" }}
              className="no-spellcheck"
            />
          </div>
        </div>
      );
    }

    return days;
  };

  const handleTimeBlockMouseDown = (day, hour, minute) => {
    setIsDragging(true);
    const timeKey = `${day}-${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}`;
    setSelectedTimeBlocks([timeKey]);
  };

  const handleTimeBlockMouseEnter = (day, hour, minute) => {
    if (isDragging) {
      const timeKey = `${day}-${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`;
      setSelectedTimeBlocks((prev) => [...new Set([...prev, timeKey])]);
    }
  };

  const handleTimeBlockMouseUp = (e) => {
    setIsDragging(false);
    setShowMiniToolbar(true);
    setMiniToolbarPosition({
      x: window.innerWidth / 2,
      y: window.innerHeight - 100,
    });
  };

  const hexToRGBA = (hex, alpha = 1) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const handleColorSelect = (color) => {
    if (color === "transparent") {
      setSelectedTimeBlocks((prevSelected) => {
        const newColors = { ...timeBlockColors };
        prevSelected.forEach((timeKey) => {
          delete newColors[timeKey];
        });
        setTimeBlockColors(newColors);

        saveWeek2ColorsToFirebase(newColors);

        return [];
      });
    } else {
      const colorWithOpacity = hexToRGBA(color, 0.8);
      setSelectedTimeBlocks((prevSelected) => {
        const newColors = { ...timeBlockColors };
        prevSelected.forEach((timeKey) => {
          newColors[timeKey] = colorWithOpacity;
        });
        setTimeBlockColors(newColors);

        saveWeek2ColorsToFirebase(newColors);

        return [];
      });
    }
    setShowMiniToolbar(false);
  };

  const handleColorNameChange = (color, newName) => {
    setColorNames((prevNames) => {
      const newNames = { ...prevNames, [color]: newName };
      saveColorNamesToFirebase(newNames);
      return newNames;
    });
  };

  useEffect(() => {
    const loadWeek2Data = async () => {
      const colors = await fetchWeek2ColorsFromFirebase();
      setTimeBlockColors(colors);

      const names = await fetchColorNamesFromFirebase();
      setColorNames(names);
    };

    loadWeek2Data();
  }, []);

  const calculateStudyTime = (day, color) => {
    let count = 0;
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 10) {
        const timeKey = `${day}-${hour.toString().padStart(2, "0")}:${minute
          .toString()
          .padStart(2, "0")}`;
        if (timeBlockColors[timeKey] === hexToRGBA(color, 0.8)) {
          count++;
        }
      }
    }
    return count * 10;
  };

  const formatStudyTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    if (hours > 0) {
      return `${hours}hr ${mins}min`;
    }
    return `${mins}min`;
  };

  const renderWeekCalendar2 = () => {
    const startOfWeek = new Date(
      selectedYear,
      months.indexOf(selectedMonth),
      1 + selectedWeek * 7 - 1
    );
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1);
    const days = [];

    days.push(
      <div
        key="time-column"
        className="week2-time-column"
        style={{ width: "20px" }}
      >
        <div className="week2-time-cells-container">
          {Array.from({ length: 24 }, (_, i) => (
            <div key={`time-${i}`} className="week2-time-cell">
              {i.toString().padStart(2, "0")}
            </div>
          ))}
        </div>
      </div>
    );

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(startOfWeek);
      currentDate.setDate(startOfWeek.getDate() + i);
      const isToday = currentDate.toDateString() === today.toDateString();
      const day = `${currentDate.getFullYear()}-${
        months[currentDate.getMonth()]
      }-${currentDate.getDate()}`;
      const isEditing = editingCell === day;
      const cellStyle = getCalendarCellStyle(isToday);
      cellStyle.className += " week2-cell";

      const timeBlocks = [];
      for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 10) {
          const timeKey = `${day}-${hour.toString().padStart(2, "0")}:${minute
            .toString()
            .padStart(2, "0")}`;
          timeBlocks.push(
            <div
              key={timeKey}
              className={`time-block week2-cell ${
                selectedTimeBlocks.includes(timeKey) ? "selected" : ""
              }`}
              style={{ backgroundColor: timeBlockColors[timeKey] || "" }}
              onMouseDown={() => handleTimeBlockMouseDown(day, hour, minute)}
              onMouseEnter={() => handleTimeBlockMouseEnter(day, hour, minute)}
              onMouseUp={handleTimeBlockMouseUp}
            ></div>
          );
        }
      }

      // 각 색상별 공부시간 계산
      const studyTimes = [
        "#9DB4C0",
        "#C2B0C9",
        "#86A69D",
        "#C9A9A6",
        "#A4BE7B",
        "#DEB6AB",
        "#B5C0D0",
      ]
        .map((color) => ({
          color,
          time: calculateStudyTime(day, color),
          name: colorNames[color],
        }))
        .filter((item) => item.time > 0); // 공부시간이 0보다 큰 것만 필터링

      // 총 공부시간 계산
      const totalStudyTime = studyTimes.reduce(
        (total, item) => total + item.time,
        0
      );

      // 색상 박스 렌더링
      const colorBoxes = studyTimes.map(({ color, time, name }) => (
        <div key={`color-box-${color}`} className="week2-color-box">
          <div style={{ backgroundColor: color }} />
          <span className="color-name">
            {name} ({formatStudyTime(time)})
          </span>
        </div>
      ));

      // 총 공부시간 표시 추가
      if (totalStudyTime > 0) {
        colorBoxes.push(
          <div key="total-study-time" className="week2-total-study-time">
            Total : {formatStudyTime(totalStudyTime)}
          </div>
        );
      }

      days.push(
        <div
          key={i}
          className={`calendar-cell week2-cell ${isToday ? "today" : ""} ${
            isEditing ? "editing" : ""
          } ${selectedCell === day ? "selected" : ""}`}
          style={cellStyle}
          onClick={() => handleCellClick(day)}
        >
          <div className="week2-date">
            <span className="week2-day-name">
              {
                ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"][
                  currentDate.getDay() === 0 ? 6 : currentDate.getDay() - 1
                ]
              }
            </span>
            <span className="week2-day-number">{currentDate.getDate()}</span>
          </div>
          <div className="time-blocks-container">{timeBlocks}</div>
          <div className="week2-color-boxes">{colorBoxes}</div>
        </div>
      );
    }

    return days;
  };

  const handleYearCellDoubleClick = (month, day, e) => {
    e.stopPropagation();
    setEditingYearCell(`${selectedYear}-${month}-${day}`);
  };

  const handleYearCellInputChange = (month, day, e) => {
    const key = `${selectedYear}-${month}-${day}`;
    const newContents = {
      ...yearCellContents,
      [key]: e.target.value,
    };
    setYearCellContents(newContents);
    saveYearCellContentsToFirebase(newContents);
  };

  const handleYearCellInputBlur = () => {
    setEditingYearCell(null);
  };

  const renderYearCalendar = () => {
    const yearCalendar = [];

    const monthHeaders = months.map((month) => (
      <div key={`header-${month}`} className="year-month-header">
        {month}
      </div>
    ));
    yearCalendar.push(
      <div key="month-headers" className="year-row month-headers">
        {monthHeaders}
      </div>
    );

    for (let day = 1; day <= 31; day++) {
      const dayRow = months.map((month, monthIndex) => {
        const daysInMonth = getDaysInMonth(selectedYear, monthIndex);
        const isValidDate = day <= daysInMonth;
        const date = new Date(selectedYear, monthIndex, day);
        const dayOfWeek = date.getDay();
        const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
        const cellKey = `${selectedYear}-${month}-${day}`;
        const isEditing = editingYearCell === cellKey;

        return (
          <div
            key={`cell-${month}-${day}`}
            className={`year-cell ${isValidDate ? "" : "invalid"} ${
              isWeekend ? "weekend" : ""
            } ${selectedCell === cellKey ? "selected" : ""}`}
            onDoubleClick={(e) =>
              isValidDate && handleYearCellDoubleClick(month, day, e)
            }
            onClick={() => handleCellClick(cellKey)}
          >
            {isValidDate && (
              <>
                <span className="day-number">{day}</span>
                {isEditing ? (
                  <input
                    type="text"
                    value={yearCellContents[cellKey] || ""}
                    onChange={(e) => handleYearCellInputChange(month, day, e)}
                    onBlur={handleYearCellInputBlur}
                    autoFocus
                  />
                ) : (
                  <span className="year-cell-content">
                    {yearCellContents[cellKey]}
                  </span>
                )}
              </>
            )}
          </div>
        );
      });
      yearCalendar.push(
        <div key={`row-${day}`} className="year-row">
          {dayRow}
        </div>
      );
    }

    return yearCalendar;
  };

  const [selectedDayCell, setSelectedDayCell] = useState(null);
  const [dayCellBackgroundColors, setDayCellBackgroundColors] = useState({});
  const [rowColumnWidths, setRowColumnWidths] = useState({});
  const [dayColumnWidths, setDayColumnWidths] = useState(() => {
    const savedWidths = localStorage.getItem("dayColumnWidths");
    return savedWidths ? JSON.parse(savedWidths) : {};
  });

  const renderDayCalendar = () => {
    const timeSlots = [];
    for (let hour = 4; hour < 28; hour++) {
      const displayHour = hour % 24;
      timeSlots.push(`${displayHour.toString().padStart(2, "0")}:00`);
    }

    const dateKey = selectedDate.toISOString().split("T")[0];
    const dayWidths = dayColumnWidths[dateKey] || {};

    return (
      <div className="day-grid">
        <div className="daytable-header">
          <div className="time-label"></div>
          {[10, 20, 30, 40, 50, 60].map((num) => (
            <div key={`header-${num}`} className="header-column">
              {num}
            </div>
          ))}
        </div>

        {timeSlots.map((time) => {
          const key = `${selectedDate.toISOString().split("T")[0]}-${time}`;
          return (
            <div key={time} className="day-cell">
              <div className="time-label">{time}</div>
              <div className="time-content">
                {[0, 1, 2, 3, 4, 5].map((column) => {
                  const columnKey = `${key}-column-${column}`;
                  const columnWidth = dayWidths[time]?.[column] || 100 / 6;
                  return (
                    <div
                      key={columnKey}
                      className="time-column"
                      onClick={() => handleDayCellClick(columnKey)}
                      style={{
                        backgroundColor:
                          dayCellBackgroundColors[columnKey] || "transparent",
                        width: `${columnWidth}%`,
                      }}
                    >
                      <ReactQuill
                        ref={(el) => (quillRefs.current[columnKey] = el)}
                        value={dayCellContents[columnKey] || ""}
                        onChange={(content) =>
                          handleDayCellContentChange(columnKey, content)
                        }
                        onFocus={() => setEditingCell(columnKey)}
                        modules={{
                          toolbar: false,
                        }}
                        formats={[
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "color",
                          "size",
                        ]}
                        style={{ height: "100%" }}
                        className="no-spellcheck"
                      />
                      {column < 5 && (
                        <div
                          className="calendar-resize-handle"
                          onMouseDown={(e) => handleResize(e, time, column)}
                        ></div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const handleDayCellClick = (cellKey) => {
    setSelectedDayCell(cellKey);
    setSelectedCell(null); // 월간 뷰의 선택된 셀 초기화
  };

  const handlePrevWeek = () => {
    setSelectedWeek((prev) => Math.max(0, prev - 1));
  };

  const handleNextWeek = () => {
    setSelectedWeek((prev) => Math.min(5, prev + 1));
  };

  const handleFontChange = (newFont) => {
    setFont(newFont);
    document.documentElement.style.setProperty("--calendar-font", newFont);
  };

  useEffect(() => {
    document.documentElement.style.setProperty("--calendar-font", font);
    const fontToLoad = fonts.find((f) => f.name === font);
    if (fontToLoad && fontToLoad.url) {
      const fontFace = new FontFace(font, `url(${fontToLoad.url})`);
      fontFace
        .load()
        .then((loadedFont) => {
          document.fonts.add(loadedFont);
          document.body.style.fontFamily = font;
        })
        .catch((error) => {});
    } else {
      document.body.style.fontFamily = font;
    }
  }, [font]);

  const handleImageSelect = (imageSrc) => {
    let key;
    if (view === "day" || view === "day2") {
      key = selectedDate.toISOString().split("T")[0]; // 'YYYY-MM-DD' 형식
    } else if (view === "week1" || view === "week2") {
      // 주간 뷰를 위한 키 생성
      const startOfWeek = new Date(
        selectedYear,
        months.indexOf(selectedMonth),
        1 + selectedWeek * 7
      );
      key = `${selectedYear}-${selectedMonth}-W${selectedWeek + 1}`;
    } else {
      key = `${selectedYear}-${selectedMonth}`;
    }
    setImages((prevImages) => ({
      ...prevImages,
      [view]: {
        ...prevImages[view],
        [key]: [
          ...(prevImages[view]?.[key] || []),
          { src: imageSrc, x: "50%", y: "50%" },
        ],
      },
    }));
  };

  const handleDeleteImage = (index) => {
    let key;
    if (view === "day") {
      key = selectedDate.toISOString().split("T")[0];
    } else {
      key = `${selectedYear}-${selectedMonth}`;
    }
    setImages((prevImages) => ({
      ...prevImages,
      [view]: {
        ...prevImages[view],
        [key]: prevImages[view][key].filter((_, i) => i !== index),
      },
    }));
    setTarget(null);
    setClipTarget(null);
    setSelectedImageIndex(null);
  };

  const handleImageClick = (index, e) => {
    e.stopPropagation();
    const targetElement = targetRefs.current[index];
    setTarget(targetElement);
    setSelectedTarget(targetElement);
    setSelectedImageIndex(index);
  };

  const handleDoubleClick = (index) => {
    setClipTarget(targetRefs.current[index]);
  };

  const handleDrag = ({ target, beforeTranslate }) => {
    if (target && target.parentElement) {
      let x, y;

      if (view === "day") {
        // 데일리 뷰에서의 위치 계산
        const dayCalendar = document.querySelector(".day-calendar");
        const dayCalendarRect = dayCalendar.getBoundingClientRect();
        const scrollTop = dayCalendar.scrollTop;

        x = (beforeTranslate[0] / dayCalendarRect.width) * 100;
        y = ((beforeTranslate[1] + scrollTop) / dayCalendarRect.height) * 100;
      } else {
        // 다른 뷰에서의 위치 계산
        const containerRect = target.parentElement.getBoundingClientRect();
        x = (beforeTranslate[0] / containerRect.width) * 100;
        y = (beforeTranslate[1] / containerRect.height) * 100;
      }

      target.style.left = `${x}%`;
      target.style.top = `${y}%`;

      // 이미지 위치 상태 업데이트
      const index = targetRefs.current.indexOf(target);
      setImages((prevImages) => {
        const key =
          view === "day"
            ? selectedDate.toISOString().split("T")[0]
            : `${selectedYear}-${selectedMonth}`;
        const newImages = { ...prevImages };
        if (
          newImages[view] &&
          newImages[view][key] &&
          newImages[view][key][index]
        ) {
          newImages[view][key][index] = {
            ...newImages[view][key][index],
            x: `${x}%`,
            y: `${y}%`,
          };
        }
        return newImages;
      });
    }
  };

  const miniToolbarRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        selectedTarget &&
        (event.key === "Backspace" || event.key === "Delete")
      ) {
        handleDeleteImage(selectedImageIndex);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedTarget, selectedImageIndex]);

  const handleOutsideClick = useCallback(
    (e) => {
      if (selectedTarget && !selectedTarget.contains(e.target)) {
        setSelectedTarget(null);
        setTarget(null);
        setClipTarget(null);
      }
    },
    [selectedTarget]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showMiniToolbar) {
        const isTimeBlock = event.target.closest(".time-block");
        const isMiniToolbar = event.target.closest(".mini-toolbar");

        if (!isTimeBlock && !isMiniToolbar) {
          setShowMiniToolbar(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMiniToolbar]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        selectedImageIndex !== null &&
        (event.key === "Backspace" || event.key === "Delete")
      ) {
        handleDeleteImage(selectedImageIndex);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedImageIndex]);

  const renderImages = () => {
    let key;
    if (view === "day" || view === "day2") {
      key = selectedDate.toISOString().split("T")[0];
    } else if (view === "week1" || view === "week2") {
      const startOfWeek = new Date(
        selectedYear,
        months.indexOf(selectedMonth),
        1 + selectedWeek * 7
      );
      key = `${selectedYear}-${selectedMonth}-W${selectedWeek + 1}`;
    } else {
      key = `${selectedYear}-${selectedMonth}`;
    }

    // images[view]가 undefined일 경우를 처리
    if (!images[view]) {
      return [];
    }

    // images[view][key]가 undefined일 경우를 처리
    const viewImages = images[view][key] || [];

    return viewImages.map((image, index) => (
      <img
        key={index}
        ref={(el) => (targetRefs.current[index] = el)}
        src={image.src}
        alt={`Image ${index + 1}`}
        className={`inserted-image ${
          view === "day" || view === "day2" ? "day-view-image" : ""
        }`}
        style={{
          top:
            view === "day" || view === "day2"
              ? `calc(${image.y} + var(--scroll-top, 0px))`
              : image.y,
          left: image.x,
          position: "absolute",
        }}
        onClick={(e) => handleImageClick(index, e)}
        onDoubleClick={() => handleDoubleClick(index)}
      />
    ));
  };

  useEffect(() => {
    const dayCalendar = document.querySelector(".day-calendar");
    if (dayCalendar && view === "day") {
      const handleScroll = () => {
        document.documentElement.style.setProperty(
          "--scroll-top",
          `${-dayCalendar.scrollTop}px`
        );
      };
      dayCalendar.addEventListener("scroll", handleScroll);
      return () => dayCalendar.removeEventListener("scroll", handleScroll);
    }
  }, [view]);

  const handlePrevDay = () => {
    setSelectedDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() - 1);
      return newDate;
    });
  };

  const handleNextDay = () => {
    setSelectedDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + 1);
      return newDate;
    });
  };

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
    setSelectedDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setMonth(months.indexOf(month));
      return newDate;
    });
  };

  const renderMonthTabs = () => {
    return (
      <div className="month-tabs">
        {months.map((month) => (
          <button
            key={month}
            className={`month-tab ${selectedMonth === month ? "active" : ""}`}
            onClick={() => handleMonthChange(month)}
          >
            {month}
          </button>
        ))}
      </div>
    );
  };

  const handleResize = (e, time, column) => {
    const isFirstColumn = column === 0;

    const startX = e.clientX;
    const startWidth = e.target.parentElement.getBoundingClientRect().width;
    const containerWidth =
      e.target.parentElement.parentElement.getBoundingClientRect().width;

    const onMouseMove = (moveEvent) => {
      const deltaX = moveEvent.clientX - startX;

      // 첫 번째 열의 리사이저인 경우 최대 너비를 99.8%로 변경
      let newPercentage;
      if (isFirstColumn) {
        newPercentage = Math.min(
          99.8, // 최대값을 99.8%로 수정
          Math.max(5, ((startWidth + deltaX) / containerWidth) * 100)
        );
      } else {
        newPercentage = Math.max(
          5,
          ((startWidth + deltaX) / containerWidth) * 100
        );
      }

      setDayColumnWidths((prevWidths) => {
        const dateKey = selectedDate.toISOString().split("T")[0];
        const currentWidths = prevWidths[dateKey]?.[time] || {};

        // 첫 번째 열의 리사이저인 경우 특별한 분배 로직 적용
        if (isFirstColumn) {
          const remainingWidth = 100 - newPercentage;
          const remainingColumns = 5;
          const widthPerColumn = remainingWidth / remainingColumns;

          const columnWidths = {
            0: newPercentage,
          };

          // 나머지 열들의 너비를 균등하게 분배
          for (let i = 1; i < 6; i++) {
            columnWidths[i] = widthPerColumn;
          }

          const newWidths = {
            ...prevWidths,
            [dateKey]: {
              ...(prevWidths[dateKey] || {}),
              [time]: columnWidths,
            },
          };

          localStorage.setItem("dayColumnWidths", JSON.stringify(newWidths));
          saveDayColumnWidthsToFirebase(newWidths);
          return newWidths;
        } else {
          // 다른 열들은 기존 로직 유지
          const newWidths = {
            ...prevWidths,
            [dateKey]: {
              ...(prevWidths[dateKey] || {}),
              [time]: {
                ...currentWidths,
                [column]: newPercentage,
              },
            },
          };

          localStorage.setItem("dayColumnWidths", JSON.stringify(newWidths));
          saveDayColumnWidthsToFirebase(newWidths);
          return newWidths;
        }
      });
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  // 기존 상태에 추가
  const [showFontSizeOptions, setShowFontSizeOptions] = useState(false);

  // 기존 코드 내에 추가
  const fontSizes = [
    { value: "small", label: "Small" },
    { value: "normal", label: "Normal" },
    { value: "large", label: "Large" },
    { value: "huge", label: "Extra Large" },
  ];

  const handleDayCellBackgroundColorChange = (newColors) => {
    console.log("배경색 변경:", newColors);

    setDayCellBackgroundColors(newColors);
    console.log("Firebase에 배경색 저장 시작");

    saveDayCellBackgroundColorsToFirebase(newColors)
      .then(() => console.log("배경색 저장 완료"))
      .catch((error) => console.error("배경색 저장 실패:", error));
  };

  // Firebase 데이터 로딩 로직 수정
  useEffect(() => {
    const loadInitialData = async () => {
      try {
        // 배경색 데이터 로딩
        const colors = await fetchDayCellBackgroundColorsFromFirebase();
        setDayCellBackgroundColors(colors);
        setCellBackgroundColors(colors);

        // 셀 컨텐츠 데이터 로딩
        const contents = await fetchCellContentsFromFirebase("monthly");
        updateMonthlyContents(null, contents); // 전체 데이터 업데이트

        // 연간 뷰 데이터 로딩
        const yearContents = await fetchYearCellContentsFromFirebase();
        setYearCellContents(yearContents);
      } catch (error) {
        console.error("초기 데이터 로딩 중 오류:", error);
      }
    };

    // 사용자가 로그인했을 때만 데이터 로딩
    if (auth.currentUser) {
      loadInitialData();
    }
  }, [auth.currentUser]); // auth.currentUser를 의존성 배열에 추가

  // view가 변경될 때마다 해당 뷰의 데이터 새로 로딩
  useEffect(() => {
    const loadViewSpecificData = async () => {
      if (!auth.currentUser) return;

      try {
        switch (view) {
          case "week1":
            // week1 데이터 로딩 추가
            const week1Contents = await fetchCellContentsFromFirebase("week1");
            updateWeeklyContents(null, week1Contents);
            break;
          case "week2":
            const week2Colors = await fetchWeek2ColorsFromFirebase();
            setTimeBlockColors(week2Colors);
            const names = await fetchColorNamesFromFirebase();
            setColorNames(names);
            break;
        }
      } catch (error) {
        console.error(`${view} 뷰 데이터 로딩 중 오류:`, error);
      }
    };

    loadViewSpecificData();
  }, [view, auth.currentUser]);

  // selectedDate가 변경될 때마다 일간 뷰 데이터 로드
  useEffect(() => {
    const loadDayViewData = async () => {
      if (view === "day" && auth.currentUser) {
        try {
          const dayData = await fetchDayCellContentsFromFirebase(selectedDate);
          // 배경색과 컨텐츠 데이터 설정
          setDayCellBackgroundColors(dayData.dayCellBackgroundColors || {});
          setDayCellContents(
            Object.fromEntries(
              Object.entries(dayData).filter(
                ([key]) => !key.includes("dayCellBackgroundColors")
              )
            )
          );

          // 컬럼 너비 데이터가 있다면 설정
          if (dayData.dayColumnWidths) {
            setDayColumnWidths(dayData.dayColumnWidths);
          }
        } catch (error) {
          console.error("일간 뷰 데이터 로딩 중 오류:", error);
        }
      }
    };

    loadDayViewData();
  }, [selectedDate, view, auth.currentUser]);

  return (
    <div
      className="calendar-page"
      style={{ fontFamily: font }}
      data-view={view}
      onMouseUp={() => setIsDragging(false)}
    >
      {renderMonthTabs()}
      <div className="calendar-wrapper">
        <div className="calendar-header">
          <div className="year-selector">
            <button className="year-button" onClick={handlePrevYear}>
              &lt;
            </button>
            <span className="year-display">{selectedYear}</span>
            <button className="year-button" onClick={handleNextYear}>
              &gt;
            </button>
          </div>
          <div className="view-selector">
            <div className="tooltip">
              <button onClick={() => setView("month")}>
                <img src="/toolbaricons/inuse/monthly.svg" alt="월간" />
              </button>
              <span className="tooltiptext">Monthly</span>
            </div>
            <div className="tooltip">
              <button onClick={() => setView("week1")}>
                <img src="/toolbaricons/inuse/weekly.svg" alt="주간 1" />
              </button>
              <span className="tooltiptext">Weekly</span>
            </div>
            <div className="tooltip">
              <button onClick={() => setView("week2")}>
                <img src="/toolbaricons/inuse/weekly.svg" alt="주간 2" />
              </button>
              <span className="tooltiptext">Timetracker</span>
            </div>
            <div className="tooltip">
              <button onClick={() => setView("day")}>
                <img src="/toolbaricons/inuse/daily.svg" alt="일간" />
              </button>
              <span className="tooltiptext">Daily</span>
            </div>
            <div className="tooltip">
              <button onClick={() => setView("year")}>
                <img src="/toolbaricons/inuse/yearly.svg" alt="연간" />
              </button>
              <span className="tooltiptext">Yearly</span>
            </div>
          </div>
        </div>
        <div className="calendar-content">
          <div className="calendar-container" style={{ position: "relative" }}>
            {view === "month" && (
              <div className={`calendar-grid rows-${rowCount}`}>
                <div className="weekday-cell">MON</div>
                <div className="weekday-cell">TUE</div>
                <div className="weekday-cell">WED</div>
                <div className="weekday-cell">THU</div>
                <div className="weekday-cell">FRI</div>
                <div className="weekday-cell">SAT</div>
                <div className="weekday-cell">SUN</div>
                {renderCalendarDays()}
                {renderImages()}
              </div>
            )}
            {view === "week1" && (
              <div className="calendar-grid rows-1 week-view">
                <div className="weekday-cell">MON</div>
                <div className="weekday-cell">TUE</div>
                <div className="weekday-cell">WED</div>
                <div className="weekday-cell">THU</div>
                <div className="weekday-cell">FRI</div>
                <div className="weekday-cell">SAT</div>
                <div className="weekday-cell">SUN</div>
                {renderWeekCalendar1()}
                {renderImages()}
              </div>
            )}
            {view === "week2" && (
              <div className="calendar-grid rows-1 week-view week2-view">
                {renderWeekCalendar2()}
                {renderImages()}
              </div>
            )}
            {view === "day" && (
              <div className="day-calendar">
                <div className="day-header">
                  <button onClick={handlePrevDay}>&lt;</button>
                  <h2>{`${selectedDate.getDate()} (${
                    ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"][
                      selectedDate.getDay()
                    ]
                  })`}</h2>
                  <button onClick={handleNextDay}>&gt;</button>
                </div>
                <div className="day-container">
                  {renderDayCalendar()}
                  {renderImages()}
                </div>
              </div>
            )}
            {view === "year" && (
              <div className="year-calendar">
                <div className="year-grid">
                  {renderYearCalendar()}
                  {renderImages()}
                </div>
              </div>
            )}
          </div>
          {selectedImage && <img src={selectedImage} alt="Selected mood" />}
        </div>
        {(view === "week1" || view === "week2") && (
          <div className="week-navigation">
            <button onClick={handlePrevWeek}>&lt;</button>
            <span>Week {selectedWeek + 1}</span>
            <button onClick={handleNextWeek}>&gt;</button>
          </div>
        )}
      </div>
      {view !== "year" && (
        <div className="mini-bar">
          <button onClick={() => applyStyle("bold")}>
            <img
              src="/toolbaricons/inuse/bold.svg"
              alt="Bold"
              className="mini-bar-icon"
            />
          </button>
          <button onClick={() => applyStyle("italic")}>
            <img
              src="/toolbaricons/inuse/italic.svg"
              alt="Italic"
              className="mini-bar-icon"
            />
          </button>
          <button onClick={() => applyStyle("underline")}>
            <img
              src="/toolbaricons/inuse/underline.svg"
              alt="Underline"
              className="mini-bar-icon"
            />
          </button>
          <button onClick={() => applyStyle("strike")}>
            <img
              src="/toolbaricons/inuse/cancleline.svg"
              alt="Strike"
              className="mini-bar-icon"
            />
          </button>
          <div className="font-color-container" ref={fontColorRef}>
            <button
              onClick={() => setShowFontColors(!showFontColors)}
              style={{
                backgroundColor: fontColor,
                width: "20px",
                height: "20px",
              }}
              title="글자 색상 변경"
            />
            {showFontColors && (
              <div className="font-colors">
                {[
                  "#e1e1e1", // 기존 검은색을 밝은 회색으로 변경
                  "#F24722",
                  "#FFA629",
                  "#FFCD29",
                  "#15AE5C",
                  "#0D99FF",
                  "#9747FF",
                  "#757575",
                ].map((color) => (
                  <button
                    key={color}
                    className="font-color-button"
                    style={{ backgroundColor: color }}
                    onClick={() => handleFontColorChange(color)}
                  />
                ))}
              </div>
            )}
          </div>
          {view !== "week1" && view !== "week2" && (
            <div
              className="background-color-container"
              ref={backgroundColorRef}
            >
              <button
                onClick={() => setShowBackgroundColors(!showBackgroundColors)}
                style={{
                  backgroundColor: selectedCell
                    ? cellBackgroundColors[selectedCell] || "transparent"
                    : selectedDayCell
                    ? dayCellBackgroundColors[selectedDayCell] || "transparent"
                    : "transparent",
                  width: "24px",
                  height: "24px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
                title="배경 색상 변경"
              />
              {showBackgroundColors && renderBackgroundColorOptions()}
            </div>
          )}
          <div className="sidebar-font-selector">
            <button
              onClick={() => setShowFontOptions(!showFontOptions)}
              title="폰트 선택"
            >
              {font}
            </button>
            {showFontOptions && (
              <ul
                className="sidebar-font-options"
                style={{ bottom: "100%", top: "auto" }}
              >
                {fonts.map((fontOption) => (
                  <li
                    key={fontOption.name}
                    onClick={() => {
                      handleFontChange(fontOption.name);
                      setShowFontOptions(false);
                    }}
                  >
                    <span style={{ fontFamily: fontOption.name }}>
                      {fontOption.name}
                    </span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}

      {target && (
        <Moveable
          target={target}
          draggable={true}
          scalable={true}
          rotatable={true}
          onDragStart={({ set }) => {
            if (target && target.parentElement) {
              const rect = target.getBoundingClientRect();
              const containerRect =
                target.parentElement.getBoundingClientRect();
              const scrollTop = target.parentElement.scrollTop || 0;
              const scrollLeft = target.parentElement.scrollLeft || 0;
              set([
                rect.left - containerRect.left + scrollLeft,
                rect.top - containerRect.top + scrollTop,
              ]);
            }
          }}
          onDrag={handleDrag}
          onScale={(e) => {
            if (e.target) {
              e.target.style.transform = e.transform;
            }
          }}
          onRotate={(e) => {
            if (e.target) {
              e.target.style.transform = e.transform;
            }
          }}
        />
      )}
      {clipTarget && (
        <Moveable
          target={clipTarget}
          clippable={true}
          clipRelative={false}
          clipArea={false}
          dragWithClip={false}
          defaultClipPath={"inset"}
          clipTargetBounds={false}
          onClip={(e) => {
            e.target.style.clipPath = e.clipStyle;
          }}
        />
      )}
      {showMiniToolbar && (
        <div
          ref={miniToolbarRef}
          className="mini-toolbar"
          style={{
            position: "fixed",
            left: miniToolbarPosition.x,
            top: miniToolbarPosition.y,
          }}
        >
          {/* 컬러 옵션을 고정된 순서로 정렬 */}
          {Object.entries(colorNames)
            .sort(([colorA], [colorB]) => {
              const colorOrder = [
                "#9DB4C0",
                "#C2B0C9",
                "#86A69D",
                "#C9A9A6",
                "#A4BE7B",
                "#DEB6AB",
                "#B5C0D0",
                "transparent",
              ];
              return colorOrder.indexOf(colorA) - colorOrder.indexOf(colorB);
            })
            .map(([color, name]) => (
              <div key={color} className="calendar-color-option">
                <button
                  style={{
                    backgroundColor: color === "transparent" ? "white" : color,
                    width: "30px",
                    height: "30px",
                    border: color === "transparent" ? "1px solid #ccc" : "none",
                  }}
                  onClick={() => handleColorSelect(color)}
                />
                <input
                  type="text"
                  value={name}
                  onChange={(e) => handleColorNameChange(color, e.target.value)}
                  className="color-name-input"
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default Calendar;
