import { useState, useCallback } from "react";

export const useCurriculumState = (initialState) => {
  const [curriculum, setCurriculum] = useState(
    initialState?.curriculum || {
      sections: [],
      chapters: {},
      units: {},
      subunits: {},
    }
  );
  const [currentUnitId, setCurrentUnitId] = useState(initialState?.unitId);
  const [currentSubunit, setCurrentSubunit] = useState({ id: "", name: "" });
  const [currentSubunitName, setCurrentSubunitName] = useState("");

  const handleSubunitNameChange = useCallback((unitId, subunitId, newName) => {
    setCurriculum((prev) => {
      const newCurriculum = { ...prev };
      if (newCurriculum.subunits[unitId]) {
        newCurriculum.subunits[unitId] = newCurriculum.subunits[unitId].map(
          (subunit) =>
            subunit.id === subunitId ? { ...subunit, name: newName } : subunit
        );
      }
      return newCurriculum;
    });
  }, []);

  return {
    curriculum,
    setCurriculum,
    currentUnitId,
    setCurrentUnitId,
    currentSubunit,
    setCurrentSubunit,
    currentSubunitName,
    setCurrentSubunitName,
    handleSubunitNameChange,
  };
};
