import React, { useState, useEffect } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { auth, db } from "../firebase/firebase";
import { useCurriculum } from "../contexts/CurriculumContext";
import { useNavigate, useParams } from "react-router-dom";

const FlyoutMenu8 = ({ onClose, courseId }) => {
  const { courseName } = useParams();
  const [selectedTab, setSelectedTab] = useState("1");
  const [tabNames, setTabNames] = useState(["1", "2", "3", "4", "5"]);
  const [stampedSubunits, setStampedSubunits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Get required data from CurriculumContext
  const { curriculum, getSubunitNameById } = useCurriculum();

  // Load stamp names from Firebase
  useEffect(() => {
    const loadStampNames = async () => {
      try {
        const user = auth.currentUser;
        if (!user) return;

        const stampNamesRef = doc(
          db,
          "users",
          user.uid,
          "courses",
          courseId,
          "stampNames",
          "labels"
        );
        const docSnap = await getDoc(stampNamesRef);

        if (docSnap.exists()) {
          setTabNames(docSnap.data().names);
        }
      } catch (error) {
        console.error("Failed to load stamp names:", error);
      }
    };

    loadStampNames();
  }, [courseId]);

  // Handle stamp name change
  const handleTabNameChange = async (index, newName) => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      const newNames = [...tabNames];
      newNames[index] = newName;
      setTabNames(newNames);

      const stampNamesRef = doc(
        db,
        "users",
        user.uid,
        "courses",
        courseId,
        "stampNames",
        "labels"
      );
      await setDoc(stampNamesRef, { names: newNames }, { merge: true });
    } catch (error) {
      console.error("Failed to save stamp name:", error);
    }
  };

  // Find subunits with stamps
  const findSubunitsWithStamp = async (stampId) => {
    setIsLoading(true);
    try {
      const user = auth.currentUser;
      if (!user) return;

      // 한 번에 여러 문서를 가져오기 위해 배치 작업 사용
      const batchSize = 10; // 한 번에 처리할 서브유닛 수
      const allSubunits = [];
      Object.entries(curriculum.subunits).forEach(([unitId, subunits]) => {
        subunits.forEach((subunit) => {
          allSubunits.push({
            id: subunit.id,
            name: subunit.name,
            unitId: unitId,
          });
        });
      });

      const subunitsWithStamp = [];

      // 배치 처리
      for (let i = 0; i < allSubunits.length; i += batchSize) {
        const batch = allSubunits.slice(i, i + batchSize);
        const promises = batch.map((subunit) =>
          getDoc(
            doc(
              db,
              "users",
              user.uid,
              "courses",
              courseId,
              "curriculum",
              "stamps",
              "subunits",
              subunit.id
            )
          )
        );

        const results = await Promise.all(promises);

        results.forEach((docSnap, index) => {
          const subunit = batch[index];
          const stampsData = docSnap.exists() ? docSnap.data() : null;

          if (stampsData && Array.isArray(stampsData.data)) {
            // 가장 최근에 추가된 스탬프 찾기
            const latestStamp = stampsData.data
              .filter((stamp) => stamp.stampId === stampId)
              .sort((a, b) => b.timestamp - a.timestamp)[0];

            if (latestStamp) {
              subunitsWithStamp.push(subunit);
            }
          }
        });
      }

      setStampedSubunits(subunitsWithStamp);
    } catch (error) {
      console.error("❌ Failed to load stamp data:", error);
      setStampedSubunits([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    findSubunitsWithStamp(tab);
  };

  // Navigate to subunit
  const handleSubunitClick = (subunit) => {
    navigate(
      `/courses/${courseId}/curriculum/${courseName}/unit/${subunit.unitId}/subunit/${subunit.id}`,
      {
        state: {
          subunitId: subunit.id,
          unitId: subunit.unitId,
        },
      }
    );
    onClose();
  };

  // Find subunits with stamp 1 on initial load
  useEffect(() => {
    if (curriculum) {
      // Only execute after curriculum is loaded
      findSubunitsWithStamp("1");
    }
  }, [curriculum]); // Run when curriculum changes

  return (
    <div className="flyout-panel">
      <div className="flyout-header">
        <h3>Labels</h3>
        <button className="close-button" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
          </svg>
        </button>
      </div>
      <div
        className="tab-container"
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          width: "90%",
          maxWidth: "400px",
          margin: "20px auto 0",
        }}
      >
        {tabNames.map((name, index) => (
          <button
            key={index + 1}
            className={`tab ${
              selectedTab === (index + 1).toString() ? "active" : ""
            }`}
            onClick={() => handleTabClick((index + 1).toString())}
            style={{
              position: "relative",
              padding: "10px 15px",
              margin: "0 5px",
              border: "none",
              background: "none",
              cursor: "pointer",
              flex: 1,
            }}
          >
            <div className="stamp-container">
              <img
                src={`/img/stamp/stamp${index + 1}.svg`}
                alt={`Stamp ${name}`}
                className="stamp-image-no-filter"
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
            </div>
            {selectedTab === (index + 1).toString() && (
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: "3px",
                  backgroundColor: "#fae150",
                }}
              />
            )}
          </button>
        ))}
      </div>
      <div className="tab-content">
        <p className="label-text">Label {selectedTab} name</p>
        <input
          type="text"
          value={tabNames[parseInt(selectedTab) - 1]}
          onChange={(e) =>
            handleTabNameChange(parseInt(selectedTab) - 1, e.target.value)
          }
          className="label-input"
        />

        {/* List of stamped subunits */}
        <div className="stamped-subunits-list">
          {isLoading ? (
            <div className="loading-container">
              <div className="loading-spinner"></div>
              <p>Loading...</p>
            </div>
          ) : stampedSubunits.length > 0 ? (
            stampedSubunits.map((subunit) => (
              <div
                key={subunit.id}
                className="subunit-item"
                onClick={() => handleSubunitClick(subunit)}
              >
                {subunit.name}
              </div>
            ))
          ) : (
            <p className="no-results">No subunits found with this label.</p>
          )}
        </div>
      </div>
      <style jsx>{`
        .flyout-panel {
          width: 300px;
          background-color: var(--bg-color);
          border: 1px solid var(--border-color);
          border-radius: 4px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        }
        .flyout-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 15px;
          border-bottom: 1px solid var(--border-color);
        }
        .flyout-header h3 {
          margin: 0;
          font-size: 16px;
          color: var(--text-color);
        }
        .close-button {
          background: none;
          border: none;
          cursor: pointer;
        }
        .tab-content {
          padding: 15px;
        }
        .label-text {
          color: var(--text-color);
        }
        .label-input {
          width: 100%;
          padding: 8px;
          margin-top: 5px;
          background-color: var(--input-bg);
          color: var(--text-color);
          border: 1px solid var(--border-color);
          border-radius: 4px;
        }
        .label-input:focus {
          outline: none;
          box-shadow: none;
          border-color: #fae150 !important;
        }
        .stamped-subunits-list {
          margin-top: 15px;
          max-height: 200px;
          overflow-y: auto;
        }

        .subunit-item {
          padding: 8px;
          margin: 4px 0;
          background-color: var(--item-bg);
          color: var(--text-color);
          border-radius: 4px;
          cursor: pointer;
          transition: opacity 0.2s;
        }

        .subunit-item:hover {
          opacity: 0.8;
        }
        @media (prefers-color-scheme: dark) {
          :root {
            --bg-color: #1a1a1a;
            --border-color: #333;
            --text-color: #e0e0e0;
            --input-bg: #2a2a2a;
            --item-bg: #2d2d2d;
          }
          .stamp-container {
            background-color: #ffffff;
          }
        }
        @media (prefers-color-scheme: light) {
          :root {
            --bg-color: #ffffff;
            --border-color: #e0e0e0;
            --text-color: #333333;
            --input-bg: #ffffff;
            --item-bg: #f5f5f5;
          }
          .stamp-container {
            background-color: #ffffff;
          }
        }
        .stamp-container {
          width: 40px;
          height: 40px;
          background-color: #ffffff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
        }
        .loading-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 20px;
          color: var(--text-color);
        }

        .loading-spinner {
          width: 30px;
          height: 30px;
          border: 3px solid var(--border-color);
          border-top: 3px solid #fae150;
          border-radius: 50%;
          animation: spin 1s linear infinite;
          margin-bottom: 10px;
        }

        .no-results {
          text-align: center;
          color: var(--text-color);
          opacity: 0.7;
          padding: 20px;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        .stamp-image-no-filter {
          filter: none !important;
        }

        /* 기존 active 필터 스타일을 무시 */
        .layout1-button.active .stamp-image-no-filter,
        button.active .stamp-image-no-filter {
          filter: none !important;
        }
      `}</style>
    </div>
  );
};

export default FlyoutMenu8;
