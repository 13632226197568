// src/components/Sidebar.js
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "../context/UserContext";
import dashboardIcon from "../assets/icons/inuse/Dashboard.svg";
import coursesIcon from "../assets/icons/inuse/courses.svg";
import calendarIcon from "../assets/icons/inuse/calendar.svg";
import todolistIcon from "../assets/icons/inuse/todolist.svg";
import settingsIcon from "../assets/icons/inuse/settings.svg";
import windowLayoutIcon from "../assets/icons/inuse/window-layout.svg";

import { getAvatarSrc } from "../utils/avatarUtils";
import "../css/sidebar.css";

const Sidebar = ({ minimized, setMinimized }) => {
  const location = useLocation();
  const { userAvatar, userEmail } = useUser();

  const getUserName = (email) => {
    if (!email) return "Guest";
    return email.split("@")[0];
  };

  const toggleSidebar = () => {
    setMinimized(!minimized);
  };

  return (
    <div className={`sidebar ${minimized ? "minimized" : ""}`}>
      <button className="toggle-button" onClick={toggleSidebar}>
        <img
          src={windowLayoutIcon}
          alt="Toggle Sidebar"
          className="toggle-icon"
        />
      </button>
      <div className="user-profile">
        <div className="avatar-container">
          <img
            src={getAvatarSrc(userAvatar)}
            alt="User Avatar"
            className="avatar-icon"
          />
        </div>
        {!minimized && (
          <>
            <span className="user-name">{getUserName(userEmail)}</span>
          </>
        )}
      </div>
      <ul>
        {[
          { path: "/dashboard", icon: dashboardIcon, label: "Dashboard" },
          { path: "/dashboard/courses", icon: coursesIcon, label: "Courses" },
          {
            path: "/dashboard/calendar",
            icon: calendarIcon,
            label: "Calendar",
          },
          { path: "/dashboard/todo", icon: todolistIcon, label: "To Do List" },
          {
            path: "/dashboard/settings",
            icon: settingsIcon,
            label: "Settings",
          },
        ].map((item) => (
          <li
            key={item.path}
            className={location.pathname === item.path ? "active" : ""}
          >
            <Link to={item.path}>
              <img
                src={item.icon}
                alt={`${item.label} Icon`}
                className="sidebar-icon"
              />
              {!minimized && (
                <span className={item.label === "To Do List" ? "no-wrap" : ""}>
                  {item.label}
                </span>
              )}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
