import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "../css/Flashcards.css";
import { db, auth } from "../firebase/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

const Flashcards = () => {
  const { courseId } = useParams();
  const [decks, setDecks] = useState([]);
  const [currentDeck, setCurrentDeck] = useState(null);
  const [currentCard, setCurrentCard] = useState(0);
  const [deckInput, setDeckInput] = useState("");
  const [questionInput, setQuestionInput] = useState("");
  const [answerInput, setAnswerInput] = useState("");
  const [openDeck, setOpenDeck] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const deckRef = useRef(null);
  const [viewMode, setViewMode] = useState("card"); // 'card' or 'list'
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    message: "",
    onConfirm: null,
  });

  const ConfirmDialog = ({ isOpen, title, message, onConfirm, onCancel }) => {
    if (!isOpen) return null;

    return (
      <div className="confirm-dialog-overlay">
        <div className="confirm-dialog">
          <h3>{title}</h3>
          <p>{message}</p>
          <div className="confirm-dialog-buttons">
            <button className="confirm-button" onClick={onConfirm}>
              Confirm
            </button>
            <button className="cancel-button" onClick={onCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const loadFlashcards = async () => {
      try {
        const user = auth.currentUser;
        if (!user || !courseId) return;

        const flashcardsRef = doc(
          db,
          "users",
          user.uid,
          "courses",
          courseId,
          "flashcards",
          "list"
        );

        const docSnap = await getDoc(flashcardsRef);
        if (docSnap.exists()) {
          setDecks(docSnap.data().decks || []);
        }
      } catch (error) {
        console.error("플래시카드 로드 중 에러:", error);
      }
    };

    loadFlashcards();
  }, [courseId]);

  const toggleAnswer = (deckIndex, cardIndex) => {
    const updatedDecks = [...decks];
    updatedDecks[deckIndex].cards[cardIndex].flipped =
      !updatedDecks[deckIndex].cards[cardIndex].flipped;
    setDecks(updatedDecks);
  };

  const nextCard = () => {
    if (
      currentDeck !== null &&
      decks[currentDeck].cards &&
      currentCard < decks[currentDeck].cards.length - 1
    ) {
      setCurrentCard(currentCard + 1);
    }
  };

  const prevCard = () => {
    if (currentDeck !== null && decks[currentDeck].cards && currentCard > 0) {
      setCurrentCard(currentCard - 1);
    }
  };

  const handleAddDeck = () => {
    if (deckInput.trim()) {
      const newDecks = [...decks, { name: deckInput, cards: [] }];
      setDecks(newDecks);
      setDeckInput("");
      saveToFirebase(newDecks);
    }
  };

  const handleAddCard = () => {
    if (currentDeck !== null && questionInput.trim() && answerInput.trim()) {
      const updatedDecks = [...decks];
      updatedDecks[currentDeck].cards.push({
        question: questionInput,
        answer: answerInput,
        flipped: false,
      });
      setDecks(updatedDecks);
      setQuestionInput("");
      setAnswerInput("");
      saveToFirebase(updatedDecks);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && answerInput.trim()) {
      handleAddCard();
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const deckIndex = parseInt(result.source.droppableId);
    const updatedDecks = [...decks];
    const [reorderedCard] = updatedDecks[deckIndex].cards.splice(
      result.source.index,
      1
    );
    updatedDecks[deckIndex].cards.splice(
      result.destination.index,
      0,
      reorderedCard
    );

    setDecks(updatedDecks);
    saveToFirebase(updatedDecks);
  };

  const handleContextMenu = (event, deckIndex, cardIndex = null) => {
    event.preventDefault();
    event.stopPropagation(); // 이벤트 버블링 방지
    setContextMenu({
      x: event.clientX,
      y: event.clientY,
      deckIndex: deckIndex,
      cardIndex: cardIndex,
    });
  };
  const handleDeleteDeck = (deckIndex) => {
    setConfirmDialog({
      isOpen: true,
      title: "Delete Deck",
      message: "Are you sure you want to delete this deck?",
      onConfirm: () => {
        const updatedDecks = decks.filter((_, index) => index !== deckIndex);
        setDecks(updatedDecks);
        saveToFirebase(updatedDecks);
        setContextMenu(null);
        setConfirmDialog({ isOpen: false });
      },
    });
  };

  const handleDeleteCard = (deckIndex, cardIndex) => {
    setConfirmDialog({
      isOpen: true,
      title: "Delete Card",
      message: "Are you sure you want to delete this card?",
      onConfirm: () => {
        const updatedDecks = [...decks];
        updatedDecks[deckIndex].cards.splice(cardIndex, 1);
        setDecks(updatedDecks);
        saveToFirebase(updatedDecks);

        if (currentDeck === deckIndex) {
          if (cardIndex <= currentCard && currentCard > 0) {
            setCurrentCard(currentCard - 1);
          } else if (updatedDecks[deckIndex].cards.length === 0) {
            setCurrentCard(0);
          }
        }
        setConfirmDialog({ isOpen: false });
      },
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (deckRef.current && !deckRef.current.contains(event.target)) {
        setContextMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const saveToFirebase = async (updatedDecks) => {
    try {
      const user = auth.currentUser;
      if (!user || !courseId) return;

      const flashcardsRef = doc(
        db,
        "users",
        user.uid,
        "courses",
        courseId,
        "flashcards",
        "list"
      );

      await setDoc(
        flashcardsRef,
        {
          decks: updatedDecks,
          updatedAt: new Date().toISOString(),
        },
        { merge: true }
      );
    } catch (error) {
      console.error("플래시카드 저장 중 에러:", error);
    }
  };
  return (
    <div className="flashcards-container">
      <div className="flashcards-left">
        <div className="add-deck">
          <div className="input-button-group">
            <input
              type="text"
              value={deckInput}
              onChange={(e) => setDeckInput(e.target.value)}
              placeholder="Enter new deck name"
              className="deck-input"
            />
            <button onClick={handleAddDeck} className="add-deck-button">
              <img
                src="/toolbaricons/inuse/add.svg"
                alt="add"
                className="plus-icon"
              />
              <span className="button-text">Deck</span>
            </button>
          </div>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="deck-list" ref={deckRef}>
            {decks.map((deck, deckIndex) => (
              <div
                key={deckIndex}
                className="deck-item"
                onContextMenu={(e) => handleContextMenu(e, deckIndex)}
                onClick={() => setCurrentDeck(deckIndex)}
              >
                <h2
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenDeck(openDeck === deckIndex ? null : deckIndex);
                  }}
                >
                  {deck.name} ({deck.cards.length})
                </h2>
                {openDeck === deckIndex && (
                  <Droppable droppableId={deckIndex.toString()}>
                    {(provided) => (
                      <ul {...provided.droppableProps} ref={provided.innerRef}>
                        {deck.cards.map((card, cardIndex) => (
                          <Draggable
                            key={cardIndex}
                            draggableId={`${deckIndex}-${cardIndex}`}
                            index={cardIndex}
                          >
                            {(provided) => (
                              <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="card-preview"
                                onContextMenu={(e) =>
                                  handleContextMenu(e, deckIndex, cardIndex)
                                }
                              >
                                <div className="card-preview-question">
                                  {card.question}
                                </div>
                                <div className="card-preview-answer">
                                  {card.answer}
                                </div>
                              </li>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                )}
              </div>
            ))}
            {contextMenu && (
              <div
                className="context-menu"
                style={{ top: contextMenu.y, left: contextMenu.x }}
              >
                {contextMenu.cardIndex !== null ? (
                  <button
                    onClick={() =>
                      handleDeleteCard(
                        contextMenu.deckIndex,
                        contextMenu.cardIndex
                      )
                    }
                  >
                    Delete Card
                  </button>
                ) : (
                  <button
                    onClick={() => handleDeleteDeck(contextMenu.deckIndex)}
                  >
                    Delete Deck
                  </button>
                )}
              </div>
            )}
          </div>
        </DragDropContext>
      </div>
      <div className="flashcards-right">
        {currentDeck !== null && decks[currentDeck] ? (
          <div className="card-container">
            <div className="deck-header">
              <h3>{decks[currentDeck].name}</h3>
              <div className="view-mode-toggle">
                <button
                  className={`view-mode-button ${
                    viewMode === "card" ? "active" : ""
                  }`}
                  onClick={() => setViewMode("card")}
                >
                  Card View
                </button>
                <button
                  className={`view-mode-button ${
                    viewMode === "list" ? "active" : ""
                  }`}
                  onClick={() => setViewMode("list")}
                >
                  List View
                </button>
              </div>
            </div>
            <div className="add-card">
              <div className="card-inputs">
                <input
                  type="text"
                  value={questionInput}
                  onChange={(e) => setQuestionInput(e.target.value)}
                  placeholder="Question"
                  className="deck-input"
                />
                <input
                  type="text"
                  value={answerInput}
                  onChange={(e) => setAnswerInput(e.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder="Answer"
                  className="deck-input"
                />
              </div>
              <button onClick={handleAddCard} className="new-card-button">
                <img
                  src="/toolbaricons/inuse/add.svg"
                  alt="add"
                  className="new-card-icon"
                />
                <span className="new-card-text">Card</span>
              </button>
            </div>
            {decks[currentDeck].cards.length > 0 ? (
              <>
                {viewMode === "card" ? (
                  <div className="flashcard">
                    <div className="flashcard-label">
                      {decks[currentDeck].cards[currentCard].flipped
                        ? "Back"
                        : "Front"}
                      <button
                        className="delete-card-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteCard(currentDeck, currentCard);
                        }}
                      >
                        Delete Card
                      </button>
                    </div>
                    <div
                      className="flashcard-content"
                      onClick={() => toggleAnswer(currentDeck, currentCard)}
                    >
                      <div className="flip-hint">
                        <img
                          src="/toolbaricons/inuse/switch-flip.svg"
                          alt="flip"
                          className="flip-icon"
                        />
                        <span className="flip-text">Click to flip</span>
                      </div>
                      <div className="card-text">
                        {decks[currentDeck].cards[currentCard].flipped
                          ? decks[currentDeck].cards[currentCard].answer
                          : decks[currentDeck].cards[currentCard].question}
                      </div>
                    </div>
                    <div className="navigation">
                      <button
                        className="nav-button"
                        onClick={prevCard}
                        disabled={currentCard === 0}
                      >
                        Previous
                      </button>
                      <span className="card-counter">
                        {currentCard + 1} / {decks[currentDeck].cards.length}
                      </span>
                      <button
                        className="nav-button"
                        onClick={nextCard}
                        disabled={
                          currentCard === decks[currentDeck].cards.length - 1
                        }
                      >
                        Next
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="cards-list">
                    {decks[currentDeck].cards.map((card, index) => (
                      <div key={index} className="card-list-item">
                        <div className="card-list-content">
                          <div className="card-list-question">
                            <strong>Q:</strong> {card.question}
                          </div>
                          <div className="card-list-answer">
                            <strong>A:</strong> {card.answer}
                          </div>
                        </div>
                        <button
                          className="delete-card-button"
                          onClick={() => handleDeleteCard(currentDeck, index)}
                        >
                          Delete
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <p>There are no cards in this deck yet. Add a new card above!</p>
            )}
          </div>
        ) : (
          <p>Select a deck to study or add cards.</p>
        )}
      </div>
      {confirmDialog.isOpen && (
        <ConfirmDialog
          isOpen={confirmDialog.isOpen}
          title={confirmDialog.title}
          message={confirmDialog.message}
          onConfirm={confirmDialog.onConfirm}
          onCancel={() => setConfirmDialog({ isOpen: false })}
        />
      )}
    </div>
  );
};

export default Flashcards;
