import React, { createContext, useState } from "react";

export const FontContext = createContext();

export const FontProvider = ({ children }) => {
  const [font, setFont] = useState("Arial");

  return (
    <FontContext.Provider value={{ font, setFont }}>
      {children}
    </FontContext.Provider>
  );
};
