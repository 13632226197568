import React, { createContext, useContext, useEffect } from "react";
import { auth, db } from "../firebase/firebase";
import { doc, deleteDoc, getDoc } from "firebase/firestore";
import eventEmitter from "../utils/eventEmitter";

export const UnitNoteContext = createContext();

export const UnitNoteProvider = ({ children }) => {
  useEffect(() => {
    const handleSubunitDeleted = async ({ courseId, subunitId }) => {
      if (!auth.currentUser) {
        return;
      }

      try {
        const collections = ["canvas", "textbox", "stamps", "layout"];

        for (const collection of collections) {
          const docRef = doc(
            db,
            "users",
            auth.currentUser.uid,
            "courses",
            courseId,
            "curriculum",
            collection,
            "subunits",
            subunitId
          );

          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            await deleteDoc(docRef);
          }
        }
      } catch (error) {}
    };

    eventEmitter.on("subunitDeleted", handleSubunitDeleted);

    return () => {
      eventEmitter.off("subunitDeleted", handleSubunitDeleted);
    };
  }, []);

  return (
    <UnitNoteContext.Provider value={{}}>{children}</UnitNoteContext.Provider>
  );
};
