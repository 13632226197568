import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { db, auth } from "../firebase/firebase";
import {
  doc,
  setDoc,
  getDoc,
  serverTimestamp,
  deleteDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import EventEmitter from "../utils/eventEmitter";

const TableContext = createContext();

export const TableProvider = ({ children }) => {
  const [tableData, setTableData] = useState(null);
  const [columnWidths, setColumnWidths] = useState({ col1: 50, col2: 50 });
  const [editorWidths, setEditorWidths] = useState({});
  const [styleMap, setStyleMap] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentCourseId, setCurrentCourseId] = useState(null);
  const [currentSubunitId, setCurrentSubunitId] = useState(null);
  const [currentLoadedSubunitId, setCurrentLoadedSubunitId] = useState(null);
  const [tables, setTables] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [highlightedText, setHighlightedText] = useState(null);
  const [isRedTextActive, setIsRedTextActive] = useState(false);

  // 사용자 인증 상태 감지
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // 테이블 데이터 로드
  const loadTableData = async (courseId, subunitId) => {
    const user = await new Promise((resolve) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        unsubscribe();
        resolve(user || null);
      });

      // 타임아웃 설정 (선택사항)
      setTimeout(() => {
        unsubscribe();
        resolve(auth.currentUser);
      }, 5000);
    });

    if (!user || !courseId || !subunitId) {
      return null;
    }

    try {
      setIsLoading(true);
      const tableRef = doc(
        db,
        "users",
        user.uid,
        "courses",
        courseId.toString(),
        "curriculum",
        "tableData",
        "subunits",
        subunitId.toString()
      );

      const tableDoc = await getDoc(tableRef);

      if (!tableDoc.exists()) {
        return null;
      }

      const data = tableDoc.data();
      const initialColumnWidths = { col1: 20, col2: 80 }; // 기본값 20:80
      const savedColumnWidths = data.columnWidths || initialColumnWidths;

      // 에디터 너비 초기화 - 컬럼 너비에 맞춤
      const processedEditorWidths = {};
      if (data.tableData) {
        data.tableData.forEach((row, rowIndex) => {
          Object.keys(row).forEach((colId) => {
            const cellKey = `${rowIndex}-${colId}`;
            if (Array.isArray(row[colId])) {
              const columnWidth = savedColumnWidths[colId];
              const editorCount = row[colId].length;
              if (editorCount === 1) {
                processedEditorWidths[cellKey] = [columnWidth];
              } else {
                // 균등 분할
                processedEditorWidths[cellKey] = Array(editorCount).fill(
                  columnWidth / editorCount
                );
              }
            }
          });
        });
      }

      // 테이블 데이터 처리
      const processedData = {
        ...data,
        tableData:
          data.tableData?.map((row) => {
            if (!row) return row;
            const newRow = {};
            Object.keys(row).forEach((colId) => {
              if (Array.isArray(row[colId])) {
                newRow[colId] = row[colId].map((editor) => ({
                  ...editor,
                  blockData: {
                    textAlign:
                      editor.blockData?.textAlign ||
                      editor.textAlignment ||
                      "left",
                  },
                  textAlignment:
                    editor.blockData?.textAlign ||
                    editor.textAlignment ||
                    "left",
                }));
              }
            });
            return newRow;
          }) || [],
        columnWidths: data.columnWidths || { col1: 50, col2: 50 },
        editorWidths: data.editorWidths || {},
      };

      setTableData(processedData.tableData);
      setColumnWidths(processedData.columnWidths);
      setEditorWidths(processedData.editorWidths);

      return processedData;
    } catch (error) {
      console.error("테이블 데이터 로드 중 오류:", error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  // 새로운 서브유닛을 위한 초기 데이터 생성 함수
  const initializeNewSubunit = async (courseId, subunitId) => {
    if (!currentUser || !courseId || !subunitId) return;

    const tableRef = doc(
      db,
      "users",
      currentUser.uid,
      "courses",
      courseId.toString(),
      "curriculum",
      "tableData",
      "subunits",
      subunitId.toString()
    );
    try {
      const existingDoc = await getDoc(tableRef);
      if (existingDoc.exists()) {
        return;
      }

      const initialData = {
        tableData: [],
        columnWidths: { col1: 20, col2: 80 },
        editorWidths: {},
        styleMap: {},
        lastUpdated: serverTimestamp(),
        createdAt: serverTimestamp(),
        subunitId: subunitId,
      };

      await setDoc(tableRef, initialData, { merge: false });
    } catch (error) {}
  };

  const saveTableData = async (courseId, subunitId, newData) => {
    if (!currentUser || !courseId || !subunitId) {
      return false;
    }

    try {
      const tableRef = doc(
        db,
        "users",
        currentUser.uid,
        "courses",
        courseId.toString(),
        "curriculum",
        "tableData",
        "subunits",
        subunitId.toString()
      );

      // 각 에디터의 blockData와 텍스트 정렬 정보를 포함하여 저장
      const dataToSave = {
        tableData: newData.tableData.map((row) => {
          if (!row) return row;

          const newRow = {};
          Object.keys(row).forEach((colId) => {
            if (Array.isArray(row[colId])) {
              newRow[colId] = row[colId].map((editor) => ({
                content: editor.content || "",
                styleRanges: editor.styleRanges || [],
                blockData: {
                  ...editor.blockData,
                  textAlign:
                    editor.textAlignment ||
                    editor.blockData?.textAlign ||
                    "left",
                },
                textAlignment: editor.textAlignment || "left",
                ...editor,
              }));
            }
          });
          return newRow;
        }),
        columnWidths: newData.columnWidths || { col1: 50, col2: 50 },
        editorWidths: newData.editorWidths || {},
        styleMap: newData.styleMap || {},
        lastUpdated: serverTimestamp(),
      };

      await setDoc(tableRef, dataToSave);

      // 로컬 상태 업데이트
      setTableData(newData.tableData);
      setColumnWidths(newData.columnWidths);
      setEditorWidths(newData.editorWidths);
      setStyleMap(newData.styleMap);

      return true;
    } catch (error) {
      throw error;
    }
  };

  const setCurrentIds = useCallback(
    (courseId, subunitId) => {
      if (currentCourseId === courseId && currentSubunitId === subunitId) {
        return;
      }

      setCurrentCourseId(courseId);
      setCurrentSubunitId(subunitId);
    },
    [currentCourseId, currentSubunitId]
  );

  const updateWidths = async (
    courseId,
    subunitId,
    newColumnWidths,
    editorWidths
  ) => {
    if (!currentUser || !courseId || !subunitId) return;

    try {
      const tableRef = doc(
        db,
        "users",
        currentUser.uid,
        "courses",
        courseId.toString(),
        "curriculum",
        "tableData",
        "subunits",
        subunitId.toString()
      );

      // 현재 데이터 가져오기
      const tableDoc = await getDoc(tableRef);
      const currentData = tableDoc.exists() ? tableDoc.data() : {};

      // 데이터 업데이트
      const dataToSave = {
        ...currentData,
        columnWidths: newColumnWidths,
        editorWidths: editorWidths,
        lastUpdated: serverTimestamp(),
      };

      await setDoc(tableRef, dataToSave);

      // 로컬 상태 업데이트
      setColumnWidths(newColumnWidths);
      setEditorWidths(editorWidths);

      return true;
    } catch (error) {
      console.error("Width update error:", error);
      return false;
    }
  };

  // 디바이더 제거 후 데이터 업데이트를 위한 함수
  const updateEditorDivider = async (
    courseId,
    subunitId,
    newEditorWidths,
    newTableData
  ) => {
    if (!currentUser || !courseId || !subunitId) return;

    try {
      const tableRef = doc(
        db,
        "users",
        currentUser.uid,
        "courses",
        courseId.toString(),
        "curriculum",
        "tableData",
        "subunits",
        subunitId.toString()
      );

      const tableDoc = await getDoc(tableRef);
      const currentData = tableDoc.exists() ? tableDoc.data() : {};

      const dataToSave = {
        ...currentData,
        editorWidths: newEditorWidths,
        tableData: newTableData,
        lastUpdated: serverTimestamp(),
      };
      await setDoc(tableRef, dataToSave);

      // 로컬 상태 업데이트
      setEditorWidths(newEditorWidths);
      setTableData(newTableData);

      return true;
    } catch (error) {
      throw error;
    }
  };

  const toggleRedText = useCallback(() => {
    setIsRedTextActive((prev) => !prev);
  }, []);

  useEffect(() => {
    if (!EventEmitter?.listeners) {
      EventEmitter.listeners = {};
    }

    const handleSubunitDeleted = async ({ courseId, subunitId, unitId }) => {
      if (!auth.currentUser) return;

      try {
        // 1. 테이블 데이터 삭제
        const tableRef = doc(
          db,
          "users",
          auth.currentUser.uid,
          "courses",
          courseId,
          "curriculum",
          "tableData",
          "subunits",
          subunitId
        );
        await deleteDoc(tableRef);

        // 2. 로컬 상태 초기화
        if (currentSubunitId === subunitId) {
          setTableData(null);
          setColumnWidths({ col1: 50, col2: 50 });
          setEditorWidths({});
          setStyleMap({});
        }

        // 3. 기타 관련 데이터 삭제 (예: 이미지 등)
        // 필요한 경우 다른 컬렉션의 데이터도 여기서 삭제
      } catch (error) {
        console.error("서브유닛 관련 데이터 삭제 중 오류:", error);
      }
    };

    const handleSubunitCreated = async ({ courseId, subunitId }) => {
      if (!auth.currentUser) return;

      try {
        const tableRef = doc(
          db,
          "users",
          auth.currentUser.uid,
          "courses",
          courseId,
          "curriculum",
          "tableData",
          "subunits",
          subunitId
        );

        const existingDoc = await getDoc(tableRef);
        if (existingDoc.exists()) {
          return;
        }

        const initialData = {
          tableData: [],
          columnWidths: { col1: 20, col2: 80 },
          editorWidths: {},
          styleMap: {},
          lastUpdated: serverTimestamp(),
        };

        await setDoc(tableRef, initialData, { merge: false });
      } catch (error) {
        console.error("❌ 새 서브유닛 테이블 데이터 생성 실패:", error);
      }
    };

    EventEmitter.on("subunitDeleted", handleSubunitDeleted);
    EventEmitter.on("subunitCreated", handleSubunitCreated);

    return () => {
      // 명시적으로 이벤트 리스너 제거
      EventEmitter.off("subunitDeleted", handleSubunitDeleted);
      EventEmitter.off("subunitCreated", handleSubunitCreated);
    };
  }, []);

  // 컴포넌트가 언마운트될 때 데이터 초기화
  useEffect(() => {
    return () => {
      setTableData(null);
    };
  }, []);

  // courseId나 subunitId가 변경될 때 데이터 초기화 및 새로운 데이터 로드
  useEffect(() => {
    let isMounted = true;

    const loadData = async () => {
      if (currentCourseId && currentSubunitId) {
        setTableData(null);
        if (isMounted) {
          await loadTableData(currentCourseId, currentSubunitId);
        }
      }
    };

    loadData();

    return () => {
      isMounted = false;
      setTableData(null);
    };
  }, [currentCourseId, currentSubunitId]);

  const updateTables = (newTables) => {
    setTables(newTables);
  };

  const updateColumnWidths = async (courseId, subunitId, newColumnWidths) => {
    if (!currentUser || !courseId || !subunitId) return;

    try {
      const updatedEditorWidths = { ...editorWidths };

      // 각 셀의 에디터 너비 조정
      Object.keys(updatedEditorWidths).forEach((cellKey) => {
        const [_, columnId] = cellKey.split("-");
        const currentWidths = updatedEditorWidths[cellKey];
        const oldColumnWidth = columnWidths[columnId];
        const newColumnWidth = newColumnWidths[columnId];

        if (Array.isArray(currentWidths)) {
          // 각 에디터의 상대적 비율을 유지하며 새로운 컬럼 너비에 맞게 조정
          updatedEditorWidths[cellKey] = currentWidths.map((width) => {
            const ratio = width / oldColumnWidth;
            return ratio * newColumnWidth;
          });
        }
      });

      // Firebase 업데이트
      const tableRef = doc(
        db,
        "users",
        currentUser.uid,
        "courses",
        courseId.toString(),
        "curriculum",
        "tableData",
        "subunits",
        subunitId.toString()
      );

      await setDoc(
        tableRef,
        {
          columnWidths: newColumnWidths,
          editorWidths: updatedEditorWidths,
          lastUpdated: serverTimestamp(),
        },
        { merge: true }
      );

      // 로컬 상태 업데이트
      setColumnWidths(newColumnWidths);
      setEditorWidths(updatedEditorWidths);

      return true;
    } catch (error) {
      throw error;
    }
  };

  const searchAllTableData = async (courseId, searchTerm) => {
    if (!currentUser || !courseId || !searchTerm.trim()) return [];

    try {
      const results = [];
      const subunitsRef = collection(
        db,
        "users",
        currentUser.uid,
        "courses",
        courseId,
        "curriculum",
        "tableData",
        "subunits"
      );
      const subunitsSnapshot = await getDocs(subunitsRef);

      for (const doc of subunitsSnapshot.docs) {
        const subunitData = doc.data();
        const subunitId = doc.id;

        if (subunitData.tableData) {
          subunitData.tableData.forEach((row, rowIndex) => {
            Object.keys(row).forEach((colId) => {
              if (Array.isArray(row[colId])) {
                row[colId].forEach((editor, editorIndex) => {
                  if (
                    editor.content &&
                    editor.content
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    results.push({
                      subunitId,
                      rowIndex,
                      colId,
                      editorIndex,
                      content: editor.content,
                      matchText: searchTerm,
                    });
                  }
                });
              }
            });
          });
        }
      }

      setSearchResults(results);
      setIsSearchModalOpen(true);
      return results;
    } catch (error) {
      console.error("검색 중 오류 발생:", error);
      return [];
    }
  };

  const highlightSearchResult = (text) => {
    setHighlightedText(text);
  };

  const value = useMemo(
    () => ({
      tableData,
      columnWidths,
      editorWidths,
      styleMap,
      isLoading,
      loadTableData,
      saveTableData,
      setTableData,
      setColumnWidths,
      setEditorWidths,
      setStyleMap,
      currentCourseId,
      currentSubunitId,
      setCurrentIds,
      updateWidths,
      updateEditorDivider,
      toggleRedText,
      tables,
      setTables: updateTables,
      updateColumnWidths,
      searchAllTableData,
      searchResults,
      isSearchModalOpen,
      setIsSearchModalOpen,
      highlightedText,
      highlightSearchResult,
      isRedTextActive,
    }),
    [
      tableData,
      columnWidths,
      editorWidths,
      styleMap,
      isLoading,
      loadTableData,
      saveTableData,
      setTableData,
      setColumnWidths,
      setEditorWidths,
      setStyleMap,
      currentCourseId,
      currentSubunitId,
      setCurrentIds,
      updateWidths,
      updateEditorDivider,
      toggleRedText,
      tables,
      updateTables,
      updateColumnWidths,
      searchAllTableData,
      searchResults,
      isSearchModalOpen,
      setIsSearchModalOpen,
      highlightedText,
      highlightSearchResult,
      isRedTextActive,
    ]
  );

  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
};

export const useTable = () => {
  const context = useContext(TableContext);
  if (!context) {
    throw new Error("useTable must be used within a TableProvider");
  }
  return context;
};
