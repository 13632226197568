import React, { createContext, useState, useContext, useEffect } from "react";
import { auth } from "../firebase/config";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

const UserContext = createContext();
const db = getFirestore();

export const UserProvider = ({ children }) => {
  const [userAvatar, setUserAvatar] = useState(1);
  const [userEmail, setUserEmail] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserEmail(user.email);
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          setUserAvatar(userDoc.data().avatar || 1);
        }
      } else {
        setUserEmail(null);
        setUserAvatar(1);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const updateAvatar = async (avatarId) => {
    if (auth.currentUser) {
      await setDoc(
        doc(db, "users", auth.currentUser.uid),
        {
          avatar: avatarId,
          email: userEmail,
        },
        { merge: true }
      );
      setUserAvatar(avatarId);
    }
  };

  if (loading) {
    return null;
  }

  return (
    <UserContext.Provider
      value={{
        userAvatar,
        updateAvatar,
        userEmail,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
