import avatar1 from "../assets/img/avatar/avatar_1.svg";
import avatar2 from "../assets/img/avatar/avatar_2.svg";
import avatar3 from "../assets/img/avatar/avatar_3.svg";
import avatar4 from "../assets/img/avatar/avatar_4.svg";
import avatar5 from "../assets/img/avatar/avatar_5.svg";
import avatar6 from "../assets/img/avatar/avatar_6.svg";
import avatar7 from "../assets/img/avatar/avatar_7.svg";
import avatar8 from "../assets/img/avatar/avatar_8.svg";
import avatar9 from "../assets/img/avatar/avatar_9.svg";
import avatar10 from "../assets/img/avatar/avatar_10.svg";

const avatarMap = {
  1: avatar1,
  2: avatar2,
  3: avatar3,
  4: avatar4,
  5: avatar5,
  6: avatar6,
  7: avatar7,
  8: avatar8,
  9: avatar9,
  10: avatar10,
};

export const getAvatarSrc = (id) => {
  return avatarMap[id] || avatar1; // 기본값으로 avatar1 반환
};

export const avatarImages = Object.entries(avatarMap).map(([id, src]) => ({
  id: parseInt(id),
  src,
}));
