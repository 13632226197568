import React from "react";
import { auth } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import logo from "../assets/img/logo.svg";
import "../css/EmailVerificationPage.css";

const EmailVerificationPage = () => {
  const navigate = useNavigate();

  return (
    <div className="email-verify-page">
      <div className="email-verify-content">
        <img src={logo} alt="RedoNote Logo" className="email-verify-logo" />
        <h1 className="email-verify-title">Thanks for verifying your email!</h1>
        <p className="email-verify-text">
          Click below to return to RedoNote and keep exploring
        </p>
        <button
          className="email-verify-continue-btn"
          onClick={() => navigate("/subscription")}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default EmailVerificationPage;
