import React from "react";
import "../css/Dashboard.css";
import { useCourses } from "../contexts/CourseContext";
import { Link } from "react-router-dom";
import MiniCalendar from "../components/MiniCalendar";
import { useTasks } from "../contexts/TaskContext";
import { useState, useEffect, useMemo } from "react";

const CourseCard = ({ course }) => {
  const { updateLastAccessed } = useCourses();
  const [showImage, setShowImage] = useState(false);

  const handleClick = () => {
    updateLastAccessed(course.id);
  };

  useEffect(() => {
    // Intersection Observer를 사용하여 뷰포트에 들어올 때만 이미지 로드
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setShowImage(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    const element = document.getElementById(`course-card-${course.id}`);
    if (element) observer.observe(element);

    return () => observer.disconnect();
  }, [course.id]);

  return (
    <Link
      to={`/courses/${course.id}/curriculum/${encodeURIComponent(course.name)}`}
      id={`course-card-${course.id}`}
      className="course-card-mini"
      style={{ textDecoration: "none", color: "inherit" }}
      onClick={handleClick}
    >
      {course.thumbnail ? (
        showImage ? (
          <img
            src={course.thumbnail}
            alt={course.name}
            className="course-thumbnail-mini"
            loading="lazy"
          />
        ) : (
          <div className="thumbnail-placeholder-mini">
            {/* 로딩 중 표시할 플레이스홀더 */}
            <div className="thumbnail-skeleton"></div>
          </div>
        )
      ) : (
        <div className="default-thumbnail-mini">
          <img
            src="/toolbaricons/inuse/book.svg"
            alt="Default thumbnail"
            className="default-thumbnail-icon"
          />
        </div>
      )}
      <h3 className="course-name-mini">{course.name}</h3>
      <div className="progress-bar-mini">
        <div
          className="progress-mini"
          style={{ width: `${course.progress || 0}%` }}
        ></div>
      </div>
      <span className="progress-text-mini">
        {`${course.progress || 0}% Completed`}
      </span>
    </Link>
  );
};

const Dashboard = () => {
  const { courses, isLoading } = useCourses();
  const { taskLists, fetchTasks } = useTasks();
  const [localTaskLists, setLocalTaskLists] = useState([]);
  const [isTaskLoading, setIsTaskLoading] = useState(true);

  // 컴포넌트 마운트 시 즉시 데이터 로드
  useEffect(() => {
    const loadTasks = async () => {
      setIsTaskLoading(true);
      try {
        await fetchTasks();
      } catch (error) {
        console.error("할 일 목록 로딩 실패:", error);
      } finally {
        setIsTaskLoading(false);
      }
    };

    loadTasks();
  }, [fetchTasks]);

  useEffect(() => {
    if (taskLists && Array.isArray(taskLists) && taskLists.length > 0) {
      setLocalTaskLists(taskLists);
      setIsTaskLoading(false);
    }
  }, [taskLists]);

  // 메모이제이션 추가
  const recentCourses = useMemo(() => {
    console.log(
      "정렬 전 courses:",
      courses.map((c) => ({
        name: c.name,
        lastAccessedAt: c.lastAccessedAt
          ? new Date(c.lastAccessedAt.seconds * 1000)
          : null,
        openedAt: c.openedAt,
      }))
    );

    const sorted = [...courses]
      .sort((a, b) => {
        // Firestore Timestamp 처리
        const timeA = a.lastAccessedAt
          ? new Date(a.lastAccessedAt.seconds * 1000)
          : new Date(a.openedAt);
        const timeB = b.lastAccessedAt
          ? new Date(b.lastAccessedAt.seconds * 1000)
          : new Date(b.openedAt);

        return timeB - timeA;
      })
      .slice(0, 4);

    console.log(
      "정렬 후 과목 (최근 접근순):",
      sorted.map((c) => ({
        name: c.name,
        lastAccessedAt: c.lastAccessedAt
          ? new Date(c.lastAccessedAt.seconds * 1000)
          : null,
        openedAt: c.openedAt,
      }))
    );

    return sorted;
  }, [courses]);

  return (
    <div className="dashboard">
      <div className="overview">
        <div className="overview-header">
          <img
            src="/toolbaricons/inuse/magazine.svg"
            alt="Overview"
            className="overview-icon"
          />
          <h2>Overview</h2>
        </div>
        <div className="course-cards-mini">
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            recentCourses.map((course, index) => (
              <CourseCard key={course.id} course={course} />
            ))
          )}
        </div>
      </div>
      <div className="schedule-todo-container">
        <div className="schedule">
          <div className="schedule-header">
            <img
              src="/toolbaricons/inuse/calendar-heart1.svg"
              alt="Schedule Icon"
              className="section-icon"
            />
            <h2>Your schedule</h2>
          </div>
          <MiniCalendar />
        </div>
        <div className="todo-list">
          <div className="todolist-header">
            <img
              src="/toolbaricons/inuse/caret-down-circle.svg"
              alt="Todo list icon"
              className="section-icon"
            />
            <h2>To do list</h2>
          </div>
          <div className="dashboard-todo-list">
            {isTaskLoading ? (
              <div className="loading-message">Loading data...</div>
            ) : Array.isArray(taskLists) && taskLists.length > 0 ? (
              taskLists.map((list, listIndex) => (
                <div key={listIndex} className="dashboard-task-list">
                  <h3>{list.name}</h3>
                  <ul>
                    {list.tasks &&
                      list.tasks.map((task, taskIndex) => (
                        <li
                          key={taskIndex}
                          className={task.completed ? "completed" : ""}
                        >
                          <input
                            type="checkbox"
                            checked={task.completed}
                            readOnly
                          />
                          <span>{task.text}</span>
                        </li>
                      ))}
                  </ul>
                </div>
              ))
            ) : (
              <div className="no-tasks-message">No tasks available.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
