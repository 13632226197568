import React, { useState, useEffect } from "react";
import "../css/SubscriptionPage.css";
import { auth } from "../firebase/firebase";
import { createCheckout } from "../config/lemonsqueezy";
import LoadingIndicator from "../components/LoadingIndicator";
import { useNavigate } from "react-router-dom";

const SubscriptionPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        // 구독 상태 체크 로직
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    checkSubscription();
  }, []);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const planDetails = {
    price: "12",
    period: "month",
    billing: "billed monthly",
  };

  const handleSubscribe = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        return;
      }

      createCheckout(false, user.uid, user.email);
    } catch (error) {
      console.error("구독 처리 중 오류 발생:", error);
    }
  };

  return (
    <div className="subscription-container">
      <div className="header-section">
        <button className="back-button" onClick={() => navigate("/")}>
          ← Home
        </button>
        <h1>Full Access Plan</h1>
      </div>

      <div className="plan-card">
        <div className="plan-header">
          <h2>Full Access</h2>
          <div className="price">
            ${planDetails.price}
            <span>/{planDetails.period}</span>
          </div>
          <div className="billing-info">{planDetails.billing}</div>
        </div>

        <ul className="features-list">
          <li>✓ Unlimited Notes & Study Sets</li>
          <li>✓ Flashcards</li>
          <li>✓ Study Planner</li>
          <li>✓ Review Mode</li>
          <li>✓ All Premium Features</li>
        </ul>

        <button className="subscribe-button" onClick={handleSubscribe}>
          Start 3-Day Free Trial
        </button>
      </div>
    </div>
  );
};

export default SubscriptionPage;
