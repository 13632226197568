import React, { useEffect, useState } from "react";
import { auth } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import logo from "../assets/img/logo.svg";
import "../css/EmailVerificationPage.css";

const VerificationPage = () => {
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const checkVerification = async () => {
      const unsubscribe = auth.onAuthStateChanged(async (user) => {
        if (user) {
          await user.reload();

          if (user.emailVerified) {
            navigate("/email-verified");
          }
        }
      });

      return () => unsubscribe();
    };

    const intervalId = setInterval(checkVerification, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [navigate]);

  return (
    <div className="email-verify-page">
      <div className="email-verify-content">
        <img src={logo} alt="RedoNote Logo" className="email-verify-logo" />
        <h1 className="email-verify-title">Please verify your email</h1>
        <p className="email-verify-text">
          Check your inbox and verify your email to continue
        </p>
      </div>
    </div>
  );
};

export default VerificationPage;
