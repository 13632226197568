// src/contexts/CalendarContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { db } from "../firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { useAuth } from "./AuthContext"; // Auth 컨텍스트도 필요합니다

const CalendarContext = createContext();

export const CalendarProvider = ({ children }) => {
  const [monthlyContents, setMonthlyContents] = useState({});
  const [weeklyContents, setWeeklyContents] = useState({});
  const { currentUser } = useAuth(); // 현재 로그인된 사용자 정보 가져오기

  // Firebase에서 데이터 실시간 감지
  useEffect(() => {
    if (!currentUser) return;

    const monthlyRef = doc(db, `users/${currentUser.uid}/Calendar/monthly`);

    // 실시간 구독 설정
    const unsubscribe = onSnapshot(monthlyRef, (doc) => {
      if (doc.exists()) {
        setMonthlyContents(doc.data() || {});
      } else {
        setMonthlyContents({});
      }
    });

    // 컴포넌트 언마운트시 구독 해제
    return () => unsubscribe();
  }, [currentUser]);

  const updateMonthlyContents = (day, content) => {
    if (day === null) {
      setMonthlyContents(content);
    } else {
      setMonthlyContents((prev) => ({
        ...prev,
        [day]: content,
      }));
    }
  };

  const updateWeeklyContents = (day, content) => {
    if (day === null) {
      setWeeklyContents(content);
    } else {
      setWeeklyContents((prev) => ({
        ...prev,
        [day]: content,
      }));
    }
  };

  return (
    <CalendarContext.Provider
      value={{
        monthlyContents,
        weeklyContents,
        updateMonthlyContents,
        updateWeeklyContents,
        cellContents: monthlyContents, // MiniCalendar용 cellContents 추가
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

export const useCalendar = () => {
  const context = useContext(CalendarContext);
  if (!context) {
    throw new Error("useCalendar must be used within a CalendarProvider");
  }
  return context;
};
