import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { storage } from "../firebase/firebase";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  deleteObject,
  getMetadata,
} from "firebase/storage";
import { auth } from "../firebase/firebase";
import "../css/FileManager.css";
import { useImage } from "../contexts/ImageContext";
import { serverTimestamp } from "firebase/firestore";

const FlyoutMenu3 = ({ onClose, courseId, subunitId }) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("Images");
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { saveImageData } = useImage();
  const [uploading, setUploading] = useState(false);
  const [storageUsage, setStorageUsage] = useState(0);

  // Constants
  const MAX_STORAGE_PER_USER = 1000 * 1024 * 1024; // 1GB in bytes (1000MB)

  // 사용자의 전체 스토리지 사용량을 확인하는 함수
  const getUserStorageSize = async (userId) => {
    const userStorageRef = ref(storage, `users/${userId}`);
    let totalSize = 0;

    // 모든 하위 폴더와 파일을 재귀적으로 확인
    async function calculateSize(folderRef) {
      const items = await listAll(folderRef);

      // 현재 폴더의 파일들 크기 계산
      for (const item of items.items) {
        const metadata = await getMetadata(item);
        totalSize += metadata.size;
      }

      // 하위 폴더들 확인
      for (const folder of items.prefixes) {
        await calculateSize(folder);
      }
    }

    try {
      await calculateSize(userStorageRef);
      return totalSize;
    } catch (error) {
      console.error("Failed to calculate storage size:", error);
      return 0;
    }
  };

  // Fetch file list
  const fetchFiles = async () => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;

    setLoading(true);
    try {
      const storageRef = ref(
        storage,
        `users/${userId}/${selectedTab.toLowerCase()}`
      );
      const result = await listAll(storageRef);

      // Get download URL and name for each file
      const filesData = await Promise.all(
        result.items.map(async (item) => {
          const url = await getDownloadURL(item);
          return {
            name: item.name,
            url: url,
            path: item.fullPath,
          };
        })
      );

      setFiles(filesData);
    } catch (error) {
      console.error("Failed to fetch file list:", error);
    }
    setLoading(false);
  };

  // Refresh file list when tab changes
  useEffect(() => {
    fetchFiles();
  }, [selectedTab]);

  // File deletion functionality
  const handleDelete = async (filePath) => {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      return;
    }

    try {
      const fileRef = ref(storage, filePath);

      // Delete file from Firebase Storage
      await deleteObject(fileRef);

      // Update files state to refresh UI
      setFiles((prevFiles) =>
        prevFiles.filter((file) => file.path !== filePath)
      );
    } catch (error) {
      console.error("Failed to delete file:", error);
      alert(`File deletion failed: ${error.message}`);
    }
  };

  // Check file extension
  const getFileType = (file) => {
    const imageTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/webp",
      "image/jpg",
    ];

    if (imageTypes.includes(file.type)) {
      return "Images";
    }

    return null;
  };

  // Handle file upload
  const handleFileUpload = async (event) => {
    const files = event.target.files;
    const userId = auth.currentUser?.uid;

    if (!userId) {
      alert("Login required");
      return;
    }

    setUploading(true);

    try {
      const currentSize = await getUserStorageSize(userId);

      for (let file of files) {
        if (currentSize + file.size > MAX_STORAGE_PER_USER) {
          alert(
            `Storage space insufficient. Current usage: ${(
              currentSize /
              1024 /
              1024
            ).toFixed(2)}MB / 1000MB`
          );
          setUploading(false);
          return;
        }

        const fileType = getFileType(file);
        if (!fileType) {
          alert(`Unsupported file format: ${file.name} (${file.type})`);
          continue;
        }

        try {
          const storageRef = ref(
            storage,
            `users/${userId}/${fileType.toLowerCase()}/${file.name}`
          );
          await uploadBytes(storageRef, file);
          await getDownloadURL(storageRef);
        } catch (error) {
          console.error("File upload failed:", error);
          alert(`File upload failed: ${file.name}\nError: ${error.message}`);
        }
      }

      await fetchFiles();
    } finally {
      setUploading(false);
    }
  };

  const handleImageClick = async (file) => {
    try {
      if (!auth.currentUser) {
        alert("Login required");
        return;
      }
      // Create new image data
      const imageData = {
        id: Date.now(),
        src: file.url,
        name: file.name,
        position: { x: 100, y: 100 }, // Initial position
        size: { width: 200, height: 200 }, // Initial size
        rotation: 0,
        zIndex: 1000,
      };

      // Save image data to Firebase through ImageContext
      await saveImageData(courseId, subunitId, imageData);

      // Close flyout menu
      onClose();
    } catch (error) {
      console.error("Failed to save image:", error);
    }
  };

  const renderFileContent = (file) => {
    if (selectedTab === "Images") {
      return (
        <div
          className="image-item-container"
          onClick={() => handleImageClick(file)}
        >
          <img
            src={file.url}
            alt={file.name}
            onClick={() => handleImageClick(file)}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              cursor: "pointer",
            }}
          />
          <div className="image-item-overlay">
            <button
              className="image-item-delete"
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(file.path);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="white"
              >
                <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
              </svg>
            </button>
            <div className="image-item-filename">{file.name}</div>
          </div>
        </div>
      );
    }

    return <span>{file.name}</span>;
  };

  // Modify file list container section
  const getContainerStyle = () => {
    return {
      className:
        selectedTab === "Images" ? "file-manager-grid" : "file-manager-list",
    };
  };

  // Add drag and drop event handlers
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const droppedFiles = Array.from(e.dataTransfer.files);
    const userId = auth.currentUser?.uid;

    if (!userId) {
      alert("Login required");
      return;
    }

    setUploading(true);

    try {
      const currentSize = await getUserStorageSize(userId);

      for (let file of droppedFiles) {
        if (currentSize + file.size > MAX_STORAGE_PER_USER) {
          alert(
            `Storage space insufficient. Current usage: ${(
              currentSize /
              1024 /
              1024
            ).toFixed(2)}MB / 1000MB`
          );
          setUploading(false);
          return;
        }

        const fileType = getFileType(file);
        if (!fileType) {
          alert(`Unsupported file format: ${file.name} (${file.type})`);
          continue;
        }

        try {
          const storageRef = ref(
            storage,
            `users/${userId}/${fileType.toLowerCase()}/${file.name}`
          );
          await uploadBytes(storageRef, file);
          await getDownloadURL(storageRef);
        } catch (error) {
          alert(`File upload failed: ${file.name}\nError: ${error.message}`);
        }
      }

      await fetchFiles();
    } finally {
      setUploading(false);
    }
  };

  // 스토리지 사용량 가져오기
  const fetchStorageUsage = async () => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;

    try {
      const usage = await getUserStorageSize(userId);
      setStorageUsage(usage);
    } catch (error) {
      console.error("Failed to fetch storage usage:", error);
    }
  };

  // 컴포넌트 마운트 및 파일 업로드/삭제 후 스토리지 사용량 업데이트
  useEffect(() => {
    fetchStorageUsage();
  }, [files]);

  return (
    <div
      className="flyout-panel"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      {/* Header */}
      <div className="flyout-header">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3>Uploads</h3>
        </div>
        <button className="close-button" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="white"
          >
            <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
          </svg>
        </button>
      </div>

      {/* Storage Usage Bar */}
      <div style={{ padding: "20px 20px 0 20px", marginTop: "10px" }}>
        <div
          style={{
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "12px",
            color: "#666",
          }}
        >
          <span>Storage Usage</span>
          <span>{(storageUsage / 1024 / 1024).toFixed(1)}MB / 1000MB</span>
        </div>
        <div
          style={{
            width: "100%",
            height: "4px",
            backgroundColor: "#f0f0f0",
            borderRadius: "2px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: `${(storageUsage / MAX_STORAGE_PER_USER) * 100}%`,
              height: "100%",
              backgroundColor:
                storageUsage > MAX_STORAGE_PER_USER * 0.9
                  ? "#ff4444"
                  : "#fae150",
              transition: "width 0.3s ease",
            }}
          />
        </div>
      </div>

      {/* Upload Button Area */}
      <div
        style={{
          padding: "20px",
        }}
      >
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileUpload}
          style={{ display: "none" }}
          multiple
          disabled={uploading}
        />
        <div
          style={{
            border: "2px dashed #e0e0e0",
            borderRadius: "24px",
            padding: "20px",
            textAlign: "center",
            backgroundColor: "#f8f8f8",
            marginBottom: "10px",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <p style={{ margin: "0 0 10px 0", color: "#666" }}>
            {uploading ? "Uploading files..." : "Drag and drop files here or"}
          </p>
          <button
            className="custom-file-upload"
            onClick={() => fileInputRef.current.click()}
            disabled={uploading}
            style={{
              cursor: "pointer",
              padding: "0px 8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row-reverse",
              gap: "7px",
              border: "1px solid #ffffff",
              boxSizing: "border-box",
              borderRadius: "24px",
              backgroundColor: "#333333",
              color: "#ffffff",
              fontSize: "14px",
              fontFamily: "DM Sans",
              fontWeight: 700,
              lineHeight: "18px",
              outline: "none",
              width: "100%",
              maxWidth: "380px",
              height: "36px",
              margin: "0 auto",
              transition: "all 0.2s ease",
              "&:hover": {
                backgroundColor: "#444444",
              },
              opacity: uploading ? 0.5 : 1,
              cursor: uploading ? "not-allowed" : "pointer",
            }}
          >
            {uploading ? "Uploading..." : "Upload Image"}
          </button>
          {uploading && (
            <div
              style={{
                marginTop: "10px",
                color: "#666",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  border: "2px solid #f3f3f3",
                  borderTop: "2px solid #333",
                  borderRadius: "50%",
                  animation: "spin 1s linear infinite",
                }}
              />
              <style>
                {`
                  @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                  }
                `}
              </style>
              Uploading...
            </div>
          )}
        </div>
      </div>

      {/* File List Display Area */}
      <div
        className="files-container"
        style={{
          flex: 1,
          padding: "0 20px",
          overflowY: "auto",
          minHeight: 0,
        }}
      >
        {loading ? (
          <div>Loading...</div>
        ) : files.length === 0 ? (
          <div>No files</div>
        ) : (
          <div
            className={
              selectedTab === "Images"
                ? "file-manager-grid"
                : "file-manager-list"
            }
          >
            {files.map((file) => (
              <div
                key={file.path}
                className={selectedTab === "Images" ? "" : "list-item"}
              >
                <div style={{ flex: 1 }}>{renderFileContent(file)}</div>
                {selectedTab !== "Images" && (
                  <button
                    onClick={() => handleDelete(file.path)}
                    className="image-item-delete"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="white"
                    >
                      <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                    </svg>
                  </button>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FlyoutMenu3;
