// src/layouts/MainLayout.js
import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";

const MainLayout = ({ isSidebarMinimized, setIsSidebarMinimized }) => (
  <div className="layout">
    <Sidebar
      minimized={isSidebarMinimized}
      setMinimized={setIsSidebarMinimized}
    />
    <div className="content">
      <Outlet />
    </div>
  </div>
);

export default MainLayout;
