export const STORE_ID = process.env.REACT_APP_LEMON_SQUEEZY_STORE_ID;

export const VARIANT_IDS = {
  monthly: "624801",
  yearly: "616827",
};

// 체크아웃 생성 함수
export const createCheckout = async (isYearly, uid, email) => {
  console.log("Checkout 생성 시작:", { isYearly, uid, email });

  try {
    if (typeof window.LemonSqueezy !== "undefined") {
      window.LemonSqueezy.Setup({
        eventHandler: (event) => {
          console.log(event);
        },
      });

      const monthlyProductId = "87ea8e90-f2f9-432f-b4c3-fcc414f1e175";
      const yearlyProductId = "17ade25c-2588-4f87-ab22-eec6b4c1b5bb";
      const productId = isYearly ? yearlyProductId : monthlyProductId;

      const checkoutUrl =
        `https://redonote.lemonsqueezy.com/buy/${productId}?` +
        `checkout[email]=${encodeURIComponent(email)}&` +
        `checkout[custom][firebase_uid]=${encodeURIComponent(uid)}&` +
        `t=${Date.now()}`;

      window.LemonSqueezy.Url.Open(checkoutUrl);
    } else {
      console.error("Lemon Squeezy script not loaded");
    }
  } catch (error) {
    console.error("Checkout 생성 중 에러:", error);
    throw error;
  }
};
