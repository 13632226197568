import React, { useState, useEffect } from "react";
import "../css/MiniCalendar.css";
import { useCalendar } from "../contexts/CalendarContext";

const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

const MiniCalendar = () => {
  const today = new Date();
  const [selectedMonth, setSelectedMonth] = useState(months[today.getMonth()]);
  const [selectedYear, setSelectedYear] = useState(today.getFullYear());
  const [rowCount, setRowCount] = useState(6);
  const { cellContents = {} } = useCalendar();

  useEffect(() => {
    const daysInMonth = getDaysInMonth(
      selectedYear,
      months.indexOf(selectedMonth)
    );
    const firstDay = getFirstDayOfMonth(
      selectedYear,
      months.indexOf(selectedMonth)
    );
    const totalDays = firstDay + daysInMonth;
    const newRowCount = Math.ceil(totalDays / 7);
    setRowCount(newRowCount);
  }, [selectedMonth, selectedYear]);

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    const firstDay = new Date(year, month, 1).getDay();
    return firstDay === 0 ? 6 : firstDay - 1;
  };

  const renderCalendarDays = () => {
    const daysInMonth = getDaysInMonth(
      selectedYear,
      months.indexOf(selectedMonth)
    );
    const firstDay = getFirstDayOfMonth(
      selectedYear,
      months.indexOf(selectedMonth)
    );
    const days = [];

    for (let i = 0; i < firstDay; i++) {
      days.push(
        <div key={`empty-${i}`} className="mini-calendar-cell empty"></div>
      );
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const isToday =
        day === today.getDate() &&
        selectedMonth === months[today.getMonth()] &&
        selectedYear === today.getFullYear();
      const cellKey = `${selectedYear}-${selectedMonth}-${day}`;
      const cellContent = cellContents?.[cellKey] || "";

      days.push(
        <div
          key={day}
          className={`mini-calendar-cell ${isToday ? "today" : ""}`}
        >
          <div className="mini-calendar-day">{day}</div>
          {cellContent && (
            <div
              className="mini-calendar-content"
              dangerouslySetInnerHTML={{ __html: cellContent }}
            />
          )}
        </div>
      );
    }

    return days;
  };

  return (
    <div className="mini-calendar-wrapper">
      <div className="mini-calendar-header">
        <span>{`${selectedMonth} ${selectedYear}`}</span>
      </div>
      <div className={`mini-calendar-grid rows-${rowCount}`}>
        <div className="mini-weekday-cell">MON</div>
        <div className="mini-weekday-cell">TUE</div>
        <div className="mini-weekday-cell">WED</div>
        <div className="mini-weekday-cell">THU</div>
        <div className="mini-weekday-cell">FRI</div>
        <div className="mini-weekday-cell">SAT</div>
        <div className="mini-weekday-cell">SUN</div>
        {renderCalendarDays()}
      </div>
    </div>
  );
};

export default MiniCalendar;
