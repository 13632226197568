import React from "react";
import { Link } from "react-router-dom";
import "./NoteTaking.css";

const NoteTaking = () => {
  return (
    <div className="note-taking-page">
      <div className="header">
        <div className="nav-tabs">
          <Link to="/" className="tab">
            Main
          </Link>
          <Link to="/note-taking" className="tab active">
            Note Taking
          </Link>
          <Link to="/review-mode" className="tab">
            Review Mode
          </Link>
          <Link to="/planner" className="tab">
            Planner
          </Link>
        </div>
      </div>

      <h1>Smart Note-Taking Features</h1>

      <div className="feature-container">
        <div className="feature-item">
          <video
            className="feature-image"
            src={process.env.PUBLIC_URL + "/video/cell_division.mov"}
            autoPlay
            loop
            muted
            playsInline
          />
          <div className="feature-description">
            <h2>Split Cell Note-Taking</h2>
            <p>
              Organize your notes efficiently with our split-cell format. Create
              two-column notes with keywords and detailed content for better
              structure and recall.
            </p>
          </div>
        </div>

        <div className="feature-item">
          <video
            className="feature-image"
            src={process.env.PUBLIC_URL + "/video/font.mov"}
            autoPlay
            loop
            muted
            playsInline
          />
          <div className="feature-description">
            <h2>Handwriting-Style Fonts</h2>
            <p>
              Experience the perfect blend of digital convenience and personal
              touch. Our handwriting-style fonts give your digital notes the
              authentic feel of handwritten notes, making them more engaging and
              memorable while maintaining perfect readability.
            </p>
          </div>
        </div>

        <div className="feature-item">
          <video
            className="feature-image"
            src={process.env.PUBLIC_URL + "/video/image.mov"}
            autoPlay
            loop
            muted
            playsInline
          />
          <div className="feature-description">
            <h2>Image Integration</h2>
            <p>
              Enhance your notes with images. Easily insert, resize, and
              annotate images to create comprehensive study materials.
            </p>
          </div>
        </div>

        <div className="feature-item">
          <video
            className="feature-image"
            src={process.env.PUBLIC_URL + "/video/drawing.mov"}
            autoPlay
            loop
            muted
            playsInline
          />
          <div className="feature-description">
            <h2>Drawing Tools</h2>
            <p>
              Unleash your creativity with our drawing tools. Sketch diagrams,
              annotate images, or create freehand illustrations directly in your
              notes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteTaking;
