import React, { useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import { auth } from "../firebase/firebase";
import "../css/SignUpPage.css";
import jumpingImage from "../assets/img/Transhumans - Fling.svg";
import logo from "../assets/img/logo.svg";

const SignUpPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    if (email) {
      setIsEmailValid(validateEmail(email));
    }
  }, [email]);

  const validatePassword = (password) => {
    let strength = 0;
    if (password.length >= 8) strength += 1;
    if (password.match(/[a-z]+/)) strength += 1;
    if (password.match(/[A-Z]+/)) strength += 1;
    if (password.match(/[0-9]+/)) strength += 1;
    if (password.match(/[$@#&!]+/)) strength += 1;

    setPasswordStrength(strength);
    return strength >= 3;
  };

  const getPasswordStrengthText = () => {
    if (passwordStrength === 0) return "Very Weak";
    if (passwordStrength === 1) return "Weak";
    if (passwordStrength === 2) return "Medium";
    if (passwordStrength === 3) return "Strong";
    if (passwordStrength >= 4) return "Very Strong";
  };

  const getPasswordStrengthColor = () => {
    if (passwordStrength <= 1) return "#ff4d4f";
    if (passwordStrength === 2) return "#faad14";
    if (passwordStrength === 3) return "#52c41a";
    if (passwordStrength >= 4) return "#1890ff";
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      setLoading(false);
      return;
    }

    if (!validatePassword(password)) {
      setError(
        "Password must be at least 8 characters long and include uppercase, lowercase, numbers, and special characters."
      );
      setLoading(false);
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await sendEmailVerification(userCredential.user);
      navigate("/verify-email");
    } catch (error) {
      switch (error.code) {
        case "auth/email-already-in-use":
          setError("This email is already in use.");
          break;
        case "auth/invalid-email":
          setError("Invalid email format.");
          break;
        case "auth/operation-not-allowed":
          setError("Email/password login is disabled.");
          break;
        case "auth/weak-password":
          setError("Password is too weak.");
          break;
        default:
          setError("An error occurred during signup. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-image">
        <img src={jumpingImage} alt="Jumping Character" />
      </div>
      <div className="signup-form-container">
        <form className="signup-form" onSubmit={handleSignUp}>
          <Link
            to="/"
            style={{ position: "absolute", top: "20px", left: "20px" }}
          >
            <img
              src={logo}
              alt="RedoNote Logo"
              style={{
                width: "60px",
                height: "60px",
              }}
            />
          </Link>
          <h2>Sign Up</h2>
          <div className="input-group">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
            {email && !isEmailValid && (
              <p className="input-error">Please enter a valid email address.</p>
            )}
          </div>
          <div className="input-group">
            <div className="password-input-container">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  validatePassword(e.target.value);
                }}
                placeholder="Password (8+ characters)"
                required
              />
              <button
                type="button"
                className="toggle-password"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
            <div className="password-strength">
              <div
                className="password-strength-bar"
                style={{
                  width: `${(passwordStrength / 5) * 100}%`,
                  backgroundColor: getPasswordStrengthColor(),
                }}
              ></div>
            </div>
            <p
              className="password-strength-text"
              style={{ color: getPasswordStrengthColor() }}
            >
              {getPasswordStrengthText()}
            </p>
          </div>
          <p className="password-requirements">
            Password must be at least 8 characters long and include uppercase,
            lowercase, numbers, and special characters.
          </p>
          {error && <p className="error-message">{error}</p>}
          {success && (
            <p className="success-message">
              Sign up completed. You will be redirected shortly.
            </p>
          )}
          <button
            type="submit"
            disabled={loading}
            style={{
              borderRadius: "24px",
              backgroundColor: "#fae150",
              color: "#231c16",
              border: "1px solid #231c16",
            }}
          >
            {loading ? (
              <>
                <span className="spinner"></span> Processing...
              </>
            ) : (
              "Sign Up"
            )}
          </button>
          <p className="terms-agreement">
            By signing up, you agree to our{" "}
            <Link to="/terms">Terms of Service</Link> and{" "}
            <Link to="/privacy">Privacy Policy</Link>
          </p>
          <p className="login-link">
            Already have an account? <Link to="/login">Login</Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default SignUpPage;
