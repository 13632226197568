import { auth } from "../firebase/firebase";
import { db } from "../firebase/firebase";
import {
  doc,
  getDoc,
  collection,
  query,
  getDocs,
  limit,
  orderBy,
} from "firebase/firestore";

export const checkSubscriptionStatus = async () => {
  try {
    const user = auth.currentUser;
    if (!user) {
      return null;
    }

    const subscriptionsRef = collection(db, `users/${user.uid}/subscriptions`);
    const q = query(subscriptionsRef, orderBy("createdAt", "desc"), limit(1));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log("구독 데이터 없음");
      return null;
    }

    const subscriptionData = querySnapshot.docs[0].data();
    console.log("최신 구독 데이터:", subscriptionData);

    return {
      status: subscriptionData.status,
      plan: subscriptionData.productId,
      currentPeriodEnd: subscriptionData.renewsAt,
      trialEndsAt: subscriptionData.trialEndsAt,
      customerPortalUrl: subscriptionData.customerPortalUrl,
    };
  } catch (error) {
    console.error("구독 상태 확인 중 오류:", error);
    return null;
  }
};
