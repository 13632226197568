import React from "react";
import { Link } from "react-router-dom";
import "../css/AboutPage.css";

const AboutPage = () => {
  return (
    <div className="about-container">
      <header className="header">
        <Link to="/" className="logo">
          Redo Note.
        </Link>
      </header>

      <main className="about-content">
        <section className="hero-section">
          <h1>About Us</h1>
          <p className="welcome-text">
            Welcome to Redo Note, a note-taking platform designed to
            revolutionize the way you study and organize your learning journey.
          </p>
        </section>

        <section className="about-section">
          <h2>Why I Created This Service</h2>
          <p>
            The idea for this platform was born from my own frustrations with
            traditional note-taking methods. Every time I started a new study
            session, I found myself creating new templates or formats for my
            notes—it was time-consuming and inefficient. I wanted a solution
            that could simplify this process while enhancing the learning
            experience.
          </p>
        </section>

        <section className="about-section">
          <h2>What Makes Us Unique</h2>
          <div className="features-grid">
            <div className="feature-card">
              <h3>Smart Templates</h3>
              <p>
                Separate keywords and content with our intelligent templates,
                enabling quick self-assessment and efficient review.
              </p>
            </div>

            <div className="feature-card">
              <h3>Organized Learning</h3>
              <p>
                Access and review notes structured according to your study
                materials' table of contents.
              </p>
            </div>

            <div className="feature-card">
              <h3>Progress Tracking</h3>
              <p>
                Monitor your study sessions, review frequency, and overall
                progress across your curriculum.
              </p>
            </div>

            <div className="feature-card">
              <h3>Integrated Flashcards</h3>
              <p>
                Create and review flashcards directly within your notes,
                organized by subject for easy access.
              </p>
            </div>

            <div className="feature-card">
              <h3>Instant Review Mode</h3>
              <p>
                Start reviewing your notes immediately after creation. No
                preparation needed - just switch to review mode and begin
                reinforcing your learning instantly.
              </p>
            </div>
          </div>
        </section>

        <section className="mission-section">
          <h2>Our Mission</h2>
          <p>
            At Redo Note, we're committed to providing a complete, all-in-one
            solution for learners. From planning your studies to reviewing your
            progress, our platform is designed to guide you through your entire
            learning journey—efficiently, effectively, and beautifully.
          </p>
        </section>

        <section className="cta-section">
          <p className="cta-text">
            Ready to transform your learning experience?
          </p>
          <Link to="/signup" className="cta-button">
            Get Started Today
          </Link>
        </section>
      </main>

      <footer className="about-footer">
        <Link to="/" className="cta-button">
          Back to Home
        </Link>
      </footer>
    </div>
  );
};

export default AboutPage;
