import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import LeftBar from "../components/LeftBar";
import Topbar from "../components/Topbar";
import Noteedit from "./Noteedit";
import RightSidebar from "../components/RightSidebar";
import Bottombar from "../components/Bottombar";
import ZoomControls from "../components/ZoomControls";
import Toolbar from "../components/Toolbar";
import "../css/LeftBar.css";
import "../css/rightsidebar.css";
import "../css/bottombar.css";
import "../css/toolbar.css";
import { ToolbarProvider } from "../contexts/ToolbarContext";
import { LayoutProvider, useLayout } from "../contexts/LayoutContext";
import debounce from "lodash/debounce";
import { useCurriculumState } from "../hooks/useCurriculumState";
import { ImageProvider } from "../contexts/ImageContext";
import { db, auth } from "../firebase/firebase";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  serverTimestamp,
  deleteField,
  writeBatch,
  deleteDoc,
} from "firebase/firestore";
import eventEmitter from "../utils/eventEmitter";
import SearchResultModal from "../components/SearchResultModal";
import { useTable } from "../contexts/TableContext";

const Unitnote = () => {
  const { courseId, courseName, unitId, subunitId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { searchAllTableData } = useTable();

  const {
    curriculum,
    setCurriculum,
    currentUnitId,
    setCurrentUnitId,
    currentSubunit,
    setCurrentSubunit,
    currentSubunitName,
    setCurrentSubunitName,
    handleSubunitNameChange,
  } = useCurriculumState({
    curriculum: location.state?.curriculum,
    unitId: unitId,
  });

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [scale, setScale] = useState(1);
  const { layout, setLayout } = useLayout();
  const [insertedImages, setInsertedImages] = useState([]);
  const [insertedAudios, setInsertedAudios] = useState([]);
  const [insertedVideos, setInsertedVideos] = useState([]);
  const contentRef = useRef(null);
  const leftBarRef = useRef(null);
  const [reminderListItems, setReminderListItems] = useState([]);
  const [vocabularyListItems, setVocabularyListItems] = useState([]);
  const [isPenMode, setIsPenMode] = useState(false);
  const [penColor, setPenColor] = useState("#000000");
  const [leftBarWidth, setLeftBarWidth] = useState(350);
  const [subunitContents, setSubunitContents] = useState({});
  const [penSize, setPenSize] = useState(2);
  const [isEraserMode, setIsEraserMode] = useState(false);
  const [eraserSize, setEraserSize] = useState(10);
  const [lineStyle, setLineStyle] = useState("solid");
  const [penOpacity, setPenOpacity] = useState(1);
  const [showLayoutWarning, setShowLayoutWarning] = useState(false);
  const [pendingLayout, setPendingLayout] = useState(null);
  const [stampNames, setStampNames] = useState(["1", "2", "3", "4", "5"]);
  const [showSections, setShowSections] = useState(() => {
    return (
      location.state?.showSections ??
      JSON.parse(localStorage.getItem("showSections") || "true")
    );
  });
  const [showChapters, setShowChapters] = useState(() => {
    return (
      location.state?.showChapters ??
      JSON.parse(localStorage.getItem("showChapters") || "true")
    );
  });
  const [lineColor, setLineColor] = useState("#000000");
  const [subunitLayouts, setSubunitLayouts] = useState({});
  const [tableData, setTableData] = useState({});
  const [insertedYoutubeVideos, setInsertedYoutubeVideos] = useState([]);
  const [textBoxes, setTextBoxes] = useState({});
  const [stamps, setStamps] = useState({});
  const pendingUpdates = useRef(false);
  const [noteEditKey, setNoteEditKey] = useState(0);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [wasRedTextToggled, setWasRedTextToggled] = useState(false);
  const [redTextState, setRedTextState] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const handleSectionVisibilityChange = useCallback((isVisible) => {
    setShowSections(isVisible);
    localStorage.setItem("showSections", JSON.stringify(isVisible));
  }, []);

  const handleChapterVisibilityChange = useCallback((isVisible) => {
    setShowChapters(isVisible);
    localStorage.setItem("showChapters", JSON.stringify(isVisible));
  }, []);

  useEffect(() => {
    if (subunitId) {
      setCurrentSubunit({ id: subunitId, name: "" });
    } else if (curriculum.subunits && currentUnitId) {
      const subunits = curriculum.subunits[currentUnitId];
      if (subunits && subunits.length > 0) {
        setCurrentSubunit({ id: subunits[0].id, name: subunits[0].name });
      }
    }
  }, [subunitId, curriculum, currentUnitId]);

  useEffect(() => {
    if (currentSubunit.id) {
      setSubunitContents((prevContents) => ({
        ...prevContents,
        [currentSubunit.id]: prevContents[currentSubunit.id] || {
          mainContent: "",
          images: [],
          audios: [],
          videos: [],
        },
      }));
    }
  }, [currentSubunit.id]);

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
    const newWidth = isOpen ? 350 : 50;
    setLeftBarWidth(newWidth);
    if (leftBarRef.current) {
      leftBarRef.current.style.width = `${newWidth}px`;
    }
    if (contentRef.current) {
      contentRef.current.style.marginLeft = `${newWidth}px`;
    }
  };

  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 2));
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 0.5));
  };

  const handleSelectLayout = (newLayout) => {
    if (["layout7", "layout8"].includes(newLayout)) {
      if (layout !== newLayout) {
        setShowLayoutWarning(true);
        setPendingLayout(newLayout);
      }
    } else {
      setLayout(newLayout);
    }
  };

  const confirmLayoutChange = () => {
    setLayout(pendingLayout);
    setShowLayoutWarning(false);
    setPendingLayout(null);
  };

  const cancelLayoutChange = () => {
    setShowLayoutWarning(false);
    setPendingLayout(null);
  };

  const handleInsertImage = (imageData) => {
    setInsertedImages((prev) => [...prev, imageData]);
  };

  const handleInsertAudio = (audio) => {
    setInsertedAudios((prevAudios) => [...prevAudios, audio]);
  };

  const handleInsertVideo = (video) => {
    setInsertedVideos((prevVideos) => [...prevVideos, video]);
  };

  useEffect(() => {
    setCurrentUnitId(unitId);
  }, [unitId]);

  useEffect(() => {
    const handleWheel = (event) => {
      if (event.ctrlKey) {
        event.preventDefault();
        if (event.deltaY < 0) {
          handleZoomIn();
        } else {
          handleZoomOut();
        }
      }
    };

    const handleScroll = () => {
      const content = contentRef.current;
      if (content && content.scrollLeft < 10) {
        content.scrollLeft = 10;
      }
    };

    const handleTouchMove = (event) => {
      if (scale > 1) {
        event.preventDefault();
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });
    window.addEventListener("touchmove", handleTouchMove, { passive: false });
    if (contentRef.current) {
      contentRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("wheel", handleWheel);
      window.removeEventListener("touchmove", handleTouchMove);
      if (contentRef.current) {
        contentRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scale]);

  const handleAddSubunit = useCallback(
    (unitId, newSubunit) => {
      setCurriculum((prevCurriculum) => {
        const newCurriculum = { ...prevCurriculum };
        if (!newCurriculum.subunits[unitId]) {
          newCurriculum.subunits[unitId] = [];
        }
        newCurriculum.subunits[unitId].push(newSubunit);
        return newCurriculum;
      });
    },
    [curriculum]
  );

  const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const handleMoveSubunit = (unitName, subunitIndex, direction) => {
    setCurriculum((prevCurriculum) => {
      const newCurriculum = { ...prevCurriculum };
      const subunits = [...newCurriculum.subunits[unitName]];
      const [movedSubunit] = subunits.splice(subunitIndex, 1);
      const newIndex = direction === "up" ? subunitIndex - 1 : subunitIndex + 1;
      subunits.splice(newIndex, 0, movedSubunit);
      newCurriculum.subunits[unitName] = subunits;
      return newCurriculum;
    });
  };

  const handleChangeSubunitUnit = (fromUnitName, subunitIndex, toUnitName) => {
    setCurriculum((prevCurriculum) => {
      const newCurriculum = { ...prevCurriculum };
      const [movedSubunit] = newCurriculum.subunits[fromUnitName].splice(
        subunitIndex,
        1
      );
      if (!newCurriculum.subunits[toUnitName]) {
        newCurriculum.subunits[toUnitName] = [];
      }
      newCurriculum.subunits[toUnitName].push(movedSubunit);
      return newCurriculum;
    });
  };

  const handleReorderSubunits = (unitName, startIndex, endIndex) => {
    setCurriculum((prevCurriculum) => {
      const newCurriculum = { ...prevCurriculum };
      const subunits = [...newCurriculum.subunits[unitName]];
      const [reorderedItem] = subunits.splice(startIndex, 1);
      subunits.splice(endIndex, 0, reorderedItem);
      newCurriculum.subunits[unitName] = subunits;
      return newCurriculum;
    });
  };

  const handlePenModeChange = (newPenMode) => {
    setIsPenMode(newPenMode);
  };

  const handlePenColorChange = (newColor) => {
    setPenColor(newColor);
  };

  const handlePenSizeChange = (newSize) => {
    setPenSize(newSize);
  };

  const handleLeftBarResize = (newWidth) => {
    setLeftBarWidth(newWidth);
    if (contentRef.current) {
      contentRef.current.style.marginLeft = `${newWidth}px`;
    }
  };

  useEffect(() => {
    const resizeBar = document.createElement("div");
    resizeBar.style.width = "10px";
    resizeBar.style.height = "100%";
    resizeBar.style.position = "absolute";
    resizeBar.style.right = "0";
    resizeBar.style.top = "0";
    resizeBar.style.cursor = "col-resize";
    resizeBar.style.zIndex = "1000";

    leftBarRef.current.appendChild(resizeBar);

    const resize = (e) => {
      const newWidth = e.clientX;
      if (newWidth >= 200 && newWidth <= 600) {
        setLeftBarWidth(newWidth);
        if (contentRef.current) {
          contentRef.current.style.marginLeft = `${newWidth}px`;
        }
      }
    };

    const stopResize = () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResize);
    };

    const initResize = () => {
      window.addEventListener("mousemove", resize);
      window.addEventListener("mouseup", stopResize);
    };

    resizeBar.addEventListener("mousedown", initResize);

    return () => {
      resizeBar.removeEventListener("mousedown", initResize);
    };
  }, []);

  useEffect(() => {
    const handleDrawingMode = (e) => {
      if (e.detail.isDrawingMode) {
        document.body.classList.add("drawing-mode");
      } else {
        document.body.classList.remove("drawing-mode");
      }
    };

    window.addEventListener("drawingModeChange", handleDrawingMode);

    return () => {
      window.removeEventListener("drawingModeChange", handleDrawingMode);
    };
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.classList.toggle("pen-mode", isPenMode);
    }
  }, [isPenMode]);

  const handleEraserModeChange = (newEraserMode) => {
    setIsEraserMode(newEraserMode);
    setIsPenMode(false);
  };

  const handleEraserSizeChange = (newSize) => {
    setEraserSize(newSize);
  };

  const handleLineWidthChange = (newWidth) => {};

  const handleLineStyleChange = (newStyle) => {
    setLineStyle(newStyle);
  };

  const handlePenOpacityChange = (newOpacity) => {
    setPenOpacity(newOpacity);
  };

  const handleLineColorChange = (newColor) => {
    setLineColor(newColor);
  };

  const handleStampNamesChange = (newNames) => {
    setStampNames(newNames);
  };

  const handleContentChange = (newContent) => {
    setSubunitContents((prevContents) => ({
      ...prevContents,
      [currentSubunit.id]: {
        ...prevContents[currentSubunit.id],
        mainContent: newContent,
      },
    }));
  };

  useEffect(() => {
    const applyInitialFont = () => {
      const unitName = document.querySelector(".unit-name");
      if (unitName) {
        unitName.style.fontFamily = "TTAgainSea";
      }
    };

    applyInitialFont();
  }, []);

  const handleSubunitClick = (unitId, subunit) => {
    setCurrentUnitId(unitId);
    setCurrentSubunit(subunit);
    setCurrentSubunitName(subunit.name);

    navigate(
      `/courses/${courseId}/curriculum/${courseName}/unit/${unitId}/subunit/${subunit.id}`,
      {
        state: {
          subunitId: subunit.id,
          subunitName: subunit.name,
          unitId: unitId,
        },
      }
    );
  };

  useEffect(() => {
    if (
      location.state?.subunitId &&
      location.state?.unitId &&
      location.state?.subunitName
    ) {
      setCurrentUnitId(location.state.unitId);
      setCurrentSubunit({
        id: location.state.subunitId,
        name: location.state.subunitName,
      });
      setCurrentSubunitName(location.state.subunitName);
    }
  }, [location.state]);

  const handleBackgroundChange = useCallback(
    async (subunitId, backgroundImage) => {
      if (!subunitId || !currentUnitId || !courseId) return;

      try {
        const user = auth.currentUser;
        if (!user) return;

        const layoutRef = doc(
          db,
          "users",
          user.uid,
          "courses",
          courseId,
          "curriculum",
          "layout",
          "subunits",
          subunitId
        );

        await setDoc(
          layoutRef,
          {
            backgroundImage: backgroundImage,
            updatedAt: serverTimestamp(),
          },
          { merge: true }
        );

        setBackgroundImage(backgroundImage);

        setCurriculum((prev) => {
          const newCurriculum = { ...prev };
          if (newCurriculum.subunits[currentUnitId]) {
            newCurriculum.subunits[currentUnitId] = newCurriculum.subunits[
              currentUnitId
            ].map((subunit) =>
              subunit.id === subunitId
                ? { ...subunit, backgroundImage: backgroundImage }
                : subunit
            );
          }
          return newCurriculum;
        });
      } catch (error) {
        setBackgroundImage(null);
      }
    },
    [currentUnitId, courseId]
  );

  useEffect(() => {
    const applyBackground = () => {
      const mainContent = document.querySelector(".main-content");
      if (!mainContent) return;

      if (backgroundImage) {
        mainContent.style.backgroundImage = `url(${backgroundImage})`;
        mainContent.style.backgroundSize = "cover";
        mainContent.style.backgroundPosition = "center";
        mainContent.style.backgroundRepeat = "no-repeat";
      } else {
        const currentSubunitData = curriculum.subunits[currentUnitId]?.find(
          (s) => s.id === currentSubunit.id
        );

        if (currentSubunitData?.backgroundImage) {
          mainContent.style.backgroundImage = `url(${currentSubunitData.backgroundImage})`;
          mainContent.style.backgroundSize = "cover";
          mainContent.style.backgroundPosition = "center";
          mainContent.style.backgroundRepeat = "no-repeat";
          setBackgroundImage(currentSubunitData.backgroundImage);
        } else {
          mainContent.style.backgroundImage = "none";
          mainContent.style.backgroundColor = "#ffffff";
        }
      }
    };

    applyBackground();
  }, [backgroundImage, currentUnitId, currentSubunit.id, curriculum]);

  useEffect(() => {
    if (curriculum.subunits && currentUnitId) {
      const subunits = curriculum.subunits[currentUnitId];
      if (subunits && subunits.length > 0) {
        const firstSubunit = subunits[0];
        setCurrentSubunit({ id: firstSubunit.id, name: firstSubunit.name });
        setCurrentSubunitName(firstSubunit.name);
      }
    }
  }, [curriculum, currentUnitId]);

  useEffect(() => {
    if (curriculum.subunits && currentUnitId && currentSubunit.id) {
      const subunit = curriculum.subunits[currentUnitId]?.find(
        (s) => s.id === currentSubunit.id
      );
      if (subunit) {
        setCurrentSubunitName(subunit.name);
      }
    }
  }, [curriculum, currentUnitId, currentSubunit.id]);

  const handleLayoutChange = useCallback(
    async (subunitId, newLayout) => {
      if (!subunitId || !currentUnitId || !courseId) return;

      try {
        const user = auth.currentUser;
        if (!user) return;

        const layoutRef = doc(
          db,
          "users",
          user.uid,
          "courses",
          courseId,
          "curriculum",
          "layout",
          "subunits",
          subunitId
        );

        await setDoc(
          layoutRef,
          {
            layout: newLayout,
            updatedAt: serverTimestamp(),
          },
          { merge: true }
        );

        setCurriculum((prev) => {
          const newCurriculum = { ...prev };
          if (newCurriculum.subunits[currentUnitId]) {
            newCurriculum.subunits[currentUnitId] = newCurriculum.subunits[
              currentUnitId
            ].map((subunit) =>
              subunit.id === subunitId
                ? { ...subunit, layout: newLayout }
                : subunit
            );
          }
          return newCurriculum;
        });
      } catch (error) {}
    },
    [currentUnitId, courseId]
  );

  const fetchSubunitLayout = useCallback(async () => {
    if (!currentUnitId || !currentSubunit.id || !courseId) {
      return;
    }

    try {
      const user = auth.currentUser;
      if (!user) return;

      const layoutRef = doc(
        db,
        "users",
        user.uid,
        "courses",
        courseId,
        "curriculum",
        "layout",
        "subunits",
        currentSubunit.id
      );

      const layoutDoc = await getDoc(layoutRef);

      if (layoutDoc.exists()) {
        const layoutData = layoutDoc.data();

        if (layoutData.layout) {
          setLayout(layoutData.layout);
        }

        if (layoutData.backgroundImage) {
          setBackgroundImage(layoutData.backgroundImage);
        }
      } else {
        setLayout("layout1");
        setBackgroundImage(null);
      }
    } catch (error) {
      console.error("레이아웃/배경화면 불러오기 실패:", error);
      setLayout("layout1");
      setBackgroundImage(null);
    }
  }, [courseId, currentUnitId, currentSubunit.id, setLayout]);

  useEffect(() => {
    fetchSubunitLayout();
  }, [fetchSubunitLayout]);

  useEffect(() => {
    if (subunitId) {
      setCurrentSubunit({ id: subunitId, name: "" });
    }
  }, [subunitId]);

  useEffect(() => {
    if (currentSubunit.id && curriculum.subunits && currentUnitId) {
      const subunit = curriculum.subunits[currentUnitId]?.find(
        (s) => s.id === currentSubunit.id
      );
      if (subunit) {
        setCurrentSubunit(subunit);
      }
    }
  }, [currentSubunit.id, curriculum, currentUnitId]);

  const handleDeleteSubunit = useCallback(
    async (unitId, subunitId) => {
      try {
        // 1. Curriculum 상태 업데이트
        setCurriculum((prevCurriculum) => {
          const newCurriculum = { ...prevCurriculum };
          if (newCurriculum.subunits[unitId]) {
            newCurriculum.subunits[unitId] = newCurriculum.subunits[
              unitId
            ].filter((subunit) => subunit.id !== subunitId);
          }
          return newCurriculum;
        });

        // 2. Firebase에서 관련 데이터 삭제
        if (!auth.currentUser) return;

        const batch = writeBatch(db);

        // 기본 컬렉션 삭제 (subunits 아래의 문서들)
        const subunitCollections = ["canvas", "textbox", "stamps"];
        for (const collection of subunitCollections) {
          const docRef = doc(
            db,
            "users",
            auth.currentUser.uid,
            "courses",
            courseId,
            "curriculum",
            collection,
            "subunits",
            subunitId
          );
          batch.delete(docRef);
        }

        // layout 문서에서 해당 subunit 필드 삭제
        const layoutRef = doc(
          db,
          "users",
          auth.currentUser.uid,
          "courses",
          courseId,
          "curriculum",
          "layout"
        );
        batch.update(layoutRef, {
          [subunitId]: deleteField(),
          lastModified: serverTimestamp(),
        });

        // 일괄 삭제 실행
        await batch.commit();

        // 3. 네비게이션 처리
        if (currentSubunit.id === subunitId) {
          const currentUnitSubunits = curriculum.subunits[unitId] || [];
          if (currentUnitSubunits.length > 0) {
            const nextSubunit = currentUnitSubunits[0];
            navigate(
              `/courses/${courseId}/curriculum/${courseName}/unit/${unitId}/subunit/${nextSubunit.id}`
            );
          } else {
            navigate(`/courses/${courseId}/curriculum/${courseName}`);
          }
        }
      } catch (error) {
        console.error("서브유닛 삭제 실패:", error);
      }
    },
    [courseId, currentUnitId, currentSubunit.id, curriculum, navigate]
  );

  const handleTableDataChange = useCallback(
    (unitId, subunitId, newTableData) => {
      setCurriculum((prevCurriculum) => {
        const newCurriculum = { ...prevCurriculum };
        if (newCurriculum.subunits[unitId]) {
          newCurriculum.subunits[unitId] = newCurriculum.subunits[unitId].map(
            (subunit) =>
              subunit.id === subunitId
                ? {
                    ...subunit,
                    tableStructure: {
                      ...subunit.tableStructure,
                      tableData: newTableData.tableData,
                      columnWidths: newTableData.columnWidths,
                      editorWidths: newTableData.editorWidths,
                    },
                  }
                : subunit
          );
        }
        return newCurriculum;
      });
    },
    []
  );

  // Firebase에 캔버스 데이 저장하는 함수 수정
  const saveCanvasData = async (canvasData) => {
    try {
      if (!auth.currentUser) {
        throw new Error("로그인이 필요합니다");
      }

      const canvasRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "courses",
        courseId,
        "curriculum",
        "canvas",
        "subunits",
        currentSubunit.id
      );

      await setDoc(canvasRef, {
        data: canvasData,
        lastModified: serverTimestamp(),
      });
    } catch (error) {
      throw error;
    }
  };

  // 디바운스된 캔버스 저장 함수
  const debouncedSaveCanvas = useCallback(
    debounce(async (canvasData) => {
      try {
        await saveCanvasData(canvasData);
      } catch (error) {
        console.error("캔버스 저장 실패:", error);
      }
    }, 1000),
    [courseId, currentSubunit.id]
  );

  // handleCanvasDataChange 수정
  const handleCanvasDataChange = useCallback(
    async (unitId, subunitId, canvasData) => {
      // 이전 상태와 비교하여 변경사항이 있을 때만 업데이트
      setCurriculum((prev) => {
        const currentSubunit = prev.subunits[unitId]?.find(
          (s) => s.id === subunitId
        );
        const currentCanvasData = currentSubunit?.canvasData;

        if (JSON.stringify(currentCanvasData) === JSON.stringify(canvasData)) {
          return prev;
        }

        const newCurriculum = { ...prev };
        if (newCurriculum.subunits[unitId]) {
          const updatedSubunits = newCurriculum.subunits[unitId].map(
            (subunit) =>
              subunit.id === subunitId
                ? {
                    ...subunit,
                    canvasData: {
                      ...canvasData,
                      lastModified: new Date().toISOString(),
                    },
                  }
                : subunit
          );
          return {
            ...newCurriculum,
            subunits: {
              ...newCurriculum.subunits,
              [unitId]: updatedSubunits,
            },
          };
        }
        return newCurriculum;
      });

      debouncedSaveCanvas(canvasData);
    },
    [debouncedSaveCanvas]
  );

  // Firebase에서 캔버스 데이터 불러오는 함수 수정
  const loadCanvasData = async () => {
    try {
      if (!auth.currentUser) {
        throw new Error("로그인이 필요합니다");
      }

      const canvasRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "courses",
        courseId,
        "curriculum",
        "canvas",
        "subunits",
        currentSubunit.id
      );

      const canvasDoc = await getDoc(canvasRef);

      if (canvasDoc.exists()) {
        return canvasDoc.data().data;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  // 컴포넌트 마운트/언마운트 시 처리
  useEffect(() => {
    if (currentSubunit.id && courseId) {
      loadCanvasData().then((canvasData) => {
        if (canvasData) {
          // curriculum 상태 업데이트 방식 변경
          setCurriculum((prev) => {
            // subunits가 없으면 초기화
            if (!prev.subunits) {
              prev.subunits = {};
            }

            // currentUnitId에 해당하는 subunits 배열이 없으면 초기화
            if (!prev.subunits[currentUnitId]) {
              prev.subunits[currentUnitId] = [];
            }

            // 현재 서브유닛이 있는지 확인
            const subunitIndex = prev.subunits[currentUnitId].findIndex(
              (s) => s.id === currentSubunit.id
            );

            const newSubunits = [...prev.subunits[currentUnitId]];

            if (subunitIndex === -1) {
              // 서브유닛이 없으면 새로 추가
              newSubunits.push({
                id: currentSubunit.id,
                name: currentSubunit.name,
                canvasData: canvasData,
              });
            } else {
              // 있으면 캔버스 데이터만 업데이트
              newSubunits[subunitIndex] = {
                ...newSubunits[subunitIndex],
                canvasData: canvasData,
              };
            }

            return {
              ...prev,
              subunits: {
                ...prev.subunits,
                [currentUnitId]: newSubunits,
              },
            };
          });
        }
      });
    }
  }, [currentSubunit.id, courseId, currentUnitId]);

  const handleInsertYoutubeVideo = useCallback((videoUrl) => {
    setInsertedYoutubeVideos((prev) => [
      ...prev,
      {
        url: videoUrl,
        id: `video-${Date.now()}`,
        position: { x: 0, y: 0 },
        size: { width: 560, height: 315 },
        rotation: 0,
      },
    ]);
  }, []);

  const handleYoutubeVideoAdd = (videoData) => {
    setInsertedYoutubeVideos((prev) => [...prev, videoData]);
  };

  const handleYoutubeVideoPositionUpdate = useCallback((videoId, transform) => {
    const matrix = new DOMMatrix(transform);
    const newPosition = {
      x: Math.round(matrix.e),
      y: Math.round(matrix.f),
    };
    const newRotation = Math.round(matrix.rotation);

    setInsertedYoutubeVideos((prev) =>
      prev.map((video) =>
        video.id === videoId
          ? {
              ...video,
              position: newPosition,
              rotation: newRotation,
            }
          : video
      )
    );
  }, []);

  const handleYoutubeVideoDelete = useCallback((videoId) => {
    setInsertedYoutubeVideos((prev) =>
      prev.filter((video) => video.id !== videoId)
    );
  }, []);
  // 텍스트박스 저장 함수 수정
  const saveTextBoxData = async (textBoxData) => {
    try {
      if (!auth.currentUser) {
        throw new Error("사용자 인증 필요");
      }

      const textBoxRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "courses",
        courseId,
        "curriculum",
        "textbox",
        "subunits",
        currentSubunit.id
      );

      await setDoc(textBoxRef, {
        data: textBoxData,
        updatedAt: serverTimestamp(),
      });

      return true;
    } catch (error) {
      throw error;
    }
  };

  // 텍스트박스 로드 함수 수정
  const loadTextBoxData = async () => {
    try {
      if (!auth.currentUser) {
        throw new Error("로그인이 필요니다");
      }

      const textBoxRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "courses",
        courseId,
        "curriculum",
        "textbox",
        "subunits",
        currentSubunit.id
      );

      const textBoxDoc = await getDoc(textBoxRef);
      if (textBoxDoc.exists()) {
        return textBoxDoc.data().data;
      }
      return [];
    } catch (error) {
      throw error;
    }
  };

  // 디바운스된 저장 함수
  const debouncedSaveTextBox = useCallback(
    debounce(async (textBoxData) => {
      try {
        await saveTextBoxData(textBoxData);
      } catch (error) {}
    }, 500),
    [courseId, currentSubunit.id]
  );
  // 텍스트박스 데이터 변경 핸들러 수정
  const handleTextBoxDataChange = useCallback(
    async (textBoxData) => {
      // 로컬 상태 업데이트
      setTextBoxes((prev) => {
        const newState = {
          ...prev,
          [currentSubunit.id]: textBoxData,
        };
        return newState;
      });

      // Firebase 저장
      try {
        await debouncedSaveTextBox(textBoxData);
      } catch (error) {
        console.error("텍스트박스 저장 중 오류 발생:", error);
      }
    },
    [currentSubunit.id, debouncedSaveTextBox]
  );

  // 컴포넌트 언마운트 시 저장되지 않은 경사항 처리
  useEffect(() => {
    return () => {
      debouncedSaveTextBox.flush();
    };
  }, [debouncedSaveTextBox]);

  // 컴포넌트 마운트 시 텍스트박스 데이터 로드
  useEffect(() => {
    if (currentSubunit.id && courseId) {
      setIsLoading(true);
      loadTextBoxData()
        .then((textBoxData) => {
          if (textBoxData) {
            setTextBoxes((prev) => ({
              ...prev,
              [currentSubunit.id]: textBoxData,
            }));
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [currentSubunit.id, courseId]);

  // 스탬프 저장 함수 수정
  const saveStampData = async (stampData) => {
    try {
      if (!auth.currentUser) {
        throw new Error("로그인이 필요합니다");
      }

      const stampRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "courses",
        courseId,
        "curriculum",
        "stamps",
        "subunits",
        currentSubunit.id
      );

      await setDoc(stampRef, {
        data: stampData,
        lastModified: serverTimestamp(),
      });
    } catch (error) {
      console.error("❌ 스탬프 데이터 저장 실패:", error);
    }
  };

  // 스탬프 로드 함수 수정
  const loadStampData = async () => {
    try {
      if (!auth.currentUser) {
        throw new Error("로그인이 필요합니다");
      }

      const stampRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "courses",
        courseId,
        "curriculum",
        "stamps",
        "subunits",
        currentSubunit.id
      );

      const stampDoc = await getDoc(stampRef);
      if (stampDoc.exists()) {
        return stampDoc.data().data;
      }
      return [];
    } catch (error) {
      console.error("❌ 스탬프 데이터 드 실패:", error);
      throw error;
    }
  };

  // 스탬프 추가 핸들러 수정
  const handleStampAdd = useCallback(
    async (stampData) => {
      // 로컬 상태 업데이트
      setStamps((prev) => {
        const newStamps = {
          ...prev,
          [currentSubunit.id]: Array.isArray(stampData)
            ? stampData
            : [...(prev[currentSubunit.id] || []), stampData],
        };
        return newStamps;
      });

      try {
        // Firebase에 저장
        await saveStampData(
          Array.isArray(stampData)
            ? stampData
            : [...(stamps[currentSubunit.id] || []), stampData]
        );
      } catch (error) {
        console.error("❌ 스탬프 저장 실패:", error);
      }
    },
    [currentSubunit.id, courseId, stamps]
  );

  const handleAddToReminderList = (text) => {
    setReminderListItems((prev) => [...prev, text]);
  };

  const handleAddToVocabulary = (text) => {
    setVocabularyListItems([
      ...vocabularyListItems,
      { word: text, meaning: "" },
    ]);
  };

  const handleUpdateImage = useCallback((imageId, updateData) => {
    setInsertedImages((prev) =>
      prev.map((image) => {
        if (image.id === imageId) {
          return { ...image, ...updateData };
        }
        return image;
      })
    );
  }, []);

  const handleDeleteImage = useCallback((imageId) => {
    setInsertedImages((prev) => prev.filter((image) => image.id !== imageId));
  }, []);

  const debouncedUpdate = useCallback(
    debounce(async (updates) => {
      try {
        pendingUpdates.current = false;
      } catch (error) {
        console.error("데이터 저장  오류:", error);
      }
    }, 1000),
    [courseId]
  );

  useEffect(() => {
    const handleBeforeUnload = async (e) => {
      if (!pendingUpdates.current) return;

      e.preventDefault();
      e.returnValue = "";

      try {
        await debouncedUpdate.flush();
        // ... rest of the logic ...
      } catch (error) {
        console.error("저장 실패:", error);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [courseId, currentSubunit.id, debouncedUpdate]);

  const customNavigate = useCallback(
    (to) => {
      if (pendingUpdates.current) {
        debouncedUpdate.flush();
        Promise.resolve().then(() => navigate(to));
      } else {
        navigate(to);
      }
    },
    [navigate, debouncedUpdate]
  );

  useEffect(() => {
    if (currentSubunit.id && courseId) {
      loadStampData();
    }
  }, [currentSubunit.id, courseId]);

  const currentSubunitData = useMemo(() => {
    return curriculum.subunits?.[currentUnitId]?.find(
      (s) => s.id === currentSubunit.id
    );
  }, [curriculum.subunits, currentUnitId, currentSubunit.id]);

  const getCurrentSubunitCanvasData = useCallback(() => {
    if (!currentSubunitData) return null;

    return (
      currentSubunitData.canvasData?.canvasData || currentSubunitData.canvasData
    );
  }, [currentSubunitData]);

  const handleSubunitDeleted = useCallback(async ({ courseId, subunitId }) => {
    if (!auth.currentUser) return;

    try {
      const collections = ["canvas", "textbox", "stamps"];

      for (const collection of collections) {
        const docRef = doc(
          db,
          "users",
          auth.currentUser.uid,
          "courses",
          courseId,
          "curriculum",
          collection,
          "subunits",
          subunitId
        );

        await deleteDoc(docRef);
      }
    } catch (error) {}
  }, []);

  const handleToggleRedText = useCallback((isActive) => {
    setRedTextState(isActive);

    const event = new CustomEvent("toggleRedText", {
      detail: { isActive },
    });
    window.dispatchEvent(event);
    setWasRedTextToggled(true);
    setNoteEditKey((prev) => prev + 1);
  }, []);

  const handleSearch = async (searchTerm) => {
    await searchAllTableData(courseId, searchTerm);
  };

  const handleSearchResultClick = (result) => {
    // 현재 URL 패턴을 유지하면서 subunitId만 변경
    const currentPath = location.pathname;
    const newPath = currentPath.replace(subunitId, result.subunitId);
    navigate(newPath);
  };

  // 스탬프 삭제 핸들러 추가
  const handleStampDelete = useCallback(
    async (stampId) => {
      try {
        if (!auth.currentUser) {
          throw new Error("로그인이 필요합니다");
        }

        // 로컬 상태 업데이트
        setStamps((prev) => {
          const currentStamps = prev[currentSubunit.id] || [];
          const newStamps = currentStamps.filter(
            (stamp) => stamp.id !== stampId
          );

          return {
            ...prev,
            [currentSubunit.id]: newStamps,
          };
        });

        // Firebase에 업데이트된 스탬프 데이터 저장
        const stampRef = doc(
          db,
          "users",
          auth.currentUser.uid,
          "courses",
          courseId,
          "curriculum",
          "stamps",
          "subunits",
          currentSubunit.id
        );

        const stampDoc = await getDoc(stampRef);
        if (stampDoc.exists()) {
          const currentData = stampDoc.data().data || [];
          const updatedData = currentData.filter(
            (stamp) => stamp.id !== stampId
          );

          await setDoc(stampRef, {
            data: updatedData,
            lastModified: serverTimestamp(),
          });
        }
      } catch (error) {}
    },
    [currentSubunit.id, courseId]
  );

  // 페린업 효과 수정
  useEffect(() => {
    return () => {
      if (wasRedTextToggled) {
        const event = new CustomEvent("toggleRedText", {
          detail: { isActive: true },
        });
        window.dispatchEvent(event);
      }
    };
  }, [wasRedTextToggled]);

  // 컴포넌트 마운트/언마운트 시 상태 유지를 위한 useEffect 추가
  useEffect(() => {
    if (!initialLoad) return;

    // 이전 상태 복원
    const savedRedTextState = localStorage.getItem(
      `redTextState-${currentSubunit.id}`
    );
    if (savedRedTextState !== null) {
      const isActive = JSON.parse(savedRedTextState);
      handleToggleRedText(isActive);
    }
    setInitialLoad(false);
  }, [currentSubunit.id, handleToggleRedText, initialLoad]);

  // redTextState가 변경될 때마다 localStorage에 저장
  useEffect(() => {
    if (!initialLoad && currentSubunit.id) {
      localStorage.setItem(
        `redTextState-${currentSubunit.id}`,
        JSON.stringify(redTextState)
      );
    }
  }, [redTextState, currentSubunit.id, initialLoad]);

  // subunit이 변경될 때 초기화
  useEffect(() => {
    setInitialLoad(true);
  }, [currentSubunit.id]);

  return (
    <ToolbarProvider>
      <div className="unitnote-page" style={{ backgroundColor: "#000000" }}>
        <Topbar onSearch={handleSearch} />
        <SearchResultModal
          onResultClick={handleSearchResultClick}
          curriculum={curriculum}
        />
        <LeftBar
          list={(curriculum.sections || []).map((section, sectionIndex) => ({
            sectionName: showSections ? section : "",
            chapters: ((curriculum.chapters || {})[sectionIndex] || []).map(
              (chapterName, chapterIndex) => ({
                chapterName: showChapters ? chapterName : "",
                units: (
                  (curriculum.units || {})[`${sectionIndex}-${chapterIndex}`] ||
                  []
                ).map((unit) => ({
                  ...unit,
                  subunits: (curriculum.subunits || {})[unit.unitId] || [],
                })),
              })
            ),
          }))}
          showSections={showSections}
          showChapters={showChapters}
          ref={leftBarRef}
          onToggle={handleSidebarToggle}
          isOpen={isSidebarOpen}
          state={curriculum}
          onAddSubunit={handleAddSubunit}
          onMoveSubunit={handleMoveSubunit}
          onChangeSubunitUnit={handleChangeSubunitUnit}
          currentUnitId={currentUnitId}
          currentSubunit={currentSubunit}
          onReorderSubunits={handleReorderSubunits}
          onWidthChange={handleLeftBarResize}
          style={{
            transition: "width 0.1s ease-out",
            width: `${leftBarWidth}px`,
          }}
          onSubunitNameChange={handleSubunitNameChange}
          onSectionVisibilityChange={handleSectionVisibilityChange}
          onChapterVisibilityChange={handleChapterVisibilityChange}
          onSubunitClick={(unit, subunit) => handleSubunitClick(unit, subunit)}
          onDeleteSubunit={handleDeleteSubunit}
        />
        <Toolbar
          onPenModeChange={handlePenModeChange}
          onPenColorChange={handlePenColorChange}
          onPenSizeChange={handlePenSizeChange}
          onEraserModeChange={handleEraserModeChange}
          onEraserSizeChange={handleEraserSizeChange}
          onLineWidthChange={handleLineWidthChange}
          onLineStyleChange={handleLineStyleChange}
          onPenOpacityChange={handlePenOpacityChange}
          onLineColorChange={handleLineColorChange}
          onCanvasDataChange={handleCanvasDataChange}
          unitId={currentUnitId}
          subunitId={currentSubunit.id}
          stampNames={stampNames}
          onYoutubeVideoAdd={handleYoutubeVideoAdd}
          insertedYoutubeVideos={insertedYoutubeVideos}
          onVideoPositionUpdate={handleYoutubeVideoPositionUpdate}
          onYoutubeVideoDelete={handleYoutubeVideoDelete}
          onTextBoxDataChange={handleTextBoxDataChange}
          initialTextBoxes={textBoxes[currentSubunit.id] || []}
          onStampAdd={handleStampAdd}
          initialStamps={stamps[currentSubunit.id] || []}
          onToggleRedText={handleToggleRedText}
          setScale={setScale}
          onStampDelete={handleStampDelete}
        />
        <div
          ref={contentRef}
          className={`content ${
            isSidebarOpen ? "sidebar-open" : "sidebar-closed"
          }`}
          style={{
            transform: `scale(${scale})`,
            transformOrigin: "left top",
            marginLeft: `${leftBarWidth}px`,
            transition: "margin-left 0.1s ease-out",
            backgroundColor: "#000000",
            height: `${100 / scale}vh`,
            width: `${100 / scale}vw`,
          }}
        >
          <Noteedit
            key={`${currentUnitId}-${currentSubunit.id}-${noteEditKey}`}
            unitId={currentUnitId}
            subunitName={
              currentSubunitName || currentSubunit?.name || "서브유닛 이름"
            }
            subunitId={currentSubunit?.id}
            layout={layout}
            insertedImages={insertedImages}
            insertedAudios={insertedAudios}
            insertedVideos={insertedVideos}
            content={subunitContents[currentSubunit.id]?.mainContent || ""}
            onContentChange={handleContentChange}
            isPenMode={isPenMode}
            isEraserMode={isEraserMode}
            penColor={penColor}
            penSize={penSize}
            eraserSize={eraserSize}
            lineStyle={lineStyle}
            penOpacity={penOpacity}
            onAddToReminderList={handleAddToReminderList}
            onAddToVocabulary={(text) => handleAddToVocabulary(text)}
            courseId={courseId}
            onTableDataChange={handleTableDataChange}
            tableData={tableData[currentSubunit.id] || []}
            backgroundImage={currentSubunit.background}
            onCanvasDataChange={handleCanvasDataChange}
            initialCanvasData={getCurrentSubunitCanvasData()}
            onStampAdd={handleStampAdd}
            initialStamps={stamps[currentSubunit.id] || []}
            onUpdateImage={handleUpdateImage}
            onDeleteImage={handleDeleteImage}
            onStampDelete={handleStampDelete}
            textBoxes={textBoxes[currentSubunit.id] || []}
            onTextBoxesChange={handleTextBoxDataChange}
          />
        </div>
        <RightSidebar
          onSelectLayout={handleSelectLayout}
          onInsertImage={handleInsertImage}
          onInsertAudio={handleInsertAudio}
          onInsertVideo={handleInsertVideo}
          reminderListItems={reminderListItems}
          vocabularyListItems={vocabularyListItems}
          courseId={courseId}
          currentSubunitId={currentSubunit.id}
          onBackgroundChange={handleBackgroundChange}
          onLayoutChange={handleLayoutChange}
        />
        <Bottombar>
          <ZoomControls onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} />
        </Bottombar>
      </div>
    </ToolbarProvider>
  );
};

export default Unitnote;
