import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { checkSubscriptionStatus } from "../utils/subscription";
import "../css/common.css";

const PrivateRoute = React.memo(({ children }) => {
  const { user, loading } = useAuth();
  const [subscriptionState, setSubscriptionState] = useState({
    checked: false,
    isValid: false,
  });

  useEffect(() => {
    let mounted = true;

    const checkSubscription = async () => {
      if (user && !subscriptionState.checked) {
        const subscription = await checkSubscriptionStatus();

        if (mounted) {
          const isValid =
            subscription &&
            (subscription.status === "active" ||
              subscription.status === "on_trial" ||
              subscription.status === "trialing" ||
              (subscription.status === "cancelled" &&
                new Date(subscription.currentPeriodEnd) > new Date()));

          setSubscriptionState({
            checked: true,
            isValid,
          });
        }
      }
    };

    checkSubscription();
    return () => {
      mounted = false;
    };
  }, [user, subscriptionState.checked]);

  console.log("PrivateRoute 상태:", {
    loading,
    subscriptionState,
  });

  if (loading || !subscriptionState.checked) {
    return (
      <div className="loading-overlay">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (!subscriptionState.isValid) {
    console.log("유효하지 않은 구독 - 구독 페이지로 이동");
    return <Navigate to="/subscription" />;
  }

  return children;
});

export default PrivateRoute;
