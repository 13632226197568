import React from "react";
import { Link } from "react-router-dom";
import "../css/LicensingPage.css";

const LicensingPage = () => {
  return (
    <div className="licensing-container">
      <header className="header">
        <Link to="/" className="logo">
          Redo&nbsp;Note.
        </Link>
      </header>

      <main className="licensing-content">
        <section className="hero-section">
          <h1>Licensing Information</h1>
        </section>

        <div className="licensing-sections">
          <section className="licensing-section">
            <h2>1. Platform Development</h2>
            <p>
              This platform was developed from the ground up using
              industry-standard technologies, including React for the frontend
              and Firebase for backend data management. Every feature, from the
              customizable templates to the integrated flashcards, was
              meticulously designed and coded by Redo Note to meet the unique
              needs of learners.
            </p>
          </section>

          <section className="licensing-section">
            <h2>2. Intellectual Property</h2>
            <p>
              All software, templates, and designs used in this platform are
              proprietary and fully owned by Redo Note. This ensures that the
              platform is unique, secure, and adheres to intellectual property
              standards. Unauthorized reproduction or distribution of the
              platform's features or design is strictly prohibited.
            </p>
          </section>

          <section className="licensing-section">
            <h2>3. User Content Ownership</h2>
            <p>
              We believe in empowering our users. Any notes, flashcards, or
              materials you create on this platform remain your property. Redo
              Note does not claim ownership of user-generated content. You have
              complete control over your data, and it is securely stored in
              compliance with our Privacy Policy.
            </p>
          </section>

          <section className="licensing-section">
            <h2>4. Data Security</h2>
            <p>
              The security of your data is our top priority. All information is
              encrypted and securely stored on trusted servers, ensuring
              compliance with global privacy standards. For more information,
              please refer to our Privacy Policy.
            </p>
          </section>

          <section className="licensing-section">
            <h2>5. Subscription Model</h2>
            <p>
              Our platform operates on a flexible subscription basis to suit
              your learning needs:
            </p>
            <ul className="pricing-list">
              <li>Monthly Plan: $10 per month.</li>
              <li>
                Annual Plan: $100 per year (with a 2-month discount included).
              </li>
            </ul>
            <p>
              Users can upgrade, downgrade, or cancel their subscriptions at any
              time. No additional fees apply for switching plans.
            </p>
          </section>

          <section className="licensing-section">
            <h2>6. Post-Sale Customization</h2>
            <p>
              Our platform provides extensive customization options to enhance
              your learning experience:
            </p>
            <ul className="features-list">
              <li>
                Design and customize note templates to suit your study style.
              </li>
              <li>Create and modify flashcards directly from your notes.</li>
              <li>
                Manage and track your study schedule using the built-in
                calendar.
              </li>
            </ul>
            <p>
              All customization features are included in your subscription, with
              no need for additional purchases.
            </p>
          </section>

          <section className="licensing-section">
            <h2>7. Contact Us</h2>
            <p>
              If you have any questions about licensing, intellectual property,
              or our development process, please feel free to reach out to us:
            </p>
            <div className="contact-details">
              <p>Email: support@redonote.com</p>
              <p>
                Address: 603, 29, Sijang-ro 79beon-gil, Bupyeong-gu, Incheon
              </p>
            </div>
          </section>
        </div>
      </main>

      <footer className="licensing-footer">
        <Link to="/" className="cta-button">
          Back to Home
        </Link>
      </footer>
    </div>
  );
};

export default LicensingPage;
