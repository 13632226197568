import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../css/ContactPage.css";

const ContactPage = () => {
  const [selectedContent, setSelectedContent] = useState(null);

  const helpContent = {
    "add-course": {
      title: "How to Add a New Course",
      content: `
        <div class="help-content-wrapper">
          <h2>Creating a New Course</h2>
          <p>Follow these steps to create a new course in Redo Note:</p>
          
          <video class="tutorial-video" width="100%" autoplay loop muted playsinline>
            <source src="/video/course_add.mov" type="video/mp4">
            Your browser does not support the video tag.
          </video>

          <div class="steps-section">
            <h3>Adding Tags to Your Course</h3>
            <ol>
              <li>
                <strong>Select a Color:</strong> Choose a color from the color palette for your tag
              </li>
              <li>
                <strong>Enter Tag Name:</strong> Type the name of your tag in the input field
              </li>
              <li>
                <strong>Confirm:</strong> Press Enter to add the tag
              </li>
            </ol>
          </div>

          <div class="note-section">
            <p><strong>Note:</strong> You can add multiple tags to better organize and categorize your courses.</p>
          </div>
        </div>
      `,
    },
    "manage-subunits": {
      title: "How to Manage Subunits",
      content: `
        <div class="help-content-wrapper">
          <h2>Managing Your Subunits</h2>
          <p>Learn how to efficiently manage your subunits in Redo Note:</p>
          
          <video class="tutorial-video" width="100%" autoplay loop muted playsinline>
            <source src="/video/subunit.mov" type="video/mp4">
            Your browser does not support the video tag.
          </video>

          <div class="steps-section">
            <h3>Customizing Subunits</h3>
            <ol>
              <li>
                <strong>Rename Subunits:</strong> Double-click on any subunit name to edit it directly
              </li>
              <li>
                <strong>Reorder Subunits:</strong> Click and drag subunits to change their order within a unit
              </li>
              <li>
                <strong>Add New Subunits:</strong>
                <div class="add-subunit-instruction">
                  <img src="/toolbaricons/newsubunit.png" alt="How to add subunit" class="example-screenshot">
                  <p>Click the + button next to any unit to add a new subunit. It will be created with the default name "New subunit"</p>
                </div>
              </li>
              <li>
                <strong>Delete Subunits:</strong>
                <div class="delete-subunit-instruction">
                  <p>To delete a subunit:</p>
                  <ol>
                    <li>Right-click on the subunit you want to delete</li>
                    <li>Click "Delete" from the context menu</li>
                  </ol>
                  <div class="warning-message">
                    <strong>⚠️ Warning:</strong> Deleted subunits cannot be recovered. Please be careful when deleting.
                  </div>
                </div>
              </li>
            </ol>
          </div>

          <div class="note-section">
            <p><strong>Tip:</strong> You can organize your study materials more effectively by keeping related subunits together.</p>
          </div>
        </div>
      `,
    },
    "cell-management": {
      title: "How to Split and Merge Cells",
      content: `
        <div class="help-content-wrapper">
          <h2>Managing Table Cells</h2>
          <p>Learn how to split and merge cells in your tables:</p>
          
          <video class="tutorial-video" width="100%" autoplay loop muted playsinline>
            <source src="/video/cellsplit.mov" type="video/mp4">
            Your browser does not support the video tag.
          </video>

          <div class="steps-section">
            <h3>Splitting Cells</h3>
            <div class="feature-instruction">
              <img src="/toolbaricons/split.png" alt="Split Cell Icon" class="toolbar-icon">
              <ol>
                <li><strong>Select the Cell:</strong> Click on the cell you want to split</li>
                <li><strong>Split Action:</strong> Click the split icon in the toolbar to divide the cell</li>
              </ol>
            </div>

            <h3>Merging Split Cells</h3>
            <div class="feature-instruction">
              <img src="/toolbaricons/split_cancle.png" alt="Merge Cells Icon" class="toolbar-icon">
              <ol>
                <li><strong>Activate Merge Mode:</strong> Click the merge icon in the toolbar</li>
                <li><strong>Complete Merge:</strong> Click on the divider between cells to merge them</li>
              </ol>
            </div>
          </div>

          <div class="note-section">
            <p><strong>Note:</strong> Split cells allow you to organize your notes more flexibly within the same column.</p>
          </div>
        </div>
      `,
    },
    "labeling-feature": {
      title: "How to Use Labels",
      content: `
        <div class="help-content-wrapper">
          <h2>Using Labels to Organize Your Notes</h2>
          <p>Learn how to use labels to organize and filter your subunits:</p>
          
          <video class="tutorial-video" width="100%" autoplay loop muted playsinline>
            <source src="/video/label.mov" type="video/mp4">
            Your browser does not support the video tag.
          </video>

          <div class="steps-section">
            <h3>Working with Labels</h3>
            <ol>
              <li>
                <strong>Adding Labels:</strong>
                <p>Click the label icon in the top toolbar to add labels to any desired location in your notes.</p>
              </li>
              <li>
                <strong>Filtering by Labels:</strong>
                <p>Create custom label names to easily filter and view only the subunits that contain specific labels.</p>
              </li>
              <li>
                <strong>Quick Navigation:</strong>
                <p>Click on any label to instantly navigate to the corresponding subunit where the label is located.</p>
              </li>
            </ol>
          </div>

          <div class="note-section">
            <p><strong>Tip:</strong> Use labels to create quick shortcuts to important parts of your notes.</p>
          </div>

          <div class="feature-benefits">
            <h3>Benefits of Using Labels</h3>
            <ul>
              <li>Organize related content across different subunits</li>
              <li>Quick access to specific topics</li>
              <li>Create custom study paths through your notes</li>
              <li>Instant navigation to labeled content</li>
            </ul>
          </div>
        </div>
      `,
    },
    "redtext-mode": {
      title: "How to Use Red Text Mode",
      content: `
        <div class="help-content-wrapper">
          <h2>Red Text Mode Features</h2>
          <p>Learn how to use Red Text Mode for effective studying:</p>
          
          <video class="tutorial-video" width="100%" autoplay loop muted playsinline>
            <source src="/video/redpen.mov" type="video/mp4">
            Your browser does not support the video tag.
          </video>

          <div class="steps-section">
            <h3>Using Red Text Mode</h3>
            <ol>
              <li>
                <strong>Highlight Important Content:</strong>
                <p>Mark important text in red to emphasize key points in your notes.</p>
              </li>
              <li>
                <strong>Toggle Red Text Visibility:</strong>
                <p>Click the "Red Text" button in the top toolbar to hide all red text.</p>
              </li>
              <li>
                <strong>Practice Active Recall:</strong>
                <p>Try to recall the hidden red text content, then click the button again to check your answer.</p>
              </li>
            </ol>
          </div>

          <div class="note-section">
            <p><strong>Tip:</strong> Use Red Text Mode to test your memory of key concepts and important details in your notes.</p>
          </div>
        </div>
      `,
    },
    flashcards: {
      title: "Where are the Flashcards?",
      content: `
        <div class="help-content-wrapper">
          <h2>Accessing and Using Flashcards</h2>
          <p>Learn how to find and use flashcards in different ways:</p>

          <div class="steps-section">
            <h3>1. Access from Course Page</h3>
            <div class="feature-instruction">
              <img src="/toolbaricons/flashcards1.png" alt="Flashcards in Course Page" class="example-screenshot">
              <p>Flashcards are available for each course individually. You can access them by:</p>
              <ol>
                <li>Going to the Course Page</li>
                <li>Selecting your desired course</li>
                <li>Clicking the Flashcards option in the top menu</li>
              </ol>
            </div>

            <h3>2. Quick Access while Taking Notes</h3>
            <div class="feature-instruction">
              <img src="/toolbaricons/flashcards2.png" alt="Flashcards in Note Page" class="example-screenshot">
              <p>Create flashcards directly while studying:</p>
              <ol>
                <li>Click the Flashcards button in the right sidebar while viewing your notes</li>
                <li>Create cards instantly for concepts you want to memorize</li>
                <li>Access the course's flashcard collection without leaving your notes</li>
              </ol>
            </div>
          </div>

          <div class="note-section">
            <p><strong>Tip:</strong> Creating flashcards while taking notes helps you identify and remember key concepts immediately.</p>
          </div>
        </div>
      `,
    },
    "account-logout": {
      title: "How to Log Out",
      content: `
        <div class="help-content-wrapper">
          <h2>Logging Out of Your Account</h2>
          <p>Follow these steps to safely log out of your account:</p>

          <div class="steps-section">
            <h3>Logout Process</h3>
            <ol>
              <li>
                <strong>Access Dashboard:</strong>
                <p>Go to your dashboard after logging in</p>
              </li>
              <li>
                <strong>Open Settings:</strong>
                <p>Click on the Settings menu</p>
              </li>
              <li>
                <strong>Privacy Section:</strong>
                <p>Navigate to the Privacy section</p>
              </li>
              <li>
                <strong>Logout:</strong>
                <p>Click the Logout button to sign out of your account</p>
              </li>
            </ol>
          </div>

          <div class="note-section">
            <p><strong>Note:</strong> Always remember to log out when using shared devices to protect your account security.</p>
          </div>
        </div>
      `,
    },
    "billing-info": {
      title: "Where Can I Find My Billing Information?",
      content: `
        <div class="help-content-wrapper">
          <h2>Accessing Your Billing Information</h2>
          <p>You can easily manage your subscription and billing details in your account settings:</p>

          <div class="steps-section">
            <h3>View and Manage Billing</h3>
            <ol>
              <li><strong>Access Dashboard:</strong>
                <p>Go to your dashboard</p>
              </li>
              <li><strong>Billing & Subscription:</strong>
                <p>Navigate to the Billing & Subscription section</p>
              </li>
            </ol>

            <div class="feature-details">
              <h3>What You Can Do Here:</h3>
              <ul>
                <li>View current subscription details</li>
                <li>Update billing information</li>
                <li>Manage subscription settings</li>
                <li>Cancel subscription if needed</li>
              </ul>
            </div>
          </div>

          <h2>Payment Methods</h2>
          <p>RedoNote supports various payment methods through Lemonsqueezy:</p>
          <ul>
            <li>Credit/Debit Cards: Visa, Mastercard, American Express, Discover, Diners Club, JCB (supported worldwide)</li>
            <li>Digital Wallets: Google Pay and Apple Pay (where available)</li>
          </ul>

          <h2>Pricing and Currency</h2>
          <p>All prices are displayed in <strong>USD (United States Dollar)</strong>. The displayed price includes:</p>
          <ul>
            <li>All fees</li>
            <li>Taxes</li>
            <li>Exchange rates and additional charges</li>
          </ul>
          <p>You will never be charged more than the displayed price. Payments are securely processed through Lemonsqueezy, and billing information is sent to your registered email.</p>

          <h2>Subscription Changes</h2>
          <p>You can modify your subscription at any time through the Settings menu.</p>

          <h2>Refund Policy</h2>
          <p>RedoNote does not typically offer refunds. However, exceptions may be made when upgrading from monthly to annual plans. Contact us with any questions.</p>

          <h2>Payment Processing</h2>
          <p>All payments are processed through Lemonsqueezy. While handling support requests, RedoNote staff may access payment information within our system. Your payment data never leaves our secure system or gets stored externally.</p>

          <div class="note-section">
            <p><strong>Note:</strong> For any billing-related questions or concerns, please contact our support team at support@redonote.com</p>
          </div>
        </div>
      `,
    },
    "change-password": {
      title: "How to Change Password",
      content: `
        <div class="help-content-wrapper">
          <h2>Changing Your Password</h2>
          <p>Follow these simple steps to change your password:</p>

          <div class="steps-section">
            <ol>
              <li>
                <strong>Go to Dashboard</strong>
                <p>Access your dashboard after logging in</p>
              </li>
              <li>
                <strong>Open Settings</strong>
                <p>Click on the Settings menu</p>
              </li>
              <li>
                <strong>Change Password</strong>
                <p>Select 'Change Password' option and follow the prompts</p>
              </li>
            </ol>
          </div>

          <div class="note-section">
            <p><strong>Tip:</strong> Choose a strong password that includes numbers, special characters, and both upper and lower case letters.</p>
          </div>
        </div>
      `,
    },
    "account-deletion": {
      title: "How to Delete Your Account",
      content: `
        <div class="help-content-wrapper">
          <h2>Account Deletion Process</h2>
          <p>Please note the following requirements for account deletion:</p>

          <div class="steps-section">
            <h3>Important Information</h3>
            <ol>
              <li>
                <strong>Active Subscription:</strong>
                <p>If you have an active subscription, you must first cancel it and wait until the subscription period ends before deleting your account.</p>
              </li>
              <li>
                <strong>Trial Period:</strong>
                <p>If you're in a trial period, you need to cancel the trial and wait until it expires before proceeding with account deletion.</p>
              </li>
              <li>
                <strong>Immediate Deletion Request:</strong>
                <p>If you need immediate account deletion in either of the above situations, please email us at support@redonote.com (include your registration email in the request).</p>
              </li>
            </ol>
          </div>

          <div class="note-section">
            <p><strong>Warning:</strong> Account deletion is permanent and cannot be undone. All your data will be permanently removed.</p>
          </div>
        </div>
      `,
    },
  };

  return (
    <div className="help-center-container">
      <header className="help-center-header">
        <Link to="/" className="website-link">
          Go Website
        </Link>
        <h1 className="help-center-title">Help Center</h1>
      </header>

      <div className="help-center-layout">
        <aside className="help-sidebar">
          <div className="category-menu">
            <div className="category-section">
              <h2>Getting Started</h2>
              <ul>
                <li>
                  <button
                    className={`menu-item ${
                      selectedContent === "add-course" ? "active" : ""
                    }`}
                    onClick={() => setSelectedContent("add-course")}
                  >
                    How to Add a New Course
                  </button>
                </li>
                <li>
                  <button
                    className={`menu-item ${
                      selectedContent === "manage-subunits" ? "active" : ""
                    }`}
                    onClick={() => setSelectedContent("manage-subunits")}
                  >
                    How to Manage Subunits
                  </button>
                </li>
                <li>
                  <button
                    className={`menu-item ${
                      selectedContent === "cell-management" ? "active" : ""
                    }`}
                    onClick={() => setSelectedContent("cell-management")}
                  >
                    How to Split and Merge Cells
                  </button>
                </li>
                <li>
                  <button
                    className={`menu-item ${
                      selectedContent === "labeling-feature" ? "active" : ""
                    }`}
                    onClick={() => setSelectedContent("labeling-feature")}
                  >
                    How to Use Labels
                  </button>
                </li>
              </ul>
            </div>

            <div className="category-section">
              <h2>Study Tools</h2>
              <ul>
                <li>
                  <button
                    className={`menu-item ${
                      selectedContent === "redtext-mode" ? "active" : ""
                    }`}
                    onClick={() => setSelectedContent("redtext-mode")}
                  >
                    How to Use Red Text Mode
                  </button>
                </li>
                <li>
                  <button
                    className={`menu-item ${
                      selectedContent === "flashcards" ? "active" : ""
                    }`}
                    onClick={() => setSelectedContent("flashcards")}
                  >
                    Where are the Flashcards?
                  </button>
                </li>
              </ul>
            </div>

            <div className="category-section">
              <h2>Account Management</h2>
              <ul>
                <li>
                  <button
                    className={`menu-item ${
                      selectedContent === "account-logout" ? "active" : ""
                    }`}
                    onClick={() => setSelectedContent("account-logout")}
                  >
                    How to Log Out
                  </button>
                </li>
                <li>
                  <button
                    className={`menu-item ${
                      selectedContent === "billing-info" ? "active" : ""
                    }`}
                    onClick={() => setSelectedContent("billing-info")}
                  >
                    Billing Information
                  </button>
                </li>
                <li>
                  <button
                    className={`menu-item ${
                      selectedContent === "change-password" ? "active" : ""
                    }`}
                    onClick={() => setSelectedContent("change-password")}
                  >
                    Change Password
                  </button>
                </li>
                <li>
                  <button
                    className={`menu-item ${
                      selectedContent === "account-deletion" ? "active" : ""
                    }`}
                    onClick={() => setSelectedContent("account-deletion")}
                  >
                    How to Delete Your Account
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </aside>

        <main className="help-content">
          {selectedContent ? (
            <article className="content-article">
              <h1>{helpContent[selectedContent].title}</h1>
              <div
                className="content-body"
                dangerouslySetInnerHTML={{
                  __html: helpContent[selectedContent].content,
                }}
              />
            </article>
          ) : (
            <div className="welcome-message">
              <h2>Welcome to Help Center</h2>
              <p>Select a topic from the menu to get started.</p>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default ContactPage;
