import { createContext, useContext, useEffect } from "react";
import { auth, db } from "../firebase/firebase";
import {
  doc,
  deleteDoc,
  getDocs,
  collection,
  writeBatch,
} from "firebase/firestore";

const AuthDeleteContext = createContext();

export const AuthDeleteProvider = ({ children }) => {
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      // 이전 사용자 ID를 저장
      const prevUserId = user?.uid;

      // 다음 상태 변경을 기다림
      setTimeout(async () => {
        const currentUser = auth.currentUser;

        // 이전 사용자가 있었고, 현재 사용자가 없다면 (삭제된 경우)
        if (prevUserId && !currentUser) {
          try {
            const batch = writeBatch(db);

            // 구독 데이터 삭제
            const subscriptionsRef = collection(
              db,
              `users/${prevUserId}/subscriptions`
            );
            const subscriptionsSnapshot = await getDocs(subscriptionsRef);
            subscriptionsSnapshot.forEach((doc) => {
              batch.delete(doc.ref);
            });

            // 캘린더 데이터 삭제
            const calendarRef = collection(db, `users/${prevUserId}/Calendar`);
            const calendarSnapshot = await getDocs(calendarRef);
            calendarSnapshot.forEach((doc) => {
              batch.delete(doc.ref);
            });

            // 코스 및 관련 데이터 삭제
            const coursesRef = collection(db, `users/${prevUserId}/courses`);
            const coursesSnapshot = await getDocs(coursesRef);

            for (const courseDoc of coursesSnapshot.docs) {
              // 커리큘럼 삭제
              const curriculumRef = collection(courseDoc.ref, "curriculum");
              const curriculumSnapshot = await getDocs(curriculumRef);
              curriculumSnapshot.forEach((doc) => {
                batch.delete(doc.ref);
              });

              // 플래시카드 삭제
              const flashcardsRef = collection(courseDoc.ref, "flashcards");
              const flashcardsSnapshot = await getDocs(flashcardsRef);
              flashcardsSnapshot.forEach((doc) => {
                batch.delete(doc.ref);
              });

              // 코스 문서 삭제
              batch.delete(courseDoc.ref);
            }

            // 사용자 문서 삭제
            const userRef = doc(db, `users/${prevUserId}`);
            batch.delete(userRef);

            // 일괄 처리 실행
            await batch.commit();
            console.log("사용자 데이터가 성공적으로 삭제되었습니다.");
          } catch (error) {
            console.error("데이터 삭제 중 오류 발생:", error);
          }
        }
      }, 100); // 상태 변경을 감지하기 위한 작은 지연
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthDeleteContext.Provider value={null}>
      {children}
    </AuthDeleteContext.Provider>
  );
};
