import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/LandingPage.css";
import landingImage from "../assets/img/landing_1.png";
import landing2Image from "../assets/img/landing_2.png";
import landing3Image from "../assets/img/landing_3.png";
import landing4Image from "../assets/img/landing_4.png";

const LandingPage = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((current) => (current + 1) % 3);
    }, 5000); // 5초마다 이미지 전환

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.1,
    });

    document.querySelectorAll(".feature-content").forEach((element) => {
      observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="landing-container">
      <header className="landing-header">
        <div className="landing-logo">
          <Link to="/">
            <img
              src="/logo192.png"
              alt="Redo Note Logo"
              style={{ height: "48px", marginRight: "10px" }}
            />
            <span style={{ fontSize: "24px", fontWeight: "bold" }}>
              Redo Note.
            </span>
          </Link>
        </div>
        <div className="landing-header-buttons">
          <div className="dropdown">
            <button className="dropbtn" style={{ fontWeight: "bold" }}>
              Products
            </button>
            <div className="dropdown-content">
              <Link to="/note-taking">Note-taking</Link>
              <Link to="/review-mode">Review mode</Link>
              <Link to="/planner">Planner</Link>
            </div>
          </div>
          <Link to="/login" className="landing-header-btn">
            Login
          </Link>
          <Link to="/signup" className="landing-header-btn primary">
            Get Started
          </Link>
        </div>
      </header>

      <main>
        <section className="hero-section">
          <div className="hero-content">
            <h1 className="main-title">Note Faster, Learn Deeper</h1>
            <p className="subtitle">
              Feel the flow of analog, powered by digital.
            </p>
            <div className="button-group">
              <Link to="/signup" className="main-button primary-action">
                🧠 Start Your 3-Day Free Trial
              </Link>
            </div>
          </div>
          <div className="hero-image">
            <div className="carousel">
              {[landingImage, landing2Image, landing3Image].map(
                (image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Preview ${index + 1}`}
                    className={`carousel-image ${
                      activeIndex === index ? "active" : ""
                    }`}
                  />
                )
              )}
              <div className="carousel-controls">
                {[0, 1, 2].map((index) => (
                  <button
                    key={index}
                    className={`carousel-dot ${
                      activeIndex === index ? "active" : ""
                    }`}
                    onClick={() => setActiveIndex(index)}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="feature-section">
          <div className="feature-content">
            <h2 className="feature-title">Smart Study Management</h2>
            <p className="feature-detail">
              Easily manage your study and review schedules by curriculum.
            </p>
            <div className="feature-image">
              <video
                src="/video/smart_study.mov"
                alt="Study Management Preview"
                className="preview-video"
                autoPlay
                loop
                muted
              />
            </div>
          </div>
        </section>

        <section className="feature-section">
          <div className="feature-content">
            <h2 className="feature-title">Review Mode</h2>
            <p className="feature-detail">
              Don't just organize notes - memorize them instantly!
            </p>
            <div className="feature-image">
              <video
                src="/video/landing_review.mov"
                alt="Focus Mode Preview"
                className="preview-video"
                autoPlay
                loop
                muted
              />
            </div>
          </div>
        </section>

        <section className="feature-section">
          <div className="feature-content">
            <h2 className="feature-title">Progress & Schedule Tracking</h2>
            <p className="feature-detail">
              Track your learning patterns and manage schedules through detailed
              study data analysis
            </p>
            <div className="feature-image">
              <img
                src={landing4Image}
                alt="Analytics Preview"
                className="preview-image"
              />
            </div>
          </div>
        </section>

        <section className="pricing-section">
          <h2 className="landing-section-title">Pricing</h2>
          <p className="section-description" style={{ color: "#666" }}>
            Simple pricing for everyone
          </p>

          <div className="pricing-cards">
            <div className="pricing-card featured">
              <div className="card-header">
                <h3>Premium</h3>
                <div className="price">
                  <span className="amount">$12</span>
                  <span className="period">/month</span>
                </div>
              </div>
              <ul className="features-list">
                <li>Unlimited Notes & Courses</li>
                <li>Flashcards</li>
                <li>Study Planner</li>
                <li>Review Mode</li>
                <li>All Premium Features</li>
              </ul>
              <Link to="/signup" className="pricing-cta">
                Start 3-Day Free Trial
              </Link>
              <p className="trial-note">
                Try free for 3 days
                <br />
                Cancel anytime
              </p>
            </div>
          </div>
        </section>
      </main>

      <footer className="business-info">
        <div className="info-container">
          <div className="footer-menu">
            <Link to="/about" className="footer-link">
              About
            </Link>
            <span className="separator">|</span>
            <Link to="/licensing" className="footer-link">
              Licensing
            </Link>
            <span className="separator">|</span>
            <Link to="/contact" className="footer-link">
              Help Center
            </Link>
            <span className="separator">|</span>
            <Link to="/terms" className="footer-link">
              Terms of Use
            </Link>
            <span className="separator">|</span>
            <Link to="/privacy" className="footer-link">
              Privacy Policy
            </Link>
            <span className="separator">|</span>
            <Link to="/refund-policy" className="footer-link">
              Cancellation Policy
            </Link>
          </div>

          <div className="copyright">
            © 2024 Redo Note. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
