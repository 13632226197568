import React from "react";
import "../css/bottombar.css";

const Bottombar = ({ children }) => {
  return (
    <div className="bottom-bar">
      <div className="zoom-controls toolbar-tooltip">
        {children}
        <span className="toolbar-tooltiptext">Zoom Controls</span>
      </div>
      <style jsx>{`
        .toolbar-tooltip {
          position: relative;
          display: inline-block;
        }

        .toolbar-tooltip .toolbar-tooltiptext {
          visibility: hidden;
          width: 120px;
          background-color: #555;
          color: #fff;
          text-align: center;
          border-radius: 6px;
          padding: 5px 0;
          position: absolute;
          z-index: 9999;
          bottom: 125%;
          left: 50%;
          margin-left: -60px;
          opacity: 0;
          transition: opacity 0.3s;
          font-size: 12px;
        }

        .toolbar-tooltip:hover .toolbar-tooltiptext {
          visibility: visible;
          opacity: 1;
        }
      `}</style>
    </div>
  );
};

export default Bottombar;
