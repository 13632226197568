import React, { createContext, useState, useContext, useEffect } from "react";
import { storage, auth } from "../firebase/firebase";
import { ref, listAll, getDownloadURL, deleteObject } from "firebase/storage";

const FileContext = createContext();

export const FileProvider = ({ children }) => {
  const [files, setFiles] = useState({});

  useEffect(() => {
    fetchAllFiles();
  }, []);

  const fetchAllFiles = async () => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;

    try {
      const categories = ["images", "documents", "audios", "videos"];
      const allFiles = {};

      for (const category of categories) {
        const storageRef = ref(storage, `users/${userId}/${category}`);
        const result = await listAll(storageRef);

        const filesData = await Promise.all(
          result.items.map(async (item) => {
            const url = await getDownloadURL(item);
            return {
              name: item.name,
              type: getFileType(category),
              url: url,
              path: item.fullPath,
            };
          })
        );

        allFiles[category] = filesData;
      }

      setFiles(allFiles);
    } catch (error) {
      console.error("파일 목록 가져오기 실패:", error);
    }
  };

  const deleteFile = async (folder, index) => {
    try {
      const fileToDelete = files[folder][index];
      const fileRef = ref(storage, fileToDelete.path);

      await deleteObject(fileRef);

      setFiles((prevFiles) => ({
        ...prevFiles,
        [folder]: prevFiles[folder].filter((_, i) => i !== index),
      }));
    } catch (error) {
      console.error("파일 삭제 실패:", error);
    }
  };

  const getFileType = (category) => {
    const typeMap = {
      images: "image/jpeg",
      documents: "application/pdf",
      audios: "audio/mpeg",
      videos: "video/mp4",
    };
    return typeMap[category] || "application/octet-stream";
  };

  return (
    <FileContext.Provider value={{ files, deleteFile, fetchAllFiles }}>
      {children}
    </FileContext.Provider>
  );
};

export const useFiles = () => useContext(FileContext);
