// src/components/FlyoutMenu2.js
import React, { useState, useEffect } from "react";
import "../css/FlyoutMenu2.css";

const FlyoutMenu2 = ({ onClose, currentSubunitId, onBackgroundChange }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  // 컴포넌트가 마운트되거나 currentSubunitId가 변경될 때마다 실행
  useEffect(() => {}, [currentSubunitId]);

  const handleImageClick = (imagePath) => {
    const mainContent = document.querySelector(".main-content");
    if (mainContent) {
      mainContent.style.backgroundImage = `url(${imagePath})`;
      mainContent.style.backgroundSize = "cover";
      mainContent.style.backgroundPosition = "center";
      mainContent.style.backgroundRepeat = "no-repeat";
      if (typeof onBackgroundChange === "function") {
        onBackgroundChange(currentSubunitId, imagePath);
      } else {
        console.error("onBackgroundChange is not a function");
      }
    }
  };

  const templates = [
    {
      name: "템플릿 1",
      path: "/template/plane_light/plane_light1.svg",
      folder: "plane_light",
      colorCount: 7,
    },

    {
      name: "템플릿 4",
      path: "/template/plane_warm/plane_warm1.svg",
      folder: "plane_warm",
      colorCount: 7,
    },
    {
      name: "템플릿 5",
      path: "/template/plane_winter/plane_winter1.svg",
      folder: "plane_winter",
      colorCount: 7,
    },
    {
      name: "템플릿 6",
      path: "/template/plane_half/plane_half1.svg",
      folder: "plane_half",
      colorCount: 6,
    },
    {
      name: "템플릿 7",
      path: "/template/plane_check/plane_check1.svg",
      folder: "plane_check",
      colorCount: 7,
    },
    {
      name: "템플릿 8",
      path: "/template/bear1/bear_1.svg",
      folder: "bear1",
      colorCount: 6,
    },
    {
      name: "템플릿 9",
      path: "/template/cat/cat_1.svg",
      folder: "cat",
      colorCount: 3,
    },
    {
      name: "템플릿 10",
      path: "/template/puppy/puppy1.svg",
      folder: "puppy",
      colorCount: 5,
    },
  ];

  const colorPalette = [
    { color: "#FBD3D4", templateIndex: 7 },
    { color: "#FDEEDB", templateIndex: 6 },
    { color: "#FFFCDB", templateIndex: 5 },
    { color: "#F4EBC2", templateIndex: 4 },
    { color: "#EBF5EC", templateIndex: 3 },
    { color: "#E9F6FE", templateIndex: 2 },
  ];

  const warmPalette = [
    { color: "#EDE1D6", templateIndex: 7 },
    { color: "#CDC3B9", templateIndex: 6 },
    { color: "#C6CFDE", templateIndex: 5 },
    { color: "#DCE7EA", templateIndex: 4 },
    { color: "#E7E6EB", templateIndex: 3 },
    { color: "#FAF0E8", templateIndex: 2 },
    { color: "#F0DDD8", templateIndex: 1 },
  ];
  const checkPatterns = [
    { icon: "/toolbaricons/templateicons/check7.svg", templateIndex: 7 },
    { icon: "/toolbaricons/templateicons/check6.svg", templateIndex: 6 },
    { icon: "/toolbaricons/templateicons/check5.svg", templateIndex: 5 },
    { icon: "/toolbaricons/templateicons/check4.svg", templateIndex: 4 },
    { icon: "/toolbaricons/templateicons/check3.svg", templateIndex: 3 },
    { icon: "/toolbaricons/templateicons/check2.svg", templateIndex: 2 },
  ];

  const halfPatterns = [
    { icon: "/toolbaricons/templateicons/half6.svg", templateIndex: 6 },
    { icon: "/toolbaricons/templateicons/half5.svg", templateIndex: 5 },
    { icon: "/toolbaricons/templateicons/half4.svg", templateIndex: 4 },
    { icon: "/toolbaricons/templateicons/half3.svg", templateIndex: 3 },
    { icon: "/toolbaricons/templateicons/half2.svg", templateIndex: 2 },
    { icon: "/toolbaricons/templateicons/half1.svg", templateIndex: 1 },
  ];

  const bearPatterns = [
    { icon: "/toolbaricons/templateicons/bear1.svg", templateIndex: 6 },
    { icon: "/toolbaricons/templateicons/bear2.svg", templateIndex: 1 },
    { icon: "/toolbaricons/templateicons/bear3.svg", templateIndex: 2 },
    { icon: "/toolbaricons/templateicons/bear4.svg", templateIndex: 3 },
    { icon: "/toolbaricons/templateicons/bear5.svg", templateIndex: 4 },
    { icon: "/toolbaricons/templateicons/bear6.svg", templateIndex: 5 },
  ];

  const catPatterns = [
    { icon: "/toolbaricons/templateicons/cat1.svg", templateIndex: 1 },
    { icon: "/toolbaricons/templateicons/cat2.svg", templateIndex: 2 },
    { icon: "/toolbaricons/templateicons/cat3.svg", templateIndex: 3 },
  ];

  const puppyPatterns = [
    { icon: "/toolbaricons/templateicons/puppyicon1.svg", templateIndex: 1 },
    { icon: "/toolbaricons/templateicons/puppyicon2.svg", templateIndex: 2 },
    { icon: "/toolbaricons/templateicons/puppyicon3.svg", templateIndex: 3 },
    { icon: "/toolbaricons/templateicons/puppyicon4.svg", templateIndex: 4 },
    { icon: "/toolbaricons/templateicons/puppyicon5.svg", templateIndex: 5 },
  ];

  const handleColorClick = (templateIndex) => {
    const template = templates[0]; // 템플릿 1 선택
    const imagePath = `/template/${template.folder}/${template.folder}${templateIndex}.svg`;
    handleImageClick(imagePath);
  };

  const handleWarmClick = (templateIndex) => {
    const template = templates[1]; // 템플릿 4 선택
    const imagePath = `/template/${template.folder}/${template.folder}${templateIndex}.svg`;
    handleImageClick(imagePath);
  };

  const handleCheckClick = (templateIndex) => {
    const template = templates[4]; // 템플릿 7 (check) 선택
    const imagePath = `/template/${template.folder}/${template.folder}${templateIndex}.svg`;
    handleImageClick(imagePath);
  };

  const handleHalfClick = (templateIndex) => {
    const template = templates[3]; // 템플릿 6 (half) 선택
    const imagePath = `/template/${template.folder}/${template.folder}${templateIndex}.svg`;
    handleImageClick(imagePath);
  };

  const handleBearClick = (templateIndex) => {
    const template = templates[5]; // 템플릿 8 (bear1) 선택
    const imagePath = `/template/${template.folder}/bear_${templateIndex}.svg`;
    handleImageClick(imagePath);
  };

  const handleCatClick = (templateIndex) => {
    const template = templates[6]; // 템플릿 9 (cat) 선택
    const imagePath = `/template/${template.folder}/cat_${templateIndex}.svg`;
    handleImageClick(imagePath);
  };

  const handlePuppyClick = (templateIndex) => {
    const template = templates[7]; // 템플릿 10 (puppy) 선택
    const imagePath = `/template/${template.folder}/puppy${templateIndex}.svg`;
    handleImageClick(imagePath);
  };

  const handleScroll = (direction, paletteClass) => {
    const container = document.querySelector(`.${paletteClass}`);
    const scrollAmount = 150; // 스크롤할 픽셀 양
    if (container) {
      if (direction === "left") {
        container.scrollBy({ left: -scrollAmount, behavior: "smooth" });
      } else {
        container.scrollBy({ left: scrollAmount, behavior: "smooth" });
      }
    }
  };

  const handleRemoveBackground = () => {
    const mainContent = document.querySelector(".main-content");
    if (mainContent) {
      // 즉시 배경 이미지 제거
      mainContent.style.backgroundImage = "none";
      mainContent.style.backgroundColor = "#ffffff";

      // Firebase 업데이트
      if (typeof onBackgroundChange === "function") {
        onBackgroundChange(currentSubunitId, null);
      }
    }
  };

  const renderTemplateOptions = () => (
    <>
      <div className="remove-background-option">
        <button
          className="remove-background-button"
          onClick={handleRemoveBackground}
        >
          <span>Remove Background</span>
        </button>
      </div>

      <div className="template-section">
        <h4 className="template-title">Pastel</h4>
        <div className="palette-container">
          <button
            className="scroll-button left"
            onClick={() => handleScroll("left", "pastel-palette")}
          >
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
            </svg>
          </button>
          <div className="color-palette pastel-palette">
            {colorPalette.map((paletteItem, index) => (
              <button
                key={index}
                className="color-button"
                style={{
                  backgroundColor: paletteItem.color,
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  border: "2px solid #fff",
                  margin: "5px",
                  cursor: "pointer",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                }}
                onClick={() => handleColorClick(paletteItem.templateIndex)}
              />
            ))}
          </div>
          <button
            className="scroll-button right"
            onClick={() => handleScroll("right", "pastel-palette")}
          >
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
            </svg>
          </button>
        </div>
      </div>

      <div className="template-section">
        <h4 className="template-title">Warm</h4>
        <div className="palette-container">
          <button
            className="scroll-button left"
            onClick={() => handleScroll("left", "warm-palette")}
          >
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
            </svg>
          </button>
          <div className="color-palette warm-palette">
            {warmPalette.map((paletteItem, index) => (
              <button
                key={index}
                className="color-button"
                style={{
                  backgroundColor: paletteItem.color,
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  border: "2px solid #fff",
                  margin: "5px",
                  cursor: "pointer",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                }}
                onClick={() => handleWarmClick(paletteItem.templateIndex)}
              />
            ))}
          </div>
          <button
            className="scroll-button right"
            onClick={() => handleScroll("right", "warm-palette")}
          >
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
            </svg>
          </button>
        </div>
      </div>

      <div className="template-section">
        <h4 className="template-title">Check</h4>
        <div className="check-pattern-palette">
          {checkPatterns.map((patternItem, index) => (
            <button
              key={index}
              className="pattern-button"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                border: "2px solid #fff",
                margin: "5px",
                cursor: "pointer",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                background: `url(${patternItem.icon}) center/cover`,
              }}
              onClick={() => handleCheckClick(patternItem.templateIndex)}
            />
          ))}
        </div>
      </div>

      <div className="template-section">
        <h4 className="template-title">Half</h4>
        <div className="half-pattern-palette">
          {halfPatterns.map((patternItem, index) => (
            <button
              key={index}
              className="pattern-button"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                border: "2px solid #fff",
                margin: "5px",
                cursor: "pointer",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                background: `url(${patternItem.icon}) center/cover`,
              }}
              onClick={() => handleHalfClick(patternItem.templateIndex)}
            />
          ))}
        </div>
      </div>

      <div className="template-section">
        <h4 className="template-title">Bear</h4>
        <div className="palette-container">
          <button
            className="scroll-button left"
            onClick={() => handleScroll("left", "bear-palette")}
          >
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
            </svg>
          </button>
          <div className="bear-pattern-palette">
            {bearPatterns.map((patternItem, index) => (
              <button
                key={index}
                className="pattern-button"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  border: "2px solid #fff",
                  margin: "5px",
                  cursor: "pointer",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  background: `url(${patternItem.icon}) center/cover`,
                }}
                onClick={() => handleBearClick(patternItem.templateIndex)}
              />
            ))}
          </div>
          <button
            className="scroll-button right"
            onClick={() => handleScroll("right", "bear-palette")}
          >
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
            </svg>
          </button>
        </div>
      </div>

      <div className="template-section">
        <h4 className="template-title">Cat</h4>
        <div className="check-pattern-palette">
          {catPatterns.map((patternItem, index) => (
            <button
              key={index}
              className="pattern-button"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                border: "2px solid #fff",
                margin: "5px",
                cursor: "pointer",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                background: `url(${patternItem.icon}) center/cover`,
              }}
              onClick={() => handleCatClick(patternItem.templateIndex)}
            />
          ))}
        </div>
      </div>

      <div className="template-section">
        <h4 className="template-title">Puppy</h4>
        <div className="check-pattern-palette">
          {puppyPatterns.map((patternItem, index) => (
            <button
              key={index}
              className="pattern-button"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                border: "2px solid #fff",
                margin: "5px",
                cursor: "pointer",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                background: `url(${patternItem.icon}) center/cover`,
              }}
              onClick={() => handlePuppyClick(patternItem.templateIndex)}
            />
          ))}
        </div>
      </div>
    </>
  );

  return (
    <div className="flyout-panel">
      <div className="flyout-header">
        <h3>Note Design</h3>
        <button className="close-button" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
          </svg>
        </button>
      </div>
      <div className="flyout-content">{renderTemplateOptions()}</div>
    </div>
  );
};

export default FlyoutMenu2;
