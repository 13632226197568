import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../css/curriculum.css";
import pencilIcon from "../assets/icons/inuse/pencil.svg";
import deleteIcon from "../assets/icons/inuse/delete.svg";
import calendarIcon from "../assets/icons/inuse/calendar.svg";
import upArrowIcon from "../assets/icons/inuse/arrow-collapse-up.svg";
import downArrowIcon from "../assets/icons/inuse/arrow-collapse-down.svg";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import LoadingSpinner from "../components/LoadingSpinner";
import { useCurriculum } from "../contexts/CurriculumContext";
import { auth } from "../firebase/firebase";
import { db } from "../firebase/firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

// 날짜 유효성 검사 및 안전한 변환 함수
const safelyParseDate = (dateString) => {
  if (!dateString) return null;
  const parsedDate = new Date(dateString);
  return isNaN(parsedDate.getTime()) ? null : parsedDate;
};

const Curriculum = () => {
  const { courseId: urlCourseId, courseName } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    curriculum,
    setCurriculum,
    setCourseId,
    addSection,
    deleteSection,
    addChapter,
    handleSectionNameChange,
    handleChapterNameChange,
    handleUnitChange,
    handleReviewChange,
    isLoading,
    setIsLoading,
    addUnit,
    deleteUnit,
    reorderCurriculumIndexes,
    debouncedUpdateFirebase,
    handleReorderCurriculum,
    addSubunit,
    handleMoveUnit,
    loadCurriculum,
    deleteChapter,
    handleUnitCompletion,
  } = useCurriculum();

  const [editingItem, setEditingItem] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [addButtonLabel, setAddButtonLabel] = useState("새 섹션 추가");
  const [deletingSectionIndex, setDeletingSectionIndex] = useState(null);
  const [deletingItemType, setDeletingItemType] = useState(null);
  const [expandedUnits, setExpandedUnits] = useState({});

  useEffect(() => {
    if (curriculum.chapters) {
      const initialExpandedState = {};
      Object.keys(curriculum.chapters).forEach((sectionIndex) => {
        curriculum.chapters[sectionIndex].forEach((_, chapterIndex) => {
          initialExpandedState[`${sectionIndex}-${chapterIndex}`] = true;
        });
      });
      setExpandedUnits(initialExpandedState);
    }
  }, [curriculum.chapters]);

  const calculateProgress = useCallback(() => {
    const totalUnits = Object.values(curriculum.units).flat().length;
    const completedUnits = Object.values(curriculum.units)
      .flat()
      .filter((unit) => unit.isCompleted).length;
    return totalUnits > 0 ? Math.round((completedUnits / totalUnits) * 100) : 0;
  }, [curriculum.units]);

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        setIsLoading(true);
        setCurriculum({
          sections: [],
          chapters: {},
          units: {},
          subunits: {},
        });

        const user = auth.currentUser;
        if (user && urlCourseId) {
          const curriculumRef = doc(
            db,
            "users",
            user.uid,
            "courses",
            urlCourseId,
            "curriculum",
            "main"
          );
          const curriculumDoc = await getDoc(curriculumRef);
          if (curriculumDoc.exists()) {
            const data = curriculumDoc.data();
            setCurriculum({
              ...data,
              toggleState: data.toggleState || {
                showSections: true,
                showChapters: true,
              },
            });
          }
        }
      } finally {
        setIsLoading(false);
      }
    };

    loadInitialData();
  }, [urlCourseId, setCurriculum]);

  const normalizeUnitName = (name) => {
    return name.toLowerCase().replace(/\s+/g, "");
  };

  const handleSectionNameBlur = (sectionIndex, e) => {
    const newName = e.target.value.trim();
    if (newName) {
      handleSectionNameChange(sectionIndex, newName);
    }
    setEditingItem(null);
  };

  const handleSectionNameKeyDown = (e, sectionIndex) => {
    if (e.key === "Enter" && !e.nativeEvent.isComposing) {
      e.preventDefault();
      const newName = e.target.value.trim();
      if (newName) {
        handleSectionNameChange(sectionIndex, newName);
      }
      setEditingItem(null);
    }
  };

  const generateUniqueName = (baseName, existingNames) => {
    let newName = baseName;
    let counter = 1;
    while (existingNames.includes(newName)) {
      newName = `${baseName} ${counter}`;
      counter++;
    }
    return newName;
  };

  const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const handleAddSectionClick = () => {
    addSection();
  };

  const handleChapterNameKeyDown = (e, sectionIndex, chapterIndex) => {
    if (e.key === "Enter") {
      handleSaveItem();
    }
  };

  const handleAddUnit = (sectionIndex, chapterIndex) => {
    addUnit(sectionIndex, chapterIndex);
  };

  const handleUnitNameKeyDown = (e, sectionIndex, chapterIndex, unitIndex) => {
    if (e.nativeEvent.isComposing) {
      return;
    }

    if (e.key === "Enter") {
      e.preventDefault();
      handleSaveItem();
      setEditingItem(null);
    }
  };

  const handleDeleteUnit = (sectionIndex, chapterIndex, unitIndex) => {
    setDeleteConfirmation({ sectionIndex, chapterIndex, unitIndex });
  };

  const confirmDeleteUnit = () => {
    if (deleteConfirmation) {
      const { sectionIndex, chapterIndex, unitIndex } = deleteConfirmation;
      deleteUnit(sectionIndex, chapterIndex, unitIndex);
      setDeleteConfirmation(null);
    }
  };

  const cancelDeleteUnit = () => {
    setDeleteConfirmation(null);
  };

  const handleEditItem = (
    type,
    sectionIndex,
    chapterIndex = null,
    unitIndex = null
  ) => {
    setEditingItem({
      type,
      sectionIndex: type === "section" ? sectionIndex : sectionIndex,
      chapterIndex,
      unitIndex,
    });
  };

  const handleSaveItem = debounce(() => {
    setEditingItem(null);
  }, 1000);

  const handleUnitClick = async (sectionIndex, chapterIndex, unitIndex) => {
    const units = curriculum.units[`${sectionIndex}-${chapterIndex}`];
    if (!units || !units[unitIndex]) return;

    const unit = units[unitIndex];
    const unitId = unit.unitId;

    if (
      !curriculum.subunits[unitId] ||
      curriculum.subunits[unitId].length === 0
    ) {
      const result = await addSubunit(unitId);
      if (result && result.newSubunit) {
        navigate(
          `/courses/${urlCourseId}/curriculum/${courseName}/unit/${unitId}/subunit/${result.newSubunit.id}`,
          {
            state: {
              subunitId: result.newSubunit.id,
              unitId: unitId,
              currentSubunit: result.newSubunit,
            },
          }
        );
        return;
      }
    }

    navigate(
      `/courses/${urlCourseId}/curriculum/${courseName}/unit/${unitId}/subunit/${curriculum.subunits[unitId]?.[0]?.id}`,
      {
        state: {
          curriculum,
        },
      }
    );
  };

  const handleEditUnit = (sectionIndex, chapterIndex, unitIndex) => {
    setEditingItem({ type: "unit", sectionIndex, chapterIndex, unitIndex });
  };

  const moveUnitToAnotherChapter = (
    prevCurriculum,
    sectionIndex,
    chapterIndex,
    unitIndex,
    direction
  ) => {
    const updatedUnits = { ...prevCurriculum.units };
    const currentKey = `${sectionIndex}-${chapterIndex}`;
    const unit = updatedUnits[currentKey][unitIndex];

    updatedUnits[currentKey].splice(unitIndex, 1);

    let newChapterIndex =
      direction === "up" ? chapterIndex - 1 : chapterIndex + 1;
    let newSectionIndex = sectionIndex;

    if (newChapterIndex < 0) {
      newSectionIndex--;
      if (newSectionIndex >= 0) {
        newChapterIndex = prevCurriculum.chapters[newSectionIndex].length - 1;
      }
    } else if (
      newChapterIndex >= prevCurriculum.chapters[sectionIndex].length
    ) {
      newSectionIndex++;
      if (newSectionIndex < prevCurriculum.sections.length) {
        newChapterIndex = 0;
      }
    }

    if (
      newSectionIndex >= 0 &&
      newSectionIndex < prevCurriculum.sections.length
    ) {
      const newKey = `${newSectionIndex}-${newChapterIndex}`;
      if (!updatedUnits[newKey]) {
        updatedUnits[newKey] = [];
      }

      const insertIndex = direction === "up" ? updatedUnits[newKey].length : 0;
      updatedUnits[newKey].splice(insertIndex, 0, unit);

      return { ...prevCurriculum, units: updatedUnits };
    }

    return prevCurriculum;
  };

  const handleUnitCheckboxChange = (
    sectionIndex,
    chapterIndex,
    unitIndex,
    isChecked
  ) => {
    handleUnitCompletion(sectionIndex, chapterIndex, unitIndex, isChecked);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    setCurriculum((prev) => {
      // 깊은 복사로 시작
      const newCurriculum = JSON.parse(JSON.stringify(prev));

      // 1. 섹션 이동
      const [movedSection] = newCurriculum.sections.splice(sourceIndex, 1);
      newCurriculum.sections.splice(destinationIndex, 0, movedSection);

      // 2. Context의 handleReorderCurriculum 호출하여 전체 데이터 재정렬
      return handleReorderCurriculum(newCurriculum);
    });
  };

  const handleDeleteSection = (sectionIndex) => {
    setDeletingSectionIndex(sectionIndex);
    setDeletingItemType("section");
  };

  const handleDeleteChapter = (sectionIndex) => {
    setDeletingSectionIndex(sectionIndex);
    setDeletingItemType("chapter");
  };

  const confirmDeleteSection = () => {
    if (deletingItemType === "section") {
      deleteSection(deletingSectionIndex);
    } else if (deletingItemType === "chapter") {
      deleteChapter(deletingSectionIndex.section, deletingSectionIndex.chapter);
    }
    setDeletingSectionIndex(null);
    setDeletingItemType(null);
  };

  const cancelDeleteSection = () => {
    setDeletingSectionIndex(null);
    setDeletingItemType(null);
  };

  const deleteCourse = async (courseId) => {
    // Firebase 관련 코드 제거
  };

  const handleAddChapter = (sectionIndex) => {
    const newChapterIndex = curriculum.chapters[sectionIndex].length;
    addChapter(sectionIndex);

    setExpandedUnits((prev) => ({
      ...prev,
      [`${sectionIndex}-${newChapterIndex}`]: true,
    }));
  };

  const handleUnitNameComplete = (
    sectionIndex,
    chapterIndex,
    unitIndex,
    value
  ) => {
    handleUnitChange(
      sectionIndex,
      chapterIndex,
      unitIndex,
      "unitName",
      value,
      true
    );
    setEditingItem(null);
  };

  const toggleUnits = (sectionIndex, chapterIndex) => {
    setExpandedUnits((prev) => ({
      ...prev,
      [`${sectionIndex}-${chapterIndex}`]:
        !prev[`${sectionIndex}-${chapterIndex}`],
    }));
  };

  const sections = curriculum?.sections || [];
  const chapters = curriculum?.chapters || {};
  const units = curriculum?.units || {};

  useEffect(() => {
    if (urlCourseId) {
      setCourseId(urlCourseId);
    }
  }, [urlCourseId, setCourseId]);
  return (
    <div className="curriculum-container">
      {isLoading ? (
        <LoadingSpinner />
      ) : sections.length === 0 ? (
        <div className="empty-curriculum">
          <h2>Curriculum is empty</h2>
          <button
            onClick={handleAddSectionClick}
            className="empty-curriculum-button"
          >
            Add New Section
          </button>
        </div>
      ) : (
        <>
          <div className="curriculum-header">
            <h1>{courseName}</h1>
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="sections">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {sections.map((section, sectionIndex) => (
                    <Draggable
                      key={section.id}
                      draggableId={section.id}
                      index={sectionIndex}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <table className="section-table">
                            <thead>
                              <tr>
                                <th className="section-title" colSpan="5">
                                  <div className="section-title-container">
                                    {editingItem?.type === "section" &&
                                    editingItem?.sectionIndex ===
                                      sectionIndex ? (
                                      <input
                                        type="text"
                                        defaultValue={section}
                                        onBlur={(e) =>
                                          handleSectionNameBlur(sectionIndex, e)
                                        }
                                        onKeyDown={(e) =>
                                          handleSectionNameKeyDown(
                                            e,
                                            sectionIndex
                                          )
                                        }
                                        autoFocus
                                        className="section-name-input"
                                      />
                                    ) : (
                                      <div
                                        className="editable-text section-title"
                                        onClick={() =>
                                          handleEditItem(
                                            "section",
                                            sectionIndex
                                          )
                                        }
                                      >
                                        {section}
                                      </div>
                                    )}
                                    <div className="icon-group">
                                      <img
                                        src="/toolbaricons/inuse/delete.svg"
                                        alt="Delete section"
                                        className="action-icon delete-icon"
                                        onClick={() =>
                                          handleDeleteSection(sectionIndex)
                                        }
                                      />
                                      <img
                                        src="/toolbaricons/inuse/menu.svg"
                                        alt="More options"
                                        className="action-icon more-icon"
                                        {...provided.dragHandleProps}
                                      />
                                    </div>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {chapters[sectionIndex] &&
                                chapters[sectionIndex].map(
                                  (chapter, chapterIndex) => (
                                    <tr key={chapterIndex}>
                                      <td colSpan="5">
                                        <table className="chapter-table">
                                          <tbody>
                                            <tr>
                                              <td className="chapter-name">
                                                {editingItem &&
                                                editingItem.type ===
                                                  "chapter" &&
                                                editingItem.sectionIndex ===
                                                  sectionIndex &&
                                                editingItem.chapterIndex ===
                                                  chapterIndex ? (
                                                  <input
                                                    type="text"
                                                    value={chapter}
                                                    onChange={(e) =>
                                                      handleChapterNameChange(
                                                        sectionIndex,
                                                        chapterIndex,
                                                        e.target.value
                                                      )
                                                    }
                                                    onBlur={handleSaveItem}
                                                    onKeyDown={(e) =>
                                                      handleChapterNameKeyDown(
                                                        e,
                                                        sectionIndex,
                                                        chapterIndex
                                                      )
                                                    }
                                                    autoFocus
                                                  />
                                                ) : (
                                                  <div className="chapter-name-container">
                                                    <span
                                                      onClick={() =>
                                                        handleEditItem(
                                                          "chapter",
                                                          sectionIndex,
                                                          chapterIndex
                                                        )
                                                      }
                                                      className="editable-text chapter-name no-cursor"
                                                    >
                                                      {chapter ||
                                                        "Click to enter chapter name"}
                                                    </span>
                                                    <div className="icon-group">
                                                      <img
                                                        src="/toolbaricons/inuse/delete.svg"
                                                        alt="Delete chapter"
                                                        className="action-icon delete-icon"
                                                        onClick={() => {
                                                          setDeletingItemType(
                                                            "chapter"
                                                          );
                                                          setDeletingSectionIndex(
                                                            {
                                                              section:
                                                                sectionIndex,
                                                              chapter:
                                                                chapterIndex,
                                                            }
                                                          );
                                                        }}
                                                      />
                                                      <span
                                                        className="curriculum-toggle-icon"
                                                        onClick={() =>
                                                          toggleUnits(
                                                            sectionIndex,
                                                            chapterIndex
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                          padding: "0 4px",
                                                        }}
                                                      >
                                                        {expandedUnits[
                                                          `${sectionIndex}-${chapterIndex}`
                                                        ]
                                                          ? "▼"
                                                          : "▶"}
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}
                                              </td>
                                            </tr>
                                            {expandedUnits[
                                              `${sectionIndex}-${chapterIndex}`
                                            ] === true && (
                                              <tr>
                                                <td>
                                                  {/* 유닛 테이블 렌더링 */}
                                                  <table className="unit-table">
                                                    <thead>
                                                      <tr>
                                                        <th className="unit-name-column">
                                                          Unit Name
                                                        </th>
                                                        <th className="date-column">
                                                          Plan Date
                                                        </th>
                                                        <th className="date-column">
                                                          Last Review Date
                                                        </th>
                                                        <th className="review-count-column">
                                                          Review Count
                                                        </th>
                                                        <th className="actions-column">
                                                          Actions
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {units[
                                                        `${sectionIndex}-${chapterIndex}`
                                                      ] &&
                                                        units[
                                                          `${sectionIndex}-${chapterIndex}`
                                                        ].map(
                                                          (unit, unitIndex) => (
                                                            <tr key={unitIndex}>
                                                              <td className="unit-name-column">
                                                                <input
                                                                  type="checkbox"
                                                                  className="unit-checkbox"
                                                                  checked={
                                                                    unit.isCompleted ||
                                                                    false
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) =>
                                                                    handleUnitCheckboxChange(
                                                                      sectionIndex,
                                                                      chapterIndex,
                                                                      unitIndex,
                                                                      e.target
                                                                        .checked
                                                                    )
                                                                  }
                                                                />
                                                                <span className="unit-name-container">
                                                                  {editingItem &&
                                                                  editingItem.type ===
                                                                    "unit" &&
                                                                  editingItem.sectionIndex ===
                                                                    sectionIndex &&
                                                                  editingItem.chapterIndex ===
                                                                    chapterIndex &&
                                                                  editingItem.unitIndex ===
                                                                    unitIndex ? (
                                                                    <input
                                                                      type="text"
                                                                      value={
                                                                        unit.unitName
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        handleUnitChange(
                                                                          sectionIndex,
                                                                          chapterIndex,
                                                                          unitIndex,
                                                                          "unitName",
                                                                          e
                                                                            .target
                                                                            .value
                                                                        )
                                                                      }
                                                                      onBlur={(
                                                                        e
                                                                      ) =>
                                                                        handleUnitNameComplete(
                                                                          sectionIndex,
                                                                          chapterIndex,
                                                                          unitIndex,
                                                                          e
                                                                            .target
                                                                            .value
                                                                        )
                                                                      }
                                                                      onKeyDown={(
                                                                        e
                                                                      ) => {
                                                                        if (
                                                                          e.key ===
                                                                            "Enter" &&
                                                                          !e
                                                                            .nativeEvent
                                                                            .isComposing
                                                                        ) {
                                                                          e.preventDefault();
                                                                          handleUnitNameComplete(
                                                                            sectionIndex,
                                                                            chapterIndex,
                                                                            unitIndex,
                                                                            e
                                                                              .target
                                                                              .value
                                                                          );
                                                                        }
                                                                      }}
                                                                      autoFocus
                                                                      className="unit-name-input"
                                                                    />
                                                                  ) : (
                                                                    <>
                                                                      <span
                                                                        className="curriculumunitname"
                                                                        onClick={() =>
                                                                          handleUnitClick(
                                                                            sectionIndex,
                                                                            chapterIndex,
                                                                            unitIndex
                                                                          )
                                                                        }
                                                                      >
                                                                        {
                                                                          unit.unitName
                                                                        }
                                                                      </span>
                                                                      <img
                                                                        src={
                                                                          pencilIcon
                                                                        }
                                                                        alt="Edit"
                                                                        className="edit-unit-icon"
                                                                        onClick={() =>
                                                                          handleEditUnit(
                                                                            sectionIndex,
                                                                            chapterIndex,
                                                                            unitIndex
                                                                          )
                                                                        }
                                                                      />
                                                                    </>
                                                                  )}
                                                                </span>
                                                              </td>
                                                              <td className="date-column">
                                                                <div className="date-picker-container">
                                                                  <DatePicker
                                                                    selected={safelyParseDate(
                                                                      unit.planDate
                                                                    )}
                                                                    onChange={(
                                                                      date
                                                                    ) =>
                                                                      handleUnitChange(
                                                                        sectionIndex,
                                                                        chapterIndex,
                                                                        unitIndex,
                                                                        "planDate",
                                                                        date
                                                                      )
                                                                    }
                                                                    dateFormat="yyyy-MM-dd"
                                                                    customInput={
                                                                      <img
                                                                        src={
                                                                          calendarIcon
                                                                        }
                                                                        alt="Select Plan Date"
                                                                        className="calendar-icon"
                                                                      />
                                                                    }
                                                                  />
                                                                  {unit.planDate && (
                                                                    <span className="selected-date">
                                                                      {new Date(
                                                                        unit.planDate
                                                                      ).toLocaleDateString()}
                                                                    </span>
                                                                  )}
                                                                </div>
                                                              </td>
                                                              <td className="date-column">
                                                                <div className="date-picker-container">
                                                                  <DatePicker
                                                                    selected={safelyParseDate(
                                                                      unit.lastReviewDate
                                                                    )}
                                                                    onChange={(
                                                                      date
                                                                    ) =>
                                                                      handleUnitChange(
                                                                        sectionIndex,
                                                                        chapterIndex,
                                                                        unitIndex,
                                                                        "lastReviewDate",
                                                                        date
                                                                      )
                                                                    }
                                                                    dateFormat="yyyy-MM-dd"
                                                                    customInput={
                                                                      <img
                                                                        src={
                                                                          calendarIcon
                                                                        }
                                                                        alt="Select Last Review Date"
                                                                        className="calendar-icon"
                                                                      />
                                                                    }
                                                                  />
                                                                  {unit.lastReviewDate && (
                                                                    <span className="selected-date">
                                                                      {new Date(
                                                                        unit.lastReviewDate
                                                                      ).toLocaleDateString()}
                                                                    </span>
                                                                  )}
                                                                </div>
                                                              </td>
                                                              <td className="review-count-column">
                                                                <div className="review-count-cell">
                                                                  <button
                                                                    className="review-count-button"
                                                                    onClick={() =>
                                                                      handleReviewChange(
                                                                        sectionIndex,
                                                                        chapterIndex,
                                                                        unitIndex,
                                                                        -1
                                                                      )
                                                                    }
                                                                    disabled={
                                                                      unit.reviewCount ===
                                                                      0
                                                                    }
                                                                  >
                                                                    -
                                                                  </button>
                                                                  <span className="review-count">
                                                                    {
                                                                      unit.reviewCount
                                                                    }
                                                                  </span>
                                                                  <button
                                                                    className="review-count-button"
                                                                    onClick={() =>
                                                                      handleReviewChange(
                                                                        sectionIndex,
                                                                        chapterIndex,
                                                                        unitIndex,
                                                                        1
                                                                      )
                                                                    }
                                                                  >
                                                                    +
                                                                  </button>
                                                                </div>
                                                              </td>
                                                              <td className="actions-column">
                                                                <img
                                                                  src={
                                                                    deleteIcon
                                                                  }
                                                                  alt="Delete"
                                                                  className="action-icon delete-unit-icon"
                                                                  onClick={() =>
                                                                    handleDeleteUnit(
                                                                      sectionIndex,
                                                                      chapterIndex,
                                                                      unitIndex
                                                                    )
                                                                  }
                                                                />
                                                                <img
                                                                  src={
                                                                    upArrowIcon
                                                                  }
                                                                  alt="Move Up"
                                                                  className="action-icon move-unit-icon"
                                                                  onClick={() =>
                                                                    handleMoveUnit(
                                                                      sectionIndex,
                                                                      chapterIndex,
                                                                      unitIndex,
                                                                      "up"
                                                                    )
                                                                  }
                                                                  style={{
                                                                    opacity:
                                                                      unitIndex ===
                                                                        0 &&
                                                                      chapterIndex ===
                                                                        0 &&
                                                                      sectionIndex ===
                                                                        0
                                                                        ? 0.5
                                                                        : 1,
                                                                  }}
                                                                />
                                                                <img
                                                                  src={
                                                                    downArrowIcon
                                                                  }
                                                                  alt="Move Down"
                                                                  className="action-icon move-unit-icon"
                                                                  onClick={() =>
                                                                    handleMoveUnit(
                                                                      sectionIndex,
                                                                      chapterIndex,
                                                                      unitIndex,
                                                                      "down"
                                                                    )
                                                                  }
                                                                  style={{
                                                                    opacity:
                                                                      unitIndex ===
                                                                        curriculum
                                                                          .units[
                                                                          `${sectionIndex}-${chapterIndex}`
                                                                        ]
                                                                          .length -
                                                                          1 &&
                                                                      chapterIndex ===
                                                                        curriculum
                                                                          .chapters[
                                                                          sectionIndex
                                                                        ]
                                                                          .length -
                                                                          1 &&
                                                                      sectionIndex ===
                                                                        curriculum
                                                                          .sections
                                                                          .length -
                                                                          1
                                                                        ? 0.5
                                                                        : 1,
                                                                  }}
                                                                />
                                                              </td>
                                                            </tr>
                                                          )
                                                        )}
                                                      <tr>
                                                        <td colSpan="5">
                                                          <button
                                                            className="add-unit-button"
                                                            onClick={() =>
                                                              handleAddUnit(
                                                                sectionIndex,
                                                                chapterIndex
                                                              )
                                                            }
                                                          >
                                                            <img
                                                              src="/toolbaricons/inuse/add.svg"
                                                              alt="Add Unit"
                                                              width="24"
                                                              height="24"
                                                              style={{
                                                                marginRight:
                                                                  "8px",
                                                              }}
                                                            />
                                                            New Unit
                                                          </button>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  )
                                )}
                              <tr>
                                <td colSpan="5">
                                  <button
                                    onClick={() =>
                                      handleAddChapter(sectionIndex)
                                    }
                                    className="add-chapter-button"
                                  >
                                    <img
                                      src="/toolbaricons/inuse/add.svg"
                                      alt="Add Unit"
                                      width="24"
                                      height="24"
                                      style={{
                                        marginRight: "8px",
                                      }}
                                    />
                                    New Chapter
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div className="add-buttons-group">
            <button
              onClick={handleAddSectionClick}
              className="add-section-button"
            >
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path
                  d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"
                  fill="currentColor"
                />
              </svg>
              New Section
            </button>
          </div>
          {deletingSectionIndex !== null && (
            <div className="delete-confirmation-modal">
              <div className="delete-confirmation-content">
                <p>
                  {deletingItemType === "section"
                    ? "This section and all its chapters, units and subunits will be deleted.\nDo you want to continue?"
                    : "This chapter and all its units and subunits will be deleted.\nDo you want to continue?"}
                </p>
                <div className="delete-confirmation-buttons">
                  <button
                    className="modal-button confirm-button"
                    onClick={confirmDeleteSection}
                  >
                    Delete
                  </button>
                  <button
                    className="modal-button cancel-button"
                    onClick={cancelDeleteSection}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
          {deleteConfirmation && (
            <div className="delete-confirmation-modal">
              <div className="delete-confirmation-content">
                <p>
                  This unit and all its subunits will be deleted.
                  <br />
                  Do you want to continue?
                </p>
                <div className="delete-confirmation-buttons">
                  <button
                    className="modal-button confirm-button"
                    onClick={confirmDeleteUnit}
                  >
                    Delete
                  </button>
                  <button
                    className="modal-button cancel-button"
                    onClick={cancelDeleteUnit}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Curriculum;
