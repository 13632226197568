import React, { useEffect } from "react";

const ContextMenu = ({ x, y, onClose, menuItems }) => {
  useEffect(() => {
    const handleClick = () => onClose();
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, [onClose]);

  return (
    <div
      className="leftbar-contextmenu"
      style={{
        position: "fixed",
        left: x,
        top: y,
        zIndex: 1000,
        background: "white",
        border: "1px solid #ccc",
        borderRadius: "4px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
        padding: "4px 0",
        minWidth: "120px",
      }}
    >
      {menuItems.map((item, index) => (
        <div
          key={index}
          className="leftbar-contextmenu__item"
          style={{
            padding: "8px 12px",
            cursor: "pointer",
            fontSize: "14px",
            hover: {
              backgroundColor: "#f5f5f5",
            },
          }}
          onClick={item.onClick}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};

export default ContextMenu;
