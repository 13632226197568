import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useNavigate, useParams } from "react-router-dom";
import "../css/toolbar.css";
import devideIcon from "../assets/icons/inuse/devide.svg";
import devidecancleIcon from "../assets/icons/inuse/devidecancle.svg";
import each1Icon from "../assets/icons/inuse/each1.svg";
import each2Icon from "../assets/icons/inuse/each2.svg";
import reviewModeIcon from "../assets/icons/inuse/review-mode.svg";
import Moveable from "react-moveable";
import { FontContext } from "../contexts/FontContext";
import stampIcon from "../assets/icons/inuse/rubber-stamp.svg";
import nextlowIcon from "../assets/icons/inuse/nextlow.svg";
import { storage, auth } from "../firebase/config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useTable } from "../contexts/TableContext";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase/config";

const DEFAULT_FONT = "TTAgainSea";

const fonts = [
  { name: "Arial", url: null },
  { name: "THEGaeideuk", url: "/fonts/THEGaeideuk.ttf" },
  { name: "TTAgainSea", url: "/fonts/TTAgainSea.ttf" },
  { name: "TTAPleasantDay", url: "/fonts/TTAPleasantDay.ttf" },
  { name: "TTARainyDayBK", url: "/fonts/TTARainyDayBK.ttf" },
  { name: "TTEveryDayThank", url: "/fonts/TTEveryDayThank.ttf" },
  { name: "TTFlowerRoad", url: "/fonts/TTFlowerRoad.ttf" },
  { name: "TTJustGift", url: "/fonts/TTJustGift.ttf" },
  { name: "TTLovelySSong", url: "/fonts/TTLovelySSong.ttf" },
  { name: "TTOhilyeoJoha", url: "/fonts/TTOhilyeoJoha.ttf" },
  { name: "TTOnABetterDay", url: "/fonts/TTOnABetterDay.ttf" },
  { name: "TTPenguinHeart", url: "/fonts/TTPenguinHeart.ttf" },
  { name: "TTPenWorkBook", url: "/fonts/TTPenWorkBook.ttf" },
  { name: "TTPrincessLike", url: "/fonts/TTPrincessLike.ttf" },
  { name: "TTSpringSun", url: "/fonts/TTSpringSun.ttf" },
  { name: "TTTodayGulimM", url: "/fonts/TTTodayGulimM.ttf" },
  { name: "TTWantToBeHappy", url: "/fonts/TTWantToBeHappy.ttf" },
];

const Toolbar = ({
  onPenModeChange,
  onEraserModeChange,
  onEraserSizeChange,
  onPenColorChange,
  onPenSizeChange,

  stampNames,
  onDrawingChange,
  onCanvasDataChange,
  unitId,
  subunitId,
  initialCanvasData,
  onTextBoxDataChange,
  initialTextBoxes = [],
  onStampAdd,
  initialStamps = [],
  onToggleRedText,
  setScale, // 추가된 prop
  data, // AnotherComponent에서 전달받을 데이터
  visibleEditors, // 현재 보이는 에디터 상태
  setVisibleEditors, // 보이는 에디터 상태 설정 함수
  hasEditorContent, // 에디터 콘텐츠 확인 함수
  ...props
}) => {
  const navigate = useNavigate();
  const { courseId, courseName } = useParams();
  const { font, setFont } = useContext(FontContext);

  const [isStampMode, setIsStampMode] = useState(false);
  const [selectedStampId, setSelectedStampId] = useState(null);
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [startPoint, setStartPoint] = useState(null);

  const noteContentRef = useRef(null);
  const [isPenMode, setIsPenMode] = useState(false);
  const [isEraserMode, setIsEraserMode] = useState(false);
  const [eraserSize, setEraserSize] = useState(10);
  const [penColor, setPenColor] = useState("#000000");
  const [isRemovingSplit, setIsRemovingSplit] = useState(false);
  const [isShowingRowByRow, setIsShowingRowByRow] = useState(false);
  const [isShowingRowByRowCol2, setIsShowingRowByRowCol2] = useState(false);
  const [showHighlightColors, setShowHighlightColors] = useState(false);
  const [showReviewModeOptions, setShowReviewModeOptions] = useState(false);
  const [moveableTargets, setMoveableTargets] = useState([]);
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [showStampOptions, setShowStampOptions] = useState(false);
  const [activePopup, setActivePopup] = useState(null);
  const toolbarRef = useRef(null);
  const [highlightNextLow1, setHighlightNextLow1] = useState(false);
  const [highlightNextLow2, setHighlightNextLow2] = useState(false);
  const [isReviewModeActive, setIsReviewModeActive] = useState(false);
  const [showFontDropdown, setShowFontDropdown] = useState(false);
  const [fontDropdownPosition, setFontDropdownPosition] = useState({
    top: 0,
    left: 0,
  });
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [history, setHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [activeMode, setActiveMode] = useState(null);
  const [penSize, setPenSize] = useState(2);

  const [stampCursorPosition, setStampCursorPosition] = useState({
    x: 0,
    y: 0,
    scale: 1,
  });
  const [textColor, setTextColor] = useState("#000000");
  const [showTextColorOptions, setShowTextColorOptions] = useState(false);

  const [initialFont, setInitialFont] = useState(DEFAULT_FONT);
  const { toggleRedText, currentCourseId, currentSubunitId } = useTable();

  const [isRedTextActive, setIsRedTextActive] = useState(false);

  // 1. 상태 추가
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // Firebase에서 폰트 설정을 가져오는 함수
  const loadFontSetting = async () => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      const fontRef = doc(
        db,
        `users/${user.uid}/courses/${courseId}/fonts`,
        "settings"
      );
      const fontDoc = await getDoc(fontRef);

      if (fontDoc.exists()) {
        const savedFont = fontDoc.data().fontFamily;
        setFont(savedFont);
        setInitialFont(savedFont);
        applyFont(savedFont);
      } else {
        // 저장된 폰트 설정이 없을 경우 기본값 저장
        await saveFontSetting(DEFAULT_FONT);
        setFont(DEFAULT_FONT);
        setInitialFont(DEFAULT_FONT);
        applyFont(DEFAULT_FONT);
      }
    } catch (error) {
      console.error("폰트 설정을 불러오는 중 오류 발생:", error);
      // 오류 발생 시에도 기본 폰트 적
      setFont(DEFAULT_FONT);
      setInitialFont(DEFAULT_FONT);
      applyFont(DEFAULT_FONT);
    }
  };

  // 폰트 설정을 Firebase에 저장하는 함수
  const saveFontSetting = async (newFont) => {
    try {
      const user = auth.currentUser;
      if (!user) return;

      const fontRef = doc(
        db,
        `users/${user.uid}/courses/${courseId}/fonts`,
        "settings"
      );
      await setDoc(fontRef, {
        fontFamily: newFont,
        updatedAt: new Date().toISOString(),
      });
    } catch (error) {}
  };

  useEffect(() => {
    setFont(initialFont);
    applyFont(initialFont);
  }, []);

  const applyFont = (newFont) => {
    document.documentElement.style.setProperty("--editor-font-family", newFont);

    const mainContent = document.querySelector(".main-content");
    if (mainContent) {
      mainContent.style.fontFamily = newFont;
    }

    const editors = document.querySelectorAll(".ql-editor");
    editors.forEach((editor) => {
      editor.style.fontFamily = newFont;
    });

    const unitName = document.querySelector(".unit-name");
    if (unitName) {
      unitName.style.fontFamily = newFont;
    }

    const inputs = document.querySelectorAll(".resizable-table input");
    inputs.forEach((input) => {
      input.style.fontFamily = newFont;
    });

    const textBoxes = document.querySelectorAll(".movable-textbox");
    textBoxes.forEach((textBox) => {
      textBox.style.fontFamily = newFont;
    });
  };

  // 기존 handleFontChange 함수 수정
  const handleFontChange = async (event) => {
    const newFont = event.target.value;
    setFont(newFont);
    applyFont(newFont);

    // 텍스트박스 폰트 업데이트
    const updatedTextBoxes = initialTextBoxes.map((textBox) => ({
      ...textBox,
      style: {
        ...textBox.style,
        fontFamily: newFont,
      },
    }));

    onTextBoxDataChange(updatedTextBoxes);

    // Firebase에 폰트 설정 저장
    await saveFontSetting(newFont);
  };

  const colors = [
    "#000000",
    "#757575",
    "#F24722",
    "#FFA629",
    "#FFCD29",
    "#15AE5C",
    "#0D99FF",
    "#9747FF",
    "#FFFFFF",
  ];

  const textColors = [
    { color: "#000000", icon: "/toolbaricons/textcolor-black.svg" },
    { color: "#757575", icon: "/toolbaricons/textcolor-grey.svg" },
    { color: "#F24722", icon: "/toolbaricons/textcolor-red.svg" },
    { color: "#FFA629", icon: "/toolbaricons/textcolor-orange.svg" },
    { color: "#FFCD29", icon: "/toolbaricons/textcolor-yellow.svg" },
    { color: "#15AE5C", icon: "/toolbaricons/textcolor-green.svg" },
    { color: "#0D99FF", icon: "/toolbaricons/textcolor-blue.svg" },
    { color: "#9747FF", icon: "/toolbaricons/textcolor-purple.svg" },
    { color: "#505BA7", icon: "/toolbaricons/textcolor-navy.svg" },
  ];

  useEffect(() => {
    fonts.forEach((font) => {
      if (font.url) {
        const fontFace = new FontFace(font.name, `url(${font.url})`);
        fontFace
          .load()
          .then((loadedFont) => {
            document.fonts.add(loadedFont);
            setFont((prevFont) => prevFont);
          })
          .catch((error) => {});
      }
    });
  }, [fonts]);

  const handleColorChange = useCallback(
    (color) => {
      setPenColor(color);
      onPenColorChange(color);
    },
    [onPenColorChange]
  );

  const exportToPDF = () => {
    const mainContent = document.querySelector(".main-content");
    if (mainContent) {
      // PDF 출력 전 줌 레벨을 100%로 설정
      setScale(1);

      const elementsToHide = mainContent.querySelectorAll(
        ".youtube-video, .stamp, .ui-element, .context-menu, .button"
      );
      elementsToHide.forEach((el) => {
        el.style.display = "none";
      });

      setTimeout(() => {
        html2canvas(mainContent, {
          scale: 2,
          useCORS: true,
          logging: false,
          windowWidth: document.documentElement.clientWidth,
          windowHeight: document.documentElement.clientHeight,
          ignoreElements: (element) => {
            return (
              element.classList.contains("ignore-in-pdf") ||
              element.id === "ignore-in-pdf"
            );
          },
        }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            format: "a4",
          });
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
          pdf.save("main-content.pdf");

          // 숨긴 요소들 다시 표시
          elementsToHide.forEach((el) => {
            el.style.display = "";
          });
        });
      }, 100);
    }
  };

  const handleTextColorChange = (color) => {
    setTextColor(color);
    setShowTextColorOptions(false);
  };

  // 메모이제이션된 핸들러들을 컴포넌트 레벨에서 선언
  const handleDrag = useCallback(
    (e, newTextBox, isDragging, startX, startY, mainContent) => {
      if (isDragging) {
        const rect = mainContent.getBoundingClientRect();
        const scaleX = mainContent.offsetWidth / rect.width;
        const scaleY = mainContent.offsetHeight / rect.height;
        const newX = (e.clientX - rect.left) * scaleX - startX;
        const newY = (e.clientY - rect.top) * scaleY - startY;
        newTextBox.style.left = `${newX}px`;
        newTextBox.style.top = `${newY}px`;
      }
    },
    []
  );

  const handleAddTextBox = (color, existingData = null) => {
    console.log("텍스트박스 추가 시작:", { color, existingData });

    const mainContent = document.querySelector(".main-content");
    if (!mainContent) {
      console.error("main-content element를 찾을 수 없음");
      return;
    }

    const rect = mainContent.getBoundingClientRect();
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;

    const newTextBox = document.createElement("div");
    const textBoxId = existingData?.id || `textbox-${Date.now()}`;
    newTextBox.dataset.id = textBoxId;

    // 삭제 버튼 추가
    const deleteButton = document.createElement("button");
    deleteButton.className = "delete-textbox";
    const deleteIcon = document.createElement("img");
    deleteIcon.src = "/toolbaricons/inuse/cancel.svg";
    deleteIcon.alt = "삭제";
    deleteButton.appendChild(deleteIcon);

    // 삭제 버튼 이벤트 리스너
    deleteButton.addEventListener("click", (e) => {
      e.stopPropagation();
      mainContent.removeChild(newTextBox);

      const remainingTextBoxes = Array.from(
        document.querySelectorAll(".movable-textbox")
      ).map((box) => ({
        id: box.dataset.id,
        text: box.textContent,
        style: {
          left: box.style.left,
          top: box.style.top,
          fontFamily: box.style.fontFamily,
          fontSize: box.style.fontSize,
          color: box.style.color,
        },
      }));

      console.log("텍스트박스 삭제됨:", remainingTextBoxes);
      onTextBoxDataChange(remainingTextBoxes);
    });

    // 기본 스타일 설정 (이전과 동일)
    const defaultStyle = {
      position: "absolute",
      left: `${centerX}px`,
      top: `${centerY}px`,
      fontSize: "12pt",
      fontFamily: font,
      color: color || "#e0e0e0",
      padding: "10px",
      minWidth: "100px",
      backgroundColor: "transparent",
      border: "1px solid transparent",
      zIndex: "100",
    };

    if (existingData) {
      Object.assign(newTextBox.style, {
        left: existingData.style.left,
        top: existingData.style.top,
        fontFamily: existingData.style.fontFamily,
        fontSize: "12pt",
        color: existingData.style.color,
      });
      newTextBox.textContent = existingData.text;
    } else {
      Object.assign(newTextBox.style, defaultStyle);
      newTextBox.textContent = "텍스트를 입력하세요";
    }

    // hover 시 삭제 버튼 표시
    newTextBox.addEventListener("mouseenter", () => {
      deleteButton.style.display = "block";
    });

    newTextBox.addEventListener("mouseleave", () => {
      deleteButton.style.display = "none";
    });

    newTextBox.contentEditable = true;
    newTextBox.className = "movable-textbox";

    // 텍스트박스 이벤트 리스너
    let isDragging = false;
    let startX, startY, initialX, initialY;

    newTextBox.addEventListener("mousedown", (e) => {
      if (e.target === newTextBox) {
        isDragging = true;
        startX = e.clientX;
        startY = e.clientY;
        initialX = newTextBox.offsetLeft;
        initialY = newTextBox.offsetTop;
      }
    });

    newTextBox.addEventListener("input", () => {
      // 텍스트박스 데이터 수��� 및 저장
      const textBoxes = Array.from(
        document.querySelectorAll(".movable-textbox")
      ).map((box) => ({
        id: box.dataset.id,
        text: box.textContent,
        style: {
          left: box.style.left,
          top: box.style.top,
          fontFamily: box.style.fontFamily,
          fontSize: box.style.fontSize,
          color: box.style.color,
        },
      }));

      console.log("텍스트박스 내용 변경:", textBoxes);
      onTextBoxDataChange(textBoxes);
    });

    document.addEventListener("mousemove", (e) => {
      if (isDragging) {
        const dx = e.clientX - startX;
        const dy = e.clientY - startY;
        newTextBox.style.left = `${initialX + dx}px`;
        newTextBox.style.top = `${initialY + dy}px`;
      }
    });

    document.addEventListener("mouseup", () => {
      if (isDragging) {
        isDragging = false;
        // 위치 변경 후 데이터 저장
        const textBoxes = Array.from(
          document.querySelectorAll(".movable-textbox")
        ).map((box) => ({
          id: box.dataset.id,
          text: box.textContent,
          style: {
            left: box.style.left,
            top: box.style.top,
            fontFamily: box.style.fontFamily,
            fontSize: box.style.fontSize,
            color: box.style.color,
          },
        }));

        console.log("텍스트박스 위치 변경:", textBoxes);
        onTextBoxDataChange(textBoxes);
      }
    });

    newTextBox.appendChild(deleteButton);
    mainContent.appendChild(newTextBox);

    // 새 텍스트박스 추가 후 데이터 저장
    const textBoxes = Array.from(
      document.querySelectorAll(".movable-textbox")
    ).map((box) => ({
      id: box.dataset.id,
      text: box.textContent.replace("×", "").trim(), // 삭제 버튼 텍스트 제거
      style: {
        left: box.style.left,
        top: box.style.top,
        fontFamily: box.style.fontFamily,
        fontSize: box.style.fontSize,
        color: box.style.color,
      },
    }));

    console.log("새 텍스트박스 추가됨:", textBoxes);
    onTextBoxDataChange(textBoxes);
  };

  // 디바운스 유틸리티 함수
  const debounce = (fn, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn.apply(this, args), delay);
    };
  };

  // 전역 이벤트 리스너 최적화
  useEffect(() => {
    const memoizedMouseMove = debounce((e) => {
      if (isStampMode && selectedStampId) {
        const mainContent = document.querySelector(".main-content");
        if (mainContent) {
          const rect = mainContent.getBoundingClientRect();
          const scaleX = mainContent.offsetWidth / rect.width;
          const scaleY = mainContent.offsetHeight / rect.height;
          setStampCursorPosition({
            x: e.clientX,
            y: e.clientY,
            scale: Math.min(scaleX, scaleY),
          });
        }
      }
    }, 16); // 약 60fps

    const memoizedKeyDown = (e) => {
      if (e.key === "Escape") {
        resetAllModes();
      }
    };

    window.addEventListener("mousemove", memoizedMouseMove);
    document.addEventListener("keydown", memoizedKeyDown);

    return () => {
      window.removeEventListener("mousemove", memoizedMouseMove);
      document.removeEventListener("keydown", memoizedKeyDown);
    };
  }, [isStampMode, selectedStampId]);

  // 모든 모드 초기화 함수
  const resetAllModes = useCallback(() => {
    setActiveMode(null);
    setIsDrawingMode(false);
    setIsPenMode(false);
    setIsEraserMode(false);
    setIsStampMode(false);
    setIsReviewModeActive(false);
    setStartPoint(null);
    document.body.classList.remove("drawing-mode");
    setShowStampOptions(false);
    setShowReviewModeOptions(false);
    setShowHighlightColors(false);
  }, []);

  const handleBackClick = () => {
    navigate(`/courses/${courseId}/curriculum/${courseName}`);
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isStampMode && selectedStampId) {
        const mainContent = document.querySelector(".main-content");
        if (mainContent) {
          const rect = mainContent.getBoundingClientRect();
          const scaleX = mainContent.offsetWidth / rect.width;
          const scaleY = mainContent.offsetHeight / rect.height;
          setStampCursorPosition({
            x: e.clientX,
            y: e.clientY,
            scale: Math.min(scaleX, scaleY),
          });
        }
      }
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isStampMode, selectedStampId]);

  const closeAllPopups = (exceptReviewMode = false) => {
    if (!exceptReviewMode) {
      setActivePopup(null);
    }
    setIsStampMode(false);
    setShowStampOptions(false);
    if (!exceptReviewMode) {
      setShowReviewModeOptions(false);
    }
    setShowFontDropdown(false);
  };

  // activeMode 상태 확인 함수 수정
  const isButtonDisabled = (buttonMode) => {
    // 현재 활성화된 모드가 있고, 그것이 현재 버튼의 모드가 아 경우 비활성화
    return activeMode !== null && activeMode !== buttonMode;
  };

  // 모드 변경 핸들러 수정
  const handleModeChange = (mode) => {
    // 같은 버튼을 다시 클릭하면 모드 해제
    if (activeMode === mode) {
      setActiveMode(null);
      // 모든 모드 상태 초기화
      setIsPenMode(false);
      setIsEraserMode(false);

      setIsStampMode(false);
      setIsReviewModeActive(false);
      document.body.classList.remove("drawing-mode");

      // 모드 해제 시 커서 초기화
      document.body.style.cursor = "default";

      // 콜백 함수 호출하여 모드 해제 알림
      if (mode === "eraser") onEraserModeChange(false);
      if (mode === "pen") onPenModeChange(false);
    } else {
      // 다른 버튼 클릭시 새로운 모드로 변경
      setActiveMode(mode);
      // 해당하는 모드만 활성화하고 나머지는 비활성화
      setIsPenMode(mode === "pen");
      setIsEraserMode(mode === "eraser");

      setIsStampMode(mode === "stamp");
      setIsReviewModeActive(mode === "review");

      // 콜백 함수 호출하여 모드 활성화 알림
      if (mode === "eraser") onEraserModeChange(true);
      if (mode === "pen") onPenModeChange(true);
    }

    // 모든 옵션 패널 닫기

    setShowTextColorOptions(false);
    setShowStampOptions(false);
  };

  const handleEraserClick = () => {
    const newEraserMode = !isEraserMode;
    setIsEraserMode(newEraserMode);
    setIsPenMode(false);
    setActiveMode(newEraserMode ? "eraser" : null);
    onEraserModeChange(newEraserMode);
    updateEraserCursor(newEraserMode ? eraserSize : null);
  };

  const handleEraserSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    setEraserSize(newSize);
    onEraserSizeChange(newSize);
    if (isEraserMode) {
      updateEraserCursor(newSize);
    }
  };

  const updateEraserCursor = (size) => {
    if (size && isEraserMode) {
      // isEraserMode 조건 추가
      const cursorSize = size + 2;
      document.body.style.cursor = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${cursorSize}" height="${cursorSize}" viewBox="0 0 ${cursorSize} ${cursorSize}"><circle cx="${
        cursorSize / 2
      }" cy="${cursorSize / 2}" r="${
        size / 2
      }" fill="rgba(255,255,255,0.5)" stroke="black" stroke-width="1"/></svg>') ${
        cursorSize / 2
      } ${cursorSize / 2}, auto`;
    } else {
      document.body.style.cursor = "default"; // 모드가 해제되면 커서를 기본값으로
    }
  };

  const handlePenClick = () => {
    const newPenMode = !isPenMode;
    setIsPenMode(newPenMode);
    setActiveMode(newPenMode ? "pen" : null);

    if (!newPenMode) {
      if (onPenModeChange) onPenModeChange(newPenMode, true);
    } else {
      if (onPenModeChange) onPenModeChange(newPenMode, false);
    }

    if (!newPenMode) {
      document.body.classList.remove("drawing-mode");
      document.body.style.cursor = "default";
    } else {
      document.body.classList.add("drawing-mode");
      document.body.style.cursor = `url(${process.env.PUBLIC_URL}/assets/icons/cursor-pen.svg) 1 1, auto`;
    }
  };

  const handleReviewModeClick = () => {
    const newState = !isReviewModeActive;

    if (!newState) {
      // 리뷰 모드를 끄는 경우
      handleExitReviewMode();
    } else {
      // 리뷰 모드를 켜는 경우
      setIsReviewModeActive(true);
      handleModeChange("review");
      setShowReviewModeOptions(true);
    }
  };

  const handleStampClick = () => {
    handleModeChange("stamp");
  };

  const handleStampOptionClick = (stampId) => {
    setSelectedStampId(stampId);
  };

  useEffect(() => {
    const handleStampInsert = (e) => {
      if (isStampMode && selectedStampId) {
        const mainContent = document.querySelector(".main-content");
        if (mainContent) {
          const rect = mainContent.getBoundingClientRect();
          const scaleX = mainContent.offsetWidth / rect.width;
          const scaleY = mainContent.offsetHeight / rect.height;
          const x = (e.clientX - rect.left) * scaleX;
          const y = (e.clientY - rect.top) * scaleY;

          const stampId = `stamp-${Date.now()}`;
          const stamp = document.createElement("img");
          stamp.src = `/img/stamp/stamp${selectedStampId}.svg`;
          stamp.className = "stamp";
          stamp.dataset.stampId = stampId;
          stamp.style.position = "absolute";
          stamp.style.left = `${x}px`;
          stamp.style.top = `${y}px`;
          stamp.style.width = "100px";
          stamp.style.height = "100px";
          stamp.style.zIndex = "1000";
          stamp.style.transform = "translate(-50%, -50%)";
          stamp.style.pointerEvents = "auto";
          stamp.style.cursor = "pointer";

          mainContent.appendChild(stamp);

          // Firebase에 스탬프 데이터 저장
          onStampAdd({
            id: stampId,
            stampId: selectedStampId,
            position: { x, y },
          });

          setIsStampMode(false);
          setSelectedStampId(null);
          setActiveMode(null);
          document.body.style.cursor = "default";
        }
      }
    };

    if (isStampMode && selectedStampId) {
      document.addEventListener("click", handleStampInsert);
    }

    return () => {
      document.removeEventListener("click", handleStampInsert);
    };
  }, [isStampMode, selectedStampId]);

  const handleToggleFirstColumnClick = () => {
    const event = new CustomEvent("toggleFirstColumn");
    window.dispatchEvent(event);
  };

  const handleToggleSecondColumnClick = () => {
    const event = new CustomEvent("toggleSecondColumn");
    window.dispatchEvent(event);
  };

  const handleToggleShowRowByRow = () => {
    const newState = !isShowingRowByRow;
    setIsShowingRowByRow(newState);
    setHighlightNextLow1(newState);

    // Hide Rows 버튼을 누를 때는 모든 에디터를 가리고
    // Show Rows 버튼을 누를 때는 모든 에디터를 보이게 함
    const event = new CustomEvent("toggleShowRowByRow", {
      detail: {
        isActive: newState,
        hideAll: newState, // true면 가리기, false면 보이기
        showAll: !newState, // true면 모두 보이기
      },
    });
    window.dispatchEvent(event);
  };

  const handleShowNextRow = useCallback(
    (event) => {
      // event.detail이 없으면 함수 종료
      if (!event || !event.detail || !event.detail.columnId || !data) return;

      const { columnId } = event.detail;
      const allEditors = [];

      // 해당 열의 모든 에디터를 순회하면서 콘텐츠가 있는 에디터만 수집
      data.forEach((row, rowIndex) => {
        const editors = row[columnId] || [];
        editors.forEach((_, editorIndex) => {
          if (hasEditorContent(rowIndex, columnId, editorIndex)) {
            allEditors.push(`${rowIndex}-${columnId}-${editorIndex}`);
          }
        });
      });

      // 현재 보이는 에디터들 중 마지막 다음 에디터를 찾아서 보이게 함
      const currentVisible = visibleEditors[columnId] || [];
      const lastVisibleIndex = Math.max(
        -1,
        ...currentVisible.map((key) => allEditors.indexOf(key))
      );

      if (lastVisibleIndex < allEditors.length - 1) {
        const nextEditor = allEditors[lastVisibleIndex + 1];
        setVisibleEditors((prev) => ({
          ...prev,
          [columnId]: [...(prev[columnId] || []), nextEditor],
        }));
      }
    },
    [data, hasEditorContent, visibleEditors]
  );

  // useEffect에서 이벤트 리스너 등록
  useEffect(() => {
    window.addEventListener("showNextRow", handleShowNextRow);

    return () => {
      window.removeEventListener("showNextRow", handleShowNextRow);
    };
  }, [handleShowNextRow]);

  // 버튼 클릭 핸들러
  const handleNextLowClick = (columnId) => {
    const event = new CustomEvent("showNextRow", {
      detail: { columnId }, // 전달받은 columnId 사용
    });
    window.dispatchEvent(event);
  };

  const handleToggleShowRowByRowCol2Click = () => {
    const newState = !isShowingRowByRowCol2;
    setIsShowingRowByRowCol2(newState);
    setHighlightNextLow2(newState);

    // Hide Rows 버튼을 누를 때는 모든 에디터를 가리고
    // Show Rows 버튼을 누를 때는 모든 에디터를 보이게 함
    const event = new CustomEvent("toggleShowRowByRowCol2", {
      detail: {
        isActive: newState,
        hideAll: newState, // true면 가리기, false면 보이기
        showAll: !newState, // true면 모두 보이기
      },
    });
    window.dispatchEvent(event);
  };

  const handleShowNextRowCol2 = () => {
    const allEditors = [];

    data.forEach((row, rowIndex) => {
      const editors = row.col2 || [];
      editors.forEach((_, editorIndex) => {
        if (hasEditorContent(rowIndex, "col2", editorIndex)) {
          allEditors.push(`${rowIndex}-col2-${editorIndex}`);
        }
      });
    });

    const lastVisibleIndex = Math.max(
      -1,
      ...visibleEditors.col2.map((key) => allEditors.indexOf(key))
    );

    if (lastVisibleIndex < allEditors.length - 1) {
      const nextEditor = allEditors[lastVisibleIndex + 1];
      setVisibleEditors((prev) => ({
        ...prev,
        col2: [...prev.col2, nextEditor],
      }));
    }
  };

  // 플레이어 밖 클릭 처리를 위한 useCallback 훅
  const handleClickOutside = useCallback(
    (e) => {
      if (
        selectedTarget &&
        !document.getElementById(selectedTarget)?.contains(e.target)
      ) {
        setSelectedTarget(null);
      }
    },
    [selectedTarget]
  );

  // 이벤트 리스너 등록 및 해제
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  // ESC 키 벤트 처리를 위 useEffect 수정
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        // 모든 모드 해제
        setActiveMode(null);
        setIsDrawingMode(false);

        setIsPenMode(false);
        setIsEraserMode(false);
        setIsStampMode(false);
        setIsReviewModeActive(false);

        // 시작점과 미리보기 라인 초기화
        setStartPoint(null);

        // drawing-mode 클래스 제거
        document.body.classList.remove("drawing-mode");

        // 든 옵션 메뉴 닫기

        setShowStampOptions(false);
        setShowReviewModeOptions(false);
        setShowHighlightColors(false);
        // 필요한 경우 다른 메뉴들도 여기에 추가

        // 커스텀 이벤트 발생 (필요한 경우)
        window.dispatchEvent(
          new CustomEvent("drawingModeChange", {
            detail: { isDrawingMode: false },
          })
        );
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // 의존성 배열을 비워 마운트 시에만 이벤 리스너를 추가하도록 함

  // 활성 상태 스타일
  const activeButtonStyle = {
    backgroundColor: "#e0e0e0",
    boxShadow: "inset 0 0 5px rgba(0,0,0,0.2)",
  };

  const highlightStyle = {
    backgroundColor: "#ffff99",
    boxShadow: "0 0 5px rgba(0,0,0,0.3)",
    transition: "all 0.3s ease",
  };

  const handleFontButtonClick = (event) => {
    const buttonRect = event.currentTarget.getBoundingClientRect();
    setFontDropdownPosition({
      top: buttonRect.bottom,
      left: buttonRect.left,
    });
    closeAllPopups(); // 모든 팝업 닫기
    setShowFontDropdown(!showFontDropdown);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      setShowFontDropdown(false);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // handleButtonClick 함수 추
  const handleButtonClick = (callback) => {
    if (activeMode === null) {
      callback();
    }
  };
  const handleSplitColumnClick = () => {
    handleButtonClick(() => {
      const event = new CustomEvent("splitColumn");
      window.dispatchEvent(event);
    });
  };

  const handleRemoveSplitClick = () => {
    handleButtonClick(() => {
      saveStateToHistory();
      setIsRemovingSplit((prev) => !prev);
      const event = new CustomEvent("removeSplit");
      window.dispatchEvent(event);
    });
  };

  const saveStateToHistory = () => {
    const mainContent = document.querySelector(".main-content");
    if (mainContent) {
      const state = mainContent.innerHTML;
      setHistory((prevHistory) => [
        ...prevHistory.slice(0, historyIndex + 1),
        state,
      ]);
      setHistoryIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePenSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    setPenSize(newSize);
    onPenSizeChange(newSize);
  };

  useEffect(() => {
    const mainContent = document.querySelector(".main-content");
    if (!mainContent || !initialTextBoxes?.length) return;

    // 이미 존재하는 텍스트박스는 다시 생성하지 않음
    const existingTextBoxIds = Array.from(
      mainContent.querySelectorAll(".movable-textbox")
    ).map((box) => box.dataset.id);

    // 새로운 텍스트박스만 생성
    initialTextBoxes.forEach((textBoxData) => {
      if (!existingTextBoxIds.includes(textBoxData.id)) {
        handleAddTextBox(textBoxData.style.color, textBoxData);
      }
    });
  }, [initialTextBoxes]);

  const updateTextBoxData = (textBoxId, newStyles) => {
    const updatedTextBoxes = initialTextBoxes.map((textBox) => {
      if (textBox.id === textBoxId) {
        return {
          ...textBox,
          style: {
            ...textBox.style,
            ...newStyles,
          },
        };
      }
      return textBox;
    });
    onTextBoxDataChange(updatedTextBoxes);
  };

  const handleDeleteTextBox = (textBoxId) => {
    // DOM에서 텍스트박스 제거
    const textBox = document.querySelector(`[data-id="${textBoxId}"]`);
    if (textBox) {
      textBox.remove();
    }

    // 현재 DOM에 있 모든 텍스트박스 정보를 수집
    const allTextBoxes = Array.from(
      document.querySelectorAll(".movable-textbox")
    ).map((box) => ({
      id: box.dataset.id,
      text: box.textContent,
      style: {
        fontFamily: box.style.fontFamily,
        fontSize: box.style.fontSize,
        color: box.style.color,
        left: box.style.left,
        top: box.style.top,
      },
    }));

    // Firebase 업데트를 위해 부모 컴포넌트에 알림
    onTextBoxDataChange(allTextBoxes);
  };

  useEffect(() => {
    const mainContent = document.querySelector(".main-content");
    if (mainContent && initialStamps?.length > 0) {
      // 기존 스탬프 제거
      const existingStamps = mainContent.querySelectorAll(".stamp");
      existingStamps.forEach((stamp) => stamp.remove());

      // 새로운 스탬프 렌더링
      initialStamps.forEach((stampData) => {
        const stamp = document.createElement("img");
        stamp.src = `/img/stamp/stamp${stampData.stampId}.svg`;
        stamp.className = "stamp";
        stamp.style.position = "absolute";
        stamp.style.left = `${stampData.position.x}px`;
        stamp.style.top = `${stampData.position.y}px`;
        stamp.style.width = "100px";
        stamp.style.height = "100px";
        stamp.style.zIndex = "1000";
        stamp.style.transform = "translate(-50%, -50%)";
        stamp.style.pointerEvents = "auto";
        stamp.style.cursor = "pointer";

        mainContent.appendChild(stamp);
      });
    }
  }, [initialStamps]);

  const handleToggleRedText = () => {
    setIsRedTextActive(!isRedTextActive);
    onToggleRedText();
  };

  useEffect(() => {
    const handleRedTextStateChanged = () => {
      setIsRedTextActive((prev) => !prev);
    };

    window.addEventListener("redTextStateChanged", handleRedTextStateChanged);
    return () => {
      window.removeEventListener(
        "redTextStateChanged",
        handleRedTextStateChanged
      );
    };
  }, []);

  // 컴포넌트 마운트 시 폰트 설정 로드
  useEffect(() => {
    loadFontSetting();
  }, [courseId]); // courseId가 변경될 때마다 폰트 설정을 다시 로드

  // useEffect를 추가하여 isEraserMode 상태 변경 감지
  useEffect(() => {
    if (!isEraserMode) {
      document.body.style.cursor = "default";
    } else if (eraserSize) {
      updateEraserCursor(eraserSize);
    }
  }, [isEraserMode, eraserSize]);

  // Exit 모드 함수 추가
  const handleExitReviewMode = () => {
    // 리뷰 모드 관련 모든 상태 초기화
    setIsReviewModeActive(false);
    setShowReviewModeOptions(false);
    setIsShowingRowByRow(false);
    setIsShowingRowByRowCol2(false);
    setHighlightNextLow1(false);
    setHighlightNextLow2(false);
    setActiveMode(null);

    // 커스텀 이벤트 발생
    const exitEvent = new CustomEvent("exitReviewMode", {
      detail: { complete: true },
    });
    window.dispatchEvent(exitEvent);

    // 모든 열 표시 이벤트 발생
    const showAllEvent = new CustomEvent("showAllColumns");
    window.dispatchEvent(showAllEvent);
  };

  // handleRedTextClick 함수 정의
  const handleRedTextClick = () => {
    onToggleRedText(); // 이제 로컬 상태는 이벤트 리스너를 통해 변경됨
  };

  return (
    <>
      <div className="toolbar" ref={toolbarRef}>
        <div className="toolbar-left">
          <button
            onClick={handleBackClick}
            className="back-button"
            disabled={activeMode !== null}
          >
            <img src="/toolbaricons/inuse/back.svg" alt="뒤로가기" />
            <span>Curriculum</span>
          </button>
        </div>
        <div className="toolbar-center">
          <div className="toolbar-tooltip">
            <button onClick={exportToPDF} disabled={activeMode !== null}>
              <img src="/toolbaricons/inuse/file-pdf.svg" alt="PDF 내보내기" />
            </button>
            <span className="toolbar-tooltiptext">Export to PDF</span>
          </div>
          <div className="toolbar-tooltip">
            <button
              onClick={() => setShowTextColorOptions(!showTextColorOptions)}
              disabled={activeMode !== null}
            >
              <img
                src="/toolbaricons/inuse/textbox.svg"
                alt="텍스트박스 추가"
              />
            </button>
            <span className="toolbar-tooltiptext">Add Text Box</span>
          </div>
          {showTextColorOptions && (
            <div className="text-color-options">
              {textColors.map(({ color, icon }) => (
                <button
                  key={color}
                  style={{
                    width: "30px",
                    height: "30px",
                    margin: "0 5px",
                    padding: 0,
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => handleAddTextBox(color)}
                >
                  <img
                    src={icon}
                    alt={`텍스트 색상: ${color}`}
                    style={{ width: "100%", height: "100%" }}
                  />
                </button>
              ))}
            </div>
          )}
          <div className="toolbar-tooltip">
            <button
              onClick={handleSplitColumnClick}
              disabled={activeMode !== null}
            >
              <img src={devideIcon} alt="열 분할" />
            </button>
            <span className="toolbar-tooltiptext">Split Column</span>
          </div>
          <div className="toolbar-tooltip">
            <button
              onClick={handleRemoveSplitClick}
              disabled={isButtonDisabled("removeSplit")}
            >
              <img src={devidecancleIcon} alt="분할 제거" />
            </button>
            <span className="toolbar-tooltiptext">Remove Split</span>
          </div>
          <div className="toolbar-tooltip">
            <button
              onClick={handleReviewModeClick}
              className={`review-mode-button ${
                isReviewModeActive ? "active" : ""
              }`}
              disabled={isButtonDisabled("review")}
            >
              <img src={reviewModeIcon} alt=" 모드" />
              <span>{isReviewModeActive ? "Exit Mode" : "Review Mode"}</span>
            </button>
            <span className="toolbar-tooltiptext">Review Mode</span>
          </div>
          {isReviewModeActive && (
            <div className="review-mode-options">
              <div className="toolbar-tooltip">
                <button onClick={handleToggleShowRowByRow}>
                  <img
                    src={each1Icon}
                    alt={
                      isShowingRowByRow
                        ? "행별 보기 해제 (1)"
                        : "행별 보기 (1열)"
                    }
                  />
                  <span>
                    {isShowingRowByRow
                      ? "Show rows (1st col)"
                      : "Hide rows (1st col)"}
                  </span>
                </button>
                <span className="toolbar-tooltiptext">
                  {isShowingRowByRow
                    ? "Show rows (1st col)"
                    : "Hide rows (1st col)"}
                </span>
              </div>
              {isShowingRowByRow && (
                <div className="toolbar-tooltip">
                  <button
                    onClick={() => handleNextLowClick("col1")}
                    style={{
                      border: "2px solid #FFD700",
                      boxShadow: "0 0 10px rgba(255, 215, 0, 0.5)",
                      animation: "pulse 1.5s infinite",
                    }}
                  >
                    <img src={nextlowIcon} alt="Next low" />
                    <span>Next low</span>
                  </button>
                  <span className="toolbar-tooltiptext">Next low</span>
                </div>
              )}
              <div className="toolbar-tooltip">
                <button onClick={handleToggleShowRowByRowCol2Click}>
                  <img
                    src={each2Icon}
                    alt={
                      isShowingRowByRowCol2
                        ? "행별 보기 해제 (2열)"
                        : "행별 보기 (2열)"
                    }
                  />
                  <span>
                    {isShowingRowByRowCol2
                      ? "Show rows (2nd col)"
                      : "Hide rows (2nd col)"}
                  </span>
                </button>
                <span className="toolbar-tooltiptext">
                  {isShowingRowByRowCol2
                    ? "Show rows (2nd col)"
                    : "Hide rows (2nd col)"}
                </span>
              </div>
              {isShowingRowByRowCol2 && (
                <div className="toolbar-tooltip">
                  <button
                    onClick={() => handleNextLowClick("col2")}
                    style={{
                      border: "2px solid #FFD700",
                      boxShadow: "0 0 10px rgba(255, 215, 0, 0.5)",
                      animation: "pulse 1.5s infinite",
                    }}
                  >
                    <img src={nextlowIcon} alt="Next low" />
                    <span>Next low</span>
                  </button>
                  <span className="toolbar-tooltiptext">Next low</span>
                </div>
              )}
            </div>
          )}
          <div className="toolbar-tooltip">
            <button
              onClick={handleStampClick}
              disabled={activeMode !== null && activeMode !== "stamp"}
            >
              <img src={stampIcon} alt="스탬프" />
            </button>
            <span className="toolbar-tooltiptext">Label</span>
          </div>
          {activeMode === "stamp" && (
            <div className="stamp-options">
              {stampNames.map((name, index) => (
                <button
                  key={index + 1}
                  onClick={() => handleStampOptionClick(`${index + 1}`)}
                >
                  <img
                    src={`/img/stamp/stamp${index + 1}.svg`}
                    alt={`스탬프 ${name}`}
                  />
                </button>
              ))}
            </div>
          )}
          <div className="font-selector">
            <div className="toolbar-tooltip">
              <button
                onClick={handleFontButtonClick}
                disabled={activeMode !== null}
              >
                <span style={{ fontFamily: font }}>{font}</span>
                <img
                  src="/toolbaricons/inuse/dropdown-arrow.svg"
                  alt="폰트 선택"
                />
              </button>
              <span className="toolbar-tooltiptext">Select Font</span>
            </div>
            {showFontDropdown && (
              <div
                className="font-dropdown"
                style={{
                  position: "fixed",
                  top: `${fontDropdownPosition.top}px`,
                  left: `${fontDropdownPosition.left}px`,
                }}
              >
                {fonts.map((fontOption) => (
                  <button
                    key={fontOption.name}
                    onClick={() => {
                      handleFontChange({ target: { value: fontOption.name } });
                      setShowFontDropdown(false);
                    }}
                    style={{ fontFamily: fontOption.name }}
                  >
                    {fontOption.name}
                  </button>
                ))}
              </div>
            )}
          </div>

          <div className="drawing-buttons">
            <div className="toolbar-tooltip">
              <button
                onClick={() => handleModeChange("pen")}
                disabled={isButtonDisabled("pen")}
                className={activeMode === "pen" ? "active" : ""}
              >
                <img src="/toolbaricons/inuse/pen.svg" alt="펜" />
              </button>
              <span className="toolbar-tooltiptext">Pen</span>
            </div>
            {activeMode === "pen" && (
              <div className="pen-options">
                <div className="pen-size-control">
                  <input
                    type="range"
                    min="2"
                    max="40"
                    value={penSize}
                    onChange={handlePenSizeChange}
                  />
                  <div
                    className="pen-size-preview"
                    style={{
                      width: `${penSize}px`,
                      height: `${penSize}px`,
                      backgroundColor: penColor,
                      borderRadius: "50%",
                      border: "1px solid #000",
                      marginLeft: "10px",
                    }}
                  ></div>
                </div>
                <div className="color-options">
                  {colors.map((color) => (
                    <button
                      key={color}
                      style={{
                        backgroundColor: color,
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        margin: "0 5px",
                        border: penColor === color ? "2px solid black" : "none",
                        padding: 0,
                        cursor: "pointer",
                        display: "inline-block",
                        verticalAlign: "middle",
                        boxShadow:
                          color === "#FFFFFF" ? "0 0 0 1px #000000" : "none",
                      }}
                      onClick={() => handleColorChange(color)}
                    />
                  ))}
                </div>
              </div>
            )}
            <div className="toolbar-tooltip">
              <button
                onClick={() => handleModeChange("eraser")}
                disabled={isButtonDisabled("eraser")}
                className={activeMode === "eraser" ? "active" : ""}
              >
                <img src="/toolbaricons/inuse/eraser.svg" alt="지우개" />
              </button>
              <span className="toolbar-tooltiptext">Eraser</span>
            </div>
            {activeMode === "eraser" && (
              <div className="eraser-size-control">
                <input
                  type="range"
                  min="1"
                  max="50"
                  value={eraserSize}
                  onChange={handleEraserSizeChange}
                />
              </div>
            )}
          </div>
          <button
            onClick={handleRedTextClick}
            className={`toolbar-button ${isRedTextActive ? "active" : ""}`}
            disabled={isButtonDisabled("redText")}
          >
            <img
              src="/toolbaricons/inuse/eraser.svg"
              alt="지우개"
              className="toolbar-icon"
            />
            <span className="toolbar-text">
              {isRedTextActive ? "Back" : "Red Text"}
            </span>
          </button>
        </div>
      </div>
      {moveableTargets.map((target) => (
        <Moveable
          key={target}
          target={`#${target}`}
          container={null}
          draggable={true}
          resizable={true}
          rotatable={true}
          onDragStart={() => setSelectedTarget(target)}
          onResizeStart={() => setSelectedTarget(target)}
          onRotateStart={() => setSelectedTarget(target)}
          onDrag={(e) => {
            e.target.style.transform = e.transform;
          }}
          onDragEnd={(e) => {
            e.target.style.transform = e.transform;
          }}
          onResize={(e) => {
            e.target.style.width = `${e.width}px`;
            e.target.style.height = `${e.height}px`;
            e.target.style.transform = e.drag.transform;
          }}
          onRotate={(e) => {
            e.target.style.transform = e.drag.transform;
          }}
          onRotateEnd={(e) => {
            e.target.style.transform = e.transform;
          }}
          {...(selectedTarget === target
            ? {}
            : { draggable: false, resizable: false, rotatable: false })}
        />
      ))}
      <style>
        {`
          .moveable-control.moveable-origin {
            display: none !important;
          }
          .review-mode-options button {
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .review-mode-options button img {
            width: 24px;
            height: 24px;
          }
          .review-mode-options button span {
            font-size: 14px;
          }
          .review-mode-button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
          }
          .review-mode-button img {
            width: 24px;
            height: 24px;
          }
          .review-mode-button span {
            font-size: 14px;
          }
          .font-selector {
            position: relative;
            z-index: 1000;
          }

          .font-selector > button {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 5px 10px;
            background-color: #f0f0f0;
            border: 1px solid #ccc;
            border-radius: 4px;
            cursor: pointer;
          }

          .font-dropdown {
            width: 200px;
            max-height: 300px;
            overflow-y: auto;
            background-color: #1a1a1a;
            border: 1px solid #333;
            border-radius: 4px;
            box-shadow: 0 2px 5px rgba(0,0,0,0.3);
            z-index: 9999;
          }

          .font-dropdown button {
            display: block;
            width: 100%;
            padding: 8px 12px;
            text-align: left;
            border: none;
            background: none;
            color: #ffffff;
            cursor: pointer;
          }

          .font-dropdown button:hover {
            background-color: #333333;
          }

      
        
          .drawing-mode {
            cursor: url("../assets/icons/cursor-pen.svg") 1 1, auto !important;
          }
          .pen-options {
            display: flex;
            align-items: center;
            
            border-radius: 5px;
            padding: 5px;
            margin-left: 10px;
          }
          .pen-size-control {
            display: flex;
            align-items: center;
            margin-right: 10px;
          }
          .pen-size-control input[type="range"] {
            width: 100px;
            margin-right: 10px;
          }
          .pen-size-preview {
            
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .color-options {
            display: flex;
            align-items: center;
          }
       
       
          .main-content {
            position: relative;
            overflow: visible !important;
          }

          .stamp {
            position: absolute;
            z-index: 1000;
            pointer-events: none;
          }

        

          .text-color-options button {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin: 0 5px;
            border: none;
            cursor: pointer;
            transition: transform 0.2s ease;
          }

          .text-color-options button:hover {
            transform: scale(1.1);
          }

        
        
          
          .toolbar-tooltip {
            position: relative;
            display: inline-block;
          }

          .toolbar-tooltip .toolbar-tooltiptext {
            visibility: hidden;
            width: 120px;
            background-color: #555;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px 0;
            position: absolute;
            z-index: 9999;
            bottom: 125%;
            left: 50%;
            margin-left: -60px;
            opacity: 0;
            transition: opacity 0.3s;
            font-size: 12px;
          }

          .toolbar-tooltip:hover .toolbar-tooltiptext {
            visibility: visible;
            opacity: 1;
          }

          @keyframes pulse {
            0% { transform: scale(1); }
            50% { transform: scale(1.05); }
            100% { transform: scale(1); }
          }
        `}
      </style>
      {isStampMode && selectedStampId && (
        <img
          src={`/img/stamp/stamp${selectedStampId}.svg`}
          alt="스탬프 커서"
          style={{
            position: "fixed",
            left: stampCursorPosition.x,
            top: stampCursorPosition.y,
            width: `${100 / stampCursorPosition.scale}px`,
            height: `${100 / stampCursorPosition.scale}px`,
            pointerEvents: "none",
            zIndex: 9999,
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </>
  );
};

export default Toolbar;
