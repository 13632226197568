import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { db, auth } from "../firebase/firebase";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import debounce from "lodash/debounce";
import { onAuthStateChanged } from "firebase/auth";
import EventEmitter from "../utils/eventEmitter";

const CurriculumContext = createContext();

// 유틸리티 함수들
const generateUniqueName = (baseName, existingNames) => {
  let newName = baseName;
  let counter = 1;
  while (existingNames.includes(newName)) {
    newName = `${baseName} ${counter}`;
    counter++;
  }
  return newName;
};

export const CurriculumProvider = ({ children }) => {
  const [curriculum, setCurriculum] = useState({
    sections: [],
    chapters: {},
    units: {},
    subunits: {},
  });
  const [isLoading, setIsLoading] = useState(true);
  const [courseId, setCourseId] = useState(null);
  const courseIdRef = useRef(null);

  // courseId 설정 함수
  const handleSetCourseId = useCallback((id) => {
    setCourseId(id);
    courseIdRef.current = id;
  }, []);

  // 커리큘럼 로드 함수
  const loadCurriculum = useCallback(async () => {
    const user = auth.currentUser;
    const currentCourseId = courseIdRef.current;

    if (user && currentCourseId) {
      try {
        setIsLoading(true);
        const curriculumRef = doc(
          db,
          "users",
          user.uid,
          "courses",
          currentCourseId,
          "curriculum",
          "main"
        );

        const curriculumDoc = await getDoc(curriculumRef);

        if (curriculumDoc.exists()) {
          const data = curriculumDoc.data();
          setCurriculum({
            sections: data.sections || [],
            chapters: data.chapters || {},
            units: data.units || {},
            subunits: data.subunits || {},
          });
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  // URL에서 courseId 추출 및 설정
  useEffect(() => {
    const params = new URLSearchParams(window.location.pathname);
    const currentCourseId =
      params.get("courseId") || window.location.pathname.split("/")[2];

    if (currentCourseId) {
      handleSetCourseId(currentCourseId);
    }
  }, []);

  // Firebase auth 상태 관리
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && courseIdRef.current) {
        loadCurriculum();
      }
    });

    return () => unsubscribe();
  }, [loadCurriculum]);

  // Firebase 업데이트 함수 수정
  const [error, setError] = useState(null);

  const updateFirebase = useCallback(async (newCurriculum) => {
    try {
      if (!auth.currentUser) throw new Error("로그인이 필요합니다");
      if (!courseIdRef.current) throw new Error("courseId가 없습니다");

      const user = auth.currentUser;
      const currentCourseId = courseIdRef.current;

      if (user && currentCourseId) {
        try {
          const curriculumRef = doc(
            db,
            "users",
            user.uid,
            "courses",
            currentCourseId,
            "curriculum",
            "main"
          );

          await setDoc(curriculumRef, {
            ...newCurriculum,
            lastUpdated: serverTimestamp(),
          });
        } catch (error) {}
      } else {
      }
    } catch (error) {
      setError(error.message);
      throw error;
    }
  }, []);

  const debouncedUpdateFirebase = useCallback(
    debounce((newCurriculum) => {
      updateFirebase(newCurriculum);
    }, 1000),
    []
  );

  // 컴포넌트 언마운트 시 cleanup
  useEffect(() => {
    return () => {
      debouncedUpdateFirebase.cancel();
    };
  }, [debouncedUpdateFirebase]);

  // 섹션 관련 함수들
  const addSection = useCallback(() => {
    setCurriculum((prev) => {
      const newCurriculum = { ...prev };
      const newSectionIndex = newCurriculum.sections.length;
      const newSectionName = generateUniqueName(
        "New Section",
        newCurriculum.sections
      );

      newCurriculum.sections.push(newSectionName);
      newCurriculum.chapters[newSectionIndex] = ["New Chapter"];

      const unitId = uuidv4();
      newCurriculum.units[`${newSectionIndex}-0`] = [
        {
          unitId,
          unitName: "New Unit",
          planDate: null,
          lastReviewDate: null,
          reviewCount: 0,
          isCompleted: false,
        },
      ];

      newCurriculum.subunits[unitId] = [];

      // Firebase 업데이트
      debouncedUpdateFirebase(newCurriculum);
      return newCurriculum;
    });
  }, [courseIdRef.current]);

  const deleteSection = useCallback(
    (sectionIndex) => {
      setCurriculum((prev) => {
        const newCurriculum = { ...prev };

        // 1. 삭제할 섹션의 모든 유닛 ID와 서브유닛 ID 수집
        const unitsToDelete = [];
        const subunitIdsToDelete = [];

        prev.chapters[sectionIndex]?.forEach((_, chapterIndex) => {
          const key = `${sectionIndex}-${chapterIndex}`;
          const units = prev.units[key] || [];

          units.forEach((unit) => {
            unitsToDelete.push(unit.unitId);
            const subunits = prev.subunits[unit.unitId] || [];
            subunits.forEach((subunit) => {
              subunitIdsToDelete.push(subunit.id);
            });
          });
        });

        // 2. 서브유닛 삭제 이벤트 발생
        subunitIdsToDelete.forEach((subunitId) => {
          EventEmitter.emit("subunitDeleted", {
            courseId: courseIdRef.current,
            subunitId,
          });
        });

        // 3. 섹션 삭제
        newCurriculum.sections.splice(sectionIndex, 1);

        // 4. 인덱스 재정렬을 위한 새로운 객체들 생성
        const newChapters = {};
        const newUnits = {};

        // 5. 남은 섹션들의 인덱스 재정렬
        newCurriculum.sections.forEach((section, newIndex) => {
          if (newIndex >= sectionIndex) {
            // 삭제된 섹션 이후의 데이터 이동
            if (prev.chapters[newIndex + 1]) {
              newChapters[newIndex] = prev.chapters[newIndex + 1];

              // 해당 섹션의 챕터들에 대한 유닛 데이터 이동
              prev.chapters[newIndex + 1].forEach((_, chapterIndex) => {
                const oldKey = `${newIndex + 1}-${chapterIndex}`;
                const newKey = `${newIndex}-${chapterIndex}`;
                if (prev.units[oldKey]) {
                  newUnits[newKey] = prev.units[oldKey];
                }
              });
            }
          } else {
            // 삭제된 섹션 이전의 데이터는 그대로 유지
            newChapters[newIndex] = prev.chapters[newIndex];
            prev.chapters[newIndex]?.forEach((_, chapterIndex) => {
              const key = `${newIndex}-${chapterIndex}`;
              if (prev.units[key]) {
                newUnits[key] = prev.units[key];
              }
            });
          }
        });

        // 6. 정리된 데이터로 업데이트
        newCurriculum.chapters = newChapters;
        newCurriculum.units = newUnits;

        // 7. 삭제된 유닛의 서브유닛 제거
        unitsToDelete.forEach((unitId) => {
          delete newCurriculum.subunits[unitId];
        });

        debouncedUpdateFirebase(newCurriculum);
        return newCurriculum;
      });
    },
    [courseIdRef.current]
  );

  const handleSectionNameChange = useCallback((sectionIndex, value) => {
    setCurriculum((prev) => {
      // 다른 섹션들의 이름 목록 생성
      const otherSectionNames = prev.sections.filter(
        (_, idx) => idx !== sectionIndex
      );

      // 중복된 이름인 경우 자동으로 고유한 이름 생성
      const uniqueName = generateUniqueName(
        value || "New Section",
        otherSectionNames
      );

      const updatedSections = [...prev.sections];
      updatedSections[sectionIndex] = uniqueName;

      const newCurriculum = { ...prev, sections: updatedSections };
      debouncedUpdateFirebase(newCurriculum);
      return newCurriculum;
    });
  }, []);

  // 챕터 관련 함수들
  const addChapter = useCallback(
    (sectionIndex) => {
      setCurriculum((prev) => {
        const newCurriculum = { ...prev };

        // 1. 챕터 배열이 없으면 초기화
        if (!newCurriculum.chapters[sectionIndex]) {
          newCurriculum.chapters[sectionIndex] = [];
        }

        // 2. 새로운 챕터 이름 생성 (중복 방지)
        const chapterIndex = newCurriculum.chapters[sectionIndex].length;
        const newChapterName = generateUniqueName(
          "New Chapter",
          newCurriculum.chapters[sectionIndex]
        );

        // 3. 챕터 추가
        newCurriculum.chapters[sectionIndex].push(newChapterName);

        // 4. 새 챕터에 대한 기본 유닛 추가
        const unitId = uuidv4();
        const key = `${sectionIndex}-${chapterIndex}`;
        newCurriculum.units[key] = [
          {
            unitId,
            unitName: "New Unit",
            planDate: null,
            lastReviewDate: null,
            reviewCount: 0,
            isCompleted: false,
          },
        ];

        // 5. 유닛에 대한 빈 서브유닛 배열 초기화
        newCurriculum.subunits[unitId] = [];

        // 6. Firebase 업데이트
        debouncedUpdateFirebase(newCurriculum);

        return newCurriculum;
      });
    },
    [courseIdRef.current]
  );

  const handleChapterNameChange = useCallback(
    (sectionIndex, chapterIndex, value) => {
      setCurriculum((prev) => {
        const updatedChapters = { ...prev.chapters };
        if (!updatedChapters[sectionIndex]) {
          updatedChapters[sectionIndex] = [];
        }

        // 같은 섹션 내의 다른 챕터 이름들
        const otherChapterNames = updatedChapters[sectionIndex].filter(
          (_, idx) => idx !== chapterIndex
        );

        // 중복된 이름인 경우 자동으로 고유한 이름 생성
        const uniqueName = generateUniqueName(value || " ", otherChapterNames);

        updatedChapters[sectionIndex][chapterIndex] = uniqueName;
        const newCurriculum = { ...prev, chapters: updatedChapters };

        debouncedUpdateFirebase(newCurriculum);
        return newCurriculum;
      });
    },
    []
  );

  // 유닛 관련 함수들
  const handleUnitChange = useCallback(
    (sectionIndex, chapterIndex, unitIndex, field, value) => {
      const sanitizedValue =
        value instanceof Date
          ? value.toISOString()
          : value === undefined
          ? ""
          : value;

      setCurriculum((prev) => {
        const updatedUnits = { ...prev.units };
        const key = `${sectionIndex}-${chapterIndex}`;
        updatedUnits[key][unitIndex][field] = sanitizedValue;
        const newCurriculum = { ...prev, units: updatedUnits };

        debouncedUpdateFirebase(newCurriculum);

        return newCurriculum;
      });
    },
    [debouncedUpdateFirebase]
  );

  // 리뷰 카운트 변경 함수 추가
  const handleReviewChange = useCallback(
    (sectionIndex, chapterIndex, unitIndex, increment) => {
      if (!courseId) {
        console.warn("courseId가 없습니다");
        return;
      }

      setCurriculum((prev) => {
        const updatedUnits = { ...prev.units };
        const key = `${sectionIndex}-${chapterIndex}`;
        const currentCount = updatedUnits[key][unitIndex].reviewCount;
        const newCount = Math.max(0, currentCount + increment);
        updatedUnits[key][unitIndex].reviewCount = newCount;

        const newCurriculum = { ...prev, units: updatedUnits };

        // Firebase 업데이트
        debouncedUpdateFirebase(newCurriculum);

        return newCurriculum;
      });
    },
    [courseId]
  );

  // useEffect로 courseId 변경 감지
  useEffect(() => {}, [courseId]);

  const addUnit = useCallback((sectionIndex, chapterIndex) => {
    setCurriculum((prev) => {
      const updatedUnits = { ...prev.units };
      const existingUnitNames = Object.values(updatedUnits)
        .flat()
        .map((unit) => unit.unitName);
      const newUnitName = generateUniqueName("New Unit", existingUnitNames);
      const newUnitId = uuidv4();

      const key = `${sectionIndex}-${chapterIndex}`;
      if (!updatedUnits[key]) {
        updatedUnits[key] = [];
      }

      const newUnit = {
        unitId: newUnitId,
        unitName: newUnitName,
        planDate: null,
        lastReviewDate: null,
        reviewCount: 0,
        isCompleted: false,
      };

      updatedUnits[key] = [...updatedUnits[key], newUnit];

      const newCurriculum = {
        ...prev,
        units: updatedUnits,
        subunits: {
          ...prev.subunits,
          [newUnitId]: [],
        },
      };

      // Firebase 업데이트
      debouncedUpdateFirebase(newCurriculum);

      return newCurriculum;
    });
  }, []);

  const deleteUnit = useCallback(
    (sectionIndex, chapterIndex, unitIndex) => {
      setCurriculum((prev) => {
        const newCurriculum = { ...prev };
        const key = `${sectionIndex}-${chapterIndex}`;
        const unitToDelete = prev.units[key][unitIndex];

        // 1. 해당 유닛의 서브유닛 ID 수집
        const subunitIdsToDelete = (
          prev.subunits[unitToDelete.unitId] || []
        ).map((subunit) => subunit.id);

        // 2. 서브유닛 삭제 이벤트 발생
        subunitIdsToDelete.forEach((subunitId) => {
          EventEmitter.emit("subunitDeleted", {
            courseId: courseIdRef.current,
            subunitId,
          });
        });

        // 3. 유닛과 관련 서브유닛 삭제
        newCurriculum.units[key].splice(unitIndex, 1);
        delete newCurriculum.subunits[unitToDelete.unitId];

        debouncedUpdateFirebase(newCurriculum);
        return newCurriculum;
      });
    },
    [courseIdRef.current]
  );

  // 순서 변경을 위한 새로운 함수 추가
  const handleReorderCurriculum = useCallback(
    (newCurriculum) => {
      // 깊은 복사로 시작
      const reorderedCurriculum = JSON.parse(JSON.stringify(newCurriculum));

      // 1. 챕터 데이터 재구성
      const oldChapters = { ...reorderedCurriculum.chapters };
      const newChapters = {};

      // 2. 유닛 데이터 재구성
      const oldUnits = { ...reorderedCurriculum.units };
      const newUnits = {};

      // 3. 섹션 순서에 따라 데이터 재매핑
      reorderedCurriculum.sections.forEach((section, newIndex) => {
        // 이전 인덱스 찾기 (원본 curriculum에서의 위치)
        const oldIndex = curriculum.sections.indexOf(section);

        // 챕터 이동
        if (oldChapters[oldIndex]) {
          newChapters[newIndex] = oldChapters[oldIndex];
        }

        // 유닛 이동
        Object.keys(oldUnits).forEach((key) => {
          const [sectionIdx, chapterIdx] = key.split("-");
          if (parseInt(sectionIdx) === oldIndex) {
            newUnits[`${newIndex}-${chapterIdx}`] = oldUnits[key];
          }
        });
      });

      // 4. 새로운 커리큘럼 구성
      const finalCurriculum = {
        ...reorderedCurriculum,
        chapters: newChapters,
        units: newUnits,
      };

      // 5. Firebase 업데이트 및 상태 변경
      debouncedUpdateFirebase(finalCurriculum);
      return finalCurriculum;
    },
    [curriculum]
  );

  // 토글 상태 업데이트 함수 추가
  const updateToggleState = useCallback(async (showSections, showChapters) => {
    setCurriculum((prev) => {
      const newCurriculum = {
        ...prev,
        toggleState: {
          showSections,
          showChapters,
        },
      };

      debouncedUpdateFirebase(newCurriculum);
      return newCurriculum;
    });
  }, []);
  // Keep existing addSubunit (for initial creation)
  const addSubunit = useCallback((unitId) => {
    return new Promise((resolve) => {
      setCurriculum((prev) => {
        if (!prev.subunits[unitId] || prev.subunits[unitId].length === 0) {
          const newSubunit = {
            id: uuidv4(),
            name: "New Subunit",
          };

          const newCurriculum = {
            ...prev,
            subunits: {
              ...prev.subunits,
              [unitId]: [newSubunit],
            },
          };

          debouncedUpdateFirebase(newCurriculum);
          resolve({ newSubunit });
          return newCurriculum;
        }
        resolve(null);
        return prev;
      });
    });
  }, []);

  // Add new function (for manual addition)
  const addNewSubunit = useCallback((unitId) => {
    setCurriculum((prev) => {
      const existingSubunits = prev.subunits[unitId] || [];
      const newSubunit = {
        id: uuidv4(),
        name: "New Subunit",
      };

      // Emit new subunit creation event
      EventEmitter.emit("subunitCreated", {
        courseId: courseIdRef.current,
        subunitId: newSubunit.id,
      });

      const newCurriculum = {
        ...prev,
        subunits: {
          ...prev.subunits,
          [unitId]: [...existingSubunits, newSubunit],
        },
      };

      debouncedUpdateFirebase(newCurriculum);
      return newCurriculum;
    });
  }, []);

  const deleteSubunit = useCallback(
    async (unitId, subunitId) => {
      EventEmitter.emit("subunitDeleted", {
        courseId: courseIdRef.current,
        subunitId,
      });

      setCurriculum((prev) => {
        const updatedSubunits = { ...prev.subunits };
        if (updatedSubunits[unitId]) {
          updatedSubunits[unitId] = updatedSubunits[unitId].filter(
            (subunit) => subunit.id !== subunitId
          );
        }

        const newCurriculum = {
          ...prev,
          subunits: updatedSubunits,
        };

        debouncedUpdateFirebase(newCurriculum);
        return newCurriculum;
      });
    },
    [courseIdRef.current]
  );

  const reorderSubunits = useCallback(
    (sourceUnitId, destUnitId, sourceIndex, destIndex) => {
      setCurriculum((prev) => {
        const updatedSubunits = { ...prev.subunits };

        // 같은 유닛 내에서의 이동
        if (sourceUnitId === destUnitId) {
          const subunits = [...(updatedSubunits[sourceUnitId] || [])];
          const [removed] = subunits.splice(sourceIndex, 1);
          subunits.splice(destIndex, 0, removed);
          updatedSubunits[sourceUnitId] = subunits;
        } else {
          // 다른 유닛으로의 이동
          const sourceSubunits = [...(updatedSubunits[sourceUnitId] || [])];
          const destSubunits = [...(updatedSubunits[destUnitId] || [])];
          const [removed] = sourceSubunits.splice(sourceIndex, 1);
          destSubunits.splice(destIndex, 0, removed);
          updatedSubunits[sourceUnitId] = sourceSubunits;
          updatedSubunits[destUnitId] = destSubunits;
        }

        const newCurriculum = {
          ...prev,
          subunits: updatedSubunits,
        };

        debouncedUpdateFirebase(newCurriculum);
        return newCurriculum;
      });
    },
    []
  );

  const handleSubunitNameChange = useCallback((unitId, subunitId, newName) => {
    setCurriculum((prev) => {
      const updatedSubunits = { ...prev.subunits };
      if (updatedSubunits[unitId]) {
        updatedSubunits[unitId] = updatedSubunits[unitId].map((subunit) =>
          subunit.id === subunitId ? { ...subunit, name: newName } : subunit
        );
      }

      const newCurriculum = {
        ...prev,
        subunits: updatedSubunits,
      };

      debouncedUpdateFirebase(newCurriculum);
      return newCurriculum;
    });
  }, []);

  // moveUnitToAnotherChapter 함수 추가
  const moveUnitToAnotherChapter = (
    prevCurriculum,
    sectionIndex,
    chapterIndex,
    unitIndex,
    direction
  ) => {
    const updatedUnits = { ...prevCurriculum.units };
    const currentKey = `${sectionIndex}-${chapterIndex}`;
    const unit = updatedUnits[currentKey][unitIndex];

    updatedUnits[currentKey].splice(unitIndex, 1);

    let newChapterIndex =
      direction === "up" ? chapterIndex - 1 : chapterIndex + 1;
    let newSectionIndex = sectionIndex;

    if (newChapterIndex < 0) {
      newSectionIndex--;
      if (newSectionIndex >= 0) {
        newChapterIndex = prevCurriculum.chapters[newSectionIndex].length - 1;
      }
    } else if (
      newChapterIndex >= prevCurriculum.chapters[sectionIndex].length
    ) {
      newSectionIndex++;
      if (newSectionIndex < prevCurriculum.sections.length) {
        newChapterIndex = 0;
      }
    }

    if (
      newSectionIndex >= 0 &&
      newSectionIndex < prevCurriculum.sections.length
    ) {
      const newKey = `${newSectionIndex}-${newChapterIndex}`;
      if (!updatedUnits[newKey]) {
        updatedUnits[newKey] = [];
      }

      const insertIndex = direction === "up" ? updatedUnits[newKey].length : 0;
      updatedUnits[newKey].splice(insertIndex, 0, unit);

      return { ...prevCurriculum, units: updatedUnits };
    }

    return prevCurriculum;
  };

  // handleMoveUnit 함수 수정
  const handleMoveUnit = useCallback(
    (sectionIndex, chapterIndex, unitIndex, direction) => {
      setCurriculum((prev) => {
        const updatedUnits = { ...prev.units };
        const currentKey = `${sectionIndex}-${chapterIndex}`;
        const units = [...updatedUnits[currentKey]];

        if (direction === "up" || direction === "down") {
          const newIndex = direction === "up" ? unitIndex - 1 : unitIndex + 1;

          if (newIndex >= 0 && newIndex < units.length) {
            [units[unitIndex], units[newIndex]] = [
              units[newIndex],
              units[unitIndex],
            ];
            updatedUnits[currentKey] = units;

            const newCurriculum = { ...prev, units: updatedUnits };
            debouncedUpdateFirebase(newCurriculum);
            return newCurriculum;
          } else {
            const newCurriculum = moveUnitToAnotherChapter(
              prev,
              sectionIndex,
              chapterIndex,
              unitIndex,
              direction
            );
            debouncedUpdateFirebase(newCurriculum);
            return newCurriculum;
          }
        }
        return prev;
      });
    },
    [debouncedUpdateFirebase]
  );

  // 챕터 삭제 함수 추가
  const deleteChapter = useCallback(
    (sectionIndex, chapterIndex) => {
      setCurriculum((prev) => {
        const newCurriculum = { ...prev };
        const key = `${sectionIndex}-${chapterIndex}`;

        // 1. 해당 챕터의 모든 유닛과 서브유닛 ID 수집
        const unitsToDelete = prev.units[key] || [];
        const subunitIdsToDelete = [];

        unitsToDelete.forEach((unit) => {
          const subunits = prev.subunits[unit.unitId] || [];
          subunits.forEach((subunit) => {
            subunitIdsToDelete.push(subunit.id);
          });
        });

        // 2. 서브유닛 삭제 이벤트 발생
        subunitIdsToDelete.forEach((subunitId) => {
          EventEmitter.emit("subunitDeleted", {
            courseId: courseIdRef.current,
            subunitId,
          });
        });

        // 3. 챕터와 관련 데이터 삭제
        newCurriculum.chapters[sectionIndex].splice(chapterIndex, 1);
        delete newCurriculum.units[key];
        unitsToDelete.forEach((unit) => {
          delete newCurriculum.subunits[unit.unitId];
        });

        debouncedUpdateFirebase(newCurriculum);
        return newCurriculum;
      });
    },
    [courseIdRef.current]
  );

  // 서브유닛 ID로 이름을 찾는 함수 추가
  const getSubunitNameById = useCallback(
    (subunitId) => {
      for (const unitId in curriculum.subunits) {
        const subunit = curriculum.subunits[unitId].find(
          (sub) => sub.id === subunitId
        );
        if (subunit) {
          return subunit.name;
        }
      }
      return "서브유닛 이름";
    },
    [curriculum.subunits]
  );

  const handleUnitCompletion = useCallback(
    (sectionIndex, chapterIndex, unitIndex, isCompleted) => {
      setCurriculum((prev) => {
        const updatedUnits = { ...prev.units };
        const key = `${sectionIndex}-${chapterIndex}`;
        updatedUnits[key][unitIndex].isCompleted = isCompleted;

        const newCurriculum = { ...prev, units: updatedUnits };

        // Firebase 업데이트
        const user = auth.currentUser;
        if (user) {
          // 진도율 계산
          const allUnits = Object.values(updatedUnits).flat();
          const completedUnits = allUnits.filter(
            (unit) => unit.isCompleted
          ).length;
          const progress =
            allUnits.length > 0
              ? Math.round((completedUnits / allUnits.length) * 100)
              : 0;

          // 코스 진도율 업데이트
          const courseRef = doc(
            db,
            "users",
            user.uid,
            "courses",
            courseIdRef.current
          );
          setDoc(courseRef, { progress }, { merge: true });
        }

        debouncedUpdateFirebase(newCurriculum);
        return newCurriculum;
      });
    },
    [debouncedUpdateFirebase]
  );

  // 모든 상태 변경 함수들을 value에 포함
  const value = useMemo(
    () => ({
      curriculum,
      setCurriculum,
      courseId,
      setCourseId: handleSetCourseId,
      isLoading,
      setIsLoading,
      addSection,
      deleteSection,
      handleSectionNameChange,
      addChapter,
      handleChapterNameChange,
      handleUnitChange,
      handleReviewChange,
      addUnit,
      deleteUnit,
      reorderCurriculumIndexes,
      debouncedUpdateFirebase,
      handleReorderCurriculum,
      updateToggleState,
      addSubunit,
      addNewSubunit,
      deleteSubunit,
      reorderSubunits,
      handleSubunitNameChange,
      handleMoveUnit,
      loadCurriculum,
      deleteChapter,
      getSubunitNameById,
      handleUnitCompletion,
    }),
    [
      curriculum,
      courseId,
      isLoading,
      updateToggleState,
      addSubunit,
      addNewSubunit,
      handleSubunitNameChange,
      loadCurriculum,
      deleteChapter,
      getSubunitNameById,
      handleUnitCompletion,
    ]
  );

  return (
    <CurriculumContext.Provider value={value}>
      {children}
    </CurriculumContext.Provider>
  );
};

export const useCurriculum = () => {
  const context = useContext(CurriculumContext);
  if (!context) {
    throw new Error("useCurriculum must be used within a CurriculumProvider");
  }
  return context;
};

// 인덱스 재정렬 헬퍼 함수
const reorderCurriculumIndexes = (curriculum) => {
  const newChapters = {};
  const newUnits = {};

  curriculum.sections.forEach((section, newSectionIndex) => {
    if (curriculum.chapters[newSectionIndex]) {
      newChapters[newSectionIndex] = curriculum.chapters[newSectionIndex];

      curriculum.chapters[newSectionIndex].forEach((_, chapterIndex) => {
        const oldKey = `${newSectionIndex}-${chapterIndex}`;
        const newKey = `${newSectionIndex}-${chapterIndex}`;
        if (curriculum.units[oldKey]) {
          newUnits[newKey] = curriculum.units[oldKey];
        }
      });
    }
  });

  return {
    ...curriculum,
    chapters: newChapters,
    units: newUnits,
  };
};
