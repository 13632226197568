import React, { createContext, useState, useCallback } from "react";

export const ToolbarContext = createContext();

export const ToolbarProvider = ({ children }) => {
  const [selectedText, setSelectedText] = useState(null);

  const applyStyle = useCallback(
    (style, value) => {
      if (!selectedText) {
        return;
      }

      const { range } = selectedText;

      const applyStyleToNode = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const span = document.createElement("span");
          span.textContent = node.textContent;
          switch (style) {
            case "bold":
              span.style.fontWeight = "bold";
              break;
            case "italic":
              span.style.fontStyle = "italic";
              break;
            case "underline":
              span.style.textDecoration = "underline";
              break;
            case "highlight":
              span.style.backgroundColor = value;
              break;
            case "color":
              span.style.color = value;
              break;
            default:
              return node;
          }
          return span;
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          const clone = node.cloneNode(false);
          for (let child of node.childNodes) {
            clone.appendChild(applyStyleToNode(child));
          }
          return clone;
        }
        return node;
      };

      const fragment = range.extractContents();
      const newFragment = document.createDocumentFragment();

      for (let child of fragment.childNodes) {
        newFragment.appendChild(applyStyleToNode(child));
      }

      range.insertNode(newFragment);

      // 선택 해제
      window.getSelection().removeAllRanges();
      setSelectedText(null);
    },
    [selectedText]
  );

  return (
    <ToolbarContext.Provider
      value={{
        selectedText,
        setSelectedText,
        applyStyle,
      }}
    >
      {children}
    </ToolbarContext.Provider>
  );
};
