import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import { db, auth } from "../firebase/firebase";
import {
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import EventEmitter from "../utils/eventEmitter";

const ImageContext = createContext();

export const ImageProvider = ({ children }) => {
  const [images, setImages] = useState([]);
  const [currentSubunitId, setCurrentSubunitId] = useState(null);
  const [currentCourseId, setCurrentCourseId] = useState(null);

  // ID 설정 함수 추가
  const setCurrentIds = (courseId, subunitId) => {
    setCurrentCourseId(courseId);
    setCurrentSubunitId(subunitId);
  };

  // 이미지 데이터 저장 함수 수정
  const saveImageData = async (courseId, subunitId, imageData) => {
    try {
      if (!auth.currentUser) throw new Error("로그인이 필요합니다");

      const imageRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "courses",
        courseId,
        "curriculum",
        "images",
        "subunits",
        subunitId
      );

      const imageDoc = await getDoc(imageRef);
      const existingImages = imageDoc.exists()
        ? imageDoc.data().images || []
        : [];

      const updatedImages = [...existingImages, imageData];

      await setDoc(imageRef, {
        images: updatedImages,
        lastModified: serverTimestamp(),
      });

      // 전역 상태 업데이트
      setImages(updatedImages);

      // EventEmitter를 통해 이미지 업데이트 알림
      EventEmitter.emit("imagesUpdated", {
        courseId,
        subunitId,
        images: updatedImages,
      });

      return true;
    } catch (error) {
      throw error;
    }
  };

  // 이미지 로드 함수 수정
  const loadImageData = useMemo(
    () => async (courseId, subunitId) => {
      try {
        if (!auth.currentUser) {
          throw new Error("로그인이 필요합니다");
        }

        const imageRef = doc(
          db,
          "users",
          auth.currentUser.uid,
          "courses",
          courseId,
          "curriculum",
          "images",
          "subunits",
          subunitId
        );

        const imageDoc = await getDoc(imageRef);

        if (imageDoc.exists()) {
          const imageData = imageDoc.data().images || [];
          return imageData;
        }

        return [];
      } catch (error) {
        throw error;
      }
    },
    []
  ); // 빈 의존성 배열

  // 이미지 삭제 함수 수정
  const deleteImageData = async (courseId, subunitId, imageId) => {
    try {
      if (!auth.currentUser) throw new Error("로그인이 필요합니다");

      const imageRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "courses",
        courseId,
        "curriculum",
        "images",
        "subunits",
        subunitId
      );
      const imageDoc = await getDoc(imageRef);
      if (!imageDoc.exists()) {
        return false;
      }

      const existingData = imageDoc.data();
      const updatedImages = existingData.images.filter(
        (img) => img.id !== imageId
      );

      await setDoc(imageRef, {
        images: updatedImages,
        lastModified: serverTimestamp(),
      });

      return true;
    } catch (error) {
      throw error;
    }
  };

  // 이미지 업데이트 함수 추가
  const updateImageData = async (courseId, subunitId, imageId, updateData) => {
    try {
      if (!auth.currentUser) throw new Error("로그인이 필요합니다");

      const imageRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "courses",
        courseId,
        "curriculum",
        "images",
        "subunits",
        subunitId
      );

      // 현재 저장된 이미지 데이터 가져오기
      const imageDoc = await getDoc(imageRef);
      if (!imageDoc.exists()) {
        throw new Error("이미지 데이터가 존재하지 않습니다");
      }

      const existingData = imageDoc.data();
      const updatedImages = existingData.images.map((img) =>
        img.id === imageId
          ? {
              ...img,
              ...updateData,
              lastModified: Date.now(),
            }
          : img
      );

      // Firebase에 업데이트된 데이터 저장
      await setDoc(imageRef, {
        images: updatedImages,
        lastModified: serverTimestamp(),
      });

      // 로컬 상태 업데이트
      setImages(updatedImages);

      return true;
    } catch (error) {
      console.error("이미지 업데이트 실패:", error);
      throw error;
    }
  };

  // EventEmitter 구독 수정
  useEffect(() => {
    const handleSubunitDeleted = async ({ courseId, subunitId }) => {
      if (!auth.currentUser) return;

      const imageRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "courses",
        courseId,
        "curriculum",
        "images",
        "subunits",
        subunitId
      );

      try {
        await deleteDoc(imageRef);
        if (currentSubunitId === subunitId) {
          setImages([]);
        }
      } catch (error) {
        console.error("이미지 삭제 실패:", error);
      }
    };

    const events = [
      "subunitDeleted",
      "unitDeleted",
      "chapterDeleted",
      "sectionDeleted",
    ];

    events.forEach((event) => {
      EventEmitter.on(event, handleSubunitDeleted);
    });

    return () => {
      events.forEach((event) => {
        EventEmitter.listeners[event] =
          EventEmitter.listeners[event]?.filter(
            (listener) => listener !== handleSubunitDeleted
          ) || [];
      });
    };
  }, [currentSubunitId]);

  const value = {
    images,
    setImages,
    saveImageData,
    loadImageData,
    deleteImageData,
    updateImageData, // 새로 추가된 함수 export
    setCurrentIds, // 새로 추가된 함수 export
    currentSubunitId,
    currentCourseId,
  };

  return (
    <ImageContext.Provider value={value}>{children}</ImageContext.Provider>
  );
};

export const useImage = () => {
  const context = useContext(ImageContext);
  if (!context) {
    throw new Error("useImage must be used within an ImageProvider");
  }
  return context;
};
