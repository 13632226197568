import React from "react";
import { Link } from "react-router-dom";
import "./Planner.css";

const Planner = () => {
  return (
    <div className="planner-page">
      <div className="header">
        <div className="nav-tabs">
          <Link to="/" className="tab">
            Main
          </Link>
          <Link to="/note-taking" className="tab">
            Note Taking
          </Link>
          <Link to="/review-mode" className="tab">
            Review Mode
          </Link>
          <Link to="/planner" className="tab active">
            Planner
          </Link>
        </div>
      </div>

      <h1>Smart Planning System</h1>

      <div className="feature-container">
        <div className="feature-item">
          <img
            className="feature-image"
            src={process.env.PUBLIC_URL + "/landing/monthly1.png"}
            alt="Monthly Overview"
          />
          <div className="feature-description">
            <h2>Monthly Overview</h2>
            <p>
              Record and manage your monthly study tasks and special events.
              Keep track of your learning activities, important deadlines, and
              upcoming events with our organized monthly planning system.
            </p>
          </div>
        </div>

        <div className="feature-item">
          <img
            className="feature-image"
            src={process.env.PUBLIC_URL + "/landing/weekly.png"}
            alt="Weekly Schedule"
          />
          <div className="feature-description">
            <h2>Weekly Schedule</h2>
            <p>
              Break down your monthly goals into actionable weekly tasks. Our
              weekly planner helps you maintain a balanced study routine while
              ensuring consistent progress towards your learning objectives.
            </p>
          </div>
        </div>

        <div className="feature-item">
          <video
            className="feature-image"
            src={process.env.PUBLIC_URL + "/video/time_tracking.mp4"}
            autoPlay
            loop
            muted
            playsInline
          />
          <div className="feature-description">
            <h2>Time Tracking Analytics</h2>
            <p>
              Monitor your study habits with detailed time tracking features.
              Analyze your most productive hours, track time spent on different
              subjects, and optimize your study schedule based on real data.
            </p>
          </div>
        </div>

        <div className="feature-item">
          <img
            className="feature-image"
            src={process.env.PUBLIC_URL + "/landing/daily.png"}
            alt="Daily Task Management"
          />
          <div className="feature-description">
            <h2>Daily Task Management</h2>
            <p>
              Stay focused on your daily goals with our detailed task management
              system. Prioritize your study sessions, set reminders, and track
              your daily achievements to maintain steady progress.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Planner;
