import React, { useState, useEffect, useRef } from "react";
import "../css/ToDoList.css";
import { useTasks } from "../contexts/TaskContext";
import { db } from "../firebase/firebase"; // Firebase 설정 파일 경로
import { doc, setDoc, getDoc } from "firebase/firestore";
import { auth } from "../firebase/firebase"; // Firebase 인증 모듈
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { onAuthStateChanged } from "firebase/auth";

const ToDoList = ({ isSidebar }) => {
  const { taskLists, setTaskLists } = useTasks();
  const [newListName, setNewListName] = useState("");
  const [newTask, setNewTask] = useState("");
  const [activeList, setActiveList] = useState(null);
  const [editingListIndex, setEditingListIndex] = useState(null);
  const [editingTaskIndices, setEditingTaskIndices] = useState({});
  const [activeMenu, setActiveMenu] = useState(null);
  const menuRef = useRef(null);

  const todoDocId = "myTodoList"; // 문서 ID를 지정

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const fetchTaskLists = async () => {
          const docRef = doc(db, `users/${user.uid}/todolist/${todoDocId}`);
          try {
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
              const data = docSnap.data();
              setTaskLists(data.taskLists);
            } else {
              // 빈 배열로 시작
              setTaskLists([]);
              await setDoc(docRef, { taskLists: [] });
            }
          } catch (error) {
            console.error("데이터 로딩 에러:", error);
          }
        };

        fetchTaskLists();
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const saveTaskLists = async () => {
      const user = auth.currentUser;
      if (!user) {
        return;
      }

      const docRef = doc(db, `users/${user.uid}/todolist/${todoDocId}`);
      try {
        await setDoc(docRef, { taskLists });
      } catch (error) {
        console.error("저장 실패:", error);
      }
    };

    if (taskLists.length > 0) {
      saveTaskLists();
    }
  }, [taskLists]);

  const addTaskList = () => {
    if (newListName.trim()) {
      const newList = { name: newListName, tasks: [] };
      setTaskLists([...taskLists, newList]);
      setNewListName("");
      setActiveList(taskLists.length);
    }
  };

  const addTask = (listIndex) => {
    if (newTask.trim()) {
      const updatedLists = [...taskLists];
      updatedLists[listIndex].tasks.push({ text: newTask, completed: false });
      setTaskLists(updatedLists);
      setNewTask("");
    }
  };

  const toggleTask = (listIndex, taskIndex) => {
    const updatedLists = [...taskLists];
    updatedLists[listIndex].tasks[taskIndex].completed =
      !updatedLists[listIndex].tasks[taskIndex].completed;
    setTaskLists(updatedLists);
  };

  const toggleList = (index) => {
    setActiveList(activeList === index ? null : index);
  };

  const handleKeyPress = (event, listIndex) => {
    if (event.key === "Enter") {
      addTask(listIndex);
    }
  };

  const editListName = (listIndex, newName) => {
    const updatedLists = [...taskLists];
    updatedLists[listIndex].name = newName;
    setTaskLists(updatedLists);
  };

  const editTask = (listIndex, taskIndex, newText) => {
    const updatedLists = [...taskLists];
    updatedLists[listIndex].tasks[taskIndex].text = newText;
    setTaskLists(updatedLists);
  };

  const deleteList = (listIndex) => {
    const updatedLists = taskLists.filter((_, index) => index !== listIndex);
    setTaskLists(updatedLists);
  };

  const deleteTask = (listIndex, taskIndex) => {
    const updatedLists = [...taskLists];
    updatedLists[listIndex].tasks = updatedLists[listIndex].tasks.filter(
      (_, index) => index !== taskIndex
    );
    setTaskLists(updatedLists);
  };

  const toggleMenu = (index, event) => {
    event.stopPropagation();
    setActiveMenu(activeMenu === index ? null : index);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const duplicateList = (listIndex) => {
    const listToDuplicate = taskLists[listIndex];
    const newList = {
      ...listToDuplicate,
      name: `${listToDuplicate.name} (복사)`,
    };
    setTaskLists([...taskLists, newList]);
  };

  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    listIndex: null,
    taskIndex: null,
  });

  const handleContextMenu = (e, listIndex, taskIndex) => {
    e.preventDefault();
    setContextMenu({
      visible: true,
      x: e.clientX,
      y: e.clientY,
      listIndex,
      taskIndex,
    });
  };

  const handleDeleteTask = () => {
    if (contextMenu.listIndex !== null && contextMenu.taskIndex !== null) {
      const updatedLists = [...taskLists];
      updatedLists[contextMenu.listIndex].tasks.splice(
        contextMenu.taskIndex,
        1
      );
      setTaskLists(updatedLists);
    }
    setContextMenu({
      visible: false,
      x: 0,
      y: 0,
      listIndex: null,
      taskIndex: null,
    });
  };

  useEffect(() => {
    const handleClick = () =>
      setContextMenu({
        visible: false,
        x: 0,
        y: 0,
        listIndex: null,
        taskIndex: null,
      });
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, []);

  const deleteCheckedTasks = (listIndex) => {
    const updatedLists = [...taskLists];
    updatedLists[listIndex].tasks = updatedLists[listIndex].tasks.filter(
      (task) => !task.completed
    );
    setTaskLists(updatedLists);
  };

  // 리스트 추가를 위한 키 이벤트 핸들러 추가
  const handleListKeyPress = (event) => {
    if (event.key === "Enter") {
      addTaskList();
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(taskLists);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setTaskLists(items); // Firebase는 자동으로 업데이트됨 (기존 useEffect에 의해)
  };

  return (
    <div className={`todo-container ${isSidebar ? "sidebar-mode" : ""}`}>
      <h1 className="todo-header">To do list</h1>

      <div className={`list-form ${isSidebar ? "sidebar-form" : ""}`}>
        <input
          type="text"
          value={newListName}
          onChange={(e) => setNewListName(e.target.value)}
          onKeyPress={handleListKeyPress}
          placeholder="New list name"
          className={isSidebar ? "sidebar-input" : ""}
        />
        <button
          onClick={addTaskList}
          className={isSidebar ? "sidebar-button" : ""}
          title="Add List"
        >
          <img src="/toolbaricons/inuse/add.svg" alt="Add" />
          {!isSidebar && "Add List"}
        </button>
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="task-lists">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {taskLists.map((list, listIndex) => (
                <Draggable
                  key={`list-${listIndex}`}
                  draggableId={`list-${listIndex}`}
                  index={listIndex}
                >
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <div className="task-list">
                        <div
                          className="task-list-header"
                          {...provided.dragHandleProps}
                        >
                          {editingListIndex === listIndex ? (
                            <input
                              type="text"
                              value={list.name}
                              onChange={(e) => {
                                const updatedLists = [...taskLists];
                                updatedLists[listIndex].name = e.target.value;
                                setTaskLists(updatedLists);
                              }}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  setEditingListIndex(null);
                                }
                              }}
                              onBlur={() => setEditingListIndex(null)}
                              autoFocus
                            />
                          ) : (
                            <h2
                              onDoubleClick={() =>
                                setEditingListIndex(listIndex)
                              }
                            >
                              {list.name}
                            </h2>
                          )}
                          <div className="header-buttons">
                            <span onClick={() => toggleList(listIndex)}>
                              {activeList === listIndex ? "▼" : "▶"}
                            </span>
                            <img
                              src="/toolbaricons/inuse/ellipsis-vertical.svg"
                              alt="메뉴"
                              className="todo-menu-icon"
                              onClick={(e) => toggleMenu(listIndex, e)}
                            />
                            {activeMenu === listIndex && (
                              <div className="list-menu" ref={menuRef}>
                                <button
                                  onClick={() => deleteList(listIndex)}
                                  className="todo-list-menu-button"
                                >
                                  <img
                                    src="/toolbaricons/inuse/menu.svg"
                                    alt="Delete List"
                                  />
                                  Delete List
                                </button>
                                <button
                                  onClick={() => deleteCheckedTasks(listIndex)}
                                  className="todo-list-menu-button"
                                >
                                  <img
                                    src="/toolbaricons/inuse/checkbox-active.svg"
                                    alt="Delete Checked Tasks"
                                  />
                                  Delete Checked Tasks
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        {activeList === listIndex && (
                          <div className="task-list-content">
                            <ul>
                              {list.tasks.map((task, taskIndex) => (
                                <li
                                  key={taskIndex}
                                  className={task.completed ? "completed" : ""}
                                  onContextMenu={(e) =>
                                    handleContextMenu(e, listIndex, taskIndex)
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    checked={task.completed}
                                    onChange={() =>
                                      toggleTask(listIndex, taskIndex)
                                    }
                                  />
                                  {editingTaskIndices[listIndex] ===
                                  taskIndex ? (
                                    <input
                                      type="text"
                                      value={task.text}
                                      onChange={(e) => {
                                        const updatedLists = [...taskLists];
                                        updatedLists[listIndex].tasks[
                                          taskIndex
                                        ].text = e.target.value;
                                        setTaskLists(updatedLists);
                                      }}
                                      onBlur={() =>
                                        setEditingTaskIndices({
                                          ...editingTaskIndices,
                                          [listIndex]: null,
                                        })
                                      }
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          setEditingTaskIndices({
                                            ...editingTaskIndices,
                                            [listIndex]: null,
                                          });
                                        }
                                      }}
                                      autoFocus
                                    />
                                  ) : (
                                    <span
                                      onDoubleClick={() =>
                                        setEditingTaskIndices({
                                          ...editingTaskIndices,
                                          [listIndex]: taskIndex,
                                        })
                                      }
                                    >
                                      {task.text}
                                    </span>
                                  )}
                                </li>
                              ))}
                            </ul>
                            <div className="task-form">
                              <input
                                type="text"
                                value={newTask}
                                onChange={(e) => setNewTask(e.target.value)}
                                onKeyPress={(e) => handleKeyPress(e, listIndex)}
                                placeholder="New task"
                              />
                              <button onClick={() => addTask(listIndex)}>
                                <img
                                  src="/toolbaricons/inuse/add-square.svg"
                                  alt="추가"
                                />
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {contextMenu.visible && (
        <div
          className="task-context-menu"
          style={{ top: `${contextMenu.y}px`, left: `${contextMenu.x}px` }}
        >
          <button onClick={handleDeleteTask}>Delete</button>
        </div>
      )}
    </div>
  );
};

export default ToDoList;
