// src/pages/CourseDetail.js
import React, { useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import Sidebar2 from "../components/Sidebar2";

const CourseDetail = () => {
  const { courseId } = useParams();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  return (
    <div className="course-detail">
      <div
        className="course-content"
        style={{ display: "flex", flexDirection: "row-reverse" }}
      >
        {isSidebarOpen && <Sidebar2 />}
        <div className="content-area" style={{ flex: 1 }}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default CourseDetail;
