// src/pages/Settings.js
import React, { useState, useEffect } from "react";
import "./Settings.css";
import { useUser } from "../context/UserContext";
import { getAvatarSrc, avatarImages } from "../utils/avatarUtils";
import {
  signOut,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import { auth } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { checkSubscriptionStatus } from "../utils/subscription";
import {
  doc,
  deleteDoc,
  getDocs,
  collection,
  writeBatch,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

const Settings = () => {
  const [activeTab, setActiveTab] = useState("privacy");
  const { userAvatar, updateAvatar, userEmail } = useUser();
  const navigate = useNavigate();
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [deleteEmail, setDeleteEmail] = useState("");
  const [deletePassword, setDeletePassword] = useState("");
  const [deleteError, setDeleteError] = useState("");
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(true);
  const [showSubscriptionWarningModal, setShowSubscriptionWarningModal] =
    useState(false);
  const [showSubscriptionActiveModal, setShowSubscriptionActiveModal] =
    useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const loadSubscriptionData = async () => {
      try {
        setIsLoadingSubscription(true);
        const subscriptionData = await checkSubscriptionStatus();
        setSubscription(subscriptionData);
      } catch (error) {
        console.error("Error loading subscription info:", error);
      } finally {
        setIsLoadingSubscription(false);
      }
    };

    loadSubscriptionData();

    const intervalId = setInterval(loadSubscriptionData, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const handleAvatarChange = (avatarId) => {
    updateAvatar(avatarId);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {}
  };

  const handleDeleteAccountClick = () => {
    if (!subscription) {
      setShowDeleteModal(true);
      return;
    }

    // 취소 안한 경우
    if (!subscription.cancelledAt && subscription.status !== "cancelled") {
      setShowSubscriptionActiveModal(true);
      return;
    }

    const now = new Date();
    const renewsAt = new Date(subscription.renewsAt);
    const trialEndsAt = new Date(subscription.trialEndsAt);

    // 트라이얼인 경우 (trialEndsAt과 renewsAt이 같음)
    if (
      subscription.trialEndsAt &&
      trialEndsAt.getTime() === renewsAt.getTime()
    ) {
      setShowDeleteModal(true);
      return;
    }

    // 일반 구독이고 기간이 남은 경우 (trialEndsAt과 renewsAt이 다른 경우만)
    if (
      subscription.cancelledAt &&
      now < renewsAt &&
      (!subscription.trialEndsAt ||
        trialEndsAt.getTime() !== renewsAt.getTime())
    ) {
      setShowSubscriptionWarningModal(true);
      return;
    }

    setShowDeleteModal(true);
  };

  const handleDeleteAccount = async () => {
    try {
      if (!deleteEmail || !deletePassword) {
        setDeleteError("이메일과 비밀번호를 모두 입력해주세요.");
        return;
      }

      setIsDeleting(true);
      const user = auth.currentUser;
      const userId = user.uid;
      console.log("🗑️ 계정 삭제 프로세스 시작:", userId);

      const batch = writeBatch(db);

      // 구독 데이터 삭제
      console.log("📝 구독 데이터 삭제 중...");
      const subscriptionsRef = collection(db, `users/${userId}/subscriptions`);
      const subscriptionsSnapshot = await getDocs(subscriptionsRef);
      subscriptionsSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
      console.log(
        `✅ 구독 데이터 ${subscriptionsSnapshot.size}개 삭제 준비 완료`
      );

      // To Do List 데이터 삭제
      console.log("📋 To Do List 데이터 삭제 중...");

      // tasklists 컬렉션의 모든 문서 삭제
      const tasklistsRef = collection(
        db,
        `users/${userId}/todolist/myTodoList/tasklists`
      );
      const tasklistsSnapshot = await getDocs(tasklistsRef);
      tasklistsSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // myTodoList 문서 삭제
      const myTodoListRef = doc(db, `users/${userId}/todolist`, "myTodoList");
      batch.delete(myTodoListRef);

      console.log(
        `✅ To Do List 데이터 ${tasklistsSnapshot.size}개 삭제 준비 완료`
      );

      // 캘린더 데이터 삭제
      console.log("📅 캘린더 데이터 삭제 중...");
      const calendarRef = collection(db, `users/${userId}/Calendar`);
      const calendarSnapshot = await getDocs(calendarRef);
      calendarSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
      console.log(`✅ 캘린더 데이터 ${calendarSnapshot.size}개 삭제 준비 완료`);

      // 코스 데이터 삭제
      console.log("📚 코스 데이터 삭제 중...");
      const coursesRef = collection(db, `users/${userId}/courses`);
      const coursesSnapshot = await getDocs(coursesRef);
      let curriculumCount = 0;
      let flashcardCount = 0;

      for (const courseDoc of coursesSnapshot.docs) {
        // 커리큘럼 삭제
        const curriculumRef = collection(courseDoc.ref, "curriculum");
        const curriculumSnapshot = await getDocs(curriculumRef);
        curriculumSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
          curriculumCount++;
        });

        // 플래시카드 삭제
        const flashcardsRef = collection(courseDoc.ref, "flashcards");
        const flashcardsSnapshot = await getDocs(flashcardsRef);
        flashcardsSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
          flashcardCount++;
        });

        batch.delete(courseDoc.ref);
      }
      console.log(`✅ 코스 관련 데이터 삭제 준비 완료:`);
      console.log(`   - 코스: ${coursesSnapshot.size}개`);
      console.log(`   - 커리큘럼: ${curriculumCount}개`);
      console.log(`   - 플래시카드: ${flashcardCount}개`);

      // 사용자 문서 삭제
      console.log("👤 사용자 문서 삭제 준비 중...");
      const userRef = doc(db, `users/${userId}`);
      batch.delete(userRef);

      // 일괄 삭제 실행
      console.log("⚡ 일괄 삭제 실행 중...");
      await batch.commit();
      console.log("✅ 모든 데이터 삭제 완료");

      // 계정 삭제
      console.log("🔐 사용자 재인증 중...");
      const credential = EmailAuthProvider.credential(
        deleteEmail,
        deletePassword
      );
      await reauthenticateWithCredential(user, credential);

      console.log("👋 Firebase Authentication에서 계정 삭제 중...");
      await user.delete();
      console.log("✅ 계정 삭제 완료");

      setShowDeleteModal(false);
      console.log("🔄 로그인 페이지로 이동 중...");
      navigate("/login");
    } catch (error) {
      console.error("❌ 계정 삭제 중 오류 발생:", error);
      setDeleteError(error.message || "계정 삭제 중 오류가 발생했습니다.");
    } finally {
      setIsDeleting(false);
    }
  };

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      setError("New passwords do not match.");
      return;
    }

    try {
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );

      // Re-authenticate
      await reauthenticateWithCredential(user, credential);

      // Change password
      await updatePassword(user, newPassword);

      alert("Password changed successfully.");
      setShowPasswordModal(false);
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setError("");
    } catch (error) {
      console.error("Error changing password:", error);
      setError("Current password is incorrect or re-login required.");
    }
  };

  const deleteUserData = async (userId) => {
    try {
      // Delete user document from users collection
      const userRef = doc(db, `users/${userId}`);
      await deleteDoc(userRef);

      console.log("User data deletion complete");
    } catch (error) {
      console.error("Error during data deletion:", error);
      throw error;
    }
  };

  return (
    <div className="settings-container">
      <h1 className="settings-title">Settings</h1>
      <nav className="settings-nav">
        <button
          className={`nav-button ${activeTab === "privacy" ? "active" : ""}`}
          onClick={() => setActiveTab("privacy")}
        >
          Privacy
        </button>
        <button
          className={`nav-button ${activeTab === "billing" ? "active" : ""}`}
          onClick={() => setActiveTab("billing")}
        >
          Billing & Subscription
        </button>
        <button
          className={`nav-button ${activeTab === "security" ? "active" : ""}`}
          onClick={() => setActiveTab("security")}
        >
          Security
        </button>
      </nav>

      {activeTab === "billing" && (
        <div>
          <h2 className="settings-section-title">
            Billing & Subscription Management
          </h2>
          <div className="billing-section">
            <div className="subscription-status-card">
              <h3>Subscription Status</h3>
              <div className="subscription-info">
                {isLoadingSubscription ? (
                  <div className="loading-subscription">
                    <p>Loading subscription information...</p>
                  </div>
                ) : subscription ? (
                  <>
                    <div className="status-row">
                      <span className="label">Status:</span>
                      <span className={`status-badge ${subscription.status}`}>
                        {subscription.status === "active"
                          ? "Active"
                          : subscription.status === "on_trial"
                          ? "Trial"
                          : subscription.status === "cancelled"
                          ? "Cancelled"
                          : "Inactive"}
                      </span>
                    </div>
                    <div className="plan-row">
                      <span className="label">Current Plan:</span>
                      <span className="value">
                        {subscription.plan === "yearly" ? "Annual" : "Monthly"}{" "}
                        Plan
                      </span>
                    </div>
                    <div className="date-row">
                      <span className="label">Next Payment:</span>
                      <span className="value">
                        {subscription.currentPeriodEnd
                          ? new Date(
                              subscription.currentPeriodEnd
                            ).toLocaleDateString()
                          : "N/A"}
                      </span>
                    </div>
                    {subscription.trialEndsAt && (
                      <div className="trial-row">
                        <span className="label">Trial Ends:</span>
                        <span className="value">
                          {new Date(
                            subscription.trialEndsAt
                          ).toLocaleDateString()}
                        </span>
                      </div>
                    )}
                    <div className="manage-subscription">
                      {subscription &&
                        (subscription.status === "active" ||
                        subscription.status === "on_trial" ? (
                          <a
                            href={subscription.customerPortalUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="manage-button"
                          >
                            Manage Subscription
                          </a>
                        ) : (
                          <button
                            onClick={() => navigate("/subscription")}
                            className="manage-button"
                          >
                            View Plans
                          </button>
                        ))}
                    </div>
                  </>
                ) : (
                  <div className="no-subscription">
                    <p>You don't have an active subscription</p>
                    <button
                      onClick={() => navigate("/subscription")}
                      className="subscribe-button"
                    >
                      View Plans
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {activeTab === "privacy" && (
        <div>
          <h2 className="settings-section-title">Privacy Settings</h2>
          <div className="settings-privacy-section">
            <div className="email-section">
              <h3>Email</h3>
              <div className="email-display">
                <span className="email-text">{userEmail}</span>
              </div>
            </div>

            <div className="avatar-section">
              <h3>Profile Image</h3>
              <div className="current-avatar">
                <p>Current Profile Image</p>
                <img
                  src={getAvatarSrc(userAvatar)}
                  alt="Current Avatar"
                  className="current-avatar-img"
                />
              </div>
              <div className="avatar-grid">
                {avatarImages.slice(0, -1).map((avatar) => (
                  <div
                    key={avatar.id}
                    className={`avatar-option ${
                      userAvatar === avatar.id ? "selected" : ""
                    }`}
                    onClick={() => handleAvatarChange(avatar.id)}
                  >
                    <img
                      src={avatar.src}
                      alt={`Avatar ${avatar.id}`}
                      className="avatar-option-img"
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="logout-section">
              <button onClick={handleLogout} className="logout-button">
                <img src="/toolbaricons/inuse/send-out.svg" alt="logout" />
                Logout
              </button>
            </div>
          </div>
        </div>
      )}

      {activeTab === "security" && (
        <div>
          <h2 className="settings-section-title">Security Settings</h2>
          <div className="settings-security-section">
            {/* Password Management Section */}
            <div className="settings-password-section">
              <h3 className="settings-section-title">Password Management</h3>
              <div className="security-info-box">
                <div>
                  <p className="security-info-text">
                    Protect your account by regularly changing your password.
                  </p>
                  <p className="security-info-subtext">
                    Last changed: March 15, 2024
                  </p>
                </div>
                <button
                  className="change-password-button"
                  onClick={() => setShowPasswordModal(true)}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path d="M12 2a5 5 0 0 1 5 5v3H7V7a5 5 0 0 1 5-5zm-7 8h14v10a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V10z" />
                  </svg>
                  Change Password
                </button>
              </div>
            </div>

            {/* Account Deletion Section */}
            <div className="settings-delete-account-section">
              <h3 className="settings-section-title">Delete Account</h3>
              <div className="security-danger-box">
                <div className="security-danger-content">
                  <div>
                    <p className="security-danger-text">
                      Deleting your account will permanently remove all data and
                      cannot be recovered.
                    </p>
                    <p className="security-danger-subtext">
                      We recommend backing up important data before deletion.
                    </p>
                  </div>
                  <button
                    className="delete-account-button"
                    onClick={handleDeleteAccountClick}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path d="M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                    </svg>
                    Delete Account
                  </button>
                </div>
              </div>
            </div>

            {/* Password Change Modal */}
            {showPasswordModal && (
              <div
                className="modal-overlay"
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1000,
                }}
              >
                <div
                  className="modal-content"
                  style={{
                    backgroundColor: "white",
                    padding: "30px",
                    borderRadius: "12px",
                    width: "400px",
                    maxWidth: "90%",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h3 style={{ margin: 0, color: "#333" }}>
                      Change Password
                    </h3>
                    <button
                      onClick={() => setShowPasswordModal(false)}
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        padding: "4px",
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#666"
                        strokeWidth="2"
                      >
                        <path d="M18 6L6 18M6 6l12 12" />
                      </svg>
                    </button>
                  </div>

                  {error && (
                    <div
                      style={{
                        padding: "12px",
                        backgroundColor: "#fff5f5",
                        color: "#e03131",
                        borderRadius: "4px",
                        marginBottom: "20px",
                        fontSize: "14px",
                      }}
                    >
                      {error}
                    </div>
                  )}

                  <div style={{ marginBottom: "15px" }}>
                    <label
                      style={{
                        display: "block",
                        marginBottom: "8px",
                        fontSize: "14px",
                        color: "#333",
                      }}
                    >
                      Current Password
                    </label>
                    <input
                      type="password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      style={{
                        width: "100%",
                        padding: "8px",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: "15px" }}>
                    <label style={{ display: "block", marginBottom: "5px" }}>
                      New Password
                    </label>
                    <input
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      style={{
                        width: "100%",
                        padding: "8px",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <label style={{ display: "block", marginBottom: "5px" }}>
                      Confirm New Password
                    </label>
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      style={{
                        width: "100%",
                        padding: "8px",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "10px",
                    }}
                  >
                    <button
                      onClick={() => setShowPasswordModal(false)}
                      style={{
                        padding: "8px 16px",
                        backgroundColor: "#ffffff",
                        color: "#666",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handlePasswordChange}
                      style={{
                        padding: "8px 16px",
                        backgroundColor: "#4a90e2",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      Change
                    </button>
                  </div>
                </div>
              </div>
            )}

            {showDeleteModal && (
              <div className="modal-overlay">
                <div className="modal-content">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h3 style={{ margin: 0, color: "#e03131" }}>
                      Confirm Account Deletion
                    </h3>
                    <button
                      onClick={() => setShowDeleteModal(false)}
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        padding: "4px",
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#666"
                        strokeWidth="2"
                      >
                        <path d="M18 6L6 18M6 6l12 12" />
                      </svg>
                    </button>
                  </div>

                  <div
                    style={{
                      backgroundColor: "#fff5f5",
                      border: "1px solid #ffe3e3",
                      borderRadius: "8px",
                      padding: "16px",
                      marginBottom: "20px",
                    }}
                  >
                    <div style={{ marginBottom: "16px", textAlign: "left" }}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="#e03131"
                        style={{
                          marginBottom: "12px",
                          display: "block",
                          margin: "0 auto",
                        }}
                      >
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
                      </svg>
                      <h4
                        style={{
                          color: "#e03131",
                          marginBottom: "8px",
                          textAlign: "center",
                        }}
                      >
                        Warning
                      </h4>
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#666",
                          marginBottom: "8px",
                          textAlign: "left",
                        }}
                      >
                        The following data will be permanently deleted when you
                        delete your account:
                      </p>
                      <ul
                        style={{
                          fontSize: "14px",
                          color: "#666",
                          paddingLeft: "20px",
                          textAlign: "left",
                        }}
                      >
                        <li>
                          All course-related materials (notes, curriculum,
                          flashcards)
                        </li>
                        <li>Calendar events and schedules</li>
                        <li>Personal settings and preferences</li>
                        <li>Chat history and interactions</li>
                        <li>Progress tracking and achievements</li>
                        <li>Subscription and payment information</li>
                      </ul>
                    </div>
                    <p
                      style={{
                        fontSize: "13px",
                        color: "#666",
                        textAlign: "left",
                      }}
                    >
                      This action cannot be undone and deleted data cannot be
                      recovered.
                    </p>
                  </div>

                  {deleteError && (
                    <div
                      style={{
                        padding: "12px",
                        backgroundColor: "#fff5f5",
                        color: "#e03131",
                        borderRadius: "4px",
                        marginBottom: "20px",
                        fontSize: "14px",
                      }}
                    >
                      {deleteError}
                    </div>
                  )}

                  <div style={{ marginBottom: "15px" }}>
                    <label
                      style={{
                        display: "block",
                        marginBottom: "8px",
                        fontSize: "14px",
                        color: "#e1e1e1",
                      }}
                    >
                      Confirm Email Address
                    </label>
                    <input
                      type="email"
                      value={deleteEmail}
                      onChange={(e) => setDeleteEmail(e.target.value)}
                      placeholder="Enter your account email"
                      style={{
                        width: "100%",
                        padding: "8px",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                      }}
                    />
                  </div>

                  <div style={{ marginBottom: "20px" }}>
                    <label
                      style={{
                        display: "block",
                        marginBottom: "8px",
                        fontSize: "14px",
                        color: "#e1e1e1",
                      }}
                    >
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      value={deletePassword}
                      onChange={(e) => setDeletePassword(e.target.value)}
                      placeholder="Enter your current password"
                      style={{
                        width: "100%",
                        padding: "8px",
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "12px",
                    }}
                  >
                    <button
                      onClick={() => {
                        setShowDeleteModal(false);
                        setDeleteEmail("");
                        setDeletePassword("");
                        setDeleteError("");
                      }}
                      style={{
                        padding: "10px 20px",
                        backgroundColor: "#ffffff",
                        color: "#666",
                        border: "1px solid #ddd",
                        borderRadius: "6px",
                        cursor: isDeleting ? "not-allowed" : "pointer",
                        opacity: isDeleting ? 0.7 : 1,
                      }}
                      disabled={isDeleting}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleDeleteAccount}
                      style={{
                        padding: "10px 20px",
                        backgroundColor: "#e03131",
                        color: "white",
                        border: "none",
                        borderRadius: "6px",
                        cursor: isDeleting ? "not-allowed" : "pointer",
                        opacity: isDeleting ? 0.7 : 1,
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                      disabled={isDeleting}
                    >
                      {isDeleting ? (
                        <>
                          <div className="spinner" />
                          Deleting Account...
                        </>
                      ) : (
                        "Delete Account"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* 활성 구독 경고 모달 */}
            {showSubscriptionActiveModal && (
              <div className="modal-overlay">
                <div className="modal-content">
                  <h3 style={{ color: "#ffffff" }}>
                    Subscription Cancellation Required
                  </h3>
                  <p style={{ color: "#e0e0e0" }}>
                    You need to cancel your subscription before deleting your
                    account.
                  </p>
                  <div className="modal-buttons">
                    <button
                      onClick={() => {
                        window.open(subscription.customerPortalUrl, "_blank");
                        setShowSubscriptionActiveModal(false);
                      }}
                      className="primary-button"
                    >
                      Manage Subscription
                    </button>
                    <button
                      onClick={() => setShowSubscriptionActiveModal(false)}
                      className="secondary-button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* 구독 기간 잔여 경고 모달 */}
            {showSubscriptionWarningModal && (
              <div className="modal-overlay">
                <div className="modal-content">
                  <h3>구독 기간 잔여</h3>
                  <p>
                    아직 구독 기간이 남아있습니다. 그래도 정을 삭제하시겠습니까?
                  </p>
                  <p className="subscription-info">
                    구독 만료일:{" "}
                    {new Date(
                      subscription.currentPeriodEnd
                    ).toLocaleDateString()}
                  </p>
                  <div className="modal-buttons">
                    <button
                      onClick={() => {
                        setShowSubscriptionWarningModal(false);
                        setShowDeleteModal(true);
                      }}
                      className="danger-button"
                    >
                      계속 진행
                    </button>
                    <button
                      onClick={() => setShowSubscriptionWarningModal(false)}
                      className="secondary-button"
                    >
                      취소
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
