import React from "react";
import { Link } from "react-router-dom";
import "../css/RefundPolicy.css";

const RefundPolicy = () => {
  return (
    <div className="refund-container">
      <div className="legal-header">
        <Link to="/" className="home-button">
          <span className="home-icon">←</span> Back to Home
        </Link>
        <h1 className="legal-title">Cancellation Policy </h1>
      </div>
      <div className="refund-content">
        <section className="legal-section">
          <h2>1. Subscription and Automatic Renewal</h2>
          <p>
            Your purchase of certain Services may require enrollment in a
            payment plan involving automatic renewal (a "Recurring
            Subscription"), and if you purchase such a Recurring Subscription,
            you authorize RedoNote to maintain your account information and
            charge that account automatically upon the renewal with no further
            action required by you.
          </p>
        </section>

        <section className="legal-section">
          <h2>2. Free Trial Period</h2>
          <p>
            We offer a 3-day free trial for new subscribers. If you cancel
            during the trial period, you will not be charged. The trial period
            begins at the time of subscription initiation.
          </p>
        </section>

        <section className="legal-section">
          <h2>3. Non-Refundable Cases</h2>
          <p>Refunds will not be issued in the following cases:</p>
          <ul className="legal-list">
            <li>
              Cancellation during current subscription period: While you can
              cancel at any time, amounts already paid will not be refunded.
              However, you will retain access to the service until the end of
              your paid period.
            </li>
            <li>
              Cancellation after automatic renewal: No refunds will be issued if
              cancellation occurs after the subscription has automatically
              renewed.
            </li>
            <li>
              User oversight: Cases where users forgot to cancel their
              subscription or made accidental payments.
            </li>
          </ul>
        </section>

        <section className="legal-section">
          <h2>4. Special Circumstances for Refunds</h2>
          <p>
            While not explicitly stated in our policy, we may consider refunds
            in exceptional circumstances, including:
          </p>
          <ul className="legal-list">
            <li>
              Double charging: If your account was charged twice for the same
              subscription
            </li>
            <li>
              Payment system errors: Cases where incorrect charges occurred due
              to system issues
            </li>
            <li>
              Trial period issues: Technical problems or service issues during
              the trial period may be considered for refund upon review
            </li>
          </ul>
        </section>

        <section className="legal-section">
          <h2>5. How to Request a Refund</h2>
          <p>To request a refund:</p>
          <ul className="legal-list">
            <li>
              Contact our support team at{" "}
              <a href="mailto:support@redonote.com">support@redonote.com</a>
            </li>
            <li>
              Include your subscription account email, payment details, and
              reason for refund request
            </li>
            <li>Attach relevant payment documentation or receipts</li>
            <li>
              Refund requests are typically processed within 3-5 business days
            </li>
          </ul>
        </section>

        <section className="legal-section">
          <h2>6. Price Changes</h2>
          <p>
            RedoNote may change the prices charged for Recurring Subscriptions
            at any time; provided, however, that the prices for your Recurring
            Subscription will remain in force for the duration of the
            subscription period for which you have paid. After that period ends,
            your use of the applicable Services will be charged at the
            then-current subscription price.
          </p>
        </section>

        <section className="legal-section">
          <h2>7. Automatic Renewal</h2>
          <p>
            If you do not cancel, your Recurring Subscription will automatically
            renew at the then-current price at the time of renewal and for the
            same duration as the initial subscription term, and RedoNote will
            charge your on-file payment card or method on the first day of the
            renewal of the subscription term.
          </p>
        </section>

        <section className="legal-section">
          <h2>8. Contact Us</h2>
          <p>
            If you have any questions about our cancellation policy, please
            contact us at:
            <a href="mailto:support@redonote.com">support@redonote.com</a>
          </p>
        </section>
      </div>
    </div>
  );
};

export default RefundPolicy;
