import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import "../css/notelayout.css"; // CSS file imported
import AnotherComponent from "../components/AnotherComponent"; // 올바른 경로로 수정
import Moveable from "react-moveable";
import { Menu, Item, useContextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { useLayout } from "../contexts/LayoutContext";
import { useCurriculum } from "../contexts/CurriculumContext";
import { useImage } from "../contexts/ImageContext";
import EventEmitter from "../utils/eventEmitter";

function Noteedit({
  unitName,
  subunitName,
  subunitId,
  unitId,
  layout,
  zoom,
  dragArea,
  isPenMode,
  isEraserMode,
  penColor,
  penSize,
  eraserSize,
  backgroundImage,
  onTableDataChange,
  tableData,
  onCanvasDataChange,
  initialCanvasData,
  onStampAdd,
  onStampDelete,
  initialStamps = [],
  courseId,
}) {
  const { loadImageData, updateImageData, saveImageData, deleteImageData } =
    useImage();
  const [images, setImages] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const fetchImages = async () => {
      if (!courseId || !subunitId) {
        return;
      }

      try {
        const loadedImages = await loadImageData(courseId, subunitId);

        if (isMounted && loadedImages) {
          setImages(loadedImages);
        }
      } catch (error) {}
    };

    fetchImages();

    return () => {
      isMounted = false;
    };
  }, [courseId, subunitId]);

  const handleUpdateImage = async (imageId, updateData) => {
    try {
      setImages((prevImages) => {
        const newImages = [...prevImages];
        const imageIndex = newImages.findIndex((img) => img.id === imageId);

        if (imageIndex !== -1) {
          newImages[imageIndex] = {
            ...newImages[imageIndex],
            ...updateData,
            size: {
              ...newImages[imageIndex].size,
              ...(updateData.size || {}),
            },
            position: {
              ...newImages[imageIndex].position,
              ...(updateData.position || {}),
            },
          };
        }

        return newImages;
      });

      await updateImageData(courseId, subunitId, imageId, updateData);
    } catch (error) {}
  };

  const handleDeleteImage = async (imageId) => {
    try {
      await deleteImageData(courseId, subunitId, imageId);
      setImages((prevImages) => prevImages.filter((img) => img.id !== imageId));
      setTarget(null);
      setClipTarget(null);
    } catch (error) {}
  };

  const [target, setTarget] = useState(null);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [clipTarget, setClipTarget] = useState(null);
  const [pdfPages, setPdfPages] = useState([]);
  const pdfRefs = useRef([]);
  const targetRefs = useRef([]);

  const { show } = useContextMenu({
    id: "image-context-menu",
  });

  const canvasRef = useRef(null);
  const noteContentRef = useRef(null);
  const isDrawing = useRef(false);
  const currentPath = useRef(null);
  const previewCanvasRef = useRef(null);

  const { layout: contextLayout, setLayout } = useLayout() || {};
  const [currentFont, setCurrentFont] = useState("TTAgainSea");

  const [isCanvasLoaded, setIsCanvasLoaded] = useState(true);

  const autoSaveTimerRef = useRef(null);

  const saveCanvasData = useCallback(async () => {
    if (!canvasRef.current) {
      return;
    }

    try {
      const canvasDataUrl = canvasRef.current.toDataURL("image/png");

      if (onCanvasDataChange) {
        const saveData = {
          mainCanvas: canvasDataUrl,
          lastModified: new Date().toISOString(),
          version: "1.0",
        };

        await onCanvasDataChange(unitId, subunitId, saveData);
      }
    } catch (error) {
      console.error("❌ 캔버스 데이터 저장 실패:", error);
    }
  }, [unitId, subunitId, onCanvasDataChange]);

  const restoreCanvasData = useCallback(() => {
    if (!canvasRef.current || !initialCanvasData?.mainCanvas) {
      return;
    }

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // 캔버스 크기가 0인지 확인
    if (canvas.width === 0 || canvas.height === 0) {
      const noteContent = noteContentRef.current;
      if (noteContent) {
        const rect = noteContent.getBoundingClientRect();
        canvas.width = rect.width;
        canvas.height = rect.height;
      } else {
        return; // noteContent가 없으면 함수 종료
      }
    }

    const image = new Image();
    image.onload = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height); // 캔버스 크기에 맞게 이미지 그리기
    };

    image.src = initialCanvasData.mainCanvas;
  }, [initialCanvasData]);

  useEffect(() => {
    autoSaveTimerRef.current = setInterval(() => {
      saveCanvasData();
    }, 5 * 60 * 1000); // 5분마다 자동 저장
    return () => {
      if (autoSaveTimerRef.current) {
        clearInterval(autoSaveTimerRef.current);
      }
    };
  }, [saveCanvasData]);

  useEffect(() => {
    if (layout) {
      setLayout(layout);
    }
  }, [layout, setLayout]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const previewCanvas = previewCanvasRef.current;
    const noteContent = noteContentRef.current;

    if (!canvas || !previewCanvas || !noteContent) {
      return;
    }

    const ctx = canvas.getContext("2d");
    const previewCtx = previewCanvas.getContext("2d");

    const resizeCanvas = () => {
      if (!noteContent) {
        return;
      }
      const rect = noteContent.getBoundingClientRect();
      canvas.width = previewCanvas.width = rect.width;
      canvas.height = previewCanvas.height = rect.height;
      setIsCanvasLoaded(true);

      // 캔버스 크기 설정 후 데이터 복원
      restoreCanvasData();
    };

    resizeCanvas();
    const resizeObserver = new ResizeObserver(() => {
      if (noteContent) {
        resizeCanvas();
      }
    });
    resizeObserver.observe(noteContent);

    return () => {
      if (noteContent) {
        resizeObserver.unobserve(noteContent);
      }
      resizeObserver.disconnect();
    };
  }, []);

  const getScaledPosition = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    const scaleX = canvasRef.current.width / rect.width;
    const scaleY = canvasRef.current.height / rect.height;
    return {
      x: (e.clientX - rect.left) * scaleX,
      y: (e.clientY - rect.top) * scaleY,
    };
  };

  const startDrawing = (e) => {
    if (!isPenMode && !isEraserMode) return;
    isDrawing.current = true;
    const { x, y } = getScaledPosition(e);
    currentPath.current = new Path2D();
    currentPath.current.moveTo(x, y);
  };
  const draw = (e) => {
    if (!isDrawing.current || (!isPenMode && !isEraserMode)) return;
    const { x, y } = getScaledPosition(e);
    currentPath.current.lineTo(x, y);

    // 실 미리보기
    const previewCtx = previewCanvasRef.current.getContext("2d");
    previewCtx.clearRect(
      0,
      0,
      previewCanvasRef.current.width,
      previewCanvasRef.current.height
    );

    if (isPenMode) {
      // 펜 모드: 기존 코드 유지

      previewCtx.strokeStyle = penColor;
      previewCtx.lineWidth = penSize;
      previewCtx.lineCap = "round";
      previewCtx.lineJoin = "round";
      previewCtx.stroke(currentPath.current);
    } else if (isEraserMode) {
      // 지우개 모드: 실시간 지우기 적용
      const ctx = canvasRef.current.getContext("2d");
      ctx.globalCompositeOperation = "destination-out";
      ctx.strokeStyle = "rgba(0,0,0,1)"; // 완전 불투명한 검은색
      ctx.lineWidth = eraserSize;
      ctx.lineCap = "round";
      ctx.lineJoin = "round";
      ctx.globalAlpha = 1; // 지우개는 항상 완전 투명
      ctx.stroke(currentPath.current);
      ctx.globalCompositeOperation = "source-over";
    }
  };

  const endDrawing = () => {
    if (!isDrawing.current) return;
    isDrawing.current = false;

    if (isPenMode) {
      const ctx = canvasRef.current.getContext("2d");

      ctx.strokeStyle = penColor;
      ctx.lineWidth = penSize;
      ctx.lineCap = "round";
      ctx.lineJoin = "round";
      ctx.stroke(currentPath.current);
    } else if (isEraserMode) {
      // 지우개 모드일 때도 캔버스 상태를 저장
      const ctx = canvasRef.current.getContext("2d");
      ctx.globalCompositeOperation = "destination-out";
      ctx.strokeStyle = "rgba(0,0,0,1)";
      ctx.lineWidth = eraserSize;
      ctx.lineCap = "round";
      ctx.lineJoin = "round";
      ctx.globalAlpha = 1;
      ctx.stroke(currentPath.current);
      ctx.globalCompositeOperation = "source-over";
    }

    // 미리보기 캔버스 지우기
    const previewCtx = previewCanvasRef.current.getContext("2d");
    previewCtx.clearRect(
      0,
      0,
      previewCanvasRef.current.width,
      previewCanvasRef.current.height
    );

    currentPath.current = null;

    // 펜 모나 지우개 모드 둘 다 캔버스 상태 저장
    if (isPenMode || isEraserMode) {
      saveCanvasData();
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.addEventListener("mousedown", startDrawing);
    canvas.addEventListener("mousemove", draw);
    canvas.addEventListener("mouseup", endDrawing);
    canvas.addEventListener("mouseout", endDrawing);

    return () => {
      canvas.removeEventListener("mousedown", startDrawing);
      canvas.removeEventListener("mousemove", draw);
      canvas.removeEventListener("mouseup", endDrawing);
      canvas.removeEventListener("mouseout", endDrawing);
    };
  }, [isPenMode, isEraserMode, penColor, penSize, eraserSize]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (target && (e.key === "Delete" || e.key === "Backspace")) {
        const imageId = target.id.split("-")[1];
        if (imageId) {
          handleDeleteImage(parseInt(imageId));
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [target, handleDeleteImage]);

  const handleDoubleClick = (index) => {
    setClipTarget(targetRefs.current[index]);
  };

  const handleOutsideClick = (e) => {
    if (
      !clipTarget && // 클립 모드가 아닐 때만
      !e.target.closest(".inserted-image") &&
      !e.target.closest(".moveable-control") &&
      !e.target.closest(".moveable-line")
    ) {
      setTarget(null);
      setSelectedImageId(null);
    }
  };

  const handleInsertImage = (image) => {
    if (image.src) {
      const newImage = {
        src: image.src,
        zIndex: 1000 + images.length,
      };
      setImages((prevImages) => [...prevImages, newImage]);
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    const target = event.target;

    if (target.classList.contains("inserted-image")) {
      const imageId = target.getAttribute("data-image-id");
      const offsetX = -300; // 왼쪽으로 이동할 픽셀
      const offsetY = 150; // 아래로 이동할 픽셀

      show({
        event,
        props: { imageId },
        position: {
          x: event.clientX + offsetX,
          y: event.clientY + offsetY,
        },
      });
    }
  };

  const handleDrag = (e) => {
    const { target, transform } = e;
    const index = parseInt(target.getAttribute("data-index"));
    target.style.transform = transform;
  };

  useEffect(() => {
    const noteContent = document.querySelector(".note-content");
    if (noteContent) {
      if (isPenMode) {
        noteContent.classList.add("pen-mode");
      } else {
        noteContent.classList.remove("pen-mode");
      }
    }
  }, [isPenMode]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      if (isEraserMode) {
        const cursorSize = eraserSize + 2;
        canvas.style.cursor = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${cursorSize}" height="${cursorSize}" viewBox="0 0 ${cursorSize} ${cursorSize}"><circle cx="${
          cursorSize / 2
        }" cy="${cursorSize / 2}" r="${
          eraserSize / 2
        }" fill="rgba(255,255,255,0.5)" stroke="black" stroke-width="1"/></svg>') ${
          cursorSize / 2
        } ${cursorSize / 2}, auto`;
      } else {
        canvas.style.cursor = ""; // 커서 스일 제거
      }
    }
  }, [isEraserMode, eraserSize]);

  useEffect(() => {
    const mainContent = document.querySelector(".main-content");
    if (mainContent && initialStamps) {
      // 기존 스탬프 모두 제거
      mainContent.querySelectorAll(".stamp").forEach((stamp) => stamp.remove());

      // 저장된 스탬프 다시 렌더링
      initialStamps.forEach((stampData) => {
        const stamp = document.createElement("img");
        stamp.src = `/img/stamp/stamp${stampData.stampId}.svg`;
        stamp.className = "stamp";
        stamp.dataset.stampId = stampData.id;
        stamp.style.position = "absolute";
        stamp.style.left = `${stampData.position.x}px`;
        stamp.style.top = `${stampData.position.y}px`;
        stamp.style.width = "100px";
        stamp.style.height = "100px";
        stamp.style.zIndex = "1000";
        stamp.style.transform = "translate(-50%, -50%)";
        stamp.style.pointerEvents = "auto";
        stamp.style.cursor = "pointer";

        // 더블클릭 이벤트 추가
        stamp.ondblclick = (e) => {
          e.preventDefault();
          e.stopPropagation();
          if (onStampDelete) {
            onStampDelete(stampData.id);
          }
        };

        mainContent.appendChild(stamp);
      });
    }
  }, [initialStamps, onStampDelete]); // onStampDelete 의존성 추가

  useEffect(() => {
    if (backgroundImage) {
      const mainContent = document.querySelector(".main-content");
      if (mainContent) {
        mainContent.style.backgroundImage = `url(${backgroundImage})`;
        mainContent.style.backgroundSize = "cover";
        mainContent.style.backgroundPosition = "center";
        mainContent.style.backgroundRepeat = "no-repeat";
      }
    }
  }, [backgroundImage]);

  const handleLocalTableDataChange = useCallback(
    (newTableData) => {
      onTableDataChange(unitId, subunitId, newTableData);
    },
    [onTableDataChange, unitId, subunitId]
  );

  useEffect(() => {
    return () => {
      saveCanvasData();
    };
  }, [subunitId, saveCanvasData]);

  const handlePenModeChange = useCallback(
    (newMode, shouldSave) => {
      if (shouldSave) {
        saveCanvasData();
      }
    },
    [saveCanvasData]
  );

  useEffect(() => {
    if (!noteContentRef.current) return;

    // 기존 그려진 요소들 제거
    const existingLines =
      noteContentRef.current.querySelectorAll(".drawn-line");
    const existingRectangles =
      noteContentRef.current.querySelectorAll(".drawn-rectangle");
    const existingTextboxes =
      noteContentRef.current.querySelectorAll(".textbox"); // 텍스트박스도 제거

    existingLines.forEach((line) => line.remove());
    existingRectangles.forEach((rect) => rect.remove());
    existingTextboxes.forEach((textbox) => textbox.remove());

    // 펜 그리기 데이터 복원
    if (initialCanvasData?.mainCanvas) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0);
      };
      img.src = initialCanvasData.mainCanvas;
    }

    // 선, 사각형, 텍스트박스 데이터 복원
    if (initialCanvasData?.drawings) {
      const {
        lines = [],
        rectangles = [],
        textboxes = [],
      } = initialCanvasData.drawings;

      // 선 그리기
      lines.forEach((line) => {
        const lineElement = document.createElement("div");
        lineElement.className = `drawn-line ${line.style}`;

        const length = Math.sqrt(
          Math.pow(line.endX - line.startX, 2) +
            Math.pow(line.endY - line.startY, 2)
        );
        const angle =
          Math.atan2(line.endY - line.startY, line.endX - line.startX) *
          (180 / Math.PI);

        lineElement.style.width = `${length}px`;
        lineElement.style.left = `${line.startX}px`;
        lineElement.style.top = `${line.startY}px`;
        lineElement.style.transform = `rotate(${angle}deg)`;
        lineElement.style.borderTopColor = line.color;
        lineElement.style.borderTopWidth = `${line.width}px`;

        if (noteContentRef.current) {
          noteContentRef.current.appendChild(lineElement);
        }
      });

      // 텍스박스 복원
      textboxes.forEach((textbox) => {
        const textboxElement = document.createElement("div");
        textboxElement.className = "textbox";
        textboxElement.contentEditable = true;
        textboxElement.innerHTML = textbox.content;

        textboxElement.style.position = "absolute";
        textboxElement.style.left = `${textbox.x}px`;
        textboxElement.style.top = `${textbox.y}px`;
        textboxElement.style.width = `${textbox.width}px`;
        textboxElement.style.height = `${textbox.height}px`;
        textboxElement.style.fontSize = `${textbox.fontSize || 16}px`;
        textboxElement.style.fontFamily = textbox.fontFamily || "inherit";
        textboxElement.style.color = textbox.color || "black";
        textboxElement.style.zIndex = textbox.zIndex || "1000";

        if (noteContentRef.current) {
          noteContentRef.current.appendChild(textboxElement);
        }
      });
    }
  }, [initialCanvasData]);

  useEffect(() => {
    if (images.length > 0) {
      const lastImage = images[images.length - 1];
      const imageElement = document.getElementById(`image-${lastImage.id}`);
      if (imageElement) {
        setTarget(imageElement);
      }
    }
  }, [images]);

  const handleImageTransform = async (imageId, transform) => {
    const numericId = parseInt(imageId.replace(/\D/g, ""));

    if (!transform && target) {
      transform = target.style.transform;
    }

    if (!transform) {
      return;
    }

    const transforms = transform.match(
      /translate\(([-\d.]+)px,\s*([-\d.]+)px\)\s*(?:rotate\(([-\d.]+)deg\))?\s*(?:scale\(([-\d.]+)(?:,\s*([-\d.]+))?\))?/
    );

    if (!transforms) {
      return;
    }

    // 기본 크기를 200x200으로 고정
    const baseWidth = 200;
    const baseHeight = 200;

    // scale 값 추출
    const scaleX = transforms[4] ? parseFloat(transforms[4]) : 1;
    const scaleY = transforms[5] ? parseFloat(transforms[5]) : scaleX;

    const updateData = {
      position: {
        x: Math.round(parseFloat(transforms[1])),
        y: Math.round(parseFloat(transforms[2])),
      },
      rotation: transforms[3] ? Math.round(parseFloat(transforms[3])) : 0,
      size: {
        width: Math.round(baseWidth * scaleX), // 200에 scale 곱기
        height: Math.round(baseHeight * scaleY), // 200에 scale 곱기
      },
    };

    await handleUpdateImage(numericId, updateData);
  };

  useEffect(() => {
    // ... effect 내용
  }, [images]); // dependency를 images로 변경

  useEffect(() => {
    if (target && target instanceof HTMLElement) {
    }
  }, [target]);

  useEffect(() => {
    const handleGlobalClick = (e) => {
      // 클립 모드일 때만 체크
      if (clipTarget) {
        // 클릭된 요소가 Moveable 관련 요소가 아닌지 확인
        const isClickedOutside =
          !e.target.closest(".moveable-control") &&
          !e.target.closest(".moveable-line") &&
          !e.target.closest(".moveable-area") &&
          !e.target.closest(".moveable-clip") &&
          !e.target.closest(".inserted-image");

        if (isClickedOutside) {
          setClipTarget(null);
        }
      }
    };

    // 전역 클릭 이벤트 리스너 추가
    document.addEventListener("mousedown", handleGlobalClick);

    // 클린업
    return () => {
      document.removeEventListener("mousedown", handleGlobalClick);
    };
  }, [clipTarget]); // clipTarget이 변경될 때마다 이벤트 리스너 갱신

  // 상수 추가
  const MIN_Z_INDEX = 100; // AnotherComponent보다 위에 있어야 할 최소 z-index 값

  // 이미지 z-index 조작 함수 추가
  const handleZIndexChange = async (imageId, action) => {
    setImages((prevImages) => {
      const newImages = [...prevImages];
      // parseInt를 사용하여 imageId를 숫자로 변환
      const imageIndex = newImages.findIndex(
        (img) => img.id === parseInt(imageId)
      );

      if (imageIndex === -1) {
        return prevImages;
      }

      const currentImage = newImages[imageIndex];
      const currentZIndex = currentImage.zIndex || 1000 + imageIndex;
      let newZIndex;

      // 현재 모든 이미지의 z-index 값들을 확인
      const zIndexes = newImages.map((img) => img.zIndex || 1000);
      const maxZIndex = Math.max(...zIndexes);
      const minZIndex = Math.min(...zIndexes);

      switch (action) {
        case "front":
          newZIndex = maxZIndex + 1;
          break;

        case "forward":
          newZIndex = currentZIndex + 1;
          break;

        case "back":
          newZIndex = minZIndex - 1;
          break;

        case "backward":
          newZIndex = currentZIndex - 1;
          break;

        default:
          return prevImages;
      }

      // 이미지 업데이트
      newImages[imageIndex] = {
        ...currentImage,
        zIndex: newZIndex,
      };

      // Firebase 업데이트
      const updateData = {
        zIndex: newZIndex,
      };

      updateImageData(courseId, subunitId, parseInt(imageId), updateData).catch(
        (error) => console.error("Firebase 업데이트 실:", error)
      );

      return newImages;
    });
  };

  const { getSubunitNameById } = useCurriculum();

  useEffect(() => {}, [images]);

  useEffect(() => {
    // 초기 이미지 로드
    const loadImages = async () => {
      if (!courseId || !subunitId) return;
      try {
        const loadedImages = await loadImageData(courseId, subunitId);
        setImages(loadedImages || []);
      } catch (error) {
        console.error("이미지 로드 실패:", error);
      }
    };
    loadImages();

    // 이미지 업데이트 이벤트 리스너
    const handleImagesUpdate = ({ images: updatedImages }) => {
      setImages(updatedImages);
    };

    EventEmitter.on("imagesUpdated", handleImagesUpdate);

    return () => {
      EventEmitter.off("imagesUpdated", handleImagesUpdate);
    };
  }, [courseId, subunitId]);

  return (
    <div
      className={`main-content ${isPenMode ? "pen-mode" : ""}`}
      onClick={handleOutsideClick}
      onContextMenu={handleContextMenu}
    >
      <h3 className="unit-name">{getSubunitNameById(subunitId)}</h3>

      <div className="note-content-wrapper">
        <div
          ref={noteContentRef}
          className={`note-content ${isPenMode ? "pen-mode" : ""}`}
          style={{ position: "relative", width: "100%", height: "100%" }}
        >
          <AnotherComponent
            tableStyle={contextLayout}
            currentFont={currentFont}
            tableData={tableData}
            onTableDataChange={handleLocalTableDataChange}
          />
          {images.map((image, index) => {
            if (!image) return null;
            const initialTransform = `translate(${image.position?.x || 0}px, ${
              image.position?.y || 0
            }px) rotate(${image.rotation || 0}deg) scale(${
              image.size?.width ? image.size.width / 200 : 1
            }, ${image.size?.height ? image.size.height / 200 : 1})`;

            return (
              <img
                key={image.id}
                ref={(el) => {
                  targetRefs.current[index] = el;
                }}
                src={image.src}
                id={`image-${image.id}`}
                alt={`Inserted image ${index}`}
                className="inserted-image"
                style={{
                  position: "absolute",
                  zIndex: (image.zIndex || 1000 + index) - 100,
                  pointerEvents: "auto",
                  width: "200px",
                  height: "200px",
                  transform: initialTransform,
                  clipPath: image.clipPath || "none",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  const clickedElement = document.getElementById(
                    `image-${image.id}`
                  );
                  if (clickedElement) {
                    setTarget(clickedElement);
                  }
                }}
                onDoubleClick={() => handleDoubleClick(index)}
                data-image-id={image.id}
                data-index={index}
              />
            );
          })}

          {target && (
            <Moveable
              target={target}
              draggable={true}
              rotatable={true}
              scalable={true}
              throttleDrag={1}
              // 드래그 이벤트
              onDrag={(e) => {
                e.target.style.transform = e.transform;
              }}
              onDragEnd={(e) => {
                handleImageTransform(
                  e.target.id.split("-")[1],
                  e.target.style.transform
                );
              }}
              // 회전 이벤트
              onRotate={(e) => {
                e.target.style.transform = e.transform;
              }}
              onRotateEnd={(e) => {
                handleImageTransform(
                  e.target.id.split("-")[1],
                  e.target.style.transform
                );
              }}
              // 크기 조절 이벤트
              onScale={(e) => {
                e.target.style.transform = e.transform;
              }}
              onScaleEnd={(e) => {
                handleImageTransform(
                  e.target.id.split("-")[1],
                  e.target.style.transform
                );
              }}
              // 드래그 원점 이동 이벤트
              onDragOrigin={(e) => {
                const imageId = e.target.getAttribute("id");
                e.target.style.transformOrigin = e.transformOrigin;
              }}
              onRender={(e) => {
                const imageId = e.target.getAttribute("id");
                e.target.style.transform = e.transform;
              }}
            />
          )}
          {clipTarget && (
            <Moveable
              target={clipTarget}
              draggable={true}
              startDragRotate={0}
              throttleDragRotate={0}
              zoom={zoom}
              clippable={true}
              clipRelative={false}
              clipArea={false}
              dragArea={dragArea}
              dragWithClip={0}
              defaultClipPath={"inset"}
              clipTargetBounds={false}
              snapThreshold={5}
              keepRatio={false}
              onDrag={(e) => {
                e.target.style.transform = e.transform;
              }}
              onClip={async (e) => {
                const imageId = parseInt(e.target.id.split("-")[1]);
                const clipStyle = e.clipStyle;
                const currentTransform = e.target.style.transform;
                e.target.style.clipPath = clipStyle;
                await handleUpdateImage(imageId, {
                  clipPath: clipStyle,
                  transform: currentTransform,
                });
                setClipTarget(null);
              }}
              onClickGroup={(e) => {
                if (
                  !e.target.closest(".moveable-area") &&
                  !e.target.closest(".moveable-clip")
                ) {
                  setClipTarget(null);
                }
              }}
            />
          )}
          {pdfPages.map((pageUrl, index) => (
            <img
              key={index}
              ref={(el) => (pdfRefs.current[index] = el)}
              src={pageUrl}
              alt={`PDF Page ${index + 1}`}
              style={{ width: "100%", height: "auto" }}
            />
          ))}
          <canvas
            ref={canvasRef}
            style={{
              position: "absolute",
              backgroundColor: "transparent",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              pointerEvents: isPenMode || isEraserMode ? "auto" : "none",
              zIndex: 2000,
            }}
          />
          <canvas
            ref={previewCanvasRef}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              pointerEvents: "none",
              zIndex: 2001,
            }}
          />
        </div>
      </div>
      <Menu
        id="image-context-menu"
        style={{
          "--contexify-menu-bgColor": "#fff",
          "--contexify-item-color": "#333",
          "--contexify-activeItem-bgColor": "#fae150", // 노란색으로 변경
          "--contexify-activeItem-color": "#000",
          "--contexify-separator-color": "#eee",
        }}
      >
        <Item
          onClick={({ props }) => handleZIndexChange(props.imageId, "front")}
        >
          Bring to Front
        </Item>
        <Item
          onClick={({ props }) => handleZIndexChange(props.imageId, "forward")}
        >
          Bring Forward
        </Item>
        <Item
          onClick={({ props }) => handleZIndexChange(props.imageId, "back")}
        >
          Send to Back
        </Item>
        <Item
          onClick={({ props }) => handleZIndexChange(props.imageId, "backward")}
        >
          Send Backward
        </Item>
      </Menu>
    </div>
  );
}

export default Noteedit;
