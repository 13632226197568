import React, { useState } from "react";
import {
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import { auth } from "../firebase/firebase";
import "../css/LoginPage.css";
import jumpingImage from "../assets/img/Transhumans - Mechanical Love.svg";
import logo from "../assets/img/logo.svg";
import { checkSubscriptionStatus } from "../utils/subscription";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    setLoadingMessage("Logging in...");

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      if (!user.emailVerified) {
        setError("Email verification required. Please check your email.");
        await sendEmailVerification(user);
        setLoading(false);
        return;
      }

      setLoadingMessage("Checking subscription status...");
      const subscription = await checkSubscriptionStatus();

      if (
        !subscription ||
        (subscription.status !== "active" &&
          subscription.status !== "on_trial" &&
          subscription.status !== "trialing" &&
          !(
            subscription.status === "cancelled" &&
            new Date(subscription.currentPeriodEnd) > new Date()
          ))
      ) {
        navigate("/subscription");
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Login failed:", error);
      setError("Login failed. Please check your email and password.");
    } finally {
      setLoading(false);
      setLoadingMessage("");
    }
  };

  return (
    <div className="login-container">
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
      <div className="login-image">
        <img src={jumpingImage} alt="Jumping Character" />
      </div>
      <div className="login-form-container">
        <div className="login-box">
          <Link
            to="/"
            style={{ position: "absolute", top: "20px", left: "20px" }}
          >
            <img
              src={logo}
              alt="RedoNote Logo"
              style={{
                width: "60px",
                height: "60px",
              }}
            />
          </Link>
          <h1 className="login-title">Login</h1>
          <form onSubmit={handleLogin} className="login-form">
            <div className="input-group">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor="password">Password</label>
              <div
                className="password-input-wrapper"
                style={{ position: "relative" }}
              >
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                    padding: "5px",
                  }}
                >
                  <img
                    src={
                      showPassword
                        ? `${process.env.PUBLIC_URL}/toolbaricons/inuse/eye-off.svg`
                        : `${process.env.PUBLIC_URL}/toolbaricons/inuse/eye.svg`
                    }
                    alt={showPassword ? "Hide password" : "Show password"}
                    style={{ width: "20px", height: "20px" }}
                  />
                </button>
              </div>
            </div>
            {error && <p className="error-message">{error}</p>}
            <button
              type="submit"
              className="login-button"
              disabled={loading}
              style={{
                backgroundColor: "#fae150",
                color: "#231c16",
                border: "1px solid #231c16",
                borderRadius: "24px",
                padding: "10px",
                fontSize: "14px",
                fontWeight: "600",
                cursor: loading ? "not-allowed" : "pointer",
                width: "100%",
                transition: "background-color 0.2s ease",
                opacity: loading ? 0.7 : 1,
              }}
            >
              {loading ? "Logging in..." : "Login"}
            </button>
          </form>
          <div className="login-options">
            <Link to="/forgot-password" className="forgot-password">
              Forgot Password?
            </Link>
            <p className="signup-prompt">
              Don't have an account? <Link to="/signup">Sign Up</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
