import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useCallback,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useCurriculum } from "../contexts/CurriculumContext";
import arrowDown from "../assets/icons/inuse/caret-right.svg";
import arrowRight from "../assets/icons/inuse/caret-down.svg";
import { v4 as uuidv4 } from "uuid";
import ContextMenu from "./ContextMenu";
import LoadingSpinner from "./LoadingSpinner";
import { useTable } from "../contexts/TableContext";
import { useImage } from "../contexts/ImageContext";
import EventEmitter from "../utils/eventEmitter";

const LeftBar = forwardRef(
  (
    {
      isOpen,
      onToggle,
      onWidthChange,
      style,
      currentUnitId,
      currentSubunit,
      showSections,
      showChapters,
    },
    ref
  ) => {
    const {
      curriculum,
      handleSubunitNameChange,
      reorderSubunits,
      deleteSubunit,
      addNewSubunit,
    } = useCurriculum();
    const { setCurrentIds: setTableIds } = useTable();
    const { setCurrentIds: setImageIds } = useImage();
    const navigate = useNavigate();
    const { courseId, courseName, subunitId } = useParams();

    const sidebarContentRef = useRef(null);
    const [expandedUnits, setExpandedUnits] = useState({});
    const [editingSubunit, setEditingSubunit] = useState(null);
    const [contextMenu, setContextMenu] = useState({
      visible: false,
      x: 0,
      y: 0,
      subunitInfo: null,
    });

    // Toggle Sidebar
    const toggleSidebar = () => {
      if (onToggle) {
        onToggle(!isOpen);
      }
    };

    // Handle Subunit Click
    const handleSubunitClick = useCallback(
      (unit, subunit) => {
        // Sync all states with clicked subunit
        const newSubunitId = subunit.id;

        // 1. Update URL
        navigate(
          `/courses/${courseId}/curriculum/${courseName}/unit/${unit.unitId}/subunit/${newSubunitId}`
        );

        // 2. Update Context
        setTableIds(courseId, newSubunitId);
        setImageIds(courseId, newSubunitId);
      },
      [courseId, navigate, courseName, setTableIds, setImageIds, subunitId]
    );

    // Add Unit Toggle Handler
    const toggleUnit = (unitId) => {
      setExpandedUnits((prev) => ({
        ...prev,
        [unitId]: !prev[unitId],
      }));
    };

    // Automatically set toggle state of current selected unit
    useEffect(() => {
      if (currentUnitId) {
        setExpandedUnits((prev) => ({
          ...prev,
          [currentUnitId]: true,
        }));
      }
    }, [currentUnitId]);

    // Add useEffect for initial selection state
    useEffect(() => {
      if (subunitId && currentUnitId) {
        const currentSubunitData = curriculum.subunits[currentUnitId]?.find(
          (subunit) => subunit.id === subunitId
        );
        if (currentSubunitData) {
          setExpandedUnits((prev) => ({
            ...prev,
            [currentUnitId]: true,
          }));
        }
      }
    }, [subunitId, currentUnitId, curriculum.subunits]);

    const onDragEnd = (result) => {
      if (!result.destination) return;

      const sourceUnitId = result.source.droppableId;
      const destUnitId = result.destination.droppableId;
      const sourceIndex = result.source.index;
      const destIndex = result.destination.index;

      reorderSubunits(sourceUnitId, destUnitId, sourceIndex, destIndex);
    };

    // Add handler for drag start
    const onDragStart = () => {
      // Logic needed when drag starts
    };

    // Modify handler for drag update
    const onDragUpdate = (update) => {
      if (update.destination) {
        const targetUnitId = update.destination.droppableId;
        // Auto expand unit when dragged item is over it
        setExpandedUnits((prev) => ({
          ...prev,
          [targetUnitId]: true,
        }));
      } else if (update.over) {
        // When dragged item is over a closed unit
        const hoveredUnitId = update.over.droppableId;
        setExpandedUnits((prev) => ({
          ...prev,
          [hoveredUnitId]: true,
        }));
      }
    };

    // Handle Context Menu Display
    const handleContextMenu = (e, unit, subunit) => {
      e.preventDefault();
      setContextMenu({
        visible: true,
        x: e.clientX,
        y: e.clientY,
        subunitInfo: { unitId: unit.unitId, subunitId: subunit.id },
      });
    };

    // Close Context Menu
    const handleCloseContextMenu = () => {
      setContextMenu({ visible: false, x: 0, y: 0, subunitInfo: null });
    };

    // Handle Subunit Delete
    const handleDeleteSubunit = () => {
      if (contextMenu.subunitInfo) {
        const { unitId, subunitId } = contextMenu.subunitInfo;

        // 현재 유닛의 서브유닛들 가져오기
        const currentUnitSubunits = curriculum.subunits[unitId] || [];
        const subunitIndex = currentUnitSubunits.findIndex(
          (subunit) => subunit.id === subunitId
        );

        // 다음에 표시할 서브유닛 결정
        let nextSubunit = null;
        if (currentUnitSubunits.length > 1) {
          nextSubunit =
            currentUnitSubunits[subunitIndex + 1] ||
            currentUnitSubunits[subunitIndex - 1] ||
            currentUnitSubunits[0];
        }

        // 1. 커리큘럼에서 서브유닛 삭제
        deleteSubunit(unitId, subunitId);

        // 2. 서브유닛 삭제 이벤트 발생 (테이블 데이터와 관련 데이터 모두 삭제)
        setTimeout(() => {
          EventEmitter.emit("subunitDeleted", {
            courseId,
            subunitId,
          });
        }, 0);

        // 3. 네비게이션 처리
        if (currentSubunit?.id === subunitId || subunitId === subunitId) {
          if (nextSubunit) {
            navigate(
              `/courses/${courseId}/curriculum/${courseName}/unit/${unitId}/subunit/${nextSubunit.id}`
            );
          } else {
            navigate(`/courses/${courseId}/curriculum/${courseName}`);
          }
        }
      }
      handleCloseContextMenu();
    };

    // Add useEffect for scrolling to selected subunit
    useEffect(() => {
      if (!isOpen) return;

      // Add slight delay to allow DOM updates
      const timer = setTimeout(() => {
        const selectedElement = document.querySelector(
          ".leftbar-subunit.selected"
        );
        if (selectedElement) {
          selectedElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 300);

      return () => clearTimeout(timer);
    }, [isOpen, currentUnitId, currentSubunit?.id, subunitId]);

    return (
      <DragDropContext
        onDragStart={onDragStart}
        onDragUpdate={onDragUpdate}
        onDragEnd={onDragEnd}
      >
        <div
          ref={ref}
          className={`left-bar ${isOpen ? "open" : "closed"}`}
          style={style}
        >
          <div className="sidebar-header">
            <button className="toggle-button" onClick={toggleSidebar}>
              {isOpen ? "<<" : ">>"}
            </button>
          </div>

          {isOpen && (
            <div className="leftbar-content" ref={sidebarContentRef}>
              <h2 className="leftbar-course-title">{courseName}</h2>
              <div className="leftbar-scroll">
                {curriculum.sections?.map((sectionName, sectionIndex) => (
                  <div key={sectionName} className="leftbar-section">
                    {/* Section */}
                    {showSections && (
                      <div className="section-header">
                        <span>{sectionName}</span>
                      </div>
                    )}

                    {/* Chapter */}
                    {curriculum.chapters[sectionIndex]?.map(
                      (chapterName, chapterIndex) => {
                        // Generate unit key
                        const unitKey = `${sectionIndex}-${chapterIndex}`;
                        const unitsInChapter = curriculum.units[unitKey] || [];

                        return (
                          <div
                            key={`${sectionIndex}-${chapterName}`}
                            className="leftbar-chapter"
                          >
                            {showChapters && (
                              <div className="chapter-header">
                                <span>{chapterName}</span>
                              </div>
                            )}

                            {/* Unit */}
                            {unitsInChapter.map((unit) => (
                              <div key={unit.unitId} className="leftbar-unit">
                                <Droppable droppableId={unit.unitId}>
                                  {(provided, snapshot) => (
                                    <div>
                                      <div
                                        className={`leftbar-unit-header ${
                                          snapshot.isDraggingOver
                                            ? "drag-over"
                                            : ""
                                        }`}
                                        onClick={() => toggleUnit(unit.unitId)}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            width: "100%",
                                          }}
                                        >
                                          <span className="leftbar-unit-name">
                                            {unit.unitName}
                                          </span>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "4px",
                                            }}
                                          >
                                            <button
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();

                                                const currentSubunits =
                                                  curriculum.subunits[
                                                    unit.unitId
                                                  ] || [];

                                                addNewSubunit(unit.unitId);

                                                setExpandedUnits((prev) => ({
                                                  ...prev,
                                                  [unit.unitId]: true,
                                                }));
                                              }}
                                              style={{
                                                border: "none",
                                                background: "none",
                                                padding: "2px",
                                                cursor: "pointer",
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <img
                                                src="/toolbaricons/inuse/add.svg"
                                                alt="Add subunit"
                                                style={{
                                                  width: "14px",
                                                  height: "14px",
                                                  filter: "invert(1)",
                                                }}
                                              />
                                            </button>
                                            <img
                                              src={
                                                expandedUnits[unit.unitId]
                                                  ? arrowDown
                                                  : arrowRight
                                              }
                                              alt="toggle"
                                              className="leftbar-unit-toggle-icon"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      {/* Subunit List Section */}
                                      <div
                                        className={`subunit-list ${
                                          !expandedUnits[unit.unitId]
                                            ? "collapsed"
                                            : ""
                                        }`}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                      >
                                        {curriculum.subunits[unit.unitId]?.map(
                                          (subunit, index) => (
                                            <Draggable
                                              key={subunit.id}
                                              draggableId={subunit.id}
                                              index={index}
                                            >
                                              {(provided) => (
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  className={`leftbar-subunit ${
                                                    currentUnitId ===
                                                      unit.unitId &&
                                                    subunitId === subunit.id
                                                      ? "selected"
                                                      : ""
                                                  }`}
                                                  style={{
                                                    ...provided.draggableProps
                                                      .style,
                                                    display: expandedUnits[
                                                      unit.unitId
                                                    ]
                                                      ? "block"
                                                      : "none",
                                                    cursor: "default",
                                                  }}
                                                  onClick={() =>
                                                    handleSubunitClick(
                                                      unit,
                                                      subunit
                                                    )
                                                  }
                                                  onContextMenu={(e) =>
                                                    handleContextMenu(
                                                      e,
                                                      unit,
                                                      subunit
                                                    )
                                                  }
                                                  onDoubleClick={(e) => {
                                                    e.stopPropagation();
                                                    setEditingSubunit(
                                                      subunit.id
                                                    );
                                                  }}
                                                >
                                                  {editingSubunit ===
                                                  subunit.id ? (
                                                    <input
                                                      type="text"
                                                      value={subunit.name}
                                                      onClick={(e) =>
                                                        e.stopPropagation()
                                                      }
                                                      onChange={(e) => {
                                                        const newValue =
                                                          e.target.value.trim() ===
                                                          ""
                                                            ? "New Subunit"
                                                            : e.target.value;
                                                        handleSubunitNameChange(
                                                          unit.unitId,
                                                          subunit.id,
                                                          newValue
                                                        );
                                                      }}
                                                      onBlur={() => {
                                                        if (
                                                          subunit.name.trim() ===
                                                          ""
                                                        ) {
                                                          handleSubunitNameChange(
                                                            unit.unitId,
                                                            subunit.id,
                                                            "New Subunit"
                                                          );
                                                        }
                                                        setEditingSubunit(null);
                                                      }}
                                                      onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                          if (
                                                            subunit.name.trim() ===
                                                            ""
                                                          ) {
                                                            handleSubunitNameChange(
                                                              unit.unitId,
                                                              subunit.id,
                                                              "New Subunit"
                                                            );
                                                          }
                                                          setEditingSubunit(
                                                            null
                                                          );
                                                        }
                                                      }}
                                                      autoFocus
                                                    />
                                                  ) : (
                                                    <span>{subunit.name}</span>
                                                  )}
                                                </div>
                                              )}
                                            </Draggable>
                                          )
                                        )}
                                        {provided.placeholder}
                                      </div>
                                    </div>
                                  )}
                                </Droppable>
                              </div>
                            ))}
                          </div>
                        );
                      }
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        {contextMenu.visible && (
          <ContextMenu
            x={contextMenu.x}
            y={contextMenu.y}
            onClose={handleCloseContextMenu}
            menuItems={[
              {
                label: "Delete Subunit",
                onClick: handleDeleteSubunit,
              },
            ]}
          />
        )}
      </DragDropContext>
    );
  }
);

export default LeftBar;
